import React from 'react';

import BodyTextArray from "../../../../../_common/text/body-text/array";
import { DeclineReason } from "../../../api/interfaces";
import H4Serif from "../../../../../_common/text/heading/h4/serif";
import { optimizePhoneUrl } from "../../../utils/quoteUtils";
import defaultDeclineStrings from '../../../data/defaultDeclineStrings.json';

export interface Props {
    partnerInfo: any,
    declineReason?: DeclineReason,
    isAgent?: boolean
}

const TEAM_EMAIL = "team@bestow.com";

const DeclineTemplate: React.FC<Props> = ({ declineReason = "", partnerInfo, children }) => {
    const declineStrings: any = partnerInfo?.declineStrings || defaultDeclineStrings;
    const {title, description} = declineStrings[declineReason] || declineStrings["default"];

    // KBM - TODO: Need to find a better place for this, or can we re-do this?
    const formattedDescription = description.map((item: string) => item
        .replace(/@EmailLink/g, `<a href="mailto:${partnerInfo?.email}">${partnerInfo?.email}</a>`)
        .replace(/@PhoneLink/g, `<a href="tel:${optimizePhoneUrl(partnerInfo?.telNumber || "")}">${partnerInfo?.telNumber}</a>`)
        .replace(/@Partner/g, partnerInfo?.name || "")
        .replace(/@BestowEmail/g, TEAM_EMAIL)
        .replace(/@DisplayName/g, partnerInfo?.displayName ? `<strong>${partnerInfo.displayName}</strong>` : "your agent"));

    return (
        <div>
            <H4Serif className="text-navy my-6 font-bold text-center">
                {title}
            </H4Serif>
            <BodyTextArray data={formattedDescription} classes="mb-6 text-center" />
            {children}
        </div>
    )
};

export default DeclineTemplate;