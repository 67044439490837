import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QuoteProps } from '../../..';
import QuoteForm from '../../../form';
import QuoteMobileOptimizationForm from '../../MOE-335-QuoteMobileOptimization/QuoteMobileOptimizationForm';
import NoHealthQuoteForm from '../../MOE-404-NohealthQuote/nhQuoteForm';

// This component routes the customer to the correct version of the <QuoteForm> component.
// It is conditional based on our experiment flags.

const QuoteFormExperimentRouter: React.FC<QuoteProps> = ({
    quoteMobileOptimizationExists = false,
    agentCode = null,
}) => {
    const { moe404NoHealthQuote } = useFlags();

    let quoteForm = <QuoteForm agentCode={agentCode} />;
    if (moe404NoHealthQuote) {
        quoteForm = <NoHealthQuoteForm />; // MOE-404
    } else if (quoteMobileOptimizationExists) {
        quoteForm = <QuoteMobileOptimizationForm />; //MOE-335
    }
    return quoteForm;
};
export default QuoteFormExperimentRouter;
