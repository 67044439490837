import React, {useMemo} from 'react';
import Slider from "@material-ui/core/Slider";
import numeral from "numeral";

import {Product} from "../../../api/interfaces";
import SecondaryButtonLight from "../../../../../_common/button/secondary/light";
import SmallText from "../../../../../_common/text/small-text/index";
import {trackCoverageSelect} from "../../../api/analytics";
import useQueryParamData from "../../../hooks/useQueryParamsData";

import plusIcon from "../../../../../../images/icons/plus.svg";
import minusIcon from "../../../../../../images/icons/minus.svg";

import './coverageSlider.scss';

interface Props {
    product: Product
    coverageAmount: string,
    setCoverageAmount: (coverageAmount: string) => void
}

const CoverageSlider: React.FC<Props> = ({ product, coverageAmount, setCoverageAmount }) => {
    const queryParams = useQueryParamData();

    // Generate the "marks" for the Slider
    const { coverageAmounts, marks, min, max } = useMemo(() => {
        // Only show the coverage amounts that are greater than the mincoverage from query params, assuming it's a valid value
        const originalCoverageAmounts  = Object.keys(product);
        const filteredCoverageAmounts = originalCoverageAmounts.filter(amount => parseInt(amount, 10) >= (queryParams.mincoverage || 0));
        const coverageAmounts = filteredCoverageAmounts.length ? filteredCoverageAmounts : originalCoverageAmounts;
        const marks = coverageAmounts.map(amount => ({ value: parseInt(amount, 10) }));
        const min = marks[0]?.value;
        const max = marks[marks.length - 1]?.value;
        return { coverageAmounts, marks, min, max }
    }, [product, queryParams]);

    const index = coverageAmounts.indexOf(coverageAmount);

    const updateCoverage = (newValue: string) => {
        setCoverageAmount(newValue);
        trackCoverageSelect(newValue);
    };

    return (
        <div className="coverage-slider">
            <div className="flex sm:mx-14">
                <div className="flex-1 text-left uppercase">
                    <SmallText className="font-normal" data-testid="quote-min-coverage-label">
                        {numeral(min).format('$0[.]0a')}
                    </SmallText>
                </div>
                <div className="uppercase">
                    <SmallText className="font-normal" data-testid="quote-max-coverage-label">
                        {numeral(max).format('$0.0a')}
                    </SmallText>
                </div>
            </div>
            <div className="flex">
                <div className="items-center hidden sm:flex">
                    <SecondaryButtonLight disabled={index === 0} onClick={() => updateCoverage(coverageAmounts[index - 1])} data-testid="quote-coverage-down-btn">
                        <img src={minusIcon} alt="decrease" className="value-toggle-btn" />
                    </SecondaryButtonLight>
                </div>
                <div className="flex-1 pt-1 sm:mx-4">
                    <div className="flex items-center">
                        <div className="slider-cap bg-charcoal" />
                        <div className="flex-1">
                            <Slider
                                data-testid="coverage-slider"
                                aria-label="quote-adjuster-coverage-slider"
                                aria-labelledby="discrete-slider-restrict"
                                step={null}
                                valueLabelDisplay="off"
                                marks={marks}
                                min={min}
                                max={max}
                                onChange={(event: any, newValue: number | number[]) => updateCoverage(newValue.toString())}
                                // ThumbComponent={SliderThumb}
                                value={parseInt(coverageAmount, 10)}
                            />
                        </div>
                        <div className="slider-cap bg-gray-500 h-full" />
                    </div>
                </div>
                <div className="items-center hidden sm:flex">
                    <SecondaryButtonLight disabled={index === coverageAmounts.length - 1} onClick={() => updateCoverage(coverageAmounts[index + 1])} data-testid="quote-coverage-up-btn">
                        <img src={plusIcon} alt="increase" className="value-toggle-btn" />
                    </SecondaryButtonLight>
                </div>
            </div>
        </div>

    );
};

export default CoverageSlider;