import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

import { BlogRightArrow } from "../../../../images/svgs";
import placeholder from '../../../../images/svgs/blog/placeholder.svg';

import './index.scss';

interface Props {
    title: string,
    imageUrl?: string,
    postSlug: string,
    readTime: string,
    isLearningCenter?: boolean
}

const SinglePost: React.FC<Props> = ({title, imageUrl, postSlug, readTime, isLearningCenter}) => {
    return (
        <div className="single-post flex mb-2 transition">
            <Link to={`/${isLearningCenter ? "learningcenter" : "blog"}/${postSlug}`} className="no-underline text-charcoal flex-1 flex flex-col">
                <LazyLoad offset={200} once>
                    <div className="single-post-img bg-cover bg-center relative" style={{backgroundImage: `url(${imageUrl ?? placeholder})`}}>
                        <div className="single-post-img-overlay transition absolute inset-0 bg-opacity-0 bg-navy flex justify-center items-center">
                            <BlogRightArrow className="opacity-0 transition" />
                        </div>
                    </div>
                    {/*<div className="aspect-ratio-box mb-4">*/}
                    {/*    <div className="aspect-ratio-box-inner flex relative">*/}
                    {/*        <img className="w-full object-cover" src={imageUrl ?? 'https://via.placeholder.com/325x216'} />*/}
                    {/*        <div className="single-post-img-overlay transition absolute inset-0 bg-opacity-0 bg-navy flex justify-center items-center">*/}
                    {/*            <BlogRightArrow className="opacity-0 transition" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </LazyLoad>
                {/*<div className="single-post-img bg-cover bg-center relative" style={{backgroundImage: `url(${imageUrl ?? placeholder})`}}>*/}
                {/*    <div className="single-post-img-overlay transition absolute inset-0 bg-opacity-0 bg-navy flex justify-center items-center">*/}
                {/*        <BlogRightArrow className="opacity-0 transition" />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="flex flex-col flex-1 p-2">
                    <h6 className="text-sm font-sans font-medium flex-1" dangerouslySetInnerHTML={{__html: title}} />
                    {readTime &&
                        <span className="text-xs font-light mt-4 font-sans">
                            {readTime} Minute Read
                        </span>
                    }
                </div>
            </Link>
        </div>
    );
};

export default SinglePost;
