import React from 'react';
import { useFormikContext } from 'formik';
import {string, object} from 'yup';
import CustomFormikField from "../../../../components/_common/formik/customField";
import OutsideLabelInput from "../../../../components/_common/inputs/outsideLabel";
import RadioGroup from "../../../../components/_common/inputs/radio-group";
import BodyTextBold from '../../../../components/_common/text/body-text/bold';
import Subtitle1 from '../../../../components/_common/text/heading/subtitle1';
import TextFieldLabel from '../../../../components/_common/text/textFieldLabel';

export const agentQuestionsSchema = object().shape({
    isAffiliatedWithGroups: string().required("Please choose an option"),
    affiliatedGroups: string().when('isAffiliatedWithGroups', {
        is: val => val === 'yes',
        then: string().required('Listing BGA/IMO groups is required'),
        else: string()
    })
});

const AgentQuestions: React.FC = () => {
    const { values } = useFormikContext<any>();

    return (
        <div data-testid="affiliates-agent-questions">
            <Subtitle1 className="mb-3 text-center sm:text-left">
                Agent Questions
            </Subtitle1>
            <BodyTextBold className="mb-6 text-center sm:text-left">
                If you are a life insurance agent please answer some additional questions.
            </BodyTextBold>
            <div className="mb-8">
                <TextFieldLabel className="text-gray-800 mb-4">
                    Are you affiliated with any BGA/IMO groups?<span className="text-error">*</span>
                </TextFieldLabel>
                <CustomFormikField
                    CustomComponent={RadioGroup}
                    options={[
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                    ]}
                    name="isAffiliatedWithGroups"
                    required />
            </div>
            <div className="mb-8">
                <CustomFormikField
                    CustomComponent={OutsideLabelInput}
                    name="affiliatedGroups"
                    className="flex-1"
                    label="Which BGA/IMO groups are you currently affiliated with?"
                    placeholder="Enter answer here"
                    rows={4}
                    required={values.isAffiliatedWithGroups === 'yes'}
                />
            </div>
        </div>
    );
};

export default AgentQuestions;
