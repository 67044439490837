import React from 'react';

import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import BodyTextLarge from "../../../components/_common/text/body-text/large";
import BodyText from "../../../components/_common/text/body-text";

export type QuoteTextType = string;

export interface QuoteTextProps {
    quoteText?: QuoteTextType
}

const QuoteText: React.FC<QuoteTextProps> = ({quoteText}) => {
    return (
        <>
            {quoteText &&
                <PageSection>
                    <Grid>
                        <BodyTextLarge data-cy="quote.hero.subtitle.desktop" className="text-center hidden sm:block sm:col-span-full sm:mx-12 sm:mt-24 lg:mt-32 lg:mb-3 lg:mx-0 lg:col-start-2 lg:col-span-10">
                            {quoteText}
                        </BodyTextLarge>
                        <BodyText data-cy="quote.hero.subtitle.mobile" className="text-center sm:hidden col-span-full mt-16 mb-8">
                            {quoteText}
                        </BodyText>
                    </Grid>
                </PageSection>
            }
        </>
    );
};

export default QuoteText;
