import React from 'react';
import cx from 'classnames';
import { useFormikContext } from 'formik';

import TextFieldQuestion from "../../text/textFieldQuestion";
import HelperText from "../../text/helperText";
import { FormikCustomFieldCommonProps } from "../";
import RadioButton from "../radioBtn";

import './index.scss';

export interface Props extends FormikCustomFieldCommonProps {
    options: {
        label: string,
        value: string
    }[],
    RadioButtonType: any
}

const RadioGroup: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
    className = "",
    label,
    error,
    helper,
    required,
    name = "",
    value,
    onChange,
    options = [],
    RadioButtonType = RadioButton,
    ...otherProps
}) => {
    const { setFieldValue } = useFormikContext();

    const classes = cx("radio-group", className, {
        error
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(name, event.target.value, true);
    };

    return (
        <>
            {label &&
                <TextFieldQuestion className="mb-2 text-gray-800">
                    {label}{required && <span className="text-error text-base">*</span>}
                </TextFieldQuestion>
            }
            <div className={classes} role="group">
                {options.map((option, idx) => (
                    <div
                        key={`${name}-${idx}`}
                        className={cx('flex items-center', {
                                'mb-3': idx !== options.length - 1
                        })}>
                        <RadioButtonType
                            name={name}
                            value={option.value}
                            onChange={handleChange}
                            checked={value === option.value}
                            {...otherProps}>
                            {option.label}
                        </RadioButtonType>
                    </div>
                ))}
            </div>
            {helper &&
                <div className="mt-3">
                    <HelperText error={error}>
                        {helper}
                    </HelperText>
                </div>
            }
        </>
    )
};

export default RadioGroup;
