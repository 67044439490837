import React from 'react';
import cx from 'classnames';

import PageSection from "../../../section/page";
import H2Serif from "../../../text/heading/h2/serif";
import BodyTextLarge from "../../../text/body-text/large";

interface Props {
    title: any,
    text: string,
    className?: string,
}

const CTAModuleTwo: React.FC<Props> = ({
    className = "",
    title,
    text,
    children
}) => {
    const classes = cx("py-16 sm:pt-24 sm:pb-24 lg:pt-28 lg:pb-28", className);

    return (
        <PageSection className={classes}>
            <H2Serif className="mb-4 text-center text-navy">
                {title}
            </H2Serif>
            <BodyTextLarge className="mb-16 text-navy text-center">
                {text}
            </BodyTextLarge>
            <div className="flex justify-center">
                {children}
            </div>
        </PageSection>
    );
};

export default CTAModuleTwo;
