import React from 'react';

import PrimaryButton from "../";
import { Props } from '../../index';

import './index.scss';

const PrimaryButtonSmall: React.FC<Props> = ({ className = "", ...otherProps }) => {
    return (
        <PrimaryButton className={`primary-btn-small ${className}`} {...otherProps} />
    )
};

export default PrimaryButtonSmall;

