import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import PageSection from '../../../components/_common/section/page';
import Grid from '../../../components/_common/grid';
import Quote from '../../../components/quote/new';
import H2Serif from '../../../components/_common/text/heading/h2/serif';
import QuoteValueProp from './value-prop';
import Subtitle2 from '../../../components/_common/text/heading/subtitle2';

import trustedIco from '../../../images/icons/icon-badgecheck.svg';
import affordableIco from '../../../images/icons/icon-money.svg';
import fastIco from '../../../images/icons/Intant_Clock2Tone 1.svg';

const valueProps = [
    {
        img: fastIco,
        imgAlt: 'Fast',
        title: 'Fast',
        description:
            'Free quote in seconds, apply in minutes. If approved, activate coverage today.',
    },
    {
        img: affordableIco,
        imgAlt: 'Affordable',
        title: 'Affordable',
        description:
            'Find rates that meet your needs and fit your budget – starting from just $10/mo.',
    },
    {
        img: trustedIco,
        imgAlt: 'Trusted',
        title: 'Trusted',
        description:
            'Rated "excellent" by real customers on Trustpilot. Policies are issued by an A+ rated carrier.',
    },
];

interface Props {
    setIsQuoteInView: Function;
}

const QuoteHeader: React.FC<Props> = ({ setIsQuoteInView }) => {
    const { ref, inView } = useInView({});

    useEffect(() => {
        console.log('inView', inView);
        setIsQuoteInView(inView);
    }, [inView, setIsQuoteInView]);

    return (
        <PageSection className="pt-16 bg-sage-light mb-16">
            <Grid
                className="pt-8 sm:pt-12 sm:pb-12 lg:pt-9 lg:pb-16 xl:py-12"
                smGap={8}
                lgGap={8}
                xlGap={8}
            >
                <div
                    className="col-span-full sm:col-span-4 lg:col-span-6 xl:col-start-2 xl:col-span-5 relative quote-widget"
                    ref={ref}
                >
                    <div className="sm:hidden mb-8">
                        <div className="pb-4 sm:pb-5 lg:pb-3 text-center">
                            <H2Serif className="text-navy">
                                Get your free quote
                            </H2Serif>
                        </div>
                        <div className="sm:pb-12 text-center">
                            <Subtitle2 className="text-navy">
                                Affordable term life insurance
                            </Subtitle2>
                        </div>
                    </div>
                    <Quote
                        className="w-full filter drop-shadow-quote"
                        isAgent={false}
                    />
                </div>
                <div className="col-span-full sm:col-span-4 lg:col-span-6 xl:col-start-8 xl:col-span-5 mb-2 sm:mb-0 mt-8 sm:mt-2 lg:mt-16 xl:-mr-8">
                    <div className="hidden sm:block">
                        <div className="sm:pb-5 lg:pb-3">
                            <H2Serif className="text-navy">
                                Get your free quote
                            </H2Serif>
                        </div>
                        <div className="sm:pb-12">
                            <Subtitle2 className="text-navy">
                                Affordable term life insurance
                            </Subtitle2>
                        </div>
                    </div>
                    {valueProps.map((valueProp, index) => (
                        <QuoteValueProp
                            key={`quote-value-prop-${index}`}
                            {...valueProp}
                        />
                    ))}
                </div>
            </Grid>
        </PageSection>
    );
};

export default QuoteHeader;
