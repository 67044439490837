import React from 'react';

import PageContent from "../../components/_common/pageContent";
import PageSection from "../../components/_common/section/page";
import Grid from "../../components/_common/grid";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import BodyText from "../../components/_common/text/body-text";
import LegalText from "../../components/_common/text/legal-text";
import BodyTextLarge from '../../components/_common/text/body-text/large';
import Subtitle2 from '../../components/_common/text/heading/subtitle2';
import SecondaryButtonCharcoal from '../../components/_common/button/secondary/charcoal';
import Divider from '../../components/_common/divider';
import Link from '../../components/_common/link';

import { partners } from './data';

const MeetOurPartners: React.FC = () => {
    return (
        <PageContent includeBottomPadding>
            <PageSection className="py-10 sm:py-16 mb-12 lg:mb-16 bg-navy">
                <Grid>
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10">
                        <H1Serif className="text-center mx-8 sm:mx-0 mb-12 sm:mb-6 text-white">
                            Meet our partners
                        </H1Serif>
                        <BodyTextLarge className="mb-4 mx-4 sm:mx-0 sm:text-center text-white">
                            We're always looking for new ways to make a positive difference
                            in people's lives. That's why we've teamed up with these like-minded
                            companies, who may be able to lend you a hand.
                        </BodyTextLarge>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="mb-28">
                {partners.map(({Logo, title, body, linkUrl}) => (
                    <Grid className="mb-16">
                        <div className="col-span-full sm:col-span-3 lg:col-span-4">
                            <Link href={linkUrl} className="border-0" newTab>
                                <img src={Logo} className="mb-6 sm:mb-0 w-full h-auto" />
                            </Link>
                        </div>
                        <div className="col-span-full sm:col-span-5 lg:col-span-8">
                            <Subtitle2 className="text-center mb-6 sm:text-left" dangerouslySetInnerHTML={{__html: title}} />
                            <BodyText className="mb-6">
                                <span dangerouslySetInnerHTML={{__html: body}} />
                            </BodyText>
                            <div className="flex justify-center sm:justify-start">
                                <SecondaryButtonCharcoal href={linkUrl}>
                                    Learn More
                                </SecondaryButtonCharcoal>
                            </div>
                        </div>
                    </Grid>
                ))}
            </PageSection>
            <PageSection>
                <Divider className="border-gray-500" />
                <LegalText className="mt-6">
                    T/C's
                    <br /><br />
                    Paid non-client endorsement. See Apple App Store and Google Play reviews. View important {' '}
                    <Link href="https://www.stash.com/start-investing/bestowmarket" newTab>
                        disclosures
                    </Link>.
                    <br /><br />
                    <sup>1</sup>Stash Stock-Back® Rewards is not sponsored or endorsed by Green Dot Bank, Green Dot Corporation, Visa U.S.A., or
                    any of their respective affiliates, and none of the foregoing has any responsibility to fulfill any stock rewards
                    earned through this program. What doesn’t count: Cash withdrawals, money orders, prepaid cards, and P2P payment. If
                    stock of the merchant is not available for a qualifying purchase, the security will be in shares of a predetermined
                    ETF or from a list of predetermined publicly-traded companies available on the Stash Platform. See full {' '}
                    <Link href="https://lp.stash.com/stash-stock-back-rewards-terms-and-conditions/" newTab>
                        terms and conditions
                    </Link>.
                    <br /><br />
                    Bestow provides you with accurate, reliable information. Some of the links in this post are from third party providers
                    who have sole financial responsibility for their products and services. Many or all of the companies featured here
                    provide compensation to Bestow. The information provided in this article is not intended to offer any tax, legal or
                    financial advice. It is always a good idea to consult your tax, legal and financial advisors regarding your specific
                    situation. Furthermore, this article does not ensure the availability of or your eligibility for any specific product.
                </LegalText>
            </PageSection>
        </PageContent>
    )
};

export default MeetOurPartners;
