import React from 'react';
import cx from 'classnames';
import { useField } from 'formik';
import TextFieldLabel from '../../text/textFieldLabel';
import HelperText from '../../text/helperText';
import { FormikCustomFieldCommonProps } from '../';

import './index.scss';

export interface Props extends FormikCustomFieldCommonProps {
    options: {
        label: string;
        value: string;
    }[];
}
/**
 * Alternative to radio group when just two options are available.
 * Renders them inline with a backslash bestween like... M / F
 */
const BinarySelector: React.FC<
    Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
    className = '',
    label,
    error,
    helper,
    required,
    name,
    value,
    onChange,
    options = [],
    ...otherProps
}) => {
    const [field, meta, helpers] = useField(name ?? '');

    const classes = cx('flex items-center', className, {
        error,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(event.target.value);
    };

    return (
        <div className="binary-selector flex flex-col items-center">
            {label && (
                <TextFieldLabel className="text-gray-800">
                    {label}
                    {required && <span className="text-error">*</span>}
                </TextFieldLabel>
            )}
            <div className={classes} role="group">
                <label
                    className={cx(
                        'items-center font-sans font-medium binary-item flex',
                    )}
                >
                    <input
                        value={options[0].value}
                        className="radio-button mr-3"
                        onChange={handleChange}
                        checked={value === options[0].value}
                        {...otherProps}
                        name={name}
                        type="radio"
                    />
                    <div className="mb-0 block binary-item-label flex items-center justify-center">
                        <span>{options[0].label}</span>
                    </div>
                </label>
                <div className="mx-3 sm:mx-1">
                    <svg
                        width="13"
                        height="23"
                        viewBox="0 0 13 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11 0.5H12.5L2 22.5H0.5L11 0.5Z"
                            fill="#514E4D"
                        />
                    </svg>
                </div>
                <label
                    className={cx(
                        'items-center font-sans font-medium binary-item flex',
                    )}
                >
                    <input
                        value={options[1].value}
                        className="radio-button mr-3"
                        onChange={handleChange}
                        checked={value === options[1].value}
                        {...otherProps}
                        type="radio"
                    />
                    <div className="mb-0 block binary-item-label flex items-center justify-center">
                        <span>{options[1].label}</span>
                    </div>
                </label>
            </div>
            {helper && (
                <div className="mt-3">
                    <HelperText error={error}>{helper}</HelperText>
                </div>
            )}
        </div>
    );
};

export default BinarySelector;
