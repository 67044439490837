import React from 'react';

import SmallText from '../../../components/_common/text/small-text/index';
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import BodyTextBold from "../../../components/_common/text/body-text/bold";

import './index.scss';

interface Props {
    className?: string,
    data: {
        state: string,
        status: string,
        producerLicense: string,
        tpaLicense: string
    }
}

const LicenseCard: React.FC<Props> = ({className = "", data}) => (
    <div className={`mb-2 sm:mb-6 ${className}`}>
        <SmallText className="mb-1 text-center uppercase sm:text-left">
            State
        </SmallText>
        <Subtitle2 className="license-card-title mb-1 text-center sm:text-left text-primary">
            {data.state}
        </Subtitle2>
        <div className="mt-2 mb-2 border-solid border-0 border-b border-coral"/>

        <SmallText className="mb-1 text-center uppercase sm:text-left">
            Open For Business
        </SmallText>
        <BodyTextBold className="mb-2 font-normal text-center sm:text-left">
            {data.status}
        </BodyTextBold>

        <SmallText className="mb-1 text-center uppercase sm:text-left">
            Producer License
        </SmallText>
        <BodyTextBold className="mb-2 font-normal text-center sm:text-left">
            {data.producerLicense}
        </BodyTextBold>

        <SmallText className="mb-1 text-center uppercase sm:text-left">
            TPA License
        </SmallText>
        <BodyTextBold className="mb-2 font-normal text-center sm:text-left">
            {data.tpaLicense}
        </BodyTextBold>
    </div>
);

export default LicenseCard;
