import React, { useEffect, useState, useMemo } from 'react';
import { includes } from 'lodash';
import { useDispatch } from 'react-redux';

import { useMenuSelector } from "../../../redux/menus/selectors";
import { fetchMenu } from '../../../redux/menus/index';
import useCategoryPostsFilter from "../../../hooks/useCategoryPostsFilter";
import useAllPostsFilter from "../../../hooks/useAllPostsFilter";
import Eyebrow from '../../../components/_common/text/eyebrow';
import Grid from '../../../components/_common/grid';
import Search from '../../../components/_common/inputs/search';
import SinglePost from './single-post';
import TabButton from '../../../components/_common/button/tab';
import LinkButton from '../../../components/_common/button/link';
import PaginationFilter from './pagination-filter';

import './index.scss';

interface Props {
    isLearningCenter?: boolean
}

const AllPosts: React.FC<Props> = ({ isLearningCenter }) => {
    const [numShowing, setNumShowing] = useState(30);
    const [selectedCategory, setSelectedCategory] = useState('blog');
    const [postsPerPage, setPostsPerPage] = useState(30);
    const [search, setSearch] = useState('');
    const allPosts = useAllPostsFilter(isLearningCenter, true);
    const postCategory = useCategoryPostsFilter(selectedCategory, isLearningCenter, true);
    const categories = useMenuSelector(_getMenuName(isLearningCenter));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!categories.slug) {
            dispatch(fetchMenu(_getMenuName(isLearningCenter)));
        }
    }, [categories, dispatch, isLearningCenter]);

    const posts = useMemo(() => {
        const postPool = selectedCategory === 'blog' ? allPosts : postCategory;

        if (search.length < 3) {
            const showing = Math.min(postPool.length, postsPerPage);
            setNumShowing(showing === -1 ? postPool.length : showing);
            return postPool;
        }

        const results = postPool.filter(post => {
            const title = post.title.rendered;
            const content = post.content.rendered;
            const excerpt = post.excerpt.rendered;

            return includes(title, search) ||
                includes(content, search) ||
                includes(excerpt, search);
        });

        setNumShowing(results.length);

        return results;
    }, [selectedCategory, allPosts, search, postCategory, postsPerPage]);

    const onSelctBlogCategory = (slug: string) => {
        setSelectedCategory(slug);
    };

    const onViewMore = () => {
        setNumShowing(numShowing + postsPerPage);
    };

    const onSelectPostsPerPage = (numPosts: number) => {
        setNumShowing(Math.min(numPosts, posts.length));
        setPostsPerPage(numPosts);
    };

    const onSelectShowAll = () => {
        setPostsPerPage(-1);
        // setNumShowing(posts?.length);
        // setSearch('');
    };

    return (
        <div className="blog-landing-all-posts">
            <Eyebrow className="text-center mb-10 mt-12 sm:mt-16 sm:mb-11 lg:mb-16 lg:mt-24">
                ALL POSTS
            </Eyebrow>
            <Grid cols={2} smCols={4} lgCols={6}>
                <div className="col-span-full sm:col-span-1">
                    <Eyebrow className="mb-4 sm:mb-2 text-xs sm:text-sm text-center sm:text-left filter-text">
                        FILTER BY
                    </Eyebrow>
                    <div className="flex sm:block justify-around flex-wrap ">
                        {categories.slug &&
                            categories.items.map((category: { title: string, slug: string }, index: number) => (
                                <div key={`tab-btn-${index}`} className="mb-2">
                                    <TabButton
                                        onClick={() => onSelctBlogCategory(category.slug)}
                                        className="category-tab-button mb-4 sm:mb-1 mx-1 sm:mx-0"
                                        selected={selectedCategory === category.slug}
                                    >
                                        <span className="text-sm sm:text-base font-sans leading-4">{category.title}</span>
                                    </TabButton>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="col-span-full sm:col-span-3 lg:col-span-5 mb-10">
                    <Grid cols={1} smCols={6} lgCols={10} className="flex justify-between mb-4 sm:mb-6 lg:mb-9">
                        <div className="sm:col-span-3 lg:col-span-6 hidden sm:block">
                            <PaginationFilter
                                numShowing={numShowing}
                                postsPerPage={postsPerPage}
                                totalNumPosts={posts.length}
                                onSelectShowAll={onSelectShowAll}
                                onSelectPostsPerPage={onSelectPostsPerPage}
                            />
                        </div>
                        <div className="sm:col-span-3 lg:col-span-4 mb-10 sm:mb-0 sm:-mt-1 px-8 sm:px-0">
                            <Search
                                placeholder={`Search ${isLearningCenter ? "Learning Center" : "Blog"}`}
                                className="w-full"
                                value={search}
                                onChange={e => setSearch((e.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div className="sm:hidden">
                            <PaginationFilter
                                numShowing={numShowing}
                                postsPerPage={postsPerPage}
                                totalNumPosts={posts.length}
                                onSelectShowAll={onSelectShowAll}
                                onSelectPostsPerPage={onSelectPostsPerPage}
                            />
                        </div>
                    </Grid>
                    <Grid cols={2} smCols={3} lgCols={5}>
                        {posts.slice(0, numShowing).map(post => (
                            <SinglePost
                                title={post.title.rendered}
                                postSlug={post.slug}
                                imageUrl={post._embedded[`wp:featuredmedia`]?.[0]?.media_details?.sizes?.['medium']?.source_url}
                                readTime={post.acf.time_to_read}
                                isLearningCenter={isLearningCenter}
                            />
                        ))}
                    </Grid>
                    {numShowing < posts.length &&
                        <div className="mt-10 flex text-sm justify-center sm:justify-end">
                            <LinkButton onClick={onViewMore}>
                                <div className="text-sm font-medium">View More</div>
                            </LinkButton>
                        </div>
                    }
                </div>
            </Grid>
        </div>
    )
};

function _getMenuName(isLearningCenter?: boolean) {
    return isLearningCenter ? "learning-center-categories" : "blog-categories";
}

export default AllPosts;
