export const privacyData = {
    body: [
        `For California residents: <a href='/ccpa-notice' class='font-medium'>California Consumer Privacy Act Privacy Notice</a>`
    ],
    sections: [
        {
            title: `At Bestow, we use data to modernize the life insurance application process`,
            body: [
                `Data is the engine behind all of your favorite technology. It provides the perfect playlist mash-up, recommends coupons for your top items, and even connects you with that childhood friend you haven’t seen in a long time. Data enables a personalized and engaging customer experience.`,
                `At Bestow, we believe data transparency is key. We want to make sure you understand how we collect, protect, and use your data. This privacy policy describes the privacy practices of Bestow Inc., Bestow Agency LLC dba Bestow Insurance Services in CA, and Bestow Life Insurance Company (known as Centurion Life Insurance Company in some states) (“<span class='font-medium'>Bestow</span>,” “<span class='font-medium'>we</span>”, “<span class='font-medium'>us</span>” or “<span class='font-medium'>our</span>”). `
            ]
        },
        {
            title: `How we use your personal data`,
            subSections: [
                {
                    title: `We use personal data to make it faster to apply for a life insurance policy`,
                    body: [
                        `Typical life insurance companies require forms, medical exams, and lots of your time. Bestow enables you to quickly apply for life insurance without a medical exam--all by relying on your personal data. With a few personal details we can quickly provide a life insurance premium estimate. When you apply for a policy, we ask for other information like your medical and financial history to determine your eligibility for a policy.`
                    ]
                },
                {
                    title: `We use personal data to protect you and your beneficiaries`,
                    body: [
                        `To prevent fraud, we ask for information that helps us verify your identity. This includes your name, social security number, drivers license or state identification number, financial information, and employment information. We also ask for information to help us verify your beneficiaries if you pass away. `
                    ]
                },
                {
                    title: `We use personal data to manage your policy`,
                    body: [
                        `Details like your mailing address, phone number, billing address, and email address make it easy to update your policy, make payments, receive policy updates, and perform other customer services.`,
                    ],
                },
                {
                    title: `We use personal data to improve your customer experience`,
                    body: [
                        `We may collect and use your personal data if you choose to submit feedback to help us evaluate and improve your customer experience. Participation is completely voluntary and may require personal data like your name, address, and zip code. For example, with your consent, we may post your reviews on our website along with your name.`,
                        `We may use your personal data for marketing and promotional purposes. For example, we may use your information, such as your email address, to send you newsletters, special offers, surveys, promotions, or other information we think may interest you.`,
                        `We also use your information to assist us in advertising on third-party websites and to evaluate the success of our advertising campaigns. Third-party cookies and other technologies are governed by their specific privacy policy, not this one.`,
                        `We may also use personal data to improve our products and services and for other internal business purposes, including detecting security incidents, research and analytics, improving site functionality, performing services on your behalf or that you request, and fulfilling transactions.`
                    ]
                }, // end Will we share your personal information with third parties or for marketing purposes?
            ]
        },
        {
            title: `How we collect your personal data`,
            subSections: [
                {
                    title: `Information collected automatically from your devices`,
                    body: [
                        `We use cookies (small bits of electronic information) to improve your website experience by personalizing content, making it easy to log in to your account, and speeding up performance. Cookies automatically collect the following information about your use of our site:`,
                        `<ul>
                            <li>Your domain name</li>
                            <li>Your browser type and operating system</li>
                            <li>Web pages you view</li>
                            <li>Links you click</li>
                            <li>Your IP address</li>
                            <li>The length of time you visit our site and/or use our services</li>
                            <li>The referring URL or the webpage that led you to our site.</li>
                        </ul>`,
                        `We also use cookies, session replay and other third party predictive analytic services and software to monitor and analyze your interactions with our site. We may combine this information with other information that we have collected about you.`
                    ]
                },
                {
                    title: `Information collected as part of the application`,
                    body: [
                        `If you get a quote or apply for a policy, we will collect information directly from you or with your authorization as described in the application. The types of information collected can vary including your birth date, gender, zip code, and health and lifestyle information. `
                    ]
                },
                {
                    title: `Third-Party analytics`,
                    body: [
                        `Our site may use services operated by third parties, such as Google Analytics, which uses cookies and similar technologies. This service may also collect information regarding the use of other websites, apps, and online resources. You can learn about Google’s practices by going to www.google.com/policies/privacy/partners/, and you can opt out of them by downloading the Google Analytics opt-out browser add-on, available at <a href='https://tools.google.com/dlpage/gaoptout' target="_blank" rel="noopener referrer">https://tools.google.com/dlpage/gaoptout</a>.`,
                    ],
                },
                {
                    title: `Social Plugins`,
                    body: [
                        `We use social plugins like Facebook, Twitter, Instagram, or other social plugins on our sites. When you view a site that contains a plugin, your browser will create a direct link to the third parties’ servers. If you are logged into your social media account, the social media platform will record the visit to your social media account. Please visit the social media platform’s privacy policy for more information.`
                    ]
                }
            ]
        },
        {
            title: `How we share your personal data`,
            subSections: [
                {
                    title: `Service providers`,
                    body: [
                        `We may share your personal data with third-party service providers to process payments, verify your identity, perform background checks, obtain medical information, authenticate your signature, perform underwriting, and administer your policy, manage data, conduct research and analytics, and other similar purposes.`
                    ]
                },
                {
                    title: `Agents and business partners`,
                    body: [
                        `We may receive your personal data from an insurance agent or business partner. In this case, we will share your information as necessary to provide you with the products and services that may be of interest to you. `
                    ]
                },
                {
                    title: `Affiliates`,
                    body: [
                        `We may disclose the personal information we collect from you to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your personal information will be subject to this privacy policy.`,
                    ],
                },
                {
                    title: `Business transfers`,
                    body: [
                        `We may transfer your personal information if we are acquired by or merged with another company, our assets are transferred to another company, or there is a bankruptcy proceeding.`
                    ]
                },
                {
                    title: `In response to legal requests`,
                    body: [
                        `We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena.`
                    ]
                },
                {
                    title: `To protect us and others`,
                    body: [
                        `We also may disclose your information to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our terms or this privacy policy, or as evidence in litigation in which we are involved.`
                    ]
                },
                {
                    title: `Aggregated or de-identified information`,
                    body: [
                        `We may share aggregated or de-identified information about users with third parties for marketing, advertising, research, or any other purposes.`
                    ]
                }
            ]
        },
        {
            title: `Opting Out`,
            body: [
                `We want you to have choices about how and when you do business with us. You can email us at privacy@bestow.com to update your information or change your preferences. If you withdraw your consent, you may be unable to apply for or receive our life insurance products and services. Please note that if you disable your account, we will keep your contact information on file and may retain other account information for a reasonable period of time for the purpose of internal account management, fraud prevention and as permitted by law.`
            ],
            subSections: [
                {
                    title: `Marketing emails`,
                    body: [
                        `If you no longer want to receive our marketing emails or other announcements, you may unsubscribe using the “unsubscribe” link at the bottom of our emails. Please note that you cannot unsubscribe from certain correspondence from us, such as operational messages relating directly to your account.`
                    ]
                },
                {
                    title: `Cookies`,
                    body: [
                        `You can adjust the use of cookies for Bestow or other third party websites by updating your browser or device settings. Please be aware that you must update your preferences on each browser and each device.`,
                        `Users in the United States may opt out of many third-party ad networks. For example, you may go to the Digital Advertising Alliance (DAA) Consumer Choice Page (www.optout.aboutads.info) for information about opting out of interest-based advertising and your choices. Opting out from one or more companies listed on the DAA Consumer Choice Page (optout.aboutads.info) will opt you out from those companies’ delivery of interest-based content or ads to you, but it does not mean you will no longer receive any advertising through our site or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (i.e., contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA site, your opt out may not be effective. Additional information is available on the DAA’s website at www.aboutads.info.`
                    ]
                },
                {
                    title: `Automated calls`,
                    body: [
                        `You can opt out of receiving calls from automatic telephone dialing systems or prerecorded messages at any time. The Do Not Call Registry accepts registrations from both cell phones and landlines. To register by telephone, call 1-888-382-1222 (TTY: 1-866-290-4236) from the phone number you want to register or register online at donotcall.gov.`,
                    ],
                }
            ]
        },
        {
            title: `Other important privacy information`,
            subSections: [
                {
                    title: `Confidentiality practices for victims of domestic violence or abuse`,
                    body: [
                        `Bestow understands that certain personal data may require special handling in instances where an individual is, or has been, a victim of domestic violence or abuse. This information may include the individual’s address, telephone number, name, place of employment, or other contact information. If you are a Bestow applicant, policyowner, insured, or beneficiary, who is a victim of abuse and would like Bestow to take additional steps to safeguard your information from others, our Customer Service Representatives are available to assist you.`
                    ]
                },
                {
                    title: `Third-Party links`,
                    body: [
                        `Our site and services may contain links to third-party websites and we may work with third parties to offer you additional products or services. We do not directly share your information with these third parties, but when using any third-party links, features, or services, your data will be subject to their terms of use, terms of service, and privacy policy. We recommend you review these terms and policies as we are not responsible for their information practices.`,
                    ]
                },
                {
                    title: `Security`,
                    body: [
                        `We have implemented commercially reasonable precautions to protect the personal information we collect from loss, misuse, and unauthorized access, disclosure, and destruction. Please be aware that despite these measures, no data security measures can guarantee 100% security. You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. Do not share your password with others. We are not responsible for any lost, stolen, shared, or compromised passwords or for any activity on your account via unauthorized password activity.`,
                    ],
                },
                {
                    title: `Children`,
                    body: [
                        `Bestow’s site and services are not intended for use by children under the age of 13. If we learn that we have collected personal information from a child under 13 without parental or guardian consent as required by law, we will delete it.`,
                    ],
                }
            ]
        },
        {
            title: `Questions?`,
            body: [
                `If you have any questions about our privacy practices or this Privacy Policy, please contact us by email at <a class="font-medium underline" href="mailto:privacy@bestow.com">privacy@bestow.com</a> or write to us at 750 North St. Paul Street #1900, Dallas, TX 75201.`,
                `This policy may change from time to time. Any changes will be posted to Bestow’s website and effective when posted. Your use of Bestow’s site or service after the effective date of the updated policy indicates your acceptance of that policy.`,
                `Last Updated: Jan 1, 2022`
            ],
        },
    ]
};
