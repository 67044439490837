import React from 'react';

import ReviewSliderModule from "../../../components/_common/modules/reviewSlider"

const Reviews: React.FC = () => {
    const reviews = [
        {
            review_author: 'Andy Chang',
            review_content: 'Bestow’s life insurance products resonate very well with our audience, and their affiliate team really understands how to maximize cooperation opportunities and work towards growth in a transparent and data-driven manner.',
            review_title: 'Applied Mind',
            stars: ''
        },
        {
            review_author: 'Vincent Wei',
            review_content: 'The Bestow API is one of the easiest and fastest integration experiences I’ve had. Our partnership has been working well and the Bestow team has been very collaborative, accessible, and innovative.',
            review_title: 'Safebutler',
            stars: ''
        },
        {
            review_author: 'Arnon Schottenfels',
            review_content: 'When we were building up our Life Insurance business, Bestow quickly proved to be a strong and valuable partner. Their straightforward and hassle-free approach is exactly what our users are looking for and we couldn’t be happier with our partnership.',
            review_title: 'Natural Intelligence',
            stars: ''
        },
    ];

    return (
        <ReviewSliderModule
            className="pt-16 pb-12 sm:pt-24 sm:pb-20 lg:py-16 bg-off-white"
            reviews={reviews}
            title="OUR AFFILIATES SAY"
            hideTrustPilot
        />
    );
};

export default Reviews;
