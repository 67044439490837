import React from 'react';

import BodyText, { Props } from "../index";

import './index.scss';

const BodyTextLarge: React.FC<Props> = ({className = "", ...otherProps}) => (
    <BodyText className={`body-text-large font-thin ${className}`} {...otherProps} />
);

export default BodyTextLarge;
