import React from 'react';

import Card, { Props } from "../";

import './index.scss';

const CardRound: React.FC<Props> = ({className = "", ...otherProps}) => {
    return (
        <Card className={`card-round ${className}`} {...otherProps} />
    )
};

export default CardRound;