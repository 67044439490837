import React from 'react';
import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import BodyText from "../../../components/_common/text/body-text";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import H2Serif from "../../../components/_common/text/heading/h2/serif";
import PhoneDesktop from '../../../images/api-partners/whitephone_angled_v2_desktop.jpg';
import PhoneTablet from '../../../images/api-partners/whitephone_angled_v2_tablet.jpg';
import PhoneMobile from '../../../images/api-partners/whitephone_angled_v2_mobile.png';
import IconBlurb from './icon-blurb';

import {
    MobileIcon,
    ScalableIcon,
    InAppTileIcon,
    LandingPageIcon,
    EmailMarketingIcon,
} from '../../../images/icons/index';

import AffiliatesIcon from '../../../images/icons/Affiliates.svg';
import ProtectLiteIcon from '../../../images/icons/Protect-Lite-1.svg';
import ProtectSDKIcon from '../../../images/icons/Protect-SDK-2.svg';

const ConsiderBestow: React.FC = () => (
    <PageSection className="pt-14 sm:pt-0 pb-4 sm:pb-0 lg:pb-10">
        <div className="sm:hidden flex justify-center">
            <img src={PhoneMobile} className="-mt-16 mb-4 w-44" alt="Leverage innovative tech and a trusted product" />
        </div>
        <Grid className="mb-8">
            <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-3 lg:col-span-8">
                <H2Serif className="text-navy text-center mb-6 sm:mb-12 lg:mb-16">
                    Leverage innovative tech and a trusted product
                </H2Serif>
            </div>
            <div className="sm:col-span-4 lg:col-span-6 px-2 lg:px-4 hidden sm:block">
                <img src={PhoneDesktop} className="sm:hidden lg:block w-full" alt="Leverage innovative tech and a trusted product" />
                <img src={PhoneTablet} className="sm:block lg:hidden w-full" alt="Leverage innovative tech and a trusted product" />
            </div>
            <div className="col-span-full sm:col-span-4 lg:col-span-5">
                <div className="mb-8 sm:mt-6 lg:mt-9">
                    <Subtitle2 className="sm:pl-2 mb-2 text-center sm:text-left">
                        Your customers
                    </Subtitle2>
                    <BodyText className="sm:p-2 sm:pl-8 text-center sm:text-left">
                        You already have the customers. Engage them with a product they want and need - with an incredibly
                        simple, wildly fast path to peace of mind.
                    </BodyText>
                </div>
                <div className="mb-8">
                    <Subtitle2 className="sm:pl-2 mb-2 text-center sm:text-left">
                        Your brand
                    </Subtitle2>
                    <BodyText className="sm:p-2 sm:pl-8 text-center sm:text-left">
                        Your customers know and trust you. Offering life insurance expands your product suite, increases
                        brand affinity, and extends your customer relationship.
                    </BodyText>
                </div>
                <div className="mb-8">
                    <Subtitle2 className="sm:pl-2 mb-2 text-center sm:text-left">
                        Bestow’s platform
                    </Subtitle2>
                    <BodyText className="sm:p-2 sm:pl-8 text-center sm:text-left">
                        Our end-to-end tech stack supports a range of integrations that match your unique business needs
                        and resources. Offer a fully-hosted life insurance solution in as little as one day. We can help
                        with everything from licensing to launch.
                    </BodyText>
                </div>
            </div>
        </Grid>
        <div className="mb-10 sm:mb-12 lg:mb-16">
            <H2Serif className="text-navy text-center">
                Ways to partner
            </H2Serif>
        </div>
        <Grid cols={1} smCols={3} lgCols={3}>
            <div>
                <IconBlurb
                    Icon={AffiliatesIcon}
                    title="Affiliate"
                    titleClassName="text-coral-dark"
                    body="From emails and splash pages to in-app tiles. Learn more about our affiliate program <a href='/affiliates'>here.</a>"
                />
            </div>
            <div>
                <IconBlurb
                    Icon={ProtectLiteIcon}
                    title="Protect Web"
                    titleClassName="text-spruce-dark"
                    body="Seamless, plug-and-play solutions that enable companies to launch life insurance in as little as one day."
                />
            </div>
            <div className="col-span-full sm:col-span-1 px-16 sm:px-0">
                <IconBlurb
                    Icon={ProtectSDKIcon}
                    title="Protect API"
                    titleClassName="text-gold-dark"
                    body="With both SDK and API integration options, launch highly customizable, white-labeled life insurance solutions in a few sprints."
                />
            </div>
        </Grid>
    </PageSection>
);

export default ConsiderBestow;
