import React from 'react';

import Grid from '../../../components/_common/grid';
import H3Serif from '../../../components/_common/text/heading/h3/serif';
import BodyTextLarge from '../../../components/_common/text/body-text/large';
import PrimaryButton from '../../../components/_common/button/primary';
import PageSection from '../../../components/_common/section/page';
import SmallText from '../../../components/_common/text/small-text';

import { ScaleIcon } from '../../../images/icons';
import { smoothScrollToTop } from '../../../utilities/smoothScrollToTop';

const SurprisinglyAffordable: React.FC = () => {
    return (
        <PageSection className="bg-white py-16 lg:pt-48 pb-12 lg:pb-24">
            <Grid>
                <div className="col-span-full lg:col-span-4 mb-6 lg:mb-0 flex justify-center lg:justify-start">
                    <ScaleIcon className="w-full px-16 sm:px-0" style={{maxWidth: 250}} />
                </div>
                <div className="col-span-full lg:col-span-8">
                    <H3Serif className="font-bold mb-8 text-center lg:text-left">
                        Surprisingly affordable
                    </H3Serif>
                    <BodyTextLarge className="mb-4 text-center lg:text-left">
                        People overestimate the cost of life insuance by as much as 300%!<sup>*</sup> With Bestow,{' '}
                        many applicants see rates starting under $1/day.
                    </BodyTextLarge>
                    <SmallText className="mb-8 text-center lg:text-left">
                        <sup>*</sup>Source:&nbsp;
                            <a className="text-charcoal" href="https://www.limra.com/en/research/research-abstracts-public/2021/2021-insurance-barometer-study/" target="_blank" rel="noopener noreferrer">
                                Life Insurance Marketing and Research Association 2021 Insurance Barometer Study
                            </a>
                    </SmallText>
                    <div className="flex justify-center lg:justify-start">
                        <PrimaryButton  onClick={smoothScrollToTop}>
                            See your quote
                        </PrimaryButton>
                    </div>
                </div>
            </Grid>
        </PageSection>
    )
};

export default SurprisinglyAffordable;
