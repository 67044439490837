export const videos = [
    {
        video_title: 'Life Insurance Basics',
        video_id: '_xND5DyZnuM',
        video_thumbnail: {
            alt: 'Bestow Life Insurance Basics Video Thumbnail',
            url: 'https://mk0hellobestowjm6762.kinstacdn.com/wp-content/uploads/2020/10/LifeInsuranceBasics_RestingVideo.jpg'
        }
    },
    {
        video_title: 'Term vs Whole',
        video_id: 'fkNxk3GoBHU',
        video_thumbnail: {
            alt: 'Bestow Term vs Whole Video Thumbnail',
            url: 'https://mk0hellobestowjm6762.kinstacdn.com/wp-content/uploads/2020/10/TermVsWhole_RestingVideo.jpg'
        }
    },
    {
        video_title: 'Beneficiaries',
        video_id: 'I6GuAWTaYXo',
        video_thumbnail: {
            alt: 'Bestow Beneficiaries Video Thumbnail',
            url: 'https://mk0hellobestowjm6762.kinstacdn.com/wp-content/uploads/2020/10/Beneficiaries_RestingVideo.jpg'
        }
    },
    {
        video_title: 'Insurance at Work',
        video_id: 'Fops1QYkZlQ',
        video_thumbnail: {
            alt: '',
            url: 'https://mk0hellobestowjm6762.kinstacdn.com/wp-content/uploads/2020/10/InsuranceAtWork_RestingVideo.jpg'
        }
    },
    {
        video_title: 'Coverage Amount',
        video_id: 'F9lzf_rbMt8',
        video_thumbnail: {
            alt: '',
            url: 'https://mk0hellobestowjm6762.kinstacdn.com/wp-content/uploads/2020/10/CoverageAmount_RestingVideo.jpg'
        }
    },
    {
        video_title: 'Trust Bestow',
        video_id: 'zE2fcdMg9Oo',
        video_thumbnail: {
            alt: '',
            url: 'https://mk0hellobestowjm6762.kinstacdn.com/wp-content/uploads/2020/10/TrustBestow_RestingVideo.jpg'
        }
    }
]
