import React from 'react';

import Heading, { Props } from '../';

import './index.scss';

const H4: React.FC<Props> = ({children, className = "", ElementType = "h4", ...otherProps}) => {
  return (
    <Heading className={`brand-h4 ${className}`} ElementType={ElementType} {...otherProps}>
        {children}
    </Heading>
  );
};

export default H4;
