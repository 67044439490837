import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { loadQuotePartner } from '../../../../../redux/quote';
import {
    quotePartnerSelector,
    quoteSelector,
} from '../../../../../redux/quote/selectors';
import {
    DEFAULT_PARTNER,
    PARTNER_CONSTANTS,
    PARTNER_KEYS,
} from '../../data/partnerConstants';
import useKnockoutReason from '../../hooks/useKnockoutReason';
import QuotacyDeclineScreen from './templates/partners/quotacy';
import PolicyGeniusDeclineScreen from './templates/partners/policyGenius';
import TBGDeclineScreen from './templates/partners/theBaileyGroup';
import HubDeclineScreen from './templates/partners/hubInternational';
import { trackDeclinePageView } from '../../api/analytics';
import { QuoteProps } from '../../';
import DeclineTemplate from './templates';
import ZanderDeclineScreen from './templates/partners/zanderinsurance';
import { getCurrentPartner } from '../../utils/quoteUtils';
import { bestQuoteSelectorNoHealthExperiment } from '../../experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic/selectors';
import GerberDeclineScreen from './templates/partners/gerber';
import useDeclineQuotePartnerName from '../../hooks/useDeclineQuotePartnerName';

const Decline: React.FC<QuoteProps> = ({ isAgent, gerberEligible }) => {
    const originalQuote = useSelector(quoteSelector);
    const noHealthQuote = useSelector(bestQuoteSelectorNoHealthExperiment);
    const quote = noHealthQuote ? noHealthQuote : originalQuote; //MOE-404
    const quotePartner = useSelector(quotePartnerSelector);
    const dispatch = useDispatch();
    const { defaultDeclineScreen } = useFlags();
    const declineReason = useKnockoutReason();

    const quotePartnerName = useDeclineQuotePartnerName(declineReason, gerberEligible);
    // TODO: Create hook
    const currentPartner = (PARTNER_CONSTANTS as any)[quotePartnerName]
        ? quotePartnerName
        : isAgent
        ? PARTNER_KEYS.agent
        : null;
    const defaultPartner = defaultDeclineScreen || DEFAULT_PARTNER;
    const partnerKey = currentPartner || defaultPartner;
    const partnerInfo = (PARTNER_CONSTANTS as any)[partnerKey];

    // TODO: How can we do this better? Does this case even need to be supported?
    if (isAgent) {
        partnerInfo.displayName = quotePartnerName;
    }

    useEffect(() => {
        if (quote?.quote_id) {
            dispatch(loadQuotePartner(quote.quote_id));
        } else {
            // This is a weird case currently...
            trackDeclinePageView({}, '');
        }
    }, [dispatch, quote.quote_id]);

    useEffect(() => {
        if (quotePartner?.partner) {
            trackDeclinePageView(quotePartner.partner, quote?.quote_id);
        }
    }, [quote.quote_id, quotePartner]);

    const DeclineScreen = useMemo(() => {
        if (isAgent && getCurrentPartner() === PARTNER_KEYS.zanderinsurance) {
            return ZanderDeclineScreen;
        }

        switch (partnerKey) {
            case PARTNER_KEYS.agent:
                return DeclineTemplate;
            case PARTNER_KEYS.policyGenius:
                return PolicyGeniusDeclineScreen;
            case PARTNER_KEYS.theBaileyGroup:
                return TBGDeclineScreen;
            // TODO: This goes to Agent decline screen in AQX - figure this out
            case PARTNER_KEYS.hubInternational:
                return HubDeclineScreen;
            case PARTNER_KEYS.gerber:
                return GerberDeclineScreen;
            default:
            case PARTNER_KEYS.quotacy:
                return QuotacyDeclineScreen;
        }
    }, [partnerKey, isAgent]);

    return (
        <div>
            {!quotePartner &&
            declineReason !== 'agent_not_licensed_in_state' ? (
                'Loading...'
            ) : (
                <div data-testid="quote-decline">
                    <DeclineScreen
                        declineReason={declineReason}
                        partnerInfo={partnerInfo}
                    />
                </div>
            )}
        </div>
    );
};

export default Decline;
