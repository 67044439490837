import React from 'react';
import cx from 'classnames';

import PageSection from "../../section/page";
import TextFieldLabel from "../../text/textFieldLabel";

import {CNN, Fastco, TechCrunch, WSJ, Forbes} from '../../../../images/svgs';

import './index.scss';

interface Props {
    className?: string
}

const TrustModule: React.FC<Props> = ({ className = "" }) => {
    const classes = cx("trust-module pt-6 pb-2 sm:pt-8 sm:pb-10 lg:pt-12 lg:pb-14", className);

    return (
        <PageSection className={classes}>
            <TextFieldLabel className="text-center text-charcoal mb-6 sm:mb-4">
                AS SEEN IN
            </TextFieldLabel>
            <div className="hidden sm:flex justify-center flex-wrap">
                {[CNN, Fastco, TechCrunch, WSJ, Forbes].map((Icon, index) => (
                    <div key={`as-seen-in-icon-${index}`} className="mx-4">
                        <Icon />
                    </div>
                ))}
            </div>
            <div className="flex sm:hidden justify-center flex-wrap">
                {[CNN, Fastco, Forbes, TechCrunch, WSJ].map((Icon, index) => (
                    <div key={`as-seen-in-icon-${index}`} className="mb-6">
                        <Icon />
                    </div>
                ))}
            </div>
        </PageSection>
    );
};

export default TrustModule;
