import React from 'react';
import {FastField, Field} from "formik";

import {FormikCustomFieldCommonProps} from '../../inputs';

interface Props extends FormikCustomFieldCommonProps {
    name: string,
    CustomComponent: any,
    fast?: boolean,
    type?: string,
    rows?: number,
    options?: {
        label: string,
        value: string,
        disabled?: boolean
    }[],
    variant?: string
}

const CustomFormikField: React.FC<Props> = ({name, helper, CustomComponent, fast, type, ...otherProps}) => {
    const FormikField = fast ? FastField : Field;
    return (
        <FormikField name={name}>
            {/*//@ts-ignore */}
            {({ field: {value, onChange}, meta: {error, touched} }) => {
                return (
                    <CustomComponent
                        {...{name, value, onChange, type, ...otherProps}}
                        error={type === "checkbox" ? error : Boolean(touched && error)}
                        helper={touched && error ? error : helper}
                    />
                )
            }}
        </FormikField>
    )};

export default CustomFormikField;
