import React, {HTMLAttributes} from 'react';

export interface Props extends HTMLAttributes<HTMLHeadingElement> {
    // TODO: is there a better way to declare this?
    ElementType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

const Heading: React.FC<Props> = ({ ElementType = "h1", ...otherProps }) => {
    return (
        <ElementType {...otherProps} />
    )
};

export default Heading;