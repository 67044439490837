import React from 'react';
import PageContent from '../../components/_common/pageContent';
import ReviewSliderTwoSection from '../../components/_common/modules/reviewSliderTwo';
import NavBar from './navbar';
import QuoteHeader from './quote-header';
import TraditionalVsBestow from './traditional-vs-bestow';
import SurprisinglyAffordable from './surprisingly-affordable';
import traditionalVsBestowDesktop from "./videos/bestow-vs-trad_desktop.mp4";
import traditionalVsBestowMobile from "./videos/bestow-vs-trad_mobile.mp4";
import Trusted from './trusted';
import FAQs from './faqs';
import BottomCTA from './bottom-cta';

const HighIntentLander: React.FC = () => {
    const [isQuoteInView, setIsQuoteInView] = React.useState<boolean>(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState<boolean>(false);

    return (
        <>
            <NavBar
                isQuoteInView={isQuoteInView}
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
            />
            <PageContent className="high-intent-lander" showTopNav={false}>
                <QuoteHeader setIsQuoteInView={setIsQuoteInView} />
                <ReviewSliderTwoSection />
                <TraditionalVsBestow
                    videoDesktop={traditionalVsBestowDesktop}
                    videoMobile={traditionalVsBestowMobile}
                />
                <SurprisinglyAffordable />
                <Trusted />
                <FAQs />
                <BottomCTA />
            </PageContent>
        </>

    );
};

export default HighIntentLander;
