import _ from "lodash";

/**
 * Looks for pre-rendered state from React Snap, and if one exists we use that as the initial state for the Redux store
 * or fallback to the default empty initial state.
 * @param sliceName     The name of the store to inflate
 * @param emptyState    The default empty state of the store, should a pre-rendered state not be available
 * @returns {*}         The pre-rendered state or empty state if one doesn't exist
 */
export const getInitialState = (sliceName, emptyState) => {
    if (window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__[sliceName]) {
        return window.__PRELOADED_STATE__[sliceName];
    }
    return emptyState;
};


/**
 * Paginates through all results and returns a flattened array
 * @param createRequest   Function that creates the request to paginate through
 * @returns {*} An array with all results
 */
export const getAll = async (createRequest) => {
    // Get the first page to get paging info
    const page1 = await createRequest().page(1);

    // Paging info will either come from top-level if response is from WP or from first record if from local cached json
    const totalNumPages = page1._paging?.totalPages || page1[0]?._paging?.totalPages;

    // If we need subsequent pages, then we grab all those in parallel
    if (totalNumPages > 1) {
        const dummyArray = Array.apply(null, Array(totalNumPages - 1));
        const remainingPages = await Promise.all(dummyArray.map((_, index) => createRequest().page(index + 2)));
        return _.flatten( [page1, ...remainingPages] );
    }

    // Otherwise, just return page1
    return page1;
};

/**
 * Paginates through all results and returns a flattened array. Executes requests in Serial
 * @param createRequest   Function that creates the request to paginate through
 * @param pageNumber    The current page to fetch
 * @param totalNumPages The total number of pages to fetch
 * @returns {*} An array with all results
 */
export const getAllSerial = async (createRequest, pageNumber = 1, totalNumPages) => {
    // Get the current page
    const result = await createRequest().page(pageNumber);

    // Paging info will either come from top-level if response is from WP or from first record if from local cached json
    const totalPages = totalNumPages || result._paging?.totalPages || result[0]?._paging?.totalPages;

    // If there are more pages, get them too.
    if (pageNumber < totalPages) {
        const extraPages = await getAllSerial(createRequest, pageNumber + 1, totalPages);
        return _.flatten( [result, ...extraPages] );
    }

    // Otherwise, just return the current page
    return result;
};
