import { CustomerReviewsPageModel } from "./types";

export const REVIEWS = [
    {
        "review_author": "Charles",
        "review_content": "Application process was thorough and straight forward without exam which was a plus during COVID-19. Glad I decided to take this step to protect my family and would highly recommend Bestow to family and friends.",
        "review_title": "Best decision...wish I would have done this sooner!",
        "stars": "5"
    },
    {
        "review_author": "Stephen",
        "review_content": "I'd been meaning to get life insurance in place for our family for a while now, but dreaded the process I anticipated. Bestow couldn't have made it easier, and now I have that extra peace of mind.",
        "review_title": "Easy, fair and fast.",
        "stars": "5"
    },
    {
        "review_author": "Shannon",
        "review_content": "Signing up for a term life policy with Bestow was literally easier than filling out any form you'd find online. Simple questions and layout, easy to understand, and no fluff.",
        "review_title": "Easy and quick for many years of security",
        "stars": "5"
    },
];

const PAGE: CustomerReviewsPageModel = {
    "title": "Customer Reviews",
    "cta": {
        "title": "Coverage today, if approved.",
        "subtitle": "Yep. It's that easy.",
        "buttonText": "Get a Quote",
    },
    "reviews": {
        "slider": REVIEWS,
        "list": [
            {
                "review_author": "Taeysha",
                "review_content": "I'm trying to break the generational curse of not understanding finances. I wanted to make small investments for my son and realized life insurance was a big step that I was missing. The site made sign up hassle free and easy. I COULD NOT believe how easy it was and now I regret waiting so long.",
                "review_title": "Easy, fast and understandable",
                "stars": "5"
            },
            {
                "review_author": "Blake",
                "review_content": "I'm basing this review on ease of obtaining coverage. It was the easiest thing I've done in awhile. I have peace of mind knowing my girls will be taken care of if anything terrible happens. The best part was no blood draws or doctors. Highly recommend!",
                "review_title": "I'm basing this review on ease of\u2026",
                "stars": "5"
            },
            {
                "review_author": "Valerie",
                "review_content": "I was doubtful this would be \"easy and fast.\" I have heard that before. I went from start (initial review of coverage) to finish (policy paid for and covered) in less than 30 minutes. No joke. I could not be happier.",
                "review_title": "I Was Doubtful at First",
                "stars": "5"
            },
            {
                "review_author": "Kellin",
                "review_content": "I love how quick and easy the process was. From the online questions, rate offered, to the qualification process was a breeze! 20-year term policy puts me at ease and gives me peace of mind knowing my family will be taken care of. Thank you Bestow!",
                "review_title": "Quick and easy process!",
                "stars": "5"
            },
            {
                "review_author": "DT",
                "review_content": "I was initially going to purchase additional life insurance coverage through my employer, but after comparing premiums Bestow came out as the clear winner.",
                "review_title": "Very easy and straight forward",
                "stars": "5"
            },
            {
                "review_author": "Kysha",
                "review_content": "You made it so easy to do something I\u2019ve been putting off for a long time!",
                "review_title": "You made it so easy to do something\u2026",
                "stars": "5"
            },
            {
                "review_author": "Jonathan",
                "review_content": "The sign-up process was super fast. I was able to complete a few questionnaires right from my mobile, which took about 10 mins. Once complete, the app returned an instant rate quote. Additionally, I was able to select from an impressive range of coverage to best suit my budget and needs. \r\n",
                "review_title": "Super Easy, Super Fast!",
                "stars": "5"
            },
            {
                "review_author": "Irene",
                "review_content": "I was very impressed with how easy and fast it went! Wow...highly recommended. No agents to deal with, no tests, super fun!\r\n",
                "review_title": "I was very impressed ",
                "stars": "5"
            },
            {
                "review_author": "Randy",
                "review_content": "This was by far the easiest life insurance process I've ever experienced. In a matter of minutes, I had a 20-year $500,000 term policy in place with a monthly premium less expensive than the $100,000 policy I currently had. Bestow is an amazing platform and I can't recommend it highly enough.",
                "review_title": "Bestow is Amazing",
                "stars": "5"
            },
            {
                "review_author": "Emily Evans",
                "review_content": "Woo-hoo! The best part was that I could do everything online and didn't end up with 15 voicemails from agents afterward. Everything was clear and easy to understand.\r\n",
                "review_title": "Coverage in less than 5-minutes",
                "stars": "5"
            },
            {
                "review_author": "Justin S.",
                "review_content": "I applied and was approved for a $1 million dollar policy in less than 10 minutes. Links right to your credit card and is incredibly affordable! Signed up my wife 2 days later. Every single person looking for Life Insurance should come here!",
                "review_title": "Excellent, easy and quick application process!",
                "stars": "5"
            },
            {
                "review_author": "Laura",
                "review_content": "I am so impressed with the customer service at Bestow. The process was incredibly easy and quick, and everyone I spoke with was friendly and supportive. :)",
                "review_title": "So Impressed",
                "stars": "5"
            },
            {
                "review_author": "Benjamin",
                "review_content": "Very efficient and easy to complete life insurance online. Upfront on what you had to do, what you're receiving and step-by-step instructions on how to complete. \r\nWould recommend to other family and friends looking for term life insurance.",
                "review_title": "Very efficient and easy to complete\u2026",
                "stars": "5"
            },
            {
                "review_author": "Timothy Chism",
                "review_content": "In less than 10 minutes I was insured and that gave me peace of mind. \r\nAlso, I just scored some cool points with my wife. ",
                "review_title": "The process was quick, easy and affordable. ",
                "stars": "5"
            },
            {
                "review_author": "Brian",
                "review_content": "The application process was easy and not time-consuming at all. Literally had coverage in about 10 minutes. I appreciate that being a very busy person. The follow up was great and it is easy to access your account if you have questions. Thanks Bestow!\r\n",
                "review_title": "Great application process!",
                "stars": "5"
            },
            {
                "review_author": "Keith",
                "review_content": "My experience with Bestow could not have been better. After comparison-shopping, I was pleased that Bestow found coverage for me which fit my needs perfectly and saved me money. The online process was fast and easy. The representatives I spoke to were helpful, but not overbearing with high-pressure sales tactics. Overall, I highly recommend Bestow to anyone in the market for term life insurance.\r\n",
                "review_title": "Great Experience",
                "stars": "5"
            },
            {
                "review_author": "Alison",
                "review_content": "You can get the information that you need, make a decision, and move on with your day!",
                "review_title": "The website is very easy to use",
                "stars": "5"
            },
            {
                "review_author": "Angel P",
                "review_content": "Very competitive rates/ coverage limits. A very good overall value. An underlying sense of being treated fairly by the underwriting process. A refreshing, welcome and much needed addition to what\u2019s out there in the life insurance market.",
                "review_title": "Excellent",
                "stars": "5"
            },
            {
                "review_author": "Marques",
                "review_content": "Just easier than I ever thought getting life insurance would be! Great rates as well!!",
                "review_title": "Easier than I ever thought!",
                "stars": "5"
            },
            {
                "review_author": "Raymond",
                "review_content": "I called a few days later asking some questions and the person I spoke to was very nice and gave me the information I needed. No headaches and you get what you pay for. Felt a big relief after knowing my son had some cushion in case something were to happen to me.",
                "review_title": "Quick and easy online",
                "stars": "5"
            }
        ],
    },
};

export default PAGE;
