import React from 'react';

import PageContent from '../../components/_common/pageContent';
import PageSection from '../../components/_common/section/page';
import PrimaryButton from '../../components/_common/button/primary';
import H2Serif from '../../components/_common/text/heading/h2/serif';
import Subtitle2 from '../../components/_common/text/heading/subtitle2';
import BodyText from '../../components/_common/text/body-text';
import TrustModule from '../../components/_common/modules/trust';
import PrimaryTranslucentButton from '../../components/_common/button/primary/translucent';
import H1Serif from '../../components/_common/text/heading/h1/serif';
import Reviews from './reviews';
import Options from './options';
import PhoneSection from './phone-section';
import TrustedAffordableFast from './trusted-affordable-fast';
import BusinessSlider from './business-slider';
import PrimaryLargeButton from '../../components/_common/button/primary/large';
import CTAModuleOne from '../../components/_common/modules/cta/one';
import LinkButton from '../../components/_common/button/link';
import Grid from '../../components/_common/grid';
import SecondaryButtonCharcoal from '../../components/_common/button/secondary/charcoal';
import H4Serif from '../../components/_common/text/heading/h4/serif';
import { trackApplyNowClicked, trackIt } from '../../utilities/segment.track';

import { ReactComponent as Arrow } from '../../images/svgs/btn-arrow-right.svg';

import './index.scss';

const HomePage: React.FC = () => {
    const trackAndRedirect = () => {
        trackApplyNowClicked({});
        // @ts-ignore
        window.location.href = process.env.REACT_APP_START_URL;
    };

    // Analytics wants us to track button clicks (i.e. not the get quote clicked fired with the payload)
    const trackQuoteClicked = () => {
        trackIt('get_quote_clicked');
    };

    return (
        <PageContent>
            <PageSection className="bg-cover bg-center bg-home sm:bg-home-sm relative">
                <div className="inset-0 absolute bg-navy -z-1" />
                <div
                    className="pt-4 pb-72 sm:pt-32 sm:pb-40 lg:pt-36 lg:pb-48 flex flex-col items-center sm:items-start"
                    data-cy="hero.section"
                >
                    <H2Serif
                        className="text-white text-center mb-4 sm:hidden"
                        data-cy="hero.mobile.header"
                    >
                        Term life the way
                        <br />
                        it should be: easy.
                    </H2Serif>
                    <H1Serif
                        className="hidden text-white mb-8 text-left sm:block"
                        data-cy="hero.header"
                    >
                        Term life the way
                        <br />
                        it should be: easy.
                    </H1Serif>
                    <Subtitle2 className="text-white text-center sm:text-left mb-4">
                        No hoops. No headaches. <br className="sm:hidden" />
                        Just insurance.
                    </Subtitle2>
                    <BodyText className="text-white text-center mb-8 mx-4 sm:mx-0 sm:mb-12 sm:text-left">
                        Fast, affordable term life insurance with no medical
                        exam.
                    </BodyText>
                    <div className="hidden sm:flex">
                        <div className="mr-6" data-cy="hero.get.quote">
                            <PrimaryButton
                                to="/quote"
                                onClick={trackQuoteClicked}
                            >
                                Get a Quote
                            </PrimaryButton>
                        </div>
                        <div data-cy="hero.apply.now">
                            <PrimaryTranslucentButton
                                className="bestow-track"
                                onClick={trackAndRedirect}
                            >
                                Apply Now
                            </PrimaryTranslucentButton>
                        </div>
                    </div>
                    <div className="sm:hidden" data-cy="hero.apply.now.mobile">
                        <PrimaryButton
                            className="bestow-track"
                            onClick={trackAndRedirect}
                        >
                            Apply Now
                        </PrimaryButton>
                    </div>
                </div>
            </PageSection>
            <TrustModule className="bg-off-white" />
            <PageSection className="hidden sm:block pt-20 pb-16 sm:py-24">
                <Options />
            </PageSection>
            <PhoneSection />
            <TrustedAffordableFast />
            <Reviews />
            <PageSection className="sm:hidden mb-16">
                <Options />
            </PageSection>
            <BusinessSlider />
            <CTAModuleOne
                title={
                    <span>
                        Let’s plan for the future.{' '}
                        <br className="hidden sm:block" />
                        Together.
                    </span>
                }
                className="bg-navy text-white"
            >
                <PrimaryLargeButton to="/quote">Get a Quote</PrimaryLargeButton>
            </CTAModuleOne>
            <PageSection className="py-14 px-4 sm:px-0 bg-mint">
                <Grid>
                    <div className="col-span-full sm:col-span-4 lg:col-span-7 pb-3">
                        <H2Serif className="sm:hidden text-navy mb-1 text-center">
                            Bestow for business
                        </H2Serif>
                        <H4Serif className="hidden sm:block text-navy">
                            Bestow for business
                        </H4Serif>
                        <BodyText className="text-charcoal text-center sm:text-left">
                            Learn more about partnerships.
                        </BodyText>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-5 flex flex-col items-center sm:flex-row sm:-mr-6 lg:mr-0">
                        <div className="sm:mr-4 flex mb-4 sm:mb-0">
                            <SecondaryButtonCharcoal Icon={Arrow} to="/protect">
                                Protect API
                            </SecondaryButtonCharcoal>
                        </div>
                        <div className="flex">
                            <SecondaryButtonCharcoal
                                Icon={Arrow}
                                to="/carriers"
                            >
                                Carriers
                            </SecondaryButtonCharcoal>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="py-9">
                <div className="flex justify-center sm:justify-end">
                    <LinkButton to="/about">Learn more about Bestow</LinkButton>
                </div>
            </PageSection>
        </PageContent>
    );
};

export default HomePage;
