import { Switch, Route } from 'react-router-dom';
import PageRouter from './pageRouter';
import SiteMap from '../siteMap';
import BlogPost from '../../pages/blogPost';
import NotFound from '../../pages/404';
import Affiliates from '../../pages/affiliates';
import Terms from '../../pages/terms';
import Privacy from '../../pages/privacy';
import Reviews from '../../pages/reviews';
import SelfReferral from '../../pages/self-referral';
import ReferralTerms from '../../pages/referral-terms';
import Licenses from '../../pages/licenses';
import ThirdPartyCode from '../../pages/third-party-code';
import FinishPage from '../../pages/finish';
import FAQApp from '../../pages/faq-app';
import Agents from '../../pages/agents';
import Foundation from '../../pages/foundation';
import QuotePage from '../../pages/quote';
import SEOQuotePage from '../../pages/quote/variations/seoquote';
import NoExamLifeQuotePage from '../../pages/quote/variations/noexamlife';
import Insurance101 from '../../pages/insurance-101';
import CareersListing from '../../pages/careersListing';
import FileAClaim from '../../pages/file-a-claim';
import About from '../../pages/about';
import CareersMain from '../../pages/careersMain';
import ApiPartners from '../../pages/api-partners';
import HomePage from '../../pages/home';
import BlogLandingPage from '../../pages/blogLanding';
import MeetOurPartners from '../../pages/meet-our-partners';
import BusinessLoanContactInfo from '../../pages/business-loan-contact-info';
import CaliforniaPrivacy from '../../pages/privacy/california';
import CCPAForm from '../../pages/privacy/california/form';
import ExperianQuote from '../../pages/quote/variations/experian';
import Carriers from '../../pages/carriers';
import PolicyholderRefer from '../../pages/refer/policyholder';
import CreditKarmaLander from '../../pages/quote/variations/creditkarma';
import HighIntentLander from '../../pages/high-intent-lander';
import GerberLanding from '../../pages/gerber-landing';
import TransparencyCoverage from '../../pages/transparency-in-coverage';

/**
 * This component handles the main routes of the application
 */
const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <HomePage />
            </Route>
            <Route path="/siteMap/:page?/:of?">
                <SiteMap />
            </Route>
            <Route path="/apply-now">
                <HighIntentLander />
            </Route>
            <Route path="/guaranteed">
                <GerberLanding />
            </Route>
            <Route path="/file-a-claim">
                <FileAClaim />
            </Route>
            <Route path="/affiliates">
                <Affiliates />
            </Route>
            <Route path="/protect">
                <ApiPartners />
            </Route>
            <Route path="/insurance-101">
                <Insurance101 />
            </Route>
            <Route path={['/privacy', '/agents/privacy']}>
                <Privacy />
            </Route>
            <Route path="/ccpa-notice">
                <CaliforniaPrivacy />
            </Route>
            <Route path="/ccpa-form">
                <CCPAForm />
            </Route>
            <Route path="/self-referral">
                <SelfReferral />
            </Route>
            <Route path="/referralterms">
                <ReferralTerms />
            </Route>
            <Route path="/third-party-code">
                <ThirdPartyCode />
            </Route>
            <Route path="/transparency-in-coverage">
                <TransparencyCoverage />
            </Route>
            <Route path={['/terms', '/agents/terms']}>
                <Terms />
            </Route>
            <Route path="/meet-our-partners">
                <MeetOurPartners />
            </Route>
            <Route path="/finish">
                <FinishPage />
            </Route>
            <Route path={['/licenses', '/agents/licenses']}>
                <Licenses />
            </Route>
            <Route path="/frequently-asked-questions">
                <FAQApp />
            </Route>
            <Route path="/customer-reviews">
                <Reviews />
            </Route>
            <Route exact path={['/agents', '/tma']}>
                <Agents />
            </Route>
            <Route path="/foundation">
                <Foundation />
            </Route>
            <Route path="/quote">
                <SEOQuotePage gerberEligible />
            </Route>
            <Route path="/quote-4">
                <SEOQuotePage quoteMobileOptimizationExists />
            </Route>
            <Route path="/no-exam-life-insurance">
                <NoExamLifeQuotePage />
            </Route>
            <Route path="/parents">
                <ExperianQuote />
            </Route>
            <Route path="/quote-lite">
                <QuotePage />
            </Route>
            <Route path={['/getaquote', '/easyquote', '/beforetheyknow']}>
                <QuotePage />
            </Route>
            <Route path="/creditkarma">
                <CreditKarmaLander />
            </Route>
            <Route path="/openings">
                <CareersListing />
            </Route>
            <Route path="/careers">
                <CareersMain />
            </Route>
            {/* <Route path="/refer">
                <Refer />
            </Route> */}
            <Route path="/policyholder-referral">
                <PolicyholderRefer />
            </Route>
            <Route path="/referral">
                <QuotePage
                    quoteText={`You’ve got good friends. They recommend good bands, good restaurants and great term
                    life insurance options. Sure they’ll get a small reward for their good deed, but if you’re approved,
                    you’ll get the affordable term life insurance coverage you need in as little as 5 minutes. It’s a real
                    win-win.`}
                />
            </Route>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/blog" exact>
                <BlogLandingPage />
            </Route>
            <Route path="/learningcenter" exact>
                <BlogLandingPage
                    pageTitle="Learning Center"
                    isLearningCenter={true}
                />
            </Route>
            <Route path="/learningcenter/:postId">
                <BlogPost isLearningCenter={true} />
            </Route>
            <Route path="/blog/:postId">
                <BlogPost />
            </Route>
            <Route path="/business-loan-contact-info">
                <BusinessLoanContactInfo />
            </Route>
            <Route path="/carriers">
                <Carriers />
            </Route>
            <Route path="/404.html">
                <NotFound />
            </Route>
            <Route path={['*/:slug', '']}>
                <PageRouter />
            </Route>
        </Switch>
    );
};

export default Routes;
