import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Placeholder for the Quote form, while initial requests are in flight
 */
const QuotePlaceholder = () => {
    return (
        <div data-testid="quote-placeholder">
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
        </div>
    )
};

export default QuotePlaceholder;