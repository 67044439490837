import React from 'react';
import BodyTextLarge from "../../../components/_common/text/body-text/large";

interface Props {
    quote?: string
}

const BlockQuote: React.FC<Props> = ({ quote, children }) => {
    return (
        <div className="col-span-full lg:col-start-3 lg:col-span-8 text-center py-8">
            <div className="border-solid border-0 border-b border-coral mx-4 sm:mx-28" />
            {quote &&
                <BodyTextLarge className="my-8 mx-4 sm:mx-8 lg:mx-0" dangerouslySetInnerHTML={{__html: quote}} />
            }
            {children &&
                <BodyTextLarge className="my-8 mx-4 sm:mx-8 lg:mx-0">
                    {children}
                </BodyTextLarge>
            }
            <div className="border-solid border-0 border-b border-coral mx-4 sm:mx-28" />
        </div>
    )
};

export default BlockQuote;

