import React from 'react';

interface Props {
    className?: string
}

const Divider: React.FC<Props> = ({className = '', ...otherProps}) => (
    <hr className={`${className} border-solid border-0 border-t`} {...otherProps} />
);

export default Divider;
