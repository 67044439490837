import TrustAndWill from '../../images/meet-our-partners/trust-and-will.svg';
import Backer from '../../images/meet-our-partners/backer.svg';
import Stash from '../../images/meet-our-partners/stash.svg';
import Care from '../../images/meet-our-partners/care.svg';
import TrueBill from '../../images/meet-our-partners/true-bill.svg';
import SidecarHealth from '../../images/meet-our-partners/sidecar-health.svg';
import Breeze from '../../images/meet-our-partners/breeze.svg';

export const partners = [
    {
        Logo: TrustAndWill,
        title: `Estate planning made easy`,
        body: `You should decide how your affairs will be handled, not the courts. Creating a complete
            Estate Plan with Trust & Will means your children, pets, assets, future, and legacy will
            be handled the way you want.`,
        linkUrl: `http://trustandwill.sjv.io/rnz9oR`,
    },
    {
        Logo: Backer,
        title: `Save tax-free for your kid’s education`,
        body: `Over 40 million Americans carry student loan debt – a burden that impacts their ability to
            buy a home, start a family, and make the most of life. There’s a powerful tool called a 529 Savings
            Plan, which lets you invest tax-free to better afford education costs, so if you want to give your
            kids a debt-free future, open a 529 Savings Plan with Backer. Backer makes the 529 enrollment process
            super easy and helps you save faster by reaching out to family and friends who want to support you
            and your child.`,
        linkUrl: `http://collegebacker.mw46.net/qnEQXy`,
    },
    {
        Logo: Stash,
        title: `Stash – investing made easy`,
        body: `Stash is on a mission to help everyday Americans invest and build wealth. With Stash, you can
            purchase fractional shares, earn Stock-Back® Rewards<sup>1</sup>  where you shop, and begin achieving your big
            financial goals. Start today—and invest in yourself.`,
        linkUrl: `https://www.stash.com/start-investing/bestowmarket`,
    },
    {
        Logo: Care,
        title: `Find childcare and other services that fit your budget`,
        body: `Care.com is the largest and fastest-growing online marketplace that helps families find
            trusted caregivers, including nannies, senior care providers, housekeepers, dog walkers and more. `,
        linkUrl: `http://carecom.sjv.io/vn456e`,
    },
    {
        Logo: TrueBill,
        title: `Stay on top of your spending. `,
        body: `Get an effortless breakdown of your finances to see where your money is going and how to improve. We’ll
            notify you of important events that need your attention so you’re never caught off guard.`,
        linkUrl: `https://truebill.i679.net/Ryn1Qa`,
    },
    {
        Logo: Breeze,
        title: `Protect your income with smart, affordable disability insurance`,
        body: `Breeze makes it easy to protect your income with smart, affordable disability insurance through a simple 
            online process. If you become too sick or hurt to work, your disability insurance policy will replace part of 
            your monthly income so you can still pay the bills and take care of your family. Get a personalized quote in 30 
            seconds, apply online in 10 minutes, and if approved, receive same-day coverage.`,
        linkUrl: `https://www.meetbreeze.com/offer/1197`,
    },
]
