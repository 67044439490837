/**
 * Pulled straight from AQX. This all needs to DIAF as well.
 */

// import { DateTime } from "luxon";
// import convert from "convert-units";
import { isValid, format, parse } from "date-fns";
import isNaN from "lodash/isNaN";

// Transform {first: "mr", middle, ...etc} to [{first_name:"mr"} ...etc]
// customer_name -
// output format 2019 = { first_name, middle_name, last_name }
const POSSIBLE_GENDER_VALUES = ["female", "male"];
export const transformGender = (value: string) => {
    const lowercaseValue = value.toLowerCase();
    const isValid = POSSIBLE_GENDER_VALUES.includes(lowercaseValue);
    return isValid ? { gender: value.toLowerCase() } : null;
};

// birthday - output format = "MM/DD/YYYY"
interface Day { year: number; month: number; day: number; }
export const transformBirthday = ({ year, month, day }: Day) => {
    // Using truthy check instead of == null since 0 is also an invalid value
    if (!year || !month || !day) {
        return null;
    }
    // KBM - We need to test this
    // const dateTime = DateTime.fromObject({
    //     year,
    //     month,
    //     day
    // });
    const dateTime = parse(`${month}/${day}/${year}`, "MM/dd/yyyy", new Date());
    return isValid(dateTime)
        ? {
            // date_of_birth: dateTime.toFormat("MM/dd/yyyy")
            birthdate: format(dateTime, "MM / dd / yyyy")
        }
        : null;
};

// height_feet/inches - output format = { height: numberInInches }
interface Height { inches: string; feet: string; }
export const transformHeight = (value: Height) => {
    const { inches, feet } = value;
    if (inches == null || feet == null) {
        return null;
    }
    const inchesNumber = parseInt(inches, 10);
    const feetNumber = parseInt(feet, 10);
    // No reason to use a library for this conversion
    // const feetInInches = convert(feetNumber)
    //     .from("ft")
    //     .to("in");
    const feetInInches = feetNumber * 12; //
    const totalInInches = feetInInches + inchesNumber;
    const isValid = typeof totalInInches === "number" && !isNaN(totalInInches);
    return isValid ? { height: totalInInches } : null;
};

// coverage_amount - just swaps snakecase for camelcase - output format = { coverageAmount: "productCode" }
// This formatting is due to a protobuf quirk
export const transformCoverageAmount = (value: string | null) =>
    value != null ? { coverage: value } : null;

// zip/state - should prefer to return only zip, default to state if no zip, and null if nothing
interface Address { postal_code: number; state: string; street_1: string; street_2: string; city: string }
export const transformAddress = ({ postal_code, state, street_1, street_2, city }: Address) => {
    if (postal_code) {
        return { zipCode: postal_code, street_1, street_2, city };
    }
    if (state) {
        return { stateCode: state, street_1, street_2, city };
    }
    return null;
};

const transformTerm = (term: { value: string }) => {
    return { product: term.value }
};

const enrollmentTransformationMappings = [
    {
        key: "birthday",
        transformationFunction: transformBirthday
    },
    {
        key: "height",
        transformationFunction: transformHeight
    },
    {
        key: "coverage_amount",
        transformationFunction: transformCoverageAmount
    },
    {
        key: "gender",
        transformationFunction: transformGender
    },
    {
        key: "address",
        transformationFunction: transformAddress
    },
    {
        key: "term_choice",
        transformationFunction: transformTerm
    }
];

/**
 * Takes in key, value pairs matches them to the mapping regex values above,
 * and then transforms them according to the functions above.
 * If there is no transformation function it is returned as-is
 * (basically assuming it was already in the right format)
 * @param key
 * @param value
 * @param mappings
 * @returns {[]|any}
 */
export const transformCTSQuestionData = (
    key: string,
    value: any,
    mappings = enrollmentTransformationMappings
) => {
    const transformationMapping = mappings.find(
        transformationMapping => key === transformationMapping.key
    );

    // If a mapping exists, get that value
    if (transformationMapping) {
        try {
            // get the value
            const transformationValue = transformationMapping.transformationFunction(
                value
            );
            // If the transformation function returns null we ignore the value entirely
            if (transformationValue === null) {
                return {};
            }
            // Force it to return as an array so it can be spread with other values
            return transformationValue;
        } catch (e) {
            // If a transfomation errored out
            // eslint-disable-next-line no-console
            console.error(e, { [key]: value });
            return {};
        }
    }

    // If no mapping exists, we assume that it is valid
    return { [key]: value };
};
