import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import OutsideLabelInput from "../outsideLabel";
import InsideLabelInput from "../insideLabel";

const DAY_MONTH_LENGTH = 2;
const YEAR_LENGTH = 4;
const SEPARATOR = " / ";
export const DATE_PATTERN = `MM${SEPARATOR}dd${SEPARATOR}yyyy`;

/**
 * Parses and formats values that are entered into the Dob Input field by users
 * @param newValue  The newly-entered value from the user
 * @param previousValue  The previously-formatted value
 * @returns {string|*}  The properly formatted input string
 */
const formatDateValues = (newValue, previousValue) => {
    // First, replace all characters that are not a digit or forward slash
    const sanitizedValue = newValue.replace(/[^\d/]/g, "");
    const previousSanitizedValue = previousValue.replace(/[^\d/]/g, "");

    // This means the user is deleting characters
    if (newValue.length < previousValue.length) {
        // This means the user deleted one of the non-numeric values, so we should back out the last numeric value
        if (sanitizedValue.length === previousSanitizedValue.length) {
            return newValue.substring(0, newValue.length - SEPARATOR.length)
        }

        return newValue;
    }

    const transformedValues = [];

    // Next, we need to transform each date segment into an acceptable value, so we start by splitting the string
    const [monthSegment, daySegment, yearSegment] = sanitizedValue.split("/");

    // Get the total number of Months in a year
    const numMonths = new Date(0, 0, 0).getMonth() + 1;
    const month = transformDateSegment(numMonths, sanitizeDateSegment(monthSegment, DAY_MONTH_LENGTH));
    transformedValues.push(month);

    // Only try parsing Day if the Month satisfies a length check
    if (month?.length === DAY_MONTH_LENGTH) {
        // Get the total number of days in the parsed Month, using current year as Default
        const numDaysInMonth = daysInMonth(parseInt(month, 0));
        const day = transformDateSegment(numDaysInMonth, sanitizeDateSegment(daySegment, DAY_MONTH_LENGTH));

        transformedValues.push(day || "");

        // Only try parsing Year if the Day satisfies a length check
        if (day?.length === DAY_MONTH_LENGTH) {
            let year = sanitizeDateSegment(yearSegment, YEAR_LENGTH);
            let currentYear = new Date().getFullYear();

            // Default to current year if the supplied year is greater than current year
            if (year && parseInt(year, 10) > currentYear) {
                year = currentYear.toString();
            }

            transformedValues.push(year || "");
        }
    }

    return transformedValues.join(SEPARATOR);
};

/**
 * Applies a substring on the dateSegment supplied
 * @param dateSegment The string to return a substring of
 * @param maxLength The max length of the Substring
 * @returns {string|*}
 */
const sanitizeDateSegment = (dateSegment, maxLength) => {
    if (dateSegment) {
        return dateSegment.substring(0, maxLength);
    }
    return dateSegment;
};

/**
 * Returns the number of days in a given Month, defaulting to the current Year
 * @param month The month to calculate days for
 * @param year  Optional year, defaults to a leap year in order to account for 2/29
 * @returns {number}
 */
const daysInMonth = (month, year = 2020) => {
    return new Date(year, month, 0).getDate();
};

/**
 * Transforms a date segment into an "acceptable" input value
 * @param maxValue  The maximum value that this segment can be
 * @param value The current input value from the user
 * @returns {string|*}
 */
const transformDateSegment = (maxValue, value) => {
    if (value) {
        // maxValue cannot be more than 99
        maxValue = Math.min(maxValue, 99);

        if (value.length === 1) {
            // If the current value is an acceptable first input value, let the user keep typing.
            const maxFirstDigit = maxValue / 10;
            if (parseInt(value, 10) <= maxFirstDigit) {
                return value;
            }

            // Otherwise, we can assume the segment is 0 + value and speed up the user's data entry
            return `0${value}`;
        } else if (value.length === 2) {
            // Parse an integer from the value so we can enforce some boundaries on it
            const valueInt = parseInt(value, 10);

            if (valueInt < 1) {
                // 00 is not a valid value, so default to 01
                return '01';
            } else if (valueInt > maxValue) {
                // If the user enters a value beyond the max value, then return the max value
                return maxValue.toString();
            }

            // Otherwise, the current value is legit, so return it
            return value;
        }
    }
    return value;
};

const dobChangeHandler = (e, currentValue) => {
    e.preventDefault();
    return formatDateValues(e.target.value, currentValue);
};

const DOBInput = forwardRef(({ value, onChange, InputComponent = InsideLabelInput, ...rest }, ref) => {
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current && inputRef.current.focus();
        },
        blur: () => {
            inputRef.current && inputRef.current.blur();
        },
        click: () => {
            inputRef.current && inputRef.current.click();
        },
        select: () => {
            inputRef.current && inputRef.current.select();
        },
    }));

    return (
        <InputComponent
            {...rest}
            type="text"
            inputMode="numeric"
            pattern="[0-9\s/]+"
            placeholder={DATE_PATTERN.toUpperCase()}
            value={formatDateValues(value, "")}
            ref={inputRef}
            autoComplete="off"
            onChange={(e) => {
                onChange(dobChangeHandler(e, value));
            }}
        />
    );
});

export default DOBInput;