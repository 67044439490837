import React from 'react';
import cx from 'classnames';

import BodyText from '../../text/body-text';
import { FormikCustomFieldCommonProps } from '../';

import './index.scss';

const Checkbox: React.FC<
    FormikCustomFieldCommonProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ className = '', label, error, helper, required, ...otherProps }) => {
    const classes = cx('checkbox', className, {
        error,
    });

    return (
        <div className="flex items-center">
            <div>
                {/* Make sure we put hard-coded type at the end so it can't get overwritten */}
                <input
                    className={classes}
                    {...otherProps}
                    type="checkbox"
                    aria-label={label}
                />
            </div>

            {label && <BodyText className="ml-4">{label}</BodyText>}
        </div>
    );
};

export default Checkbox;
