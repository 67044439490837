import { createSlice } from '@reduxjs/toolkit'
import {getAllSerial, getInitialState} from "../utils";

const SLICE_NAME = "pages";
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: true,
    pageMap: {},
    currentPage: null,
    showBanner: false,
    personalAgentGlobalSettings: null,
    partnerGlobalSettings: null
});

const pagesSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        pageSuccess(state, action) {
            state.loading = false;
            state.pageMap[new URL(action.payload.link).pathname] = action.payload;
        },
        pageError(state) {
            state.loading = false
        },
        pagesSuccess(state, action) {
            state.loading = false;
            state.pageMap = action.payload.reduce((ret, page) => ({
                ...ret,
                [new URL(page.link).pathname]: page
            }), state.pageMap);
        },
        partnerSettingsSuccess(state, action) {
            state.partnerGlobalSettings = action.payload;
        },
        agentSettingsSuccess(state, action) {
            state.agentGlobalSettings = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        }
    }
});

export default pagesSlice.reducer;

/**
 * Actions
 */

export const {
    loading, pageSuccess, pageError, pagesSuccess, partnerSettingsSuccess, agentSettingsSuccess, setCurrentPage
} = pagesSlice.actions;

const DEFAULT_PATH = "homepage";

/**
 * Fetches a single Page
 * @param slug  The human-readable identifier for the Page to fetch
 */
export const fetchPage = (slug, pathname) => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));

        return wpApi.pages().slug(slug || DEFAULT_PATH).get()
            .then(results => {
                if (results && results.length) {
                    const page = results.find((result, index) => {
                        if (!pathname || results.length === 1) {
                            return true;
                        }
                        return new URL(result.link).pathname === forceTrailingSlash(pathname);
                    });

                    page.slug = slug;
                    dispatch(pageSuccess(page));
                }
            })
            .catch(error => {
                console.error(error);
                dispatch(pageError(error));
            })
    }
);

/**
 * Fetches the SEO content for a page
 * @param slug  The human-readable identifier for the Page to fetch
 */
export const fetchPageSEO = (slug, pathname) => (
    (dispatch, getState, {wpApi}) => {
        return dispatch(fetchPage(slug, pathname));
    }
);

/**
 * Fetches a group of Pages
 * @param slugs  List of human-readable identifiers for the Pages to fetch
 */
export const fetchPageSet = (slugs) => (
    (dispatch, getState, {wpApi}) => {
        return Promise.all(slugs.map(slug => wpApi.pages().slug(slug || DEFAULT_PATH).get()))
            .then(results => {
                dispatch(pagesSuccess(results.map(pages => pages[0])))
            })
            .catch(error => {
                console.error(error);
                dispatch(pageError(error));
            });
    }
);

/**
 * Fetches all the pages that will need to be rendered
 */
export const fetchAllPages = () => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        return getAllSerial(() => wpApi.pages().param('_fields', 'title,link,slug,template').perPage(100))
            .then(results => {
                return dispatch(pagesSuccess(results));
            })
            .catch(error => {
                console.error(error);
                dispatch(pageError(error));
            })
    }
);

/**
 * Fetches the "Global" settings for the Partner template pages. These settings get merged with the specific page data
 */
export const fetchPartnerSettings = () => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        return wpApi.pageSettings().id("partner-pages").get()
            .then(results => {
                return dispatch(partnerSettingsSuccess(results));
            })
            .catch(error => {
                console.error(error);
            })
    }
);

/**
 * Fetches the "Global" settings for the Agent template pages. These settings get merged with the specific page data
 */
export const fetchAgentSettings = () => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        return wpApi.pageSettings().id("agent-pages").get()
            .then(results => {
                return dispatch(agentSettingsSuccess(results));
            })
            .catch(error => {
                console.error(error);
            })
    }
);

/**
 * Forces a trailing slash at the end of the supplied path
 * @param path  The path to force the trailing slash on
 * @returns {string}    The path supplied with a trailing slash on the end
 * @private
 */
export function forceTrailingSlash(path) {
    if (!path) {
        return path;
    }

    return path + (path.substring(path.length - 1) === "/" ? "" : "/");
}
