import React from 'react';

import PageSection from "../../../components/_common/section/page";
import BodyText from '../../../components/_common/text/body-text';
import Grid from "../../../components/_common/grid";
import VideoCard from "../../../components/_common/card/video";
import H4Serif from "../../../components/_common/text/heading/h4/serif";
import BodyTextLarge from "../../../components/_common/text/body-text/large";

import videoPlaceholderImage from "../../../images/finish-video-placeholder.jpeg";
import { PolicySeal } from "../../../images/svgs";

const FinishTrust: React.FC = () => {
    return (
        <PageSection className="bg-off-white sm:bg-transparent">
            <Grid className="mt-24 sm:mt-20 lg:mt-32">
                <div className="col-span-full hidden lg:flex lg:row-start-1 lg:col-start-1 lg:col-end-8 z-10 lg:mr-9 items-center">
                    <VideoCard id="video-large" videoId="zE2fcdMg9Oo" placeholderImage={videoPlaceholderImage} />
                </div>
                <div className="relative -mt-28 sm:mt-0 col-span-full sm:col-start-2 sm:col-span-6 lg:row-start-1 lg:col-start-7 lg:col-stop-13 sm:bg-off-white sm:pt-4 sm:px-6 sm:pb-6 sm:mx-10 lg:mx-0 lg:mr-9 lg:pt-16 lg:pl-14 lg:pr-8 lg:pb-16">
                    <H4Serif className="text-center sm:text-left">
                        Coverage you can trust.
                    </H4Serif>
                    <div className=" border-0 border-solid border-b border-coral my-4 hidden lg:block" />
                    <BodyTextLarge className="hidden sm:block mb-4">
                        We get it. We’re the new guys on the block, and insurance is all about trust.
                    </BodyTextLarge>
                    <div className="lg:hidden sm:-mx-16 mt-6 sm:mt-6">
                        <VideoCard id="video-small" videoId="zE2fcdMg9Oo" placeholderImage={videoPlaceholderImage} />
                    </div>
                    <BodyTextLarge className="mb-6 text-center mt-8 sm:hidden mx-2">
                        We get it. We’re the new guys on the block, and insurance is all about trust.
                    </BodyTextLarge>
                    <Grid className="mb-12 sm:mb-0">
                        <div className="col-span-1 sm:hidden flex justify-end">
                            <PolicySeal className="w-12 h-14" />
                        </div>
                        <div className="col-span-3 sm:col-span-full">
                            <BodyText className="sm:mt-8 lg:mt-0">
                                That’s why we work with some of the most trusted names in insurance, like Munich Re® and
                                North American Company for Life and Health Insurance® – each with over 150 years of experience
                                (and A+ ratings from AM Best).
                            </BodyText>
                        </div>
                    </Grid>
                    <PolicySeal className="absolute -top-9 sm:w-12 lg:w-auto sm:-right-6 lg:-right-9 z-10 overflow-visible hidden sm:block" />
                </div>
            </Grid>
        </PageSection>
    );
};

export default FinishTrust;
