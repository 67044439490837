import React from 'react';

import DeclineTemplate, { Props } from "../../index";
import BodyText from "../../../../../../../_common/text/body-text";
import { PARTNER_CONSTANTS } from "../../../../../data/partnerConstants";
import { optimizePhoneUrl } from "../../../../../utils/quoteUtils";

import Zander from '../../../../../img/partners/zanderInsurance.svg';

const ZanderDeclineScreen: React.FC<Props> = (props) => {
    const telNumber = PARTNER_CONSTANTS.zanderinsurance.telNumber;
    const email = PARTNER_CONSTANTS.zanderinsurance.email;
    props.partnerInfo.displayName = PARTNER_CONSTANTS.zanderinsurance.name;

    return (
        <DeclineTemplate {...props}>
            <div>
                <div className="flex justify-center mt-6 mb-4">
                    <BodyText>
                        Call <a href={`tel:${optimizePhoneUrl(telNumber)}`}>{telNumber}</a> or <a href={`mailto:${email}`}>email.</a>
                    </BodyText>
                </div>
                <div className="flex justify-center">
                    <a href={`tel:${optimizePhoneUrl(telNumber)}`} rel="noopener noreferrer" target="_blank">
                        <img src={Zander} alt="Zander Insurance" className="w-44" />
                    </a>
                </div>
            </div>
        </DeclineTemplate>
    )
};

export default ZanderDeclineScreen;
