import React, {MouseEventHandler, useState} from 'react';

import {ReactComponent as SearchIcon} from "../../../../images/Search.svg";

import './index.scss';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    className?: string,
    onSearch?: MouseEventHandler,
    value?: string,
}

const Search: React.FC<Props> = ({ className = "", value, onSearch, ...otherProps }) => {
    const [focused, setFocused] = useState(false);
    const inputClassName = `search-bar bg-transparent flex-1 border-0 border-solid border-b-2 font-sans text-charcoal transition-all hover:bg-off-white ${focused ? "border-turquoise" : "border-charcoal"}`;

    return(
        <div className={`search flex w-full relative ${className}`}>
            <input
                type="text"
                className={inputClassName}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                autoComplete="off"
                {...otherProps}
            />
            <SearchIcon className="search-icon" onClick={onSearch} />
        </div>
    )
};

export default Search;
