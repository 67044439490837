import { useMemo } from "react";

import allFaqs from '../components/_common/modules/faqs/all/data';

export default function useFAQsByIdFilter(ids) {
    const idString = ids.join(",");
    return useMemo(() => {
        const faqs = allFaqs.filter(faq => ids.includes(faq.id));
        return ids.map(id => faqs.find(faq => faq.id === id));
    }, [idString]); // eslint-disable-line react-hooks/exhaustive-deps
};