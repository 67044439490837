import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";

import BlogLandingHero from './hero';
import FeaturedPosts from './featured-posts';
import AllPosts from './all-posts';
import PageContent from '../../components/_common/pageContent';
import PageSection from "../../components/_common/section/page";
import useAllPostsFilter from "../../hooks/useAllPostsFilter";
import Spinner from '../../components/_common/spinner/index';
import { fetchAllPosts } from "../../redux/posts";
import { fetchAllPosts as fetchAllLearningCenterPosts } from "../../redux/learningCenter";

import './index.scss';

interface Props {
    pageTitle?: string,
    isLearningCenter?: boolean
}

const BlogLandingPage: React.FC<Props> = ({ pageTitle = "Blog", isLearningCenter = false }) => {
    const allPosts = useAllPostsFilter(isLearningCenter, true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(isLearningCenter ? fetchAllLearningCenterPosts() : fetchAllPosts());
        // if (allPosts.length < 100) {
        //     dispatch(fetchAllPosts());
        // }
    }, [dispatch, isLearningCenter]);

    return (
        <>
            <PageContent className="blog-landing">
                {/* TODO: This should maybe be 10? */}
                {allPosts.length < 1 ?
                    <Spinner/> :
                    <>
                        <PageSection className="mb-12 sm:mb-16 lg:mb-24">
                            <BlogLandingHero pageTitle={pageTitle} isLearningCenter={isLearningCenter} />
                        </PageSection>
                        <PageSection>
                            <FeaturedPosts isLearningCenter={isLearningCenter} />
                        </PageSection>
                        <PageSection>
                            <AllPosts isLearningCenter={isLearningCenter} />
                        </PageSection>
                    </>
                }
            </PageContent>
        </>
    )
};

export default BlogLandingPage;
