import React, { Dispatch, SetStateAction } from 'react';
import PrimaryButton from '../../../components/_common/button/primary';
import {
    ChatHeaderIcon,
    CloseMenuIcon,
    HamburgerIcon,
    PhoneHeaderIcon,
} from './img';
import { smoothScrollToTop } from '../../../utilities/smoothScrollToTop';
import { openSalesforceChat } from '../../../utilities/salesforce';
import BestowLogo from '../../../images/logo-blue.svg';

import './index.scss';
import PrimaryButtonSmall from '../../../components/_common/button/primary/small';


interface Props {
    isQuoteInView: boolean;
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const NavBar: React.FC<Props> = ({
    isQuoteInView,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
}) => {
    return !isMobileMenuOpen ? (
        <div className="fixed bg-white w-full drop-shadow-md z-40 h-16 flex justify-center">
            <div className="flex flex-row items-center w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
                <div className="hidden sm:block flex-initial sm:flex-1 px-4">
                </div>
                <div className="flex-initial">
                    <a href="/">
                        <img
                            src={BestowLogo}
                            alt="Bestow"
                            className="pl-4 h-4 sm:h-5 block"
                        />
                    </a>
                </div>
                <div className="pr-4 flex-1 flex justify-end">
                    <PrimaryButton
                        onClick={smoothScrollToTop}
                        className={`${isQuoteInView ? 'hidden' : 'hidden sm:block'}`}
                    >
                        Get Estimate
                    </PrimaryButton>
                    <PrimaryButtonSmall
                        onClick={smoothScrollToTop}
                        className={`${isQuoteInView ? 'hidden' : 'sm:hidden'}`}
                    >
                        Get Estimate
                    </PrimaryButtonSmall>
                </div>
            </div>
        </div>
    ) : (
        <div className="bg-white pointer-events-auto fixed w-full h-full top-0 left-0 z-50 flex flex-col justify-start font-rotunda items-center">
            <img
                src={BestowLogo}
                alt="Bestow"
                className="logo-expanded mt-4 mb-14"
            />
            <a
                className="underline text-navy text-14 font-light leading-6 mb-3"
                href="/terms/"
            >
                Terms
            </a>

            <a
                className="underline text-navy text-14 font-light leading-6 mb-3"
                href="/privacy/"
            >
                Privacy Policy
            </a>

            <a
                className="underline text-navy text-14 font-light leading-6 mb-3"
                href="/licenses/"
            >
                Licenses
            </a>
            <div className="w-full mt-auto mx-4 mb-12 flex">
                <PrimaryButton
                    onClick={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen);
                        smoothScrollToTop();
                    }}
                    className={`${isQuoteInView ? 'hidden' : ''} flex-1 mx-4`}
                >
                    Get Estimate
                </PrimaryButton>
            </div>
        </div>
    );
};

export default NavBar;
