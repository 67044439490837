import React from 'react';
import cx from 'classnames';

import PageSection from "../../section/page";
import H3Sans from "../../text/heading/h3/sans";

interface Props {
    title: any,
    className?: string,
}

const CTAModule: React.FC<Props> = ({ className = "", title, children }) => {
    const classes = cx("py-16 sm:pt-24 sm:pb-24 lg:pt-28 lg:pb-28", className);

    return (
        <PageSection className={classes}>
            <H3Sans className="mb-10 sm:mb-16 text-center">
               {title}
            </H3Sans>
            <div className="flex justify-center">
                {children}
            </div>
        </PageSection>
    );
};

export default CTAModule;