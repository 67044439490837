import React from 'react';

import Eyebrow, { Props } from '../index';

import './index.scss';

const EyebrowSlim: React.FC<Props> = ({ className = "", ...otherProps }) => {
    return <Eyebrow className={`eyebrow-slim ${className}`} {...otherProps} />
};

export default EyebrowSlim;