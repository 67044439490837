export const quoteLoadingSelectorNoHealthExperiment = state =>
    state.noHealthQuote.loading;
export const bestQuoteSelectorNoHealthExperiment = state =>
    state.noHealthQuote.bestQuote;
export const bestProductRatesSelectorNoHealthExperiment = state =>
    state.noHealthQuote.bestProductRates;
export const goodProductRatesSelectorNoHealthExperiment = state =>
    state.noHealthQuote.goodProductRates;
export const averageProductRatesSelectorNoHealthExperiment = state =>
    state.noHealthQuote.averageProductRates;
export const defaultProductSelectorNoHealthExperiment = state =>
    state.noHealthQuote.defaultProduct;
export const quotePartnerSelectorNoHealthExperiment = state =>
    state.noHealthQuote.quotePartner;
export const quoteFormSelectorNoHealthExperiment = state =>
    state.noHealthQuote.quoteForm;
export const ctsQuoteLoadingSelectorNoHealthExperiment = state =>
    state.noHealthQuote.ctsQuoteLoading;
export const ctsQuoteSelectorNoHealthExperiment = state =>
    state.noHealthQuote.ctsQuote;
