/**
 * Screen breakpoints to be used throughout the app.
 */
const screenBreakpoints = {
    xs: 320,
    sm: 768,
    lg: 1024,
    xl: 1440,
};

module.exports = { screenBreakpoints };
