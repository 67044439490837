import React from 'react';

import Subtitle1 from '../../../components/_common/text/heading/subtitle1/index';
import LegalText from '../../../components/_common/text/legal-text/index';
import ReferralTermsSubSection from '../subsection';
import Grid from "../../../components/_common/grid";
import CategoryNumber from "../../../components/_common/categoryNumber";
import BodyTextArray from "../../../components/_common/text/body-text/array";

interface Props {
    sectionData: {
        body?: Array<string>,
        title?: string,
        subSections?: Array<{
            title?: string,
            body?: Array<string>
        }>,
        legalText?: string
    },
    sectionNumber: number,
}

const ReferralTermsSection: React.FC<Props> = ({sectionData, sectionNumber}) => (
    <Grid className="mb-14 sm:mb-10 lg:mb-12">
        <div className="col-span-full sm:col-span-1 lg:col-start-2 flex justify-center">
            <CategoryNumber className="sm:mb-0">
                {sectionNumber}
            </CategoryNumber>
        </div>
        <div className="col-span-full sm:col-span-7 lg:col-span-9">
            {sectionData.title &&
                <Subtitle1 className="text-coral-darkest mb-4 sm:mt-1 text-center sm:text-left">
                    {sectionData.title}
                </Subtitle1>
            }
            {sectionData.body &&
                <BodyTextArray data={sectionData.body} />
            }
            {sectionData.subSections &&
                <div className="mt-8">
                    {sectionData.subSections.map((subSection, subSectionIdx) => (
                        <ReferralTermsSubSection
                            key={`referral-terms-sub-section-${subSectionIdx}`}
                            subsectionData={subSection}
                            idx={subSectionIdx}
                            subsectionsCount={sectionData.subSections?.length ?? 0}
                        />
                    ))}
                </div>
            }
            {sectionData.legalText &&
                <LegalText style={{fontSize: 12}} className="mt-4 italic">
                    {sectionData.legalText}
                </LegalText>
            }
        </div>
    </Grid>
);

export default ReferralTermsSection;
