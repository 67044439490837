import React from 'react';
import { HashLink as Link, HashLinkProps } from 'react-router-hash-link';

interface Props extends HashLinkProps {
    offset: number;
}

const OffsetScrollLink: React.FC<Props> = ({ offset, ...otherProps }) => {
    const scrollWithOffset = (el: Element) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: yCoordinate + offset, behavior: 'smooth' });
    };

    return (
        <Link {...otherProps} scroll={scrollWithOffset} smooth />
    );
};

export default OffsetScrollLink;
