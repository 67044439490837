import WPAPI from 'wpapi';
import sanitizeSearch from '../utilities/sanitizeSearch';
import { replaceWordPressUrls } from "../utilities/wordpressUtils";

/**
 * WordPress API Layer. We override the 'get' transport so we can dynamically fetch data from local json or the WP
 * API.
 *
 * TODO: This needs to get wrapped in one more layer so we can obfuscate the implementation details of the API such that
 * TODO: if we decided to move on from WordPress we could update the API layer with little-to-no impact on the code that
 * TODO: consumes it.
 */

const wpApi = new WPAPI({
    endpoint: `${process.env.REACT_APP_WP_PROTOCOL}://${process.env.REACT_APP_WP_URL}/wp-json`,
    transport: {
        // Override the 'get' transport so we can dynamically send requests to local json files instead of the remote server
        get: function( wpreq ) {
            // TODO: Add another environment variable we can check so that we can query live data in QA but only local data in Prod
            if (process.env.NODE_ENV === "production") {
                const url = new URL(wpreq.toString());
                const localPath = `/data${url.pathname}${sanitizeSearch(url.search)}.json`;

                return fetch(localPath)
                    .then(result => result.json())
                    .then(data => {
                        return data;
                    })
                    .catch(err => {
                        // Don't issue requests to WordPress if the app was pre-rendered
                        if (window.__PRELOADED_STATE__) {
                            throw err;
                        }

                        // Fallback to normal request if the local json file is not present
                        return _replaceWordPressLinks(wpreq);
                    });
            }
            return _replaceWordPressLinks(wpreq);
        }
    }
});

wpApi.tokens = wpApi.registerRoute("jwt-auth/v1", "/token");
wpApi.menus = wpApi.registerRoute("menus/v1", "/menus/(?P<id>)");
wpApi.faqs = wpApi.registerRoute("wp/v2", "/faq");
wpApi.pageSettings = wpApi.registerRoute("bestow/v1", "/options/(?P<id>)");
wpApi.yoastSettings = wpApi.registerRoute("wp-rest-yoast-meta/v1", "home");
wpApi.learningCenterPosts = wpApi.registerRoute("wp/v2", "/learning-center-posts/(?P<id>)");

function _replaceWordPressLinks(req) {
    return WPAPI.transport.get(req)
        .then(results => {
            // Stringify the response
            const json = JSON.stringify(results);

            // Parse the json string with new urls
            const response = JSON.parse(replaceWordPressUrls(json));

            // Copy the _paging info from the original results
            response._paging = results._paging;

            return response;
        })
}

export default wpApi;