import { pickBy, identity } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../../hooks/use-sitemetadata';
import DelayRender from "../_common/delayRender";

const SEO = ({page}) => {
  const {
    yoast_meta: yoastMeta,
    yoast_json_ld: yoastJSON,
    yoast_title: yoastTitle,
  } = page;

  const { title: defaultTitle, siteLanguage } = useSiteMetadata();
  const wordpressID = '@id';
  const wordpressType = '@type';

  const buildGraph = () => {
    const { '@graph': graph } = yoastJSON ? yoastJSON[0] : {};
    if (graph) {
      return graph.map((item) => {
        const { image, isPartOf, logo, primaryImageOfPage, publisher, sameAs, url } = item;

        const result = {
          '@type': item[wordpressType],
          '@id': item[wordpressID],
          dateModified: item.dateModified,
          datePublished: item.datePublished,
          description: item.description,
          inLanguage: item.inLanguage,
          name: item.name,
          sameAs,
          url,
        };

        if (image) {
          result.image = {
            '@id': image[wordpressID],
          };
        }
        if (isPartOf) {
          result.isPartOf = {
            '@id': isPartOf[wordpressID],
          };
        }
        if (primaryImageOfPage) {
          result.primaryImageOfPage = {
            '@id': primaryImageOfPage[wordpressID],
          };
        }
        if (publisher) {
          result.publisher = {
            '@id': publisher[wordpressID],
          };
        }
        if (logo) {
          result.logo = {
            '@type': logo[wordpressType],
            '@id': logo[wordpressID],
            inLanguage: logo.inLanguage,
            url: logo.url,
            caption: logo.caption,
          };
        }

        return pickBy(result, identity);
      });
    }
    return null;
  };
  const graph = buildGraph();
  const jsonLD = graph
    ? {
        '@context': 'http://schema.org',
        '@graph': graph,
      }
    : null;
  return (
      // KBM - Delay the rendering of our Helmet instance so we don't get overwritten by AQX
      <DelayRender timeout={500}>
        <Helmet title={yoastTitle || defaultTitle}>
          <html lang={siteLanguage} />
          <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
          {yoastMeta?.map((child, i) => {
            const properties = pickBy(child, identity);

            if (!properties) {
              return null;
            }
            return <meta {...properties} key={`${i}-meta`} />;
          })}
        </Helmet>
      </DelayRender>
  );
};

export default SEO;
