import { useMemo } from 'react';
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import wpApi from "../api";
import firebaseApi from "../api/firebase";
import rootReducer from "../redux";

const useReduxStore = () => {
    return useMemo(() => {
        const customizedMiddleware = getDefaultMiddleware({
            thunk: {
                extraArgument: { wpApi, firebaseApi }
            },
            immutableCheck: false,
            serializableCheck: false
        });

        const store = configureStore({
            reducer: rootReducer,
            middleware: customizedMiddleware
        });

        // Callback function for ReactSnap that persists the Redux store to the page's pre-rendered HTML
        window.snapSaveState = () => {
            // We need to move all the meta tags to above the first script tag, because this state gets injected before the first
            // script tag as well, and we want og meta tags to be parsable as early on in downloaded content
            const metaTags = document.getElementsByTagName("meta");
            const firstScript = Array.from(document.scripts)[0];
            for(const tag of metaTags) {
                firstScript.parentNode.insertBefore(tag, firstScript)
            }

            return {
                __PRELOADED_STATE__: store.getState()
            };
        };

        return store;
    }, [])
};

export default useReduxStore;