import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';

import Subtitle2 from '../../../components/_common/text/heading/subtitle2';
import AspectRatioBox from "../../../components/_common/aspectRatioBox";
import BodyText from "../../../components/_common/text/body-text";

import {PlayCircleIcon} from '../../../images/icons/index';

export interface Video {
    video_id: string,
    video_thumbnail: {
        alt: string,
        url: string
    },
    video_title: string
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    ytReady: boolean,
    index: number,
    currentIndex: number,
    item: Video,
}

/**
 * Single "Slide" for the Video Slider. Handles starting and stopping its corresponding video by listening
 *
 * @param ytReady True if the YouTube Api is ready
 * @param index The index of this slide
 * @param currentIndex  The current index of the Slider
 * @param item  The data object for this Slide
 */
const VideoSliderItem: React.FC<Props> = ({ytReady, index, currentIndex, item, className}) => {
    const [player, setPlayer] = useState(null);
    const ref = useRef<HTMLDivElement>(null);
    const container = ref?.current;

    useEffect(() => {
        if (ytReady && index === currentIndex && !player && Boolean(container)) {
            // @ts-ignore
            setPlayer(new window.YT.Player(item.video_id, {
                width: container?.offsetWidth,
                height: container?.offsetHeight,
                videoId: item.video_id,
                playerVars: {
                    cc_lang_pref: 'en',
                    fs: 1,
                    controls: 1,
                    showinfo: 0,
                    autoplay: 0,
                    rel: 0,
                    color: 'white',
                },
            }));
        } else if (player && index !== currentIndex) {
            // @ts-ignore
            player.destroy();
            setPlayer(null);
        }
    }, [ytReady, index, currentIndex, item, container, player]);

    return (
        <div className={cx("video-container relative", className, {
            "video-current": index === currentIndex
        })}>
            <AspectRatioBox className="placeholder bg-center bg-cover relative cursor-pointer"
                            style={{backgroundImage: `url(${item.video_thumbnail.url})`}}>
                <div className="flex center justify-center items-center h-full">
                    <PlayCircleIcon className="h-14 w-14 lg:h-20 lg:w-20"/>
                </div>
            </AspectRatioBox>
            <div className="video-wrapper absolute top-0 left-0 right-0">
                <AspectRatioBox>
                    <div ref={ref} className="video w-full h-full" id={item.video_id}/>
                </AspectRatioBox>
            </div>
            <div className="flex flex-col items-center">
                <BodyText className="video-title mt-4 text-gray-600 mb-0">
                    {item.video_title}
                </BodyText>
                <Subtitle2 className="video-title-selected text-black -mt-3 sm:mt-4 lg:mt-7 transition-all mb-4">
                    {item.video_title}
                </Subtitle2>
            </div>
        </div>
    )
};

export default VideoSliderItem;
