import React from 'react';
import cx from 'classnames';

import {CareersArrowRight} from "../../../../images/svgs";

import './index.scss';

interface Props {
    expanded: boolean
}

const ExpandingArrow: React.FC<Props> = ({ expanded }) => {
    const classes = cx("arrow-expanding", "transition-all cursor-pointer relative flex justify-center w-5", {
        "expanded": expanded
    });

    return (
        <div className={classes}>
            <div className="transition-all line-container absolute inset-0 flex items-center justify-center">
                <div className="center-line transition-all bg-coral w-0 duration-300" />
            </div>
            <CareersArrowRight className="arrow transition-all duration-300" />
        </div>
    )
};

export default ExpandingArrow;