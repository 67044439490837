import React from 'react';

import BodyTextArray from "../../../components/_common/text/body-text/array";
import BodyTextBold from "../../../components/_common/text/body-text/bold";
import { SubSection } from "../";

interface Props {
    subsectionData: SubSection,
}

const PrivacySubSection: React.FC<Props> = ({subsectionData}) => (
    <div>
        <div className={`flex flex-col sm:flex-row}`}>
            <div>
                <BodyTextBold className="text-navy mt-1 mb-4 mx-0 text-left sm:mt-1">
                    {subsectionData.title}
                </BodyTextBold>
                {subsectionData.body && <BodyTextArray data={subsectionData.body} classes="mb-4" />}
            </div>
        </div>
    </div>
);

export default PrivacySubSection;
