import React from 'react';

import ReviewSliderModule from "../../../components/_common/modules/reviewSlider"

const Reviews: React.FC = () => {
    const reviews = [
        {
            review_author: 'Danyelle W.',
            review_content: 'I am thankful to have gotten a policy with double the coverage I had previously for the same price!',
            review_title: '',
            stars: '5'
        },
        {
            review_author: 'Gregory',
            review_content: 'Literally took me five minutes. No invasive medical exams. They just asked me a few questions, and I was covered.',
            review_title: '',
            stars: '5'
        },
        {
            review_author: 'Keith',
            review_content: 'The online process was fast and easy. The representatives I spoke to were helpful but not overbearing.',
            review_title: '',
            stars: '5'
        },
        {
            review_author: 'Kelsey',
            review_content: 'So easy! I got my life insurance in less than 10 minutes with no needles! Such an easy process.',
            review_title: '',
            stars: '5'
        },
        {
            review_author: 'Donald',
            review_content: 'Easy to use. No one pressuring you, and the price is amazing.',
            review_title: '',
            stars: '5'
        }
    ];

    return (
        <ReviewSliderModule
            className="mt-16 mb-12 sm:mt-24 sm:mb-20 lg:mt-32 lg:mb-28"
            reviews={reviews}
        />
    );
};

export default Reviews;
