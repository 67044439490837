import React from 'react';
import cx from 'classnames';

import BodyText from "../../text/body-text";

import './index.scss';

const RadioButton: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({ className, name, children, ...otherProps }) => {
    return (
        <label className="flex items-center">
            <input className={cx("brand-radio-button m-0 mr-3", className)} {...otherProps} type="radio" name={name} />
            <BodyText className="mb-0 flex-1">{children}</BodyText>
        </label>
    )
};

export default RadioButton;