import React from 'react';
import BodyText from '../../../components/_common/text/body-text';

interface Props {
    subsectionsCount: number,
    subsectionData: {
        title?: string,
    },
    idx: number
}

const ReferralTermsSubSection: React.FC<Props> = ({subsectionData, idx}) => (
    <div className="flex">
        <div>
            <span className="subsection-number mr-6 text-gray-700">{idx+1}.</span>
        </div>
        <div>
            <BodyText className="mb-4">{subsectionData.title}</BodyText>
        </div>
    </div>
);

export default ReferralTermsSubSection;
