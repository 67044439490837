import React from 'react';
import cx from 'classnames';

import HelperText from "../../text/helperText";
import { FormikCustomFieldCommonProps } from "../index";

import './index.scss';

const InsideLabelInput = React.forwardRef<HTMLInputElement, FormikCustomFieldCommonProps>(({
       className = "",
       label,
       error,
       helper,
       labelHelper,
       required,
       ...otherProps
   }, ref) => {
    return (
        <>
            <div className="inside-label-input relative w-full flex">
                <input {...otherProps} className={cx("px-4 pt-4 h-8 flex-1", { "error": error })} type="text" ref={ref} />
                <span className="absolute top-0 left-0 px-4 pointer-events-none floating-label transform transition-all flex items-center font-light font-sans">
                    {label}{required && <span className="text-error">*</span>}
                </span>
            </div>
            {helper &&
                <div className="mt-1">
                    <HelperText error={error}>
                        {helper}
                    </HelperText>
                </div>
            }
        </>
    );
});

export default InsideLabelInput;