type Props = {
    className?: string;
};

export default (props: Props) => (
    <div {...props}>
        <svg width="91" height="15" viewBox="0 0 91 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.25 5.65574L11.4 9.14754L13.25 14.7541L8.4 11.2623L3.5 14.7541L5.35 9.09836L0.5 5.65574H6.5L8.4 0L10.25 5.65574H16.25ZM28.8 5.65574L26.95 0L25.1 5.65574H19.1L23.95 9.14754L22.05 14.7541L26.9 11.2623L31.75 14.7541L29.9 9.09836L34.75 5.60656H28.8V5.65574ZM47.4 5.65574L45.55 0L43.7 5.65574H37.7L42.55 9.14754L40.65 14.7541L45.5 11.2623L50.35 14.7541L48.5 9.09836L53.35 5.60656H47.4V5.65574ZM65.9 5.65574L64.05 0L62.2 5.65574H56.2L61.05 9.14754L59.2 14.7541L64.05 11.2623L68.9 14.7541L67.05 9.09836L71.9 5.60656H65.9V5.65574ZM90.5 5.65574H84.5L82.6 0L80.75 5.65574H74.75L79.6 9.14754L77.75 14.7541L82.6 11.2623L87.5 14.7541L85.65 9.09836L90.5 5.65574Z" fill="#E8B066"/>
        </svg>
    </div>
);