import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import BestowSite from './components/site';
import useReduxStore from './hooks/useReduxStore';
import useTrackSegmentAlias from './hooks/useTrackSegmentAlias';

import './App.css';
import { useVariationAnalytics } from './hooks/useVariationAnalytics';

function App({ children }) {
    const store = useReduxStore();
    useVariationAnalytics();
    useTrackSegmentAlias();
    return (
        <Provider store={store}>
            <Router>
                <div className="App">{children || <BestowSite />}</div>
            </Router>
        </Provider>
    );
}

export default App;
