import PageContent from '../../components/_common/pageContent/navy';
import PageSection from '../../components/_common/section/page';
import H1Serif from '../../components/_common/text/heading/h1/serif';
import H2Serif from '../../components/_common/text/heading/h2/serif';
import BodyTextLarge from '../../components/_common/text/body-text/large';
import BodyText from '../../components/_common/text/body-text';
import { BestowWhiteLogo } from '../../images/logos';
import CollateralForm from './CollateralForm';

const BusinessLoanContactInfo: React.FC = () => {
    return (
        <PageContent
            className="business-loan-contact-info-page"
            showTopNav={false}
        >
            <PageSection className="bg-navy justify-start pt-10 sm:pt-16 lg:pt-20 pl-4 sm:pl-16 lg:pl-0 pb-14 sm:pb-20">
                <div className="flex flex-col">
                    <BestowWhiteLogo className="h-6 w-32" />
                    <H1Serif className="text-left text-mint-lightest max-w-3xl">
                        Life Insurance Options for Business Owners
                    </H1Serif>
                    <BodyTextLarge className="text-left mt-9 text-mint-lightest">
                        Convenient and fast life insurance for your business
                        loan.
                    </BodyTextLarge>
                </div>
            </PageSection>
            <PageSection className="px-4 sm:px-20 lg:px-28 pt-6 sm:pt-12 lg:pt-20 pb-14 lg:pb-12">
                <H2Serif className="text-left text-navy">
                    Getting started is easy.
                </H2Serif>
                <BodyText className="text-charcoal text-base mt-12">
                    It's easy to assign your life insurance as collateral for a
                    small business loan. Simply fill out the information below
                    with your lender's contact information and we'll handle the
                    rest.
                </BodyText>
                <BodyText className="text-charcoal text-base mt-4">
                    If you've purchased a life insurance policy with Bestow,
                    follow the steps below. If you don't have a policy yet,
                    apply here.
                </BodyText>
                <BodyText className="text-charcoal text-base mt-4">
                    Here's how the process works:
                </BodyText>
                <ul className="process-list">
                    <li>
                        <BodyText className="text-charcoal text-base">
                            You provide your lender's contact information in the
                            form below
                        </BodyText>
                    </li>
                    <li>
                        <BodyText className="text-charcoal text-base">
                            We'll email you a collateral assignment form to
                            E-sign
                        </BodyText>
                    </li>
                    <li>
                        <BodyText className="text-charcoal text-base">
                            We'll confirm the form is accurate and send
                            confirmation of assignment to both you and your
                            lender
                        </BodyText>
                    </li>
                    <li>
                        <BodyText className="text-charcoal text-base">
                            It's that simple! Check life insurance off your
                            to-do list and focus on growing your business.
                        </BodyText>
                    </li>
                </ul>
            </PageSection>
            <PageSection className="px-0 sm:px-20 lg:px-28 pb-14 lg:pb-12">
                <div className="form-wrapper lg:px-20">
                    <CollateralForm />
                </div>
            </PageSection>
        </PageContent>
    );
};

export default BusinessLoanContactInfo;
