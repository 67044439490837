import React from 'react';

import PageContent from "../../components/_common/pageContent";
import CareersHero from "./hero";
import CareersSlider from "./slider";
import CareersAction from "./action";
import CareersJoin from "./join";

import './index.scss';

const CareersMain: React.FC = () => {
    return (
        <PageContent className="careers-main">
            <CareersHero />
            <CareersSlider />
            <CareersAction />
            <CareersJoin />
        </PageContent>
    );
};

export default CareersMain;