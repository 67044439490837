import React from 'react';

import FAQItem from '../item';
import Grid from "../../../grid";
import H2Serif from "../../../text/heading/h2/serif";
import H4Serif from "../../../text/heading/h4/serif";

interface Props {
    faqs: {
        title: {
            rendered: string
        },
        content: {
            rendered: string
        }
    }[]
}

const FAQShortStack: React.FC<Props> = ({ faqs }) => {
    return (
        <Grid>
            <div className="col-span-full sm:col-span-4 lg:col-span-6 text-center sm:text-left">
                <H4Serif className="text-navy text-center sm:mx-9 lg:hidden">
                    You've got questions.<br/>We've got answers
                </H4Serif>
                <H2Serif className="text-navy text-center hidden lg:block lg:mx-0">
                    You've got questions.<br/>We've got answers
                </H2Serif>
            </div>
            <div className="col-span-full sm:col-span-4 lg:col-span-6 pt-2 lg:pt-5">
                {faqs.map(({title, content}) => (
                    <FAQItem key={`faq-item-short-stack-${title.rendered}`} question={title.rendered} answer={content.rendered} />
                ))}
            </div>
        </Grid>
    )
};

export default FAQShortStack;