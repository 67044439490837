import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const timestamp = (setFieldValue: any) => {
    var response = document.getElementById('g-recaptcha-response');
    // @ts-ignore
    if (response == null || response.value.trim() == '') {
        var elems = JSON.parse(
            //@ts-ignore
            document.getElementsByName('captcha_settings')[0].value,
        );
        elems['ts'] = JSON.stringify(new Date().getTime());
        setFieldValue('captcha_settings', JSON.stringify(elems));
    }
};

// Not ideal, but CRM firm passed us some custom code to set a timestamp on a recaptcha widget.
// That's all well and good on a basic HTML form that works with POST actions, but doesn't work so well on a JS
// form, which keeps its own form answer state.  So this is a react component that can use formikContext, and set
// the timestamp on an interval in a way that formik understands, making sure the recaptcha settings input is updated correctly.
const TimeStamp: React.FC = () => {
    const { setFieldValue } = useFormikContext();
    useEffect(() => {
        const interval = setInterval(() => {
            timestamp(setFieldValue);
        }, 500);
        return () => clearInterval(interval);
    }, []);
    return <></>;
};

export default TimeStamp;
