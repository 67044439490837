import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import cx from 'classnames';
import HelperText from '../../../../_common/text/helperText';
import BodyText from '../../../../_common/text/body-text';
import './index.scss';

const tobaccoOptions = {
  Yes: 'true',
  No: 'false',
};

interface Props {
  className?: string;
  onChange: () => void;
  value: string;
  helper: string;
  error: any;
}

const NicotineGroup: React.FC<Props> = ({ className = '', onChange, value, helper, error }) => {
  const classes = cx('nicotine-group-buttons', className, {
    error,
  });
  const { values } = useFormikContext<Record<string, string | boolean>>();
  const [tobaccoState, setTobaccoState] = useState('');

  // This is not ideal, but there's a pre-render bug, wherein form values update, but the Mui styles do not
  // owing to the vdom/dom being out of sync. This forces a re-render on query param update.
  useEffect(() => {
    if (values.tobacco !== '') {
      setTobaccoState(values.tobacco.toString());
    }
  }, [values]);

  return (
    <>
      <ToggleButtonGroup
        value={tobaccoState}
        className={classes}
        size="large"
        onChange={onChange}
        aria-label="Term"
        exclusive
      >
        {Object.keys(tobaccoOptions).map((key, index) => {
          const keyName = key as keyof typeof tobaccoOptions;
          return (
            <ToggleButton
              key={`tobacco-${index}`}
              value={tobaccoOptions[keyName]}
              data-testid={tobaccoOptions[keyName]}
              aria-label={tobaccoOptions[keyName].toString()}
            >
              <BodyText className={cx({ 'font-medium': tobaccoState === tobaccoOptions[keyName] })}>{key}</BodyText>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {helper && (
        <div className="mt-1">
          <HelperText error={error}>{helper}</HelperText>
        </div>
      )}
    </>
  );
};

export default NicotineGroup;
