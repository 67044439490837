import React from 'react';

import DeclineTemplate, { Props } from "../../index";
import LinkButton from "../../../../../../../_common/button/link";
import { PARTNER_CONSTANTS } from "../../../../../data/partnerConstants";
import { optimizePhoneUrl } from "../../../../../utils/quoteUtils";

import TheBaileyGroup from '../../../../../img/partners/theBaileyGroup.svg';

const TBGDeclineScreen: React.FC<Props> = (props) => {
    const telNumber = PARTNER_CONSTANTS.tbg.telNumber;

    return (
        <DeclineTemplate {...props}>
            <div>
                <div className="flex justify-center">
                    <a href={`tel:${optimizePhoneUrl(telNumber)}`} rel="noopener noreferrer" target="_blank">
                        <img src={TheBaileyGroup} alt="The Bailey Group" className="w-72" />
                    </a>
                </div>
                <div className="flex justify-center mt-6 mb-4">
                    <LinkButton href={`tel:${optimizePhoneUrl(telNumber)}`}>
                        Please call {telNumber} option 4 &gt;
                    </LinkButton>
                </div>
            </div>
        </DeclineTemplate>
    )
};

export default TBGDeclineScreen;