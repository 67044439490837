export const postAffiliatesForm = (formData, setComplete, setError) => (
    (dispatch, getState, {firebaseApi}) => {
        return firebaseApi.saveAffiliateSubmission(formData)
            .then(() => {
                setComplete && setComplete();
            })
            .catch(error => {
                console.error(error);
                setError && setError(error);
            });
    }
);