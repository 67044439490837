/**
 * Track page viewed in Iterable. In the Gatsby application this behavior was handled by a plugin
 */
const trackPageView = () => {
    const _iaq = window._iaq || [];
    _iaq.push([
        "track",
        "Page Viewed",
        {
            path: window.location.path,
            // TODO: can we look at the History to get this?
            //referrer: prevLocation ? prevLocation.href : "",
            referrer: "",
            search: window.location.search,
            title: document.title,
            url: window.location.href,
        },
    ]);
};

export default trackPageView;