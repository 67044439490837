import {useMemo, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchPageSet} from "../redux/pages";
import {allSlugsSelector} from "../redux/pages/selectors";
import {isPreRendering} from "../utilities/reactSnap";

const SEPARATOR = "|";

/**
 * Hook to prefetch pages that we want in Redux to make navigating between certain pages quicker for users. This is done
 * at runtime so the extra data doesn't get packaged with each page in its HTML
 *
 * TODO: We could also bring in the Intersection Observer library that Bonnot pulled into Landing Pages to only make this
 * TODO: Happen when inside the viewport given a ref passed in here
 * @param paths
 */
export function usePrefetchPages(paths) {
    const dispatch = useDispatch();
    const allSlugs = useSelector(allSlugsSelector);
    const pathsString = paths.join(SEPARATOR);

    // First, identify the slugs that are not in our Redux store
    const missingSlugsString = useMemo(() => {
        if (pathsString) {
            const slugs = pathsString.split(SEPARATOR).map(path => {
                const parts = path.split("/").filter(part => Boolean(part));
                if (parts.length) {
                    return parts[parts.length - 1].replace(/\//g, "");
                }
                return "";
            });

            return slugs.filter(slug => !allSlugs.includes(slug)).join(SEPARATOR);
        }
        return "";
    }, [allSlugs, pathsString]);

    // Then, fetch all the pages to get them in redux
    useEffect(() => {
        const missingSlugs = missingSlugsString.split(SEPARATOR);
        if (!isPreRendering() && missingSlugsString && missingSlugs.length) {
            dispatch(fetchPageSet(missingSlugs));
        }
    }, [dispatch, missingSlugsString]);
};
