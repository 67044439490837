import React from 'react';
import cx from 'classnames';

import LinkButton, { Props as ButtonProps } from "../link";

import './index.scss';

interface Props extends ButtonProps {
    selected?: boolean
}

const TabButton: React.FC<Props> = ({ className, selected, ...otherProps }) => {
    return (
        <LinkButton className={cx("tab-btn", className, {
            "tab-btn-selected": selected
        })} {...otherProps} />
    )
};

export default TabButton;