/**
 * Random util functions pulled from AQX that needs to eventually be destroyed, but are here for parity sake
 */

export const optimizePhoneUrl = (phoneString: string) => {
    let parsedString = phoneString.replace(/\D/g, "");
    if (parsedString.length < 11) {
        parsedString = "+1" + parsedString;
    } else {
        parsedString = "+" + parsedString;
    }
    return parsedString;
};

// This call tracks a completely custom format of data than what the form and quote endpoint were dealing with...
export const getTrackQuoteGetQuoteClickedPayload = (formData: any, payload: any) => {
    const {
        gender, birthdate, height, weight, zipCode, tobacco, partner_referral, utm_source, bestow_writing_agent
    } = formData;

    return {
        gender,
        birthdate: birthdate.replace(/ /g, ""),
        height,
        weight,
        state: payload.state,
        zip: zipCode,
        tobacco,
        partner_referral,
        bestow_writing_agent,
        utm_source,
        query_string: payload.query_string
    }
};

// TODO: Kill this with fire -- pulled from AQX
export const getCurrentPartner = (url = window.location.href) => {
    const baseUrlRegex = /https?:\/\/.*?\/(.*?)(\/|$|\?)/;
    let partnerReferralValue = url.split(baseUrlRegex)[1];
    // there's a directory of co branded landing pages of the form /agents/agentname
    if (partnerReferralValue === "agents") {
        partnerReferralValue = url
            .split(baseUrlRegex)[3]
            .split("?")[0]
            .replace("/", "");
    }
    return partnerReferralValue;
};

interface QueryParams {
    bestow_writing_agent?: string;
    hierarchy?: string;
    short_url_id?: string;
}

export const validateURL = ({bestow_writing_agent, hierarchy, short_url_id}: QueryParams) => {
    if (short_url_id) { return true; }
    if (!bestow_writing_agent || !hierarchy) { return true; }

    return false;
}
