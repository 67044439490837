import PageContent from '../../components/_common/pageContent';
import PageSection from '../../components/_common/section/page';
import TrustModule from '../../components/_common/modules/trust';
import PhoneSection from './phone-section';
import Guaranteed from './guaranteed';
import { trackGerberApplyClicked } from '../../utilities/segment.track';
import Grid from '../../components/_common/grid';
import H2Serif from '../../components/_common/text/heading/h2/serif';
import FAQList from '../../components/_common/modules/faqs/list';
import faqs from './faqs';
import FutureHands from './future-hands';
import CostsGuaranteed from './costs';
import CTAModuleOne from '../../components/_common/modules/cta/one';
import PrimaryLargeButton from '../../components/_common/button/primary/large';
import Disclaimer from './disclaimer';
import GerberHero from './gerberhero';

const GerberLanding: React.FC = () => {
    const trackAndRedirect = () => {
        trackGerberApplyClicked({});
        // @ts-ignore
        window.location.href =
            'https://gerberlife.com/gl/ng/entry/agencyDirect/guarlife?aKeycode=B0770611&userId=B0770611&partnerName=Bestow';
    };

    return (
        <PageContent className="gerber">
            <GerberHero />
            <Guaranteed />
            <FutureHands />
            <TrustModule className="bg-off-white" />
            <PhoneSection />
            <CostsGuaranteed />
            <PageSection className="py-16 sm:py-20 sm:py-24">
                <Grid>
                    <H2Serif className="text-navy text-center mb-16 col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-9">
                        You've got questions.
                        <br />
                        We've got answers.
                    </H2Serif>
                </Grid>
                <FAQList faqs={faqs} />
            </PageSection>
            <CTAModuleOne
                title={
                    <span>
                        Ready to apply for <br />
                        guaranteed life insurance?{' '}
                    </span>
                }
                className="bg-mint text-navy"
            >
                <PrimaryLargeButton onClick={trackAndRedirect}>
                    Yes, Apply
                </PrimaryLargeButton>
            </CTAModuleOne>
            <Disclaimer />
        </PageContent>
    );
};
export default GerberLanding;
