import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from "../utils";
import { getAll } from "../utils";

const SLICE_NAME = "careers";
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: true,
    jobs: null
});

const careersSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        jobsSuccess(state, action) {
            state.loading = false;
            state.jobs = action.payload;
        },
        jobsError(state) {
            state.loading = false
        }
    }
});

export default careersSlice.reducer;

/**
 * Actions
 */

export const { loading, jobsSuccess, jobsError } = careersSlice.actions;

/**
 * Fetches all the FAQs
 */
export const fetchJobs = () => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        return fetch('https://api.lever.co/v0/postings/hellobestow?mode=json')
            .then(response => response.json())
            .then(jobs => {
                const jobsByDepartment = jobs.reduce((ret, job) => {
                    const department = job.categories.department;
                    if (!ret[department]) {
                        ret[department] = {}
                    }
                    ret[department][job.id] = {
                        title: job.text,
                        location: job.categories.location,
                        url: job.hostedUrl
                    };
                    return ret;
                }, {});

                dispatch(jobsSuccess(jobsByDepartment));
            })
            .catch(err => {
                dispatch(jobsError());
                console.error(err);
            });
    }
);
