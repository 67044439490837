import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    title: string,
    links: {
        to: string,
        text: string
    }[]
}

const FooterLinkSection: React.FC<Props> = ({title, links}) => {
    return (
        <div>
            <div className="font-sans text-white text-base lg:text-lg font-bold mb-4">
                {title}
            </div>
            <div>
                {links.map((link, index) => (
                    <div key={link.to} className="mb-3">
                        <Link to={link.to} className="text-light font-sans text-white text-sm no-underline lg:text-base">
                            {link.text}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default FooterLinkSection;
