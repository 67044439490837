import React from 'react';
import cx from 'classnames';

import './index.scss';

interface Props {
    className? : string
}

const TinyText: React.FC<Props> = ({ className, children }) => {
    return (
        <p className={cx("brand-tiny-text font-sans", className)}>
            {children}
        </p>
    )
};

export default TinyText;