import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import './index.scss';

interface Props extends HTMLAttributes<HTMLElement> {
    inline?: boolean;
}

const SmallText: React.FC<Props> = ({
    inline,
    className = '',
    children,
    dangerouslySetInnerHTML,
    ...otherProps
}) => {
    const classes = cx('brand-small-text font-sans', className);
    if (dangerouslySetInnerHTML) {
        return (
            <div
                className={classes}
                {...otherProps}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
        );
    }
    if (inline) {
        return <span className={classes} {...otherProps} />;
    }

    return (
        <p className={classes} {...otherProps}>
            {children}
        </p>
    );
};

export default SmallText;
