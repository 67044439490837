/**
 * Quote widget launchDarkly analytics
 */

import { LDClient } from 'launchdarkly-js-client-sdk';

export const EVENT_NAMES = {
    visit_activity: 'visit_activity',
    requested_quote: 'requested_quote',
    received_quote: 'received_quote',
    quote_to_start_application: 'quote_to_start_application',
};

/**
 * Sends tracking event to LaunchDarkly without flush - automatically sends tracking events every 2000ms.
 * @param {LDClient | undefined} client
 * @param {keyof typeof EVENTNAMES} eventName
 * @returns void
 */

export const trackLaunchDarklyEvent = (
    client: LDClient | undefined,
    eventName: keyof typeof EVENT_NAMES,
) => {
    if (client) {
        client.track(eventName, {
            key: client.getUser().key,
        });
    }
};

/**
 * Sends tracking event to LaunchDarkly immediately by using flush method to manually send tracking events and bypassing the 2000ms timer.
 * @param {LDClient | undefined} client
 * @param {keyof typeof EVENTNAMES} eventName
 * @returns void
 */

export const trackLaunchDarklyEventWithFlush = (
    client: LDClient | undefined,
    eventName: keyof typeof EVENT_NAMES,
) => {
    trackLaunchDarklyEvent(client, eventName);
    if (client) {
        client.flush();
    }
};
