import {useEffect, useState} from "react";
import lunr from 'lunr';

import allFaqs from '../components/_common/modules/faqs/all/data';

export default function useLunrIndex() {
    const [index, setIndex] = useState(null);

    useEffect(() => {
        if (!index && allFaqs.length) {
            // TODO: What does TypeScript need here so we can remove these @ts-ignore flags
            setIndex(lunr(function () {
                // @ts-ignore
                this.field('title');
                // @ts-ignore
                this.field('content');
                // @ts-ignore
                this.field('id');

                allFaqs.forEach(function ({title, content, id}) {
                    // @ts-ignore
                    this.add({ // @ts-ignore
                        title: title.rendered,
                        content: content.rendered,
                        id
                    });
                    // @ts-ignore
                }, this)
            }))
        }
    }, [index]);

    return index;
}