import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

import './index.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
    id: string,
    aspectRatioWidth?: number,
    aspectRatioHeight?: number,
    className?: string,
}

/**
 * Container for Youtube videos that maintains the correct aspect ratio so black bars do not appear
 */
const YoutubeContainer: React.FC<Props> = ({id, aspectRatioWidth = 16, aspectRatioHeight = 9, className = "", children}) => {
    return (
        <div className={cx("youtube-video-container", className)} style={{paddingBottom: `${(aspectRatioHeight / aspectRatioWidth) * 100.0}%`}}>
            {children || <div id={id} className="youtube-video" />}
        </div>
    )
};

export default YoutubeContainer;