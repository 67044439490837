import React from 'react';
import Select from '../';
import { stateOptions } from './stateOptions';

import { Props } from '../';

/**
 * Country select with options that are specific to what was
 * on the old hubspot embed claims form
 * Reusing for salesforce
 */
const StateSelect: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({ options, ...otherProps }) => {
    return <Select options={stateOptions} {...otherProps} />;
};

export default StateSelect;
