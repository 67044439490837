import React from 'react';
import cx from 'classnames';

import './index.scss';

interface Props {
    className? : string
}

const MediumText: React.FC<Props> = ({ className, children }) => {
    return (
        <p className={cx("brand-medium-text font-sans", className)}>
            {children}
        </p>
    )
};

export default MediumText;