import React from 'react';

import Privacy from "../index";

import { privacyData } from './data';

const CaliforniaPrivacy: React.FC = () => {
    return (
        <Privacy
            title="California Consumer Privacy Act (CCPA) Notice"
            data={privacyData} />
    )
};

export default CaliforniaPrivacy;
