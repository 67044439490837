import { createSlice } from '@reduxjs/toolkit'
import {getAll, getInitialState} from "../utils";

const SLICE_NAME = "learningCenter";
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: true,
    postMap: {},
    categoryMap: {},
});

const postsSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        postSuccess(state, action) {
            state.loading = false;
            state.postMap[action.payload.slug] = action.payload;
        },
        postError(state) {
            state.loading = false
        },
        postsSuccess(state, action) {
            state.loading = false;
            state.postMap = {
                ...state.postMap,
                ...(action.payload.reduce((ret, post) => ({
                    ...ret,
                    [post.slug]: post
                }), {}))
            };
        },
        categoriesSuccess(state, action) {
            state.loading = false;
            state.categoryMap = action.payload.reduce((ret, category) => (
                {
                    ...ret,
                    [category.id]: category
                }
            ), state.categoryMap);
        }
    }
});

export default postsSlice.reducer;

/**
 * Actions
 */

export const { loading, postSuccess, postError, postsSuccess, categoriesSuccess } = postsSlice.actions;

/**
 * Fetches a single Post
 * @param slug  The human-readable identifier for the Page to fetch
 */
export const fetchPost = (slug) => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        return wpApi.learningCenterPosts().embed().slug(slug).get()
            .then(results => {
                const post = results[0];
                post.slug = slug;
                return dispatch(postSuccess(post));
            })
            .catch(error => {
                console.error(error);
                dispatch(postError(error));
            })
    }
);

/**
 * Fetches every post that needs to be rendered for the Blog
 * @param siteMap   Flag that limits the fields returned if we are fetching posts to render the SiteMap.
 * @returns {function(*, *, {wpApi: *}): Promise<any> | Promise<void> | *}
 */
export const fetchAllPosts = (siteMap = false) => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        const fields = siteMap ? 'title,link,slug' :
            'date,title,content,slug,featured_media,acf,excerpt,_links,_embedded,yoast_meta,yoast_json_ld,yoast_title';

        return getAll(() => wpApi.learningCenterPosts().embed().param('_fields', fields).perPage(50))
            .then(results => {
                return dispatch(postsSuccess(results));
            })
            .catch(error => {
                console.error(error);
                dispatch(postError(error));
            })
    }
);