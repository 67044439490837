import PageSection from '../../../components/_common/section/page';
import PrimaryButton from '../../../components/_common/button/primary';
import BodyText from '../../../components/_common/text/body-text';
import H1Serif from '../../../components/_common/text/heading/h1/serif';
import { trackGerberApplyClicked } from '../../../utilities/segment.track';
import SecondaryButtonCharcoal from '../../../components/_common/button/secondary/charcoal';
import './index.scss';

const GerberHero: React.FC = () => {
    const trackAndRedirect = () => {
        trackGerberApplyClicked({});
        // @ts-ignore
        window.location.href =
            'https://gerberlife.com/gl/ng/entry/agencyDirect/guarlife?aKeycode=B0770611&userId=B0770611&partnerName=Bestow';
    };

    return (
        <PageSection className="gerber-hero bg-cover lg:bg-center bg-center sm:bg-gerber-3 gradient-blush relative">
            <div className="inset-0 absolute sm:bg-navy xs:bg-white -z-1" />
            <div
                className="xs:pt-8 xs:pb-0 pb-72 sm:pt-12 sm:pb-24 lg:pt-36 lg:pb-48 sm:ml-0 lg:ml-20 flex flex-col items-center sm:items-start"
                data-cy="hero.section"
            >
                <H1Serif
                    className="text-primary text-48 text-center mb-8 sm:hidden"
                    data-cy="hero.mobile.header"
                >
                    Your future,
                    <br />
                    guaranteed.
                </H1Serif>
                <H1Serif
                    className="hidden text-primary text-48 mb-8 text-left sm:block leading-tight"
                    data-cy="hero.header"
                >
                    Your future,
                    <br />
                    guaranteed.
                </H1Serif>
                <BodyText className="text-primary text-center mb-8 mx-4 sm:mx-0 sm:text-left">
                    Guaranteed life insurance <br /> coverage for seniors,
                    brought to <br /> you by Bestow and issued by <br /> Gerber
                    Life Insurance.
                </BodyText>
                <div className="hidden sm:flex">
                    <div>
                        <SecondaryButtonCharcoal
                            className="bestow-track bg-transparent"
                            onClick={trackAndRedirect}
                        >
                            Apply
                        </SecondaryButtonCharcoal>
                    </div>
                </div>
            </div>
            <div className="sm:hidden">
                <PrimaryButton
                    className="bestow-track w-full"
                    onClick={trackAndRedirect}
                >
                    Apply
                </PrimaryButton>
            </div>
        </PageSection>
    );
};
export default GerberHero;
