import React from 'react';
import cx from 'classnames';

import './index.scss';

const TextFieldQuestion: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className = "", ...otherProps }) => {
    return (
        <div className={cx("text-field-question font-sans", className)} {...otherProps} />
    )
};

export default TextFieldQuestion;