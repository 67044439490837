import React, {useMemo} from 'react';
import parse, { domToReact } from "html-react-parser";
import {endsWith} from "lodash";

import H4Sans from "../../../components/_common/text/heading/h4/sans";
import Subtitle1 from "../../../components/_common/text/heading/subtitle1/index";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import BodyTextBold from "../../../components/_common/text/body-text/bold";
import BlockQuote from "../blockQuote";
import BodyText from "../../../components/_common/text/body-text";
import PostVideo from "../video";
import BlogContentToc from './toc';

import './index.scss';

interface Props {
    htmlString: string,
    excerpt?: string,
    showExcerpt: boolean
}

const BlogPostContent: React.FC<Props> = ({ htmlString, excerpt, showExcerpt }) => {
    const { content, sectionTitles } = useMemo(() => {
        const sectionTitles: string[] = [];

        const content = parse(htmlString, {
            replace: (domNode: any) => _replace(domNode, sectionTitles)
        });

        return { content, sectionTitles };
    }, [htmlString]);

    return (
        <>
            {Boolean(sectionTitles?.length) &&
                <BlogContentToc sections={sectionTitles} />
            }
            {showExcerpt && excerpt &&
                <BlockQuote quote={excerpt} />
            }
            <div className="blog-post-content">
                {content}
            </div>
        </>
    )
};

/**
 * Transforms html elements into React tags
 * @param domNode   node to transform
 * @private
 */
function _replace(domNode: any, sectionTitles: string[]) {
    _deleteStyles(domNode);

    // Handle Quote Widget
    if (domNode.name === 'script') {
        const { src, type } = domNode.attribs;
        if (src && endsWith(src, '/static/js/embedded-quote.js')) {
            // KBM - Removing the quote widget until we can re-skin it
            // return <Quote modus="landing" />;
            return <></>;
        }
    }

    // Swap out tags for Components
    if (domNode.name === "h1") {
        return <H4Sans className="text-navy mb-4">{domToReact(domNode.children)}</H4Sans>
    } else if (domNode.name === "h2") {
        // Track the h2's as section titles to create a ToC
        sectionTitles.push(_getH2Text(domNode));
        return <Subtitle1 className="text-navy mb-4" ElementType="h2" id={`section-${sectionTitles.length}`}>{domToReact(domNode.children)}</Subtitle1>
    } else if (domNode.name === "h3") {
        return <Subtitle2 className="text-navy mb-4" ElementType="h3">{domToReact(domNode.children)}</Subtitle2>
    } else if (["h4", "h5", "h6"].includes(domNode.name)) {
        return <BodyTextBold className="text-navy mb-4" ElementType={domNode.name}>{domToReact(domNode.children)}</BodyTextBold>
    } else if (domNode.name === "blockquote") {
        return <BlockQuote>{domToReact(domNode.children)}</BlockQuote>;
    } else if (domNode.name === "ul" ) {
        // KBM - add inline style here to override the css on this page used to center things like images in divs
        return <BodyText ElementType="div" style={{alignItems: "flex-start"}}><ul>{domToReact(domNode.children)}</ul></BodyText>
    } else if (domNode.name === "ol" ) {
        return <BodyText><ol>{domToReact(domNode.children)}</ol></BodyText>
    } else if (domNode.name === "th") {
        return <BodyTextBold ElementType={domNode.name}>{domToReact(domNode.children)}</BodyTextBold>
    } else if (domNode.name === "td") {
        return <BodyText ElementType={domNode.name}>{domToReact(domNode.children)}</BodyText>
    } else if (domNode.name === "p" ) {
        if (domNode.children?.find((child: any) => ["text", "span", "strong", "b", "i"].includes(child.name) || child.type === "text")) {
            return <BodyText>{domToReact(domNode.children)}</BodyText>
        }

        // If there is no "text" child then switch this p to a div so we can embed other widgets that might have divs inside
        domNode.name = "div";
        return domNode;
    }

    // Enable Youtube Iframe Api for any embedded youtube videos
    if (domNode.name === 'iframe' && domNode.attribs.src?.match(/youtube/i)) {
        const srcUrl = new URL(domNode.attribs.src);
        const videoId = srcUrl.pathname.replace("/embed/", "");
        return (
            <PostVideo id={domNode.attribs.id || videoId} videoId={videoId} />
        )
    }

    return domNode;
}

/**
 * There are styles from WordPress that are conflicting with the Glowup Styleguide, so strip them all
 * @param domNode node to delete styles from
 */
function _deleteStyles(domNode: any) {
    if (domNode.attribs?.style) {
        delete domNode.attribs.style;
    }

    if (domNode.children?.length) {
        domNode.children.forEach((child: any) => _deleteStyles(child));
    }

    return domNode;
}

function _getH2Text(domNode: any): string {
    if (domNode?.data) {
        return domNode.data;
    }
    else if (domNode?.children?.length) {
        return _getH2Text(domNode.children[0])
    }
    return "";
}

export default BlogPostContent;
