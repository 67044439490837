import { useSelector } from 'react-redux';

import {
    productRatesSelector,
    quoteSelector,
} from '../../../../redux/quote/selectors';
import { DeclineReason } from '../api/interfaces';
import {
    bestProductRatesSelectorNoHealthExperiment,
    bestQuoteSelectorNoHealthExperiment,
} from '../experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic/selectors';

/**
 * Returns a DeclineReason based on state of the Quote and ProductRates in redux
 */
const useKnockoutReason = () => {
    const originalQuote = useSelector(quoteSelector);
    const originalRates = useSelector(productRatesSelector);
    const noHealthQuote = useSelector(bestQuoteSelectorNoHealthExperiment); //MOE-404
    const noHealthRates = useSelector(
        bestProductRatesSelectorNoHealthExperiment,
    ); //MOE-404
    const quote = noHealthQuote ? noHealthQuote : originalQuote; //MOE-404
    const rates = noHealthRates ? noHealthRates : originalRates; //MOE-404

    const ineligible = quote?.ineligible;

    // if an unauthorized error was returned for an agent url use "agent_not_licensed_in_state"
    if (
        quote?.error &&
        quote?.message?.includes('agent is not licensed in given state')
    ) {
        return 'agent_not_licensed_in_state' as DeclineReason;
    }

    // If we don't have an ineligible reason and we also don't have any products, then use "address"
    if (!ineligible && !Object.keys(rates || {}).length) {
        return 'address' as DeclineReason;
    }

    // Map both height and bmi to "build"
    if (ineligible === 'height' || ineligible === 'bmi') {
        return 'build' as DeclineReason;
    }

    // Fallback to default type
    return (ineligible || 'default') as DeclineReason;
};

export default useKnockoutReason;
