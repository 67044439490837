import React from 'react';

import BodyText from "../../../../components/_common/text/body-text";
import Eyebrow from '../../../../components/_common/text/eyebrow';

interface Props {
    img: string,
    imgAlt: string,
    title: string,
    description: string
}

const QuoteValueProp: React.FC<Props> = ({img, imgAlt, title, description}) => {
    return (
        <div className="flex mb-9 sm:mb-8">
            <div className="mr-3 sm:mr-4">
                <img src={img} alt="imgAlt" className="w-10 max-w-none sm:w-14" />
            </div>
            <div>
                <Eyebrow className="text-gray-8 uppercase sm:pb-2">
                    {title}
                </Eyebrow>
                <BodyText className="text-left text-charcoal">
                    {description}
                </BodyText>
            </div>
        </div>
    )
};

export default QuoteValueProp;
