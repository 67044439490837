import React from 'react';

import SEO from "../../seo/seo";
import useFetchPageSEO from "../../../hooks/useFetchPageSEO";

const AutoSEO: React.FC = () => {
    const { page } = useFetchPageSEO();

    return page ? (
        <SEO page={page} />
    ) : <></>
};

export default AutoSEO;