import React from 'react';

import PageSection from "../../../components/_common/section/page";
import BodyText from '../../../components/_common/text/body-text';
import H2Serif from '../../../components/_common/text/heading/h2/serif';
import Grid from "../../../components/_common/grid";
import SecondaryButton from "../../../components/_common/button/secondary";
import SecondaryButtonWhite from "../../../components/_common/button/secondary/white";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import BodyTextBold from "../../../components/_common/text/body-text/bold";

import './index.scss';

const FinishHero: React.FC = () => {
    return (
        <PageSection className="finish-hero">
            <Grid>
                <div className="col-span-full sm:col-span-4 lg:col-start-2 lg:col-span-5 pt-10 pb-6 sm:py-12 lg:py-32">
                    <H2Serif className="mb-8 sm:mb-6 text-center sm:text-left text-primary sm:text-white">
                        Hello again!<br/>Let’s finish your<br/>application.
                    </H2Serif>
                    <Subtitle2 className="hidden sm:block mb-2 text-left text-white sm:mb-4">
                        Instant Decision.<br/>Coverage today, if approved.
                    </Subtitle2>
                    <BodyTextBold className="sm:hidden mb-2 text-center text-charcoal">
                        Instant Decision. Coverage today,<br/>if approved.
                    </BodyTextBold>
                    <BodyText className="mb-8 text-center sm:text-left text-charcoal sm:text-white">
                        <span>The modern way to apply for<br/>term life insurance.</span>
                    </BodyText>
                    <div className="flex justify-center sm:justify-start px-8 sm:px-0">
                        <SecondaryButton className="w-full sm:hidden" href="https://login.bestow.com">
                            Log in
                        </SecondaryButton>
                        <SecondaryButtonWhite className="hidden sm:block" href="https://login.bestow.com">
                            Log in
                        </SecondaryButtonWhite>
                    </div>
                </div>
            </Grid>
        </PageSection>
    );
};

export default FinishHero;
