import React from 'react';
import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import BodyText from "../../../components/_common/text/body-text";
import H2Serif from "../../../components/_common/text/heading/h2/serif";
import { ReactComponent as ApiGear } from "../../../images/svgs/api-partners/apis-gear.svg";
import PrimaryButton from "../../../components/_common/button/primary";
import PrimaryLargeButton from "../../../components/_common/button/primary/large";
import CTAModuleThree from "../../../components/_common/modules/cta/three";

const ApiIntegration: React.FC = () => (
    <PageSection className="bg-navy">
        <Grid className="sm:absolute">
            <div className="col-start-2 col-span-2 px-4 sm:col-start-1 sm:px-2 lg:col-start-2 lg:px-0 flex justify-center">
                <ApiGear className="sm:w-full h-auto -mt-8 -mb-8 sm:-mt-10 sm:-mb-0 sm:-mb-10 api-gear" />
            </div>
        </Grid>
        <Grid className="py-16 pb-24 sm:pb-20 sm:py-20">
            <div className="col-span-full sm:col-span-6 sm:col-start-2 lg:col-start-4 sm:px-6 lg:px-0">
                <H2Serif className="text-white text-center mb-8 sm:mb-14">
                    You already have customers who want life insurance.
                </H2Serif>
                <BodyText className="text-white text-center">
                    Over <strong>100 million Americans</strong> do.
                    <br /><br />
                    <strong>48% of millennials</strong> say they plan on buying life insurance soon.
                    <br /><br />
                    And <strong>65%</strong> say they don’t know where to start looking.
                    <br /><br />
                </BodyText>
                <div className="flex justify-center mt-8 lg:mt-10">
                    {/* Mobile */}
                    <PrimaryButton scrollTo="contact" scrollToOffset={-40} className="sm:hidden">
                        Let's Work Together
                    </PrimaryButton>
                    {/* Tablet / Desktop */}
                    <PrimaryLargeButton scrollTo="contact" scrollToOffset={-40} className="hidden sm:block">
                        Let's Work Together
                    </PrimaryLargeButton>
                </div>
            </div>
        </Grid>
    </PageSection>
);

export default ApiIntegration;
