import React from 'react';

import Heading, { Props } from '../';

import './index.scss';

const H3: React.FC<Props> = ({children, className = "", ElementType = "h3", ...otherProps}) => {
  return (
    <Heading className={`brand-h3 ${className}`} ElementType={ElementType} {...otherProps}>
        {children}
    </Heading>
  );
};

export default H3;
