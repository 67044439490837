import React from 'react';

import PrivacySubSection from '../subsection';
import H4Serif from "../../../components/_common/text/heading/h4/serif";
import BodyTextArray from "../../../components/_common/text/body-text/array";

import { Section } from "../";

interface Props {
    sectionData: Section,
    sectionNumber: number,
}

const PrivacySection: React.FC<Props> = ({sectionData, sectionNumber}) => (
    <div className="sm:mb-4">
        <div>
            <H4Serif className="mb-4 text-left">
                {sectionData.title}
            </H4Serif>
            {sectionData.body &&
                <div className="mb-4">
                    <BodyTextArray
                        data={sectionData.body}
                        classes="mb-4"
                    />
                </div>
            }
        </div>
        {sectionData.subSections &&
            <div>
                {sectionData.subSections.map((subSection, subSectionIdx) => (
                    <PrivacySubSection
                        subsectionData={subSection}
                        key={`section-${subSectionIdx}`}
                    />
                ))}
            </div>
        }
    </div>
);

export default PrivacySection;
