import React from 'react';

import H1, {Props} from '../index';

import './index.scss';

const H1Serif:React.FC<Props> = ({className = "", children, ...otherProps}) => {
    return (
        <H1 className={`h1-serif font-serif font-bold ${className}`} {...otherProps}>
            {children}
        </H1>
    )
};

export default H1Serif;