import React, {useEffect, useState} from 'react';

import {isPreRendering} from "../utilities/reactSnap";

/**
 * Adds the YouTube Api to the document if not already defined
 */
export default function useYouTube() {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!isPreRendering()) {
            if (!window.YT) {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';

                // Capture any existing callbacks that need to be notified
                const onReady = window.onYouTubeIframeAPIReady;

                window.onYouTubeIframeAPIReady = () => {
                    // Let the pre-existing callbacks know YT is ready
                    if (onReady) {
                        onReady();
                    }
                    setReady(true);
                };

                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag?.parentNode.insertBefore(tag, firstScriptTag);
            } else {
                setReady(true);
            }
        }
    }, []);

    return ready;
};