import React from 'react';

import Subtitle1 from '../../../components/_common/text/heading/subtitle1/index';
import BodyText from "../../../components/_common/text/body-text";
import CategoryNumber from "../../../components/_common/categoryNumber";
import Grid from "../../../components/_common/grid";

interface Props {
    sectionNumber: number,
}

const NumberedSection: React.FC<Props> = ({children, sectionNumber}) => (
    <div className="mb-6 lg:mb-5">
        <Grid>
            <div className="col-span-full sm:col-start-2 sm:col-span-1 flex justify-center">
                <CategoryNumber>
                    {sectionNumber}
                </CategoryNumber>
            </div>
            <div className="col-span-full sm:col-span-5 lg:col-span-9">
                {children}
            </div>
        </Grid>
    </div>
);

export default NumberedSection;
