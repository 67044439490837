import React from 'react';
import cx from 'classnames';

import './index.scss';

interface Props {
    className?: string,
    uppercase?: boolean
}

const TextFieldLabel: React.FC<Props> = ({ className = "", uppercase = true, children }) => {
    return (
        <div className={cx("text-field-label text-xs font-sans leading-tight", className, {
            "uppercase": uppercase
        })}>
            {children}
        </div>
    )
};

export default TextFieldLabel;