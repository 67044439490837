import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from "../utils";

const SLICE_NAME = "siteInfo";
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: true,
    settings: {},
    showTopNav: true,
    showBanner: false,
});

const siteInfoSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        showTopNav(state, action) {
            state.showTopNav = action.payload;
        },
        showBanner(state, action) {
            state.showBanner = action.payload;
        }
    }
});

export default siteInfoSlice.reducer;

/**
 * Actions
 */

export const { loading, showTopNav, showBanner } = siteInfoSlice.actions;