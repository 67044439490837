import React from 'react';
import cx from 'classnames';

import './index.scss';

export interface Props extends React.HTMLAttributes<HTMLElement>  {
    ElementType?: "p" | "div" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

const BodyText: React.FC<Props> = ({children, className = "", dangerouslySetInnerHTML, ElementType = "p", ...otherProps}) => {
    const classes = cx('brand-body-text font-sans', className);

    if (dangerouslySetInnerHTML) {
        return (
            <div className={classes} {...otherProps} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        );
    }

    return (
        <ElementType className={classes} {...otherProps}>{children}</ElementType>
    )

    // // Return a div if we're setting innerHTML since we don't want to end up nesting <p> tags inside a <p> tag
    // if (forceDiv || dangerouslySetInnerHTML) {
    //     return dangerouslySetInnerHTML ? (
    //         <div className={classes} {...otherProps} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
    //     ) : <div className={classes} {...otherProps}>{children}</div>
    // }
    //
    // return inline ?
    //     <span className={classes} {...otherProps}>{children}</span> :
    //     <p className={classes} {...otherProps}>{children}</p>
};

BodyText.defaultProps = {
    className: '',
};

export default BodyText;
