import React from 'react';

import H3 from '../';
import { Props } from '../../';

import './index.scss';

const H3Sans:React.FC<Props> = ({className = "", children, ...otherProps}) => {
    return (
        <H3 className={`h3-sans font-sans ${className}`} {...otherProps}>
            {children}
        </H3>
    )
};

export default H3Sans;