import React from 'react';

import Button, { Props } from "../index";

import './index.scss';

const SecondaryButton: React.FC<Props> = ({ className = "", ...otherProps }) => {
    const classes = `secondary-btn ${className}`;
    return (
        <Button className={classes} {...otherProps} />
    )
};

export default SecondaryButton;
