import React, {HTMLProps} from 'react';

import './index.scss';

/**
 * This components gives us the bare minimum x-margins that we need for the Design System while allowing us to also set
 * background styles
 */
const PageSection: React.FC<HTMLProps<HTMLDivElement>> = ({ className = "", id = "", children, ...other}) => {
    return (
        <div id={id} className={`page-section ${className}`} {...other}>
            <div className="page-section-inner-container">
                {children}
            </div>
        </div>
    )
};

export default PageSection;
