import PageSection from '../../components/_common/section/page';
import PageContent from '../../components/_common/pageContent/navy';
import H1 from '../../components/_common/text/heading/h1/serif';
import Grid from '../../components/_common/grid';
import BodyTextLarge from '../../components/_common/text/body-text/large';
import PrimaryButton from '../../components/_common/button/primary';
import H4Sans from '../../components/_common/text/heading/h4/sans';
import BodyTextBold from '../../components/_common/text/body-text/bold';
import H2Serif from '../../components/_common/text/heading/h2/serif';
import Subtitle2 from '../../components/_common/text/heading/subtitle2';
import BodyText from '../../components/_common/text/body-text';
import Eyebrow from '../../components/_common/text/eyebrow';
import H3Serif from '../../components/_common/text/heading/h3/serif';
import PlatformItem from './platformItem';
import phoneImg from '../../images/carriers/carriers-phone.jpeg';
import productImg from '../../images/carriers/product.svg';
import advisorImg from '../../images/carriers/advisor.svg';
import onlineImg from '../../images/carriers/online.svg';
import instantImg from '../../images/carriers/instant.svg';
import administrationImg from '../../images/carriers/administration.svg';
import analyticsImg from '../../images/carriers/analytics.svg';
import lena from '../../images/carriers/lena-chukhno.jpeg';
import SignUp from './signup';
import './index.scss';

const Carriers = () => {
    return (
        <PageContent className="carriers">
            <PageSection className="pt-7 sm:pt-20">
                <Grid>
                    <H1 className="col-span-full lg:col-span-11 text-center text-navy sm:text-left mb-12 sm:mb-24">
                        Launch instant, fully digital life insurance in months —
                        not years.
                    </H1>
                    <BodyTextLarge className="col-span-full sm:col-start-2 sm:col-span-10 lg:col-start-3 lg:col-span-8 text-center ">
                        Bestow's enterprise SaaS solution dramatically reduces
                        the cost and complexity of selling term life insurance.
                    </BodyTextLarge>
                </Grid>
                <div className="flex justify-center mt-12">
                    <PrimaryButton href="#lets-connect" target="_self">
                        Learn more
                    </PrimaryButton>
                </div>
            </PageSection>
            <PageSection className="pt-16 sm:pt-24 lg:pt-32">
                <Grid smCols={12}>
                    <div className="col-span-full sm:col-span-6">
                        <img
                            src={phoneImg}
                            className="w-full"
                            alt="mobile phone"
                        />
                    </div>
                    <div className="col-span-full sm:col-span-6">
                        <H4Sans className="text-center sm:text-left text-navy mb-9 sm:mb-4 lg:mb-9">
                            A premium experience for advisors and their clients
                        </H4Sans>
                        <BodyTextLarge className="mb-9 text-center text-charcoal sm:text-left sm:mb-7 lg:mb-12">
                            From quote to policy delivery, our tech stack powers
                            a mobile-enabled, white-labeled buying experience.
                        </BodyTextLarge>
                        <BodyTextBold className="text-charcoal">
                            <ul>
                                <li>
                                    Recast the economics of your term life
                                    business
                                </li>
                                <li>
                                    Refocus resources to higher-touch clients
                                </li>
                                <li>Onboard new clients efficiently</li>
                                <li>
                                    Empower your advisors to reach and serve
                                    more clients, faster
                                </li>
                            </ul>
                        </BodyTextBold>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="pt-16 pb-24 sm:pt-24 sm:pb-4 lg:pt-32 lg:pb-20">
                <Grid smCols={12}>
                    <H2Serif className="col-span-full lg:col-start-2 lg:col-span-10 text-navy text-center mb-8 sm:mb-12 lg:-mx-4">
                        Bestow’s full-stack insurance platform is our advantage
                    </H2Serif>
                    <BodyTextLarge className="col-span-full text-center text-charcoal mb-8 sm:mb-12">
                        We’ve thought of everything so you don’t have to.
                    </BodyTextLarge>
                    <PlatformItem
                        icon={productImg}
                        title="Product Manufacturing"
                        desc="Expertise in developing and launching digitally optimized life insurance products on your paper"
                    />
                    <PlatformItem
                        icon={advisorImg}
                        title="Advisor Referral"
                        desc="Marrying a DTC fulfillment experience with advisory expertise of your distribution"
                    />
                    <PlatformItem
                        icon={onlineImg}
                        title="Online Enrollment"
                        desc="Apply and bind coverage in as little<br className='hidden lg:block'/> as 5 minutes"
                    />
                    <PlatformItem
                        icon={instantImg}
                        title="Underwriting"
                        desc="Instant (not simplified) decisioning"
                    />
                    <PlatformItem
                        icon={administrationImg}
                        title="Policy Administration"
                        desc="Post purchase servicing up until<br className='hidden lg:block'/> claim adjudication"
                    />
                    <PlatformItem
                        icon={analyticsImg}
                        title="Analytics"
                        desc="Sophisticated, full-funnel dashboards and tools to track performance"
                    />
                </Grid>
            </PageSection>
            <PageSection className="bg-navy py-16 sm:py-24 lg:py-32 text-center">
                <Eyebrow className="text-rose uppercase mb-8">
                    Our carriers say
                </Eyebrow>
                <Grid smCols={12}>
                    <H2Serif className="sm:col-span-full lg:col-start-2 lg:col-span-10 text-white hidden sm:mb-10 sm:block sm:px-6 lg:-mx-6">
                        “Our partnership with Bestow is another important step
                        in our strategy to help financial professionals serve
                        the needs of their clients with innovative and tailored
                        solutions designed with today’s consumer in mind.”
                    </H2Serif>
                    <H3Serif className="col-span-full text-white mb-10 sm:hidden">
                        “Our partnership with Bestow is another important step
                        in our strategy to help financial professionals serve
                        the needs of their clients with innovative and tailored
                        solutions designed with today’s consumer in mind.”
                    </H3Serif>
                </Grid>
                <Subtitle2 className="text-rose mb-2">
                    – Hector Martinez
                </Subtitle2>
                <BodyText className="text-white">
                    Head of Life Business, Equitable
                </BodyText>
            </PageSection>
            <PageSection
                className="pt-20 lg:pt-24 flex flex-col items-center text-center"
                id="lets-connect"
            >
                <H2Serif className="mb-12 text-navy">Let's connect</H2Serif>
                <div className="flex items-center mb-12">
                    <div>
                        <img
                            src={lena}
                            alt="Lena Chukhno"
                            className="w-24 h-24 rounded-full"
                        />
                    </div>
                    <div className="text-left ml-6">
                        <Subtitle2 className="text-charcoal mb-2">
                            Lena Chukhno
                        </Subtitle2>
                        <BodyText className="text-charcoal-60">
                            Chief Revenue Officer
                        </BodyText>
                    </div>
                </div>
                <div className="flex justify-center">
                    <PrimaryButton href="mailto:lena.chukhno@bestow.com">
                        Contact me
                    </PrimaryButton>
                </div>
            </PageSection>
            <SignUp />
        </PageContent>
    );
};

export default Carriers;
