import React from 'react';

import cx from 'classnames';

import H4Sans from '../../../../components/_common/text/heading/h4/sans';
import BodyText from '../../../../components/_common/text/body-text';
import SmallText from '../../../../components/_common/text/small-text/index';

interface Props {
    Icon: string;
    title: string;
    titleClassName?: string;
    body: string;
}

const IconBlurb: React.FC<Props> = ({
    Icon,
    title,
    titleClassName = '',
    body,
}) => (
    <div className="icon-blurb flex flex-col items-center lg:px-8 mb-12 sm:mb-20">
        <img src={Icon} className="mb-4 h-12 sm:h-14 sm:mb-6" alt={title} />
        <H4Sans className={cx('mb-2', titleClassName)}>{title}</H4Sans>
        <BodyText
            className="hidden sm:block mb-0 text-center"
            dangerouslySetInnerHTML={{ __html: body }}
        />
        <SmallText
            className="sm:hidden mb-0 text-center"
            dangerouslySetInnerHTML={{ __html: body }}
        >
            {body}
        </SmallText>
    </div>
);

export default IconBlurb;
