import React from 'react';

import PageContent from '../../components/_common/pageContent/navy/fifty';
import PageSection from '../../components/_common/section/page';
import SecondaryButton from '../../components/_common/button/secondary';
import SecondaryButtonSmall from '../../components/_common/button/secondary/small';
import Card from '../../components/_common/card';
import H4Serif from '../../components/_common/text/heading/h4/serif';
import PrimaryButton from '../../components/_common/button/primary';
import Grid from '../../components/_common/grid';
import FaqAppTrustPilot from './trustPilot';
import AllFAQs from '../../components/_common/modules/faqs/all';

import { ThirtyDayGuaranteeUpright } from '../../images/svgs';
import { ReactComponent as Seal } from '../../images/30-day-seal.svg';
import { ReactComponent as Arrow } from '../../images/svgs/btn-arrow-right.svg';

import './index.scss';

const getReturnUrl = (): string => {
    let returnUrl = 'https://enroll.bestow.com/get-started';
    if (window.location.search.indexOf(`returnto=readytoapply`) > 0) {
        returnUrl = `https://start.bestow.com/ready-to-apply`;
    }
    return returnUrl;
};

const FAQApp: React.FC = () => {
    // We have to leave the default in the initialization so that React can update correctly once it rehydrates.
    const returnUrl = React.useRef('https://enroll.bestow.com/get-started');

    React.useEffect(() => {
        returnUrl.current = getReturnUrl();
    });

    const cardContent = (
        <>
            <Seal className="seal hidden sm:block sm:-mt-14" />
            <div className="-mt-16 sm:hidden">
                <ThirtyDayGuaranteeUpright className="-mt-2" />
            </div>
            <div className="my-4 flex flex-column items-center w-100">
                <H4Serif className="faq-app-cta-title mb-0 text-center">
                    Finish your application.
                </H4Serif>
                <div className="mt-6 w-100 flex justify-center">
                    <PrimaryButton
                        className="w-100 sm:w-auto mx-4 sm:mx-0"
                        href={returnUrl.current}
                    >
                        Finish Now
                    </PrimaryButton>
                </div>
            </div>
        </>
    );

    return (
        <PageContent className="faq-app">
            <PageSection>
                <div className="flex justify-center mb-10 sm:mb-16 lg:mb-24 pt-10 sm:pt-16 lg:pt-28">
                    <SecondaryButton
                        Icon={Arrow}
                        className="hidden sm:flex"
                        href={returnUrl.current}
                    >
                        Return to your application.
                    </SecondaryButton>
                    <SecondaryButtonSmall
                        Icon={Arrow}
                        className="sm:hidden"
                        href={returnUrl.current}
                    >
                        Return to your application.
                    </SecondaryButtonSmall>
                </div>
                <AllFAQs className="mb-24" />
                <Grid className="mb-20 sm:mb-14">
                    <div className="col-span-4 sm:col-span-5 lg:col-span-7 flex flex-column sm:justify-center">
                        <Card className="hidden p-7 sm:flex faq-app-cta bg-off-white relative flex-column items-center">
                            {cardContent}
                        </Card>
                        <div className="-mx-4 py-9 flex flex-column sm:hidden bg-off-white relative flex-column items-center">
                            {cardContent}
                        </div>
                    </div>
                    <div className="col-span-4 sm:col-span-3 lg:col-span-5 sm:mb-0">
                        <FaqAppTrustPilot />
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default FAQApp;
