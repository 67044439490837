import React from 'react';

import PageContentNavy from "../";
import { Props } from '../../';

import './index.scss';

const PageContentNavyFifty: React.FC<Props> = ({ className = "", ...otherProps }) => {
    return (
        <PageContentNavy className={`gradient-navy-50 ${className}`} {...otherProps} />
    )
};

export default PageContentNavyFifty;
