import React from 'react';
import BodyText from '../../../components/_common/text/body-text';
import BodyTextArray from "../../../components/_common/text/body-text/array";
import TermsSubSubsection from '../subsubsection';

import { SubSubsectionData } from "../subsubsection";
import BodyTextBold from "../../../components/_common/text/body-text/bold";

export interface SubsectionData {
    title?: string,
    body?: string[]
    subSections?: SubSubsectionData[]
}

interface Props {
    subsectionsCount: number,
    subsectionData: SubsectionData,
    sectionNumber: number,
    idx: number
}

const TermsSubSection: React.FC<Props> = ({subsectionsCount, sectionNumber, subsectionData, idx}) => (
    <div className={`flex ${idx+1 !== subsectionsCount && 'mb-4'}`} key={`section-${idx}`}>
        <div style={{marginTop: -1}}>
            <span className="subsection-number mr-6 text-gray-700">{sectionNumber}.{idx+1}</span>
        </div>
        <div className="mb-4">
            {subsectionData.title && <BodyTextBold className="mb-4">{subsectionData.title}</BodyTextBold> }
            {subsectionData.body && <BodyTextArray data={subsectionData.body} classes={ `${subsectionsCount ? 'mb-4' : ''}`} /> }

            {/* Render 2nd level subsections */}
            {subsectionData.subSections && subsectionData.subSections.map((subSubSection, subSubSectionIdx) => (
                <TermsSubSubsection
                    subSubsectionData={subSubSection}
                    idx={subSubSectionIdx}
                    subsectionsCount={subsectionData.subSections?.length ?? 0}
                />
            ))}
        </div>
    </div>
);

export default TermsSubSection;
