import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';

import TabBar from "../../../tabBar";
import FAQList from "../list";
import Search from "../../../inputs/search";
import H2Serif from "../../../text/heading/h2/serif";
import H4Serif from "../../../text/heading/h4/serif";
import Grid from "../../../grid";
import useFAQsByCategoryFilter from "../../../../../hooks/useFAQsByCategoryFilter";
import useLunrIndex from '../../../../../hooks/useLunrIndex';
import allFAQs from './data';

import './index.scss';

interface Props {
    className?: string
}

const TABS = [
    {label: "Bestow Basics", value: 14},
    {label: "Life Insurance 101", value: 19},
    {label: "Manage Policy", value: 20},
    {label: "My Application", value: 15},
    {label: "Glossary", value: 18},
];

const HASH = "faq-section";

const AllFAQs: React.FC<Props> = ({ className = "" }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [categoryId, setCategoryId] = useState(TABS[0].value);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const categoryFAQs = useFAQsByCategoryFilter(categoryId);
    const index = useLunrIndex();
    const hash = useLocation().hash;
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hash?.toLowerCase() === `#${HASH}`) {
            ref.current?.scrollIntoView();
        }
    }, [hash, ref]);

    const onKeyDown = (event: any) => {
        if (event.key === "Enter" && index) {
            onSearch();
        }
    };

    const onSearch = () => {
        if (search?.length > 0) {
            setCategoryId(-1);
            setCurrentTab(-1);
            setSearchResults(_getFAQResults(search, index));
        } else {
            setCategoryId(TABS[0].value);
            setCurrentTab(0);
        }
    };

    const onTabChange = (index: number) => {
        setCategoryId(TABS[index].value);
        setCurrentTab(index);
        setSearch("");
    };

    const faqs = categoryId === -1 ? searchResults : categoryFAQs;

    return (
        <div className={`all-faqs ${className}`} id="faq-section" ref={ref}>
            <Grid>
                <H2Serif className="hidden sm:block text-center sm:text-left col-span-4 sm:col-span-5 lg:col-span-7 lg:text-center mb-0 text-primary">
                    You've got questions.<br/>
                    We've got answers.
                </H2Serif>
                <H4Serif className="sm:hidden text-center sm:text-left col-span-4 sm:col-span-5 lg:col-span-7 lg:text-center mb-0 text-primary">
                    You've got questions.<br/>
                    We've got answers.
                </H4Serif>
                <div className="mx-8 sm:mx-0 mt-4 sm:mt-8 sm:mb-2 lg:mr-16 col-span-4 sm:col-span-3 lg:col-span-5 flex items-end">
                    <Search
                        data-testid="faq.search"
                        value={search}
                        placeholder="Questions? Ask away."
                        onKeyDown={onKeyDown}
                        onSearch={onSearch}
                        onChange={e => setSearch((e.target as HTMLInputElement).value)} />
                </div>
            </Grid>
            <TabBar
                className="mb-14 sm:mb-20 lg:mb-24 mt-16 lg:mt-20"
                title="Filter by Category"
                tabs={TABS}
                onChange={onTabChange}
                value={currentTab} />
            {/* @ts-ignore */}
            <FAQList faqs={faqs} />
        </div>
    )
};

const _getFAQResults = (query:string , index:any) => {
    if (query && index) {
        const searchResults = index.search(query);

        // @ts-ignore
        const newResults = searchResults.map(({ ref }) => allFAQs.find(faq => faq.id === parseInt(ref, 10)));

        return newResults;
    }

    return [];
};

export default AllFAQs;