import React from 'react';

import FinishHero from "./hero";
import FinishTrust from "./trust";
import AsSeenIn from './as-seen-in'
import PageSection from "../../components/_common/section/page";
import PageContentBlush from "../../components/_common/pageContent/blush";
import H4Serif from "../../components/_common/text/heading/h4/serif";
import FAQList from "../../components/_common/modules/faqs/list";
import CTAModule from "../../components/_common/modules/cta";
import SecondaryButton from "../../components/_common/button/secondary";
import LinkButton from "../../components/_common/button/link";
import {ButtonArrowRight} from "../../images/svgs";
import useFAQsByIdFilter from "../../hooks/useFAQsByIdFilter";

import './index.scss';

const FinishPage: React.FC = () => {
    const faqs = useFAQsByIdFilter([288, 287, 298, 294, 509, 313]);

    return (
        <PageContentBlush className="finish-page" includeBottomPadding>
            <FinishHero />
            <AsSeenIn />
            <FinishTrust />
            <PageSection>
                <H4Serif className="mt-10 mb-8 sm:mt-20 lg:mt-28 sm:mb-10 text-center">
                    You've got questions. We've got answers.
                </H4Serif>
                <FAQList faqs={faqs} />
            </PageSection>
            <CTAModule
                title={<>Coverage today, if approved.<br/>Yep. It's that easy.</>}>
                <SecondaryButton Icon={ButtonArrowRight} href="https://login.bestow.com">
                    Finish Applying
                </SecondaryButton>
            </CTAModule>
            <PageSection>
                <div className="flex justify-center sm:justify-end">
                    <LinkButton to="/about">
                        Learn More About Bestow
                    </LinkButton>
                </div>
            </PageSection>
        </PageContentBlush>
    );
};

export default FinishPage;
