import {useMemo} from "react";
import {useSelector} from "react-redux";
import {allPostsSelector} from "../redux/posts/selectors";
import {allLCPostsSelector} from "../redux/learningCenter/selectors";

/**
 * Returns all the Posts minus any featured ones
 * @param isLearningCenter  True if posts should come from the LearningCenter instead of Blog
 * @param isLandingPage  True if the posts are displayed on a page like /blog or /learningcenter. Hides any posts with this flag set from WordPress
 * @returns {Array} An array of posts
 */
const useAllPostsFilter = (isLearningCenter = false, isLandingPage = false) => {
    const allPosts = useSelector(isLearningCenter ? allLCPostsSelector : allPostsSelector);

    return useMemo(() => {
        return allPosts.filter(post => {
            return !post._embedded["wp:term"][0]?.find(term => term.slug === "featured") && (isLandingPage ? !post.acf.hide_on_landing : true)
        });
    }, [allPosts, isLandingPage])
};

export default useAllPostsFilter;
