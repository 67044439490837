import React, { useEffect, useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { QuoteProps } from '../../..';
import {
    ctsQuoteLoadingSelector,
    ctsQuoteSelector,
    quoteFormSelector,
    quoteLoadingSelector,
} from '../../../../../../redux/quote/selectors';
import useQueryParamData from '../../../hooks/useQueryParamsData';
import multiStateZipcodes from '../../../data/multiStateZipcodes.json';
import usStates from '../../../data/usStates.json';
import {
    GENDER_VALUE_FEMALE,
    GENDER_VALUE_MALE,
    HEIGHT_OPTIONS,
} from '../../../form/constants';
import { loadCtsQuote, loadQuote } from '../../../../../../redux/quote';
import { getCurrentPartner } from '../../../utils/quoteUtils';
import Subtitle2 from '../../../../../_common/text/heading/subtitle2';
import QuotePlaceholder from '../../../placeholder';
import quoteFormSchema from '../../../form/schema';
import CustomFormikField from '../../../../../_common/formik/customField';
import InsideLabelSelect from '../../../../../_common/inputs/insideLabelSelect';
import DOBInput from '../../../../../_common/inputs/dob';
import InsideLabelInput from '../../../../../_common/inputs/insideLabel';
import { trackSmokerStatus, trackStateSelect } from '../../../api/analytics';
import PrimaryButton from '../../../../../_common/button/primary';
import CheckboxMobile from '../CheckboxFormMobile';
import InsideLabelInputMobile from '../InsideLabelInputMobile';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { trackLaunchDarklyEventWithFlush } from '../../../../../../utilities/launchDarkly.track';

const QuoteMobileOptimizationForm: React.FC<QuoteProps> = () => {
    const loading = useSelector(quoteLoadingSelector);
    const quoteForm = useSelector(quoteFormSelector);
    const dispatch = useDispatch();
    const queryParamData = useQueryParamData();
    const submitRef = useRef<HTMLButtonElement | null>(null);
    const ctsQuoteLoading = useSelector(ctsQuoteLoadingSelector);
    const ctsQuote = useSelector(ctsQuoteSelector);
    const client = useLDClient();

    // This informs our Validation schema about acceptable height inputs
    const heightValues = useMemo(
        () => HEIGHT_OPTIONS.map(option => option.value),
        [],
    );

    // Handles auto-submit (skipform) and CTS Quotes
    useEffect(() => {
        if (queryParamData.skipform && submitRef?.current) {
            submitRef.current.click();
        }
        if (queryParamData.qid && !ctsQuoteLoading && !ctsQuote) {
            dispatch(loadCtsQuote(queryParamData.qid));
        }
    }, [queryParamData, ctsQuoteLoading, ctsQuote, dispatch]);

    const getStateOptions = (zipCode: string) => {
        const multiStateZip = (multiStateZipcodes as any)[zipCode];
        if (multiStateZip) {
            return multiStateZip.map((state: string) => ({
                label: state,
                value: (usStates as any)[state],
            }));
        }
        return Object.entries(usStates).map(([key, value]) => ({
            label: key,
            value,
        }));
    };

    const showZipField = () => {
        // Show the zip field if there is no stateCode in querystring or if a multistate zipCode was explicitly passed
        return (
            !_isValidStateCode(queryParamData.stateCode) ||
            isMultiStateZipCode(queryParamData.zipCode)
        );
    };

    const showStateField = (zipCode: string) => {
        // Show the state field if the zip supplied is multistate or if a stateCode was supplied via query string
        return (
            isMultiStateZipCode(zipCode) ||
            _isValidStateCode(queryParamData.stateCode)
        );
    };

    return (
        <div>
            <Subtitle2 className="pb-4 whitespace-nowrap text-QMOSubtitleFont">
                Get an insurance quote in seconds.
            </Subtitle2>
            {ctsQuoteLoading ? (
                <QuotePlaceholder />
            ) : (
                <>
                    <Formik
                        initialValues={{
                            gender: '',
                            birthdate: '',
                            height: '',
                            weight: '',
                            zipCode: '',
                            stateCode: '',
                            tobacco: false,
                            partner_referral: getCurrentPartner(),
                            ...queryParamData,
                            ...(ctsQuote || {}),
                            ...quoteForm,
                        }}
                        validationSchema={quoteFormSchema(
                            heightValues,
                            queryParamData,
                        )}
                        onSubmit={(values, { setFieldError }) => {
                            dispatch(loadQuote(values, setFieldError));
                            trackLaunchDarklyEventWithFlush(
                                client,
                                'requested_quote',
                            );
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form noValidate>
                                    <div className="mb-3 flex flex-col lg:flex-row">
                                        {showZipField() && (
                                            <div className="flex-1">
                                                <CustomFormikField
                                                    name="zipCode"
                                                    className="w-full bg-white"
                                                    type="tel"
                                                    CustomComponent={
                                                        InsideLabelInput
                                                    }
                                                    label="Zip Code"
                                                    data-testid="zipCode"
                                                    autoComplete="off"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'zipCode',
                                                            e.target.value.replace(
                                                                /[^0-9]/g,
                                                                '',
                                                            ),
                                                        );
                                                        setFieldValue(
                                                            'stateCode',
                                                            '',
                                                        );
                                                    }}
                                                    maxLength={5}
                                                />
                                            </div>
                                        )}
                                        {showStateField(values.zipCode) &&
                                            showZipField() && (
                                                // We need a divider here, but we don't want to use Classname switches because of pre-render mismatches
                                                <div className="mr-4 hidden lg:block" />
                                            )}
                                        {showStateField(values.zipCode) && (
                                            <div className="flex-1 mt-5 lg:mt-0">
                                                <CustomFormikField
                                                    name="stateCode"
                                                    className="w-full bg-white h-12"
                                                    label="State"
                                                    data-testid="stateCode"
                                                    CustomComponent={
                                                        InsideLabelSelect
                                                    }
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'stateCode',
                                                            e.target.value,
                                                        );
                                                        trackStateSelect(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    options={getStateOptions(
                                                        values.zipCode,
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <CustomFormikField
                                            label="Birthdate"
                                            name="birthdate"
                                            className="w-full bg-white shadow-outside-label-input"
                                            type="date"
                                            data-testid="birthdate"
                                            onChange={value =>
                                                setFieldValue(
                                                    'birthdate',
                                                    value,
                                                )
                                            }
                                            CustomComponent={DOBInput}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <CustomFormikField
                                            name="gender"
                                            className="w-full bg-white"
                                            label="Gender"
                                            data-testid="gender"
                                            CustomComponent={InsideLabelSelect}
                                            options={[
                                                {
                                                    label: 'Male',
                                                    value: GENDER_VALUE_MALE,
                                                },
                                                {
                                                    label: 'Female',
                                                    value: GENDER_VALUE_FEMALE,
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="mb-3 flex flex-row justify-center">
                                        <div className="w-6/12 mr-1.5">
                                            <CustomFormikField
                                                name="weight"
                                                className="bg-white"
                                                type="tel"
                                                CustomComponent={
                                                    InsideLabelInputMobile
                                                }
                                                label="Weight (lbs)"
                                                data-testid="weight"
                                                onChange={e => {
                                                    const weight = parseInt(
                                                        e.target.value.replace(
                                                            /[^0-9]/g,
                                                            '',
                                                        ),
                                                        10,
                                                    );
                                                    // KBM - AQX has biz logic to max the user's weight out at 1000 lbs.
                                                    setFieldValue(
                                                        'weight',
                                                        weight
                                                            ? Math.min(
                                                                  weight || 0,
                                                                  1000,
                                                              )
                                                            : '',
                                                    );
                                                }}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="w-6/12 ml-1.5">
                                            <CustomFormikField
                                                name="height"
                                                className="bg-white w-full"
                                                label="Height"
                                                data-testid="height"
                                                CustomComponent={
                                                    InsideLabelSelect
                                                }
                                                options={HEIGHT_OPTIONS}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <CustomFormikField
                                            name="tobacco"
                                            className="bg-white m-0"
                                            CustomComponent={CheckboxMobile}
                                            label="I currently use nicotine products."
                                            data-testid="tobacco"
                                            onChange={e => {
                                                setFieldValue(
                                                    'tobacco',
                                                    e.target.checked,
                                                );
                                                trackSmokerStatus(
                                                    e.target.checked,
                                                );
                                            }}
                                            checked={values.tobacco}
                                        />
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            className="w-full"
                                            disabled={loading}
                                            ref={submitRef}
                                            type="submit"
                                            data-testid="quote-submit-btn"
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : 'Get your quote'}
                                        </PrimaryButton>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </div>
    );
};

export const isMultiStateZipCode = (zip: string) =>
    Boolean((multiStateZipcodes as any)[zip]);

// Checks to see if the State code supplied exists in our lookup file
function _isValidStateCode(stateCode: string) {
    return Object.values(usStates).includes(stateCode);
}

export default QuoteMobileOptimizationForm;
