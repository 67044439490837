import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import QuoteResults from './results';
import { quoteSelector } from '../../../redux/quote/selectors';
import { trackQuoteWidgetVisited } from './api/analytics';
import useQueryParamData from './hooks/useQueryParamsData';
import { validateURL } from './utils/quoteUtils';
import InvalidURLError from './error/invalidUrl';
import './index.scss';
import { bestQuoteSelectorNoHealthExperiment } from './experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic/selectors';
import QuoteFormExperimentRouter from './experimentalComponents/components/QuoteFormExperimentRouter/QuoteFormExperimentRouter';
import { trackLaunchDarklyEvent } from '../../../utilities/launchDarkly.track';

export interface QuoteProps {
    isAgent?: boolean;
    agentCode?: string | null;
    quoteMobileOptimizationExists?: boolean; // MOE-335
    gerberEligible?: boolean; // MOE-458
}

interface Props extends QuoteProps {
    className?: string;
}

const QuoteNew: React.FC<Props> = ({
    className,
    isAgent,
    agentCode = null,
    quoteMobileOptimizationExists = false, // MOE-335
    gerberEligible = false, // MOE-458
}) => {
    const originalQuote = useSelector(quoteSelector);
    const noHealthQuote = useSelector(bestQuoteSelectorNoHealthExperiment);
    const urlIsValid = validateURL(useQueryParamData());
    const client = useLDClient();
    const { moe404NoHealthQuote } = useFlags();
    const quote = moe404NoHealthQuote ? noHealthQuote : originalQuote; // MOE-404

    useEffect(() => {
        trackQuoteWidgetVisited();
        trackLaunchDarklyEvent(client, 'visit_activity');
    }, [client]);

    return (
        <div className={cx('bg-off-white', className)}>
            <div
                className={`p-5 ${
                    quoteMobileOptimizationExists ? '' : 'pt-10'
                } sm:p-6 lg:p-8`}
            >
                {urlIsValid ? (
                    !quote ? (
                        <QuoteFormExperimentRouter
                            quoteMobileOptimizationExists={
                                quoteMobileOptimizationExists
                            }
                            agentCode={agentCode}
                        />
                    ) : (
                        <QuoteResults
                            isAgent={isAgent}
                            quoteMobileOptimizationExists={
                                quoteMobileOptimizationExists
                            } // MOE-335
                            gerberEligible={gerberEligible}
                        />
                    )
                ) : (
                    <InvalidURLError />
                )}
            </div>
        </div>
    );
};

export default QuoteNew;
