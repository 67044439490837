import React from 'react';

import PageContent from '../../components/_common/pageContent';
import PageSection from '../../components/_common/section/page';
import Grid from '../../components/_common/grid';
import TextFieldLabel from '../../components/_common/text/textFieldLabel';
import Quote from '../../components/quote/new';
import BodyText from '../../components/_common/text/body-text';
import H4Serif from '../../components/_common/text/heading/h4/serif';
import SmallText from '../../components/_common/text/small-text';
import H3Sans from '../../components/_common/text/heading/h3/sans';

import { FiveMinuteSeal } from '../../images/svgs';

import './index.scss';

interface Props {
    page: {
        acf: {
            agent_hero: {
                personal_agent_tmpl_hero_logo?: string;
                personal_agent_tmpl_logo_large?: boolean;
                personal_agent_tmpl_hero_name?: string;
            };
            personal_agent_short_code?: string;
        };
    };
}

const AgentQuote: React.FC<Props> = ({ page }) => {
    const showOfferedByLabel =
        page?.acf.agent_hero.personal_agent_tmpl_hero_logo ||
        page?.acf.agent_hero.personal_agent_tmpl_hero_name;

    return (
        <PageContent
            className="agent-quote"
            showTopNav={false}
            includeBottomPadding
        >
            <PageSection className="mt-4 sm:mt-24">
                <Grid>
                    <div
                        className="col-span-full sm:col-span-4 lg:col-span-6"
                        data-cy="agent.hero.content"
                    >
                        <H4Serif
                            className="text-navy text-center mb-6 sm:mb-12 lg:mb-16 lg:px-16"
                            data-cy="agent.hero.title"
                        >
                            Apply now for a fast decision from Bestow.
                        </H4Serif>
                        {showOfferedByLabel && (
                            <TextFieldLabel className="mb-6 sm:mb-9 text-center">
                                OFFERED BY
                            </TextFieldLabel>
                        )}
                        <div className="mb-8 sm:mb-16 flex justify-center">
                            {page?.acf.agent_hero
                                .personal_agent_tmpl_hero_logo ? (
                                <img
                                    data-cy="agent.logo"
                                    className={
                                        page?.acf.agent_hero
                                            .personal_agent_tmpl_logo_large
                                            ? 'agent-logo-large'
                                            : 'agent-logo'
                                    }
                                    alt="Agent logo"
                                    src={
                                        page?.acf.agent_hero
                                            .personal_agent_tmpl_hero_logo
                                    }
                                />
                            ) : (
                                <H3Sans
                                    data-cy="agent.hero.name"
                                    className="text-navy font-bold text-center"
                                >
                                    {page?.acf.agent_hero.personal_agent_tmpl_hero_name?.replace(
                                        /<\/?br\/?>/g,
                                        ' ',
                                    )}
                                </H3Sans>
                            )}
                        </div>
                        <div
                            className="hidden sm:block"
                            data-cy="agent.hero.subtitle"
                        >
                            <BodyText className="text-center mb-4">
                                <span className="mr-4">&bull;</span>10, 15, 20,
                                25, 30 year <br className="lg:hidden" />
                                level term options
                            </BodyText>
                            <BodyText className="text-center mb-4">
                                <span className="mr-4">&bull;</span>Between
                                $50K-$1.5M <br className="lg:hidden" />
                                in life insurance coverage
                            </BodyText>
                            <BodyText className="text-center mb-8 sm:mb-0">
                                <span className="mr-4">&bull;</span>No medical
                                exam — ever!
                            </BodyText>
                        </div>
                        <div className="sm:hidden" data-cy="agent.hero.text">
                            <SmallText className="text-center mb-4">
                                <span className="mr-4">&bull;</span>10, 15, 20,
                                25, 30 year level term options
                            </SmallText>
                            <SmallText className="text-center mb-4">
                                <span className="mr-4">&bull;</span>Between
                                $50K-$1.5M in life insurance coverage
                            </SmallText>
                            <SmallText className="text-center mb-8 sm:mb-0">
                                <span className="mr-4">&bull;</span>No medical
                                exam — ever!
                            </SmallText>
                        </div>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 relative lg:px-2">
                        <Quote
                            className="filter drop-shadow-quote"
                            isAgent
                            agentCode={page?.acf.personal_agent_short_code}
                        />
                        <FiveMinuteSeal className="h-16 w-16 absolute -top-8 left-1 sm:left-auto sm:right-2 lg:right-6" />
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default AgentQuote;
