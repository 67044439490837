import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

import './index.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    aspectRatioWidth?: number,
    aspectRatioHeight?: number,
}

/**
 * Container that maintains an exact aspect ratio
 */
const AspectRatioBox: React.FC<Props> = ({
    aspectRatioWidth = 16,
    aspectRatioHeight = 9,
    className = "",
    style= {},
    children,
    ...otherProps
}) => {
    return (
        <div className={cx("relative", className)} style={{...style, paddingBottom: `${((aspectRatioHeight * 1.0) / (aspectRatioWidth * 1.0)) * 100.0}%`}} {...otherProps}>
            <div className="absolute inset-0">
                {children}
            </div>
        </div>
    )
};

export default AspectRatioBox;