import React from 'react';
import SecondaryButton from "../../../components/_common/button/secondary";
import {ButtonArrowLeft} from "../../../images/svgs";
import BlogPostShare from "../share";

interface Props {
    title: string,
    isLearningCenter?: boolean
}

const BlogPostFooter: React.FC<Props> = ({ title, isLearningCenter }) => {
    return (
        <div>
            <div className="border-solid border-0 border-t border-gray-300" />
            <div className="flex flex-col-reverse sm:flex-row">
                <div className="mt-9 sm:mt-10">
                    <SecondaryButton to={`/${isLearningCenter ? "learningcenter" : "blog"}`} LeftIcon={ButtonArrowLeft}>
                        Back to { isLearningCenter ? "Learning Center" : "Blog" }
                    </SecondaryButton>
                </div>
                <div className="flex justify-center sm:flex-1 sm:justify-end mt-9">
                    <BlogPostShare title={title} url={`https://www.bestow.com${document.location.pathname}`} />
                </div>
            </div>
        </div>
    )
};

export default BlogPostFooter;

