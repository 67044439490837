import React from 'react';

import BodyText from "../index";

interface Props {
    classes?: string,
    data: string[],
}

const BodyTextArray: React.FC<Props> = ({data, classes = "mb-4"}) => (
    <>
        {data.map((item, idx) => (
            <BodyText
                key={`p-${idx}`}
                className={classes}
                dangerouslySetInnerHTML={{ __html: item }}
            />
        ))}
    </>
);

export default BodyTextArray;