import React from 'react';

import ScrollLink from '../../../components/_common/scroll-link/index';
import Subtitle1 from '../../../components/_common/text/heading/subtitle1/index';
import BodyTextArray from "../../../components/_common/text/body-text/array";
import Grid from "../../../components/_common/grid";

import './index.scss';

import { termsData } from '../data';

const TermsTableOfContents: React.FC = () => (
    <div className="table-of-contents mb-12 sm:mb-16">
        <Subtitle1 className="text-primary text-center mt-8 sm:mt-0 mb-8 sm:mb-8 sm:mx-16 lg:mx-0">
            {termsData.tableOfContents.header}
        </Subtitle1>
        <Grid smCols={6} lgCols={10}>
            <div className="col-span-full lg:col-span-4 mb-6 sm:mb-4">
                <BodyTextArray
                    data={termsData.tableOfContents.body}
                    classes="terms-body text-center lg:text-left mb-6"
                />
            </div>
            <div className="col-span-full lg:col-span-6 toc-list pl-14 sm:pl-6">
                <ol className="list-decimal my-0">
                    {termsData.sections.map((item, idx) => (
                        <li className="pb-2 mr-4" key={`toc-${idx}`}>
                            <ScrollLink scrollTo={item.id} className="font-medium">
                                {item.title}
                            </ScrollLink>
                        </li>
                    ))}
                </ol>
            </div>
        </Grid>
    </div>
);

export default TermsTableOfContents;
