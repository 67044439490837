import React from 'react';

import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import BodyText from "../../../components/_common/text/body-text";

interface Props {
    number: number,
    title: string,
    text: string,
}

const HowItWorksItem: React.FC<Props> = ({ number, title, text, children }) => {
    return (
        <div className="flex mb-8 sm:mb-0 lg:mb-8">
            <Subtitle2 className="mr-4 mb-4">
                {number}.
            </Subtitle2>
            <div>
                <Subtitle2 className="mb-2">
                    {title}
                </Subtitle2>
                <BodyText>
                    {text}
                </BodyText>
                {children}
            </div>
        </div>
    )
};

export default HowItWorksItem;