const faqs = [
    {
        id: 1,
        title: {
            rendered: 'What is guaranteed-issue life insurance?',
        },
        content: {
            rendered: `Guaranteed-issue life insurance is an option for people between ages 50 to 80 who may have difficulty 
          qualifying for other types of life insurance policies because of age or medical history. The coverage amounts 
          are lower than other types of life insurance, but there are no health screenings or medical exams, so you could 
          get guaranteed coverage in a matter of minutes (provided you are a U.S. citizen or legal resident residing 
          in the U.S.).`,
        },
    },
    {
        id: 2,
        title: {
            rendered: 'I have aging parents. Can I buy a policy for them?',
        },
        content: {
            rendered: `You can help them apply for guaranteed life insurance either online or over the phone. Since they will 
        be the insured, your parents will need to give consent and sign the application.* If you’d like to pay the 
        premiums on their behalf, you’ll need to sign what’s called a supplemental owner form. For more information 
        on that, call 866-503-4481.`,
        },
    },
    {
        id: 3,
        title: {
            rendered: 'Does my policy have a cash value?',
        },
        content: {
            rendered: `Yes**, and you may be able to withdraw money against that cash value as it increases over time. 
        You may also be able to borrow money against the policy’s cash value, repayable with interest.`,
        },
    },
    {
        id: 4,
        title: {
            rendered: 'I have a medical issue. Will I be eligible?',
        },
        content: {
            rendered: `<div>Yes. Guaranteed life insurance does not require a medical exam in order to qualify.</div><br />

          Policy note: This policy does have what’s called a graded death benefit. This means that if the insured passes away in the first two years (other than as a result of an accident), the policy’s beneficiary(ies) will receive a payout consisting of all premiums paid so far plus 10%. The full death benefit is paid if death occurs after this two-year period.`,
        },
    },
    {
        id: 5,
        title: {
            rendered: 'Do I need a medical exam?',
        },
        content: {
            rendered: `No medical exams are required to qualify for a guaranteed-issue life insurance policy.`,
        },
    },
];

export default faqs;
