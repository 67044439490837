import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import TextFieldLabel from '../../text/textFieldLabel';
import HelperText from '../../text/helperText';
import SmallText from '../../text/small-text';
import { FormikCustomFieldCommonProps } from '../';

import './index.scss';

export interface Props extends FormikCustomFieldCommonProps {
    options: {
        label: string;
        value: string;
        disabled?: boolean;
    }[];
}

const InsideLabelSelect: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({
    className = '',
    label,
    error,
    helper,
    labelHelper,
    required,
    placeholder = 'Please Select',
    options,
    ...otherProps
}) => {
    // Not great, but need to force a re-render once value has been set from query params, else we're stuck with the pre-rendered html state
    const [selectValue, setSelectDataValue] = useState<
        string | number | readonly string[]
    >('');
    const classes = cx('inside-label-select', className, {
        error,
    });

    useEffect(() => {
        if (otherProps.value) {
            setSelectDataValue(otherProps.value);
        }
    }, [otherProps]);

    return (
        <div>
            <div className="flex w-full relative">
                <select
                    className={`${classes} leading-5`}
                    data-value={selectValue}
                    {...otherProps}
                    required
                >
                    <>
                        <option value="" disabled>
                            {placeholder}
                        </option>
                        {/* @ts-ignore */}
                        {options.map((option, idx) => (
                            <option value={option.value} key={`country-${idx}`}>
                                {option.label}
                            </option>
                        ))}
                    </>
                </select>
                <span
                    className={cx(
                        'absolute top-0 left-0 px-4 pointer-events-none transform transition-all flex items-center text-gray-700 font-sans font-light',
                        {
                            'floating-label': !otherProps.value,
                            'floating-label-raised': otherProps.value,
                        },
                    )}
                >
                    {label}
                    {required && <span className="text-error">*</span>}
                </span>
            </div>
            {helper && (
                <div className="mt-1">
                    <HelperText error={error}>{helper}</HelperText>
                </div>
            )}
        </div>
    );
};

export default InsideLabelSelect;
