import React from 'react';

import SecondaryButton from "../index";
import { Props } from "../../index";

import './index.scss';

const SecondaryButtonWhite: React.FC<Props> = ({ className = "", ...otherProps }) => {
    return (
        <SecondaryButton className={`secondary-btn-white ${className}`} {...otherProps} />
    )
};

export default SecondaryButtonWhite;
