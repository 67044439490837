import React from 'react';
import cx from 'classnames';

import Heading, { Props } from '../';

import './index.scss';

const Subtitle1: React.FC<Props> = ({children, className = "", ElementType = "h5", ...otherProps}) => {
  return (
    <Heading className={cx('brand-subtitle1 font-bold font-sans', className)} ElementType={ElementType} {...otherProps}>
        {children}
    </Heading>
  );
};

export default Subtitle1;
