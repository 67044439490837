import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnerSettingsSelector } from '../../redux/pages/selectors';
import { fetchPartnerSettings } from '../../redux/pages';
import { mergeTemplateData } from '../../utilities/wordpressUtils';

import PageContent from '../../components/_common/pageContent';
import PageSection from '../../components/_common/section/page';
import FAQShortStack from '../../components/_common/modules/faqs/shortStack';
import useFAQsByIdFilter from '../../hooks/useFAQsByIdFilter';
import TrustModule from '../../components/_common/modules/trust';
import StepModule from '../../components/_common/modules/step';
import ReviewSliderModule from '../../components/_common/modules/reviewSlider';
import CTAModule from '../../components/_common/modules/cta';
import PrimaryButton from '../../components/_common/button/primary';
import QuoteProtect from './protect';
import QuoteText from './quote-text';
import PrimaryLargeButton from '../../components/_common/button/primary/large';
import QuoteHeroImg from './heroImg/';
import QuoteHeader from './quote-header';
import QuoteProductFeatures from './product-features';
import { QuoteTextType } from './quote-text';
import { QuoteProps } from '../../components/quote/new';

import { REVIEWS } from '../reviews/data';

import {
    QuoteInstantHandIcon,
    InstantClockIcon,
    ThriftyIcon,
} from '../../images/icons/index';

import './index.scss';

interface Props extends QuoteProps {
    page?: {
        acf: {
            partner_hero: {
                partner_tmpl_hero_logo_link?: {
                    url: string;
                };
                partner_tmpl_hero_title?: string;
                partner_tmpl_hero_sub_title?: string;
                partner_tmpl_hero_text?: string;
                partner_tmpl_hero_logos: string;
                partner_tmpl_logo_large?: boolean;
            };
        };
    };
    quoteText: QuoteTextType;
    quoteHeader?: any;
}

const QuotePage: React.FC<Props> = ({ page, quoteText, quoteHeader }) => {
    const faqs = useFAQsByIdFilter([288, 287, 298, 294]);
    const globalSettings = useSelector(partnerSettingsSelector);
    const pageData = mergeTemplateData(globalSettings || {}, page, [
        'partner_hero',
    ]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (page && !globalSettings) {
            dispatch(fetchPartnerSettings());
        }
    }, [dispatch, globalSettings, page]);

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    const hero = pageData?.partner_hero;

    const mappedHero = {
        logo_link: hero?.partner_tmpl_hero_logo_link,
        title: hero?.partner_tmpl_hero_title,
        sub_title: hero?.partner_tmpl_hero_sub_title,
        text: hero?.partner_tmpl_hero_text,
        logos: hero?.partner_tmpl_hero_logos,
        logo_large: hero?.partner_tmpl_logo_large,
    };

    quoteText = hero?.partner_tmpl_hero_sub_title || quoteText;
    const title = hero?.partner_tmpl_hero_title || 'Get your fast, free quote';

    return (
        <PageContent className="partner-quote" showTopNav={false}>
            {quoteHeader || (
                <QuoteHeader
                    hero={mappedHero}
                    title={title}
                    bannerText="NO MEDICAL EXAM / INSTANT DECISION"
                />
            )}
            <QuoteHeroImg />
            <TrustModule className="bg-off-white" />
            <QuoteText quoteText={quoteText} />
            <QuoteProductFeatures />
            <ReviewSliderModule reviews={REVIEWS} />
            <StepModule
                className="bg-mint sm:bg-transparent sm:mx-20 lg:mx-0"
                title="There is a better way"
                step1={{
                    Icon: QuoteInstantHandIcon,
                    title: 'Easy',
                    text:
                        'Apply online with no medical exams, paperwork, or visits to sad office parks.',
                }}
                step2={{
                    Icon: InstantClockIcon,
                    title: 'Speedy',
                    text:
                        'No need to wait months. Get a quote in seconds, apply in minutes.',
                }}
                step3={{
                    Icon: ThriftyIcon,
                    title: 'Thrifty',
                    text:
                        'Plans start from just $10 a month. Get coverage from $50,000 to $1.5 million.',
                }}
            />

            <QuoteProtect
                items={[
                    {
                        label: 'A Home',
                        text:
                            "What's more important than a home? A policy that can help pay for all or a portion of your mortgage can ensure your family stays in the home, neighborhood, and schools you choose should you pass away.",
                        imageClass:
                            'bg-quote-protect-home sm:bg-quote-protect-home-sm lg:bg-quote-protect-home-lg',
                    },
                    {
                        label: 'An Education',
                        text:
                            'A life insurance policy might help your family fund higher education, private school or other training to give your children or spouse a leg up on their future.',
                        imageClass: 'bg-quote-protect-education',
                    },
                    {
                        label: 'A Comfortable Life',
                        text:
                            'From phone plans to back-to-school shopping, there are a lot of expenses that can add up quickly. A payout from a life insurance policy could help your loved ones stay on top of those costs should you pass away.',
                        imageClass:
                            'bg-quote-protect-life sm:bg-quote-protect-life-sm',
                    },
                ]}
            />
            <PageSection className="pt-11 pb-20 sm:pt-24 lg:pb-24">
                <FAQShortStack faqs={faqs} />
            </PageSection>
            <CTAModule
                className="bg-off-white"
                title={
                    <>
                        Coverage today, if approved.
                        <br />
                        Yep. It's that easy.
                    </>
                }
            >
                <div className="w-full px-8 flex justify-center">
                    <PrimaryButton
                        className="w-full sm:hidden"
                        onClick={scrollToTop}
                    >
                        Get a Quote
                    </PrimaryButton>
                    <PrimaryLargeButton
                        className="hidden sm:block"
                        onClick={scrollToTop}
                    >
                        Get a Quote
                    </PrimaryLargeButton>
                </div>
            </CTAModule>
        </PageContent>
    );
};

export default QuotePage;
