import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import querystring from 'querystring';
import { parse, format } from 'date-fns';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import './index.scss';
import { setCookie } from '../../../../../../utilities/cookies';
import { trackQuoteToStartApplication } from '../../../api/analytics';
import H4Sans from '../../../../../_common/text/heading/h4/sans';
import H1Sans from '../../../../../_common/text/heading/h1/sans';
import BodyText from '../../../../../_common/text/body-text';
import PrimaryButton from '../../../../../_common/button/primary';
import { DATE_PATTERN } from '../../../../../_common/inputs/dob';
import {
    averageProductRatesSelectorNoHealthExperiment,
    bestProductRatesSelectorNoHealthExperiment,
    bestQuoteSelectorNoHealthExperiment,
    defaultProductSelectorNoHealthExperiment,
    goodProductRatesSelectorNoHealthExperiment,
    quoteFormSelectorNoHealthExperiment,
} from '../nhQuoteLogic/selectors';
import Subtitle1 from '../../../../../_common/text/heading/subtitle1';
import SmallText from '../../../../../_common/text/small-text';
import InsideLabelSelect from '../../../../../_common/inputs/insideLabelSelect';
import { COVERAGE_OPTIONS, TERM_OPTIONS } from '../nhQuoteForm/constants';
import {
    trackLaunchDarklyEvent,
    trackLaunchDarklyEventWithFlush,
} from '../../../../../../utilities/launchDarkly.track';

// KBM - This was pulled from AQX. Looks like this value is not dynamic.
export const DEFAULT_COVERAGE_AMOUNT = '500000';

const NoHealthQuoteEstimate: React.FC = () => {
    const bestQuote = useSelector(bestQuoteSelectorNoHealthExperiment);
    const defaultProduct = useSelector(
        defaultProductSelectorNoHealthExperiment,
    );
    const quoteForm = useSelector(quoteFormSelectorNoHealthExperiment);
    const bestRates = useSelector(bestProductRatesSelectorNoHealthExperiment);
    const goodRates = useSelector(goodProductRatesSelectorNoHealthExperiment);
    const averageRates = useSelector(
        averageProductRatesSelectorNoHealthExperiment,
    );
    const client = useLDClient();
    const [currentProduct, setCurrentProduct] = useState(
        bestRates[quoteForm.product] ? quoteForm.product : defaultProduct,
    );
    const [coverageAmount, setCoverageAmount] = useState(
        _getInitialCoverageAmount(quoteForm, bestRates[currentProduct]),
    );

    useEffect(() => {
        trackLaunchDarklyEvent(client, 'received_quote');
    }, []);

    const getStartQuoteUrl = () => {
        const queryParams = getQueryParams(
            quoteForm,
            currentProduct,
            coverageAmount,
            bestQuote.quote_id,
        );
        const paramString = querystring.stringify(queryParams);

        return `${process.env.REACT_APP_ENROLLMENT_V3_URL}/?${paramString}`;
    };

    const onStartClick = () => {
        // VWO track final pricing Dimensions
        const lastRate = bestRates[currentProduct][coverageAmount];
        setCookie('bestowPrice', lastRate, 30, 'bestow.com');
        // End VWO track final pricing Dimensions
        // LaunchDarkly track user through funnel
        let LDClientKey = client?.getUser().key;
        setCookie('LDClientKey', LDClientKey, null, 'bestow.com');
        // END LaunchDarkly track user through funnel
        trackQuoteToStartApplication(
            coverageAmount,
            currentProduct,
            lastRate,
            bestQuote.quote_id,
        );
        trackLaunchDarklyEventWithFlush(client, 'quote_to_start_application');
    };

    // filters available term and coverage options to return a reactive list of selections based on a user's options.
    const filterTermAndCoverageOptions = useCallback(
        (TermOrCoverage: 'term' | 'coverage') => {
            const options =
                TermOrCoverage === 'term'
                    ? Object.keys(bestRates)
                    : Object.keys(bestRates[currentProduct]);
            let eligibleOptions: { label: string; value: string }[] = [];
            options.forEach(option =>
                (TermOrCoverage === 'term'
                    ? TERM_OPTIONS
                    : COVERAGE_OPTIONS
                ).forEach(match => {
                    if (match.value === option) {
                        eligibleOptions.push(match);
                        return eligibleOptions;
                    }
                    return eligibleOptions;
                }),
            );
            return eligibleOptions;
        },
        [currentProduct, bestRates],
    );

    const { eligibleProductOptions, eligibleCoverageOptions } = useMemo(() => {
        const eligibleProductOptions = filterTermAndCoverageOptions('term');
        const eligibleCoverageOptions = filterTermAndCoverageOptions(
            'coverage',
        );
        return { eligibleProductOptions, eligibleCoverageOptions };
    }, [filterTermAndCoverageOptions]);

    return (
        <>
            <div
                className="noHealth-quote-adjuster text-center"
                data-testid="noHealth-quote-adjuster"
            >
                <div className="mb-3 mt-8 text-gray-900">
                    <Subtitle1>Estimated monthly price</Subtitle1>
                </div>
                <SmallText className="font-normal whitespace-nowrap text-black">
                    Your overall health affects your quote.
                </SmallText>
                <div className=" bg-white mt-4 px-.5">
                    {!quoteForm.tobacco && (
                        <>
                            <div className="flex justify-between items-center px-5 pt-3">
                                <BodyText className="font-normal flex items-center text-lg">
                                    Best
                                </BodyText>
                                <div className="flex mt-1">
                                    <sup>
                                        <H4Sans className="mt-1 text-black text-sm">
                                            $
                                        </H4Sans>
                                    </sup>
                                    <H1Sans
                                        className="text-black flex items-center"
                                        style={{
                                            fontSize: '2rem',
                                            fontWeight: 'bold',
                                        }}
                                        data-testid="best-rate"
                                    >
                                        {numeral(
                                            bestRates[currentProduct][
                                                coverageAmount
                                            ],
                                        ).format('0,0', Math.floor) === '0' ? (
                                            <>&#8212;</>
                                        ) : (
                                            numeral(
                                                bestRates[currentProduct][
                                                    coverageAmount
                                                ],
                                            ).format('0,0', Math.floor)
                                        )}
                                    </H1Sans>
                                </div>
                            </div>
                            <hr
                                className="w-fit mx-5"
                                style={{ borderTop: '1px solid #DEDBDA' }}
                            />
                        </>
                    )}
                    <div
                        className={`flex justify-between items-center px-5 ${
                            quoteForm.tobacco && 'pt-3'
                        }`}
                    >
                        <BodyText className="font-normal flex items-center text-lg">
                            Good
                        </BodyText>
                        <div className="flex mt-2">
                            <sup>
                                <H4Sans className="mt-1 text-black text-sm">
                                    $
                                </H4Sans>
                            </sup>
                            <H1Sans
                                className="text-black flex items-center"
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                }}
                                data-testid="good-rate"
                            >
                                {numeral(
                                    goodRates[currentProduct][coverageAmount],
                                ).format('0,0', Math.floor)}
                            </H1Sans>
                        </div>
                    </div>
                    {Object.keys(averageRates).length !== 0 && (
                        <>
                            <hr
                                className="w-fit mx-5"
                                style={{ borderTop: '1px solid #DEDBDA' }}
                            />
                            <div className="flex justify-between items-center px-5 pb-2">
                                <BodyText className="font-normal flex items-center text-lg">
                                    Average
                                </BodyText>
                                <div className="flex mt-2">
                                    <sup>
                                        <H4Sans className="mt-1 text-black text-sm">
                                            {numeral(
                                                averageRates[currentProduct][
                                                    coverageAmount
                                                ],
                                            ).format('0,0', Math.floor) ===
                                            '0' ? (
                                                ''
                                            ) : (
                                                <>$</>
                                            )}
                                        </H4Sans>
                                    </sup>
                                    <H1Sans
                                        className="text-black flex items-center"
                                        style={{
                                            fontSize:
                                                numeral(
                                                    averageRates[
                                                        currentProduct
                                                    ][coverageAmount],
                                                ).format('0,0', Math.floor) ===
                                                '0'
                                                    ? '1.5rem'
                                                    : '2rem',
                                            fontWeight: 'bold',
                                            lineHeight: '40px',
                                        }}
                                        data-testid="average-rate"
                                    >
                                        {numeral(
                                            averageRates[currentProduct][
                                                coverageAmount
                                            ],
                                        ).format('0,0', Math.floor) === '0' ? (
                                            <>N/A</>
                                        ) : (
                                            numeral(
                                                averageRates[currentProduct][
                                                    coverageAmount
                                                ],
                                            ).format('0,0', Math.floor)
                                        )}
                                    </H1Sans>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="my-4">
                    <InsideLabelSelect
                        name="product"
                        label="Term length"
                        className="w-full"
                        value={currentProduct}
                        onChange={(e: React.ChangeEvent<any>) => {
                            setCurrentProduct(e.target.value);
                        }}
                        options={eligibleProductOptions}
                    />
                </div>
                <div className="mb-2">
                    <InsideLabelSelect
                        name="coverage"
                        label="Coverage amount"
                        className="w-full"
                        value={coverageAmount}
                        onChange={(e: React.ChangeEvent<any>) => {
                            setCoverageAmount(e.target.value);
                        }}
                        options={eligibleCoverageOptions}
                    />
                </div>
                <div className="mt-8">
                    <PrimaryButton
                        className="w-full"
                        onClick={onStartClick}
                        href={getStartQuoteUrl()}
                        target="_self"
                        data-testid="quote-adjuster-next-btn"
                    >
                        Next
                    </PrimaryButton>
                </div>
            </div>
        </>
    );
};

const getQueryParams = (
    quoteForm: any,
    currentProduct: string,
    coverageAmount: string,
    quoteId: string,
) => {
    // Blend together all the data needed to send forward to enrollment
    const urlData = {
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
        ...mapFormData(quoteForm),
        coverage: coverageAmount,
        product: currentProduct,
        quoteid: quoteId,
    };

    // TODO: Find out what the historical reason behind this is
    const clickIdKey =
        Object.keys(urlData).find(key => key.match(/clickid/i)) || '';
    const clickid = (urlData as any)[clickIdKey];

    return { ...urlData, clickid };
};

const mapFormData = ({
    birthdate,
    gender,
    stateCode,
    zipCode,
    partner_referral,
    tobacco,
    street_1,
    street_2,
}: any) => ({
    date_of_birth: format(
        parse(birthdate, DATE_PATTERN, new Date()),
        'MM/dd/yyyy',
    ),
    gender,
    state: stateCode,
    zip: zipCode,
    partner_referral,
    tobacco,
    street_1,
    street_2,
});

// TODO: Fix these types
function _getInitialCoverageAmount(quoteForm: any, currentProductRates: any) {
    // First validate that mincoverage and coverage are legit values in the product rates
    const minCoverage = currentProductRates[`${quoteForm.mincoverage}`]
        ? quoteForm.mincoverage
        : null;
    const coverage = currentProductRates[`${quoteForm.coverage}`]
        ? `${Math.max(quoteForm.coverage, minCoverage)}`
        : null;

    // If mincoverage was supplied but coverage was not, then set the default coverage amount to the middle value of the filtered rates
    if (minCoverage && !coverage) {
        const amounts = Object.keys(currentProductRates).filter(
            key => parseInt(key, 10) >= minCoverage,
        );
        return amounts[Math.floor(amounts.length / 2)];
    }

    return coverage || DEFAULT_COVERAGE_AMOUNT;
}

export default NoHealthQuoteEstimate;
