import React from 'react';
import SecondaryButton from '../';

import { Props } from '../../index';

import './index.scss'

const SecondaryButtonCharcoal: React.FC<Props> = ({className = '', children, ...otherProps}) => (
    <SecondaryButton className={`secondary-btn-charcoal border-charcoal focus:ring-charcoal ${className}`} {...otherProps}>
        {children}
    </SecondaryButton>
);

export default SecondaryButtonCharcoal;
