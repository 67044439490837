import { FunctionComponent } from 'react';
import { CustomerReview } from '../types';
import Stars from './stars';

import Subtitle1 from "../../../components/_common/text/heading/subtitle1/serif"
import BodyText from '../../../components/_common/text/body-text';
import BodyTextBold from '../../../components/_common/text/body-text/bold'
import LinkButton from "../../../components/_common/button/link";

type Props = {
    reviews: Array<CustomerReview>;
};

const ReviewList: FunctionComponent<Props> = ({reviews}: Props) => {
    return (
        <div className="container">
            {reviews.map((item, i) => (
                <section key={i} className="odd:bg-off-white flex flex-col sm:flex-row p-4 sm:p-6">
                    <div className="flex sm:flex-col sm:mr-10">
                        <div className="flex-1 sm:flex-initial">
                            <Stars className="mb-4 sm:mb-2" />
                        </div>
                        <BodyTextBold className="text-coral-dark text-center font-bold">{item.review_author}</BodyTextBold>
                    </div>
                    <div className="-mt-1">
                        <Subtitle1 className="font-normal mb-3">{item.review_title}</Subtitle1>
                        <BodyText>{item.review_content}</BodyText>
                    </div>
                </section>  
            ))}
            <div className="flex justify-end sm:justify-center mt-4 sm:mt-6  mb-4 sm:mb-6">
                <LinkButton href="https://www.trustpilot.com/review/bestow.com">
                    Read More Reviews
                </LinkButton>
            </div>
        </div>
    )
};


export default ReviewList;