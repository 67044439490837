import React, { useState, useMemo } from 'react';
import cx from 'classnames';

import Eyebrow from '../../../../components/_common/text/eyebrow';
import Grid from '../../../../components/_common/grid';
import OffsetScrollLink from '../../../../components/_common/offsetScrollLink';

import {ReactComponent as DownArrow} from '../../../../images/Down Arrow.svg';

import './index.scss';

interface Props {
    sections: string[]
}

const THRESHOLD = 5;

const BlogContentToc: React.FC<Props> = ({ sections }) => {
    const [expanded, setExpanded] = useState(false);

    const displayedSections = useMemo(() => {
        return expanded ? sections : sections.slice(0, THRESHOLD);
    }, [expanded, sections]);

    return (
        <Grid lgCols={10} className="mb-12">
            <div className="col-span-full lg:col-start-2 lg:col-span-8 border-solid border-1 border-gray-3 relative">
                <div className="py-3 px-6 border-solid border-0 border-b border-gray-3">
                    <Eyebrow className="m-0 p-0">
                        IN THIS ARTICLE
                    </Eyebrow>
                </div>
                <div className="py-6 px-6">
                    {displayedSections.map((title, index) => (
                        <div className="flex mb-2" key={`toc-item-${index}`}>
                            <OffsetScrollLink
                                className="text-black visited:text-black font-sans text-base"
                                to={`#section-${index + 1}`}
                                offset={-100}>
                                {title}
                            </OffsetScrollLink>
                        </div>
                    ))}
                </div>
                {sections.length > THRESHOLD &&
                    <div className="flex justify-center absolute b-0 l-0 r-0 w-full -mt-3">
                        <div className="blog-content-toggle cursor-pointer bg-white lg:hover:bg-charcoal rounded-full border-solid border-charcoal b-1 w-6 h-6 flex justify-center items-center"
                             onClick={() => setExpanded(!expanded)}>
                            <DownArrow className={cx("w-3 h-2 transform transition-all", {
                                "rotate-180": expanded
                            })} />
                        </div>
                    </div>
                }
            </div>
        </Grid>
    )
};

export default BlogContentToc;
