import React from 'react';
import cx from 'classnames';
import PageSection from "../../../section/page";
import H2Serif from "../../../text/heading/h2/serif";

interface Props {
    title: any,
    className?: string,
}

const CTAModuleOne: React.FC<Props> = ({
    className = "",
    title,
    children
}) => {
    const classes = cx("py-16 sm:pt-24 sm:pb-24 lg:pt-28 lg:pb-28", className);

    return (
        <PageSection className={classes}>
            <H2Serif className="mb-10 sm:mb-16 text-center">
                {title}
            </H2Serif>
            <div className="flex justify-center">
                {children}
            </div>
        </PageSection>
    );
};

export default CTAModuleOne;
