import React from 'react';

import './index.scss';

interface Props {
    className?: string
}

const Quotation: React.FC<Props> = ({ className = "", children }) => {
    return (
        <div className={`quotation font-serif ${className}`}>
            {children}
        </div>
    )
};

export default Quotation;