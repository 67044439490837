import React, { useState, useRef } from 'react';
import cx from 'classnames';
import Slider from 'react-slick';

import PageSection from "../../section/page";
import H3Serif from "../../text/heading/h3/serif";
import Eyebrow from "../../text/eyebrow";
import {isPreRendering} from "../../../../utilities/reactSnap";

import BusinessInsiderLogo from '../../../../images/logos/business-insider.svg';
import MoneyLogo from '../../../../images/logos/money.svg';
import NerdWalletLogo from '../../../../images/logos/nerd-wallet.svg';

import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ReviewSliderTwoSection: React.FC = () => {
    const [activeItem, setActiveItem] = useState(0);
    const sliderRef = useRef(null);

    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        arrows: false,
        autoplay: !isPreRendering(),
        autoplaySpeed: 5000,
        speed: 500,
        swipeToSlide: true,
        beforeChange: (oldIdx: number, newIdx: number) => setActiveItem(newIdx)
    };

    const onLogoClick = (idx: number) => {
        // @ts-ignore
        sliderRef.current.slickGoTo(idx);
    };

    return (
        <div className="review-slider-section">
            <PageSection>
                <div className="flex justify-center">
                    <Eyebrow className="text-xs sm:text-sm mb-6">
                        ALSO SEEN IN
                    </Eyebrow>
                </div>
                <div className="flex justify-center">
                    <div className="flex flex-col items-center">
                        <div className="logo-container flex-shrink flex items-center">
                            <img src={BusinessInsiderLogo} onClick={() => { onLogoClick(0) }} className="mb-6 w-full h-auto sm:w-auto" alt="Business Insider" />
                        </div>
                        <div className={cx('indicator flex-1', { active: activeItem === 0 })} />
                    </div>
                    <div className="flex flex-col items-center mx-4 sm:mx-7">
                        <div className="logo-container flex-shrink flex items-center">
                            <img src={MoneyLogo} onClick={() => { onLogoClick(1) }} className="mb-6 w-full h-auto sm:w-auto" alt="Money" />
                        </div>
                        <div className={cx('indicator flex-1', { active: activeItem === 1 })} />
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="logo-container flex-shrink flex items-center">
                            <img src={NerdWalletLogo} onClick={() => { onLogoClick(2) }} className="mb-6 w-full h-auto sm:w-auto" alt="Nerd Wallet" />
                        </div>
                        <div className={cx('indicator flex-1', { active: activeItem === 2 })} />
                    </div>
                </div>
            </PageSection>
            <PageSection className="bg-navy">
                <Slider {...slickSettings} ref={sliderRef} className="bg-navy text-center">
                    <div>
                        <H3Serif className="text-white">
                            “A good option for those who prioritize convenience.”
                        </H3Serif>
                    </div>
                    <div>
                        <H3Serif className="text-white">
                            “The best way to get coverage during COVID-19”
                        </H3Serif>
                    </div>
                    <div>
                        <H3Serif className="text-white">
                            “Bestow pulls available data about you (with permission)
                            and uses algorithms to decide whether you qualify and set the price.”
                        </H3Serif>
                    </div>
                </Slider>
            </PageSection>
        </div>
    );
};

export default ReviewSliderTwoSection;
