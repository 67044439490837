import React from 'react';

import PageContent from '../../components/_common/pageContent/navy';
import PageSection from "../../components/_common/section/page";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import BodyText from '../../components/_common/text/body-text';
import TermsSection from './section';
import TermsTableOfContents from './toc';
import LegalText from '../../components/_common/text/legal-text/index';
import Grid from "../../components/_common/grid";
import useIsPersonalAgent from "../../hooks/useIsPersonalAgent";

import './index.scss';

import { termsData } from './data';

const Terms: React.FC = () => {
    const isAgentPage = useIsPersonalAgent();

    return (
        <PageContent className="terms-page" showTopNav={!isAgentPage} includeBottomPadding>
            <PageSection className="pt-7 sm:pt-9 lg:pt-16">
                <H1Serif className="text-center sm:text-left mb-2 sm:mb-1">
                    Terms of Use
                </H1Serif>
                <BodyText className="font-medium text-primary lg:text-lg mb-8 sm:mb-12 lg:mb-8 italic sm:ml-2 lg:ml-3 text-center sm:text-left">
                    (aka the legal stuff)
                </BodyText>
                <Grid>
                    <div className="col-span-full lg:col-span-10 lg:col-start-2 sm:px-8 lg:px-0">
                        <LegalText className="mb-4">Last Modified {termsData.topSection.lastModified}</LegalText>
                        <BodyText className="mb-8">{termsData.topSection.topText}</BodyText>
                        <div className="mb-8">
                            <ul className="top-bullets">
                                {termsData.topSection.bullets.map((bullet, idx) => (
                                    <li className="list-disc font-light font-sans ml-12 sm:ml-16 lg:ml-36 mr-8 sm:mr-12 lg:mr-32 mb-2 sm:mb-4" key={idx}>
                                        <BodyText>{bullet}</BodyText>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <BodyText>{termsData.topSection.bottomText}</BodyText>
                    </div>
                </Grid>
                <Grid className="sm:mt-12">
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10">
                        <TermsTableOfContents />
                    </div>
                </Grid>
                {/* Render the numbered sections... */}
                {termsData.sections.map((section, idx) => (
                    <TermsSection
                        sectionData={section}
                        sectionNumber={idx+1}
                    />
                ))}
            </PageSection>
        </PageContent>
    );
};

export default Terms;
