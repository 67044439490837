import React from 'react';

import DeclineTemplate, { Props } from "../../index";

import Gerber from '../../../../../img/partners/gerber.svg';
import LinkButton from '../../../../../../../_common/button/link';
import LegalText from '../../../../../../../_common/text/legal-text';

const GerberDeclineScreen: React.FC<Props> = (props) => {
    return (
        <DeclineTemplate {...props}>
            <div data-testid="gerber-decline">
                <div className="flex justify-center">
                    <a href={process.env.REACT_APP_GERBER_URL} rel="noopener noreferrer" target="_blank">
                        <img src={Gerber} alt="Gerber Life Insurance" className="h-20" />
                    </a>
                </div>
                <div className="flex justify-center mt-6 mb-4">
                    <LinkButton href={process.env.REACT_APP_GERBER_URL}>
                        Find a plan &gt;
                    </LinkButton>
                </div>
                <div>
                    <LegalText className="p-0 mt-12 mb-0">
                        Gerber Life Insurance is a trademark. Used under license from Société des Produits Nestlé S.A.
                        and Gerber Products Company. Copyright ©2022 Gerber Life Insurance Company, White Plains, NY
                        10605.
                    </LegalText>
                </div>
            </div>
        </DeclineTemplate>
    )
};

export default GerberDeclineScreen;
