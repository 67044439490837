import React from 'react';

import Grid from '../../../components/_common/grid';
import PageSection from '../../../components/_common/section/page';
import TrustPilot from '../../../components/_common/modules/trust-pilot';
import H3Serif from '../../../components/_common/text/heading/h3/serif';

const Trusted: React.FC = () => {
    return (
        <PageSection className="bg-white pt-12 lg:pt-24 pb-12 lg:pb-20">
            <Grid>
                <div className="col-span-full flex justify-center">
                    <H3Serif className="text-navy font-bold mb-7">
                        Trusted
                    </H3Serif>
                </div>
            </Grid>
            <Grid>
                <div className="col-span-full">
                    <TrustPilot selectedReview />
                </div>
            </Grid>
        </PageSection>
    );
};

export default Trusted;
