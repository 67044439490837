import React from 'react';

import './index.scss';

interface Props {
    className?: string,
}

const CategoryNumber: React.FC<Props> = ({ className = "", children }) => {
    return (
        <div className={`category-number ${className}`}>
            <div>
                {children}
            </div>
        </div>
    )
};

export default CategoryNumber;