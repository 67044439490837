import React, { useMemo } from 'react';
import queryString from 'querystring';
import { useSelector } from 'react-redux';

import DeclineTemplate, { Props } from '../../index';
import LinkButton from '../../../../../../../_common/button/link';
import { quoteFormSelector } from '../../../../../../../../redux/quote/selectors';
import { DEFAULT_COVERAGE_AMOUNT } from '../../../../adjuster';

import PolicyGenius from '../../../../../img/partners/policyGenius.svg';
import { quoteFormSelectorNoHealthExperiment } from '../../../../../experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic/selectors';

const POLICY_GENIUS_ROOT_URL = 'http://policygenius.go2cloud.org/aff_c';

interface PolicyGeniusUrlParams {
    gender: string;
    birthdate: string;
    height: number;
    weight: number;
    zipCode: string;
    tobacco: boolean;
    coverage: string;
}

const PolicyGeniusDeclineScreen: React.FC<Props> = props => {
    const originalFormData = useSelector(quoteFormSelector);
    const noHealthFormData = useSelector(quoteFormSelectorNoHealthExperiment);
    const formData = noHealthFormData ? noHealthFormData : originalFormData; //MOE-404
    const url = useMemo(() => generatePolicyGeniusUrl(formData), [formData]);

    return (
        <DeclineTemplate {...props}>
            <div>
                <div className="flex justify-center">
                    <a
                        href={url}
                        rel="noopener noreferrer"
                        target="_blank"
                        data-testid="decline-logo-link"
                    >
                        <img
                            src={PolicyGenius}
                            alt="Policy Genius"
                            className="w-44"
                        />
                    </a>
                </div>
                <div className="flex justify-center mt-2 mb-4">
                    <LinkButton href={url}>Find coverage now &gt;</LinkButton>
                </div>
            </div>
        </DeclineTemplate>
    );
};

// Taken straight from AQX...
const generatePolicyGeniusUrl = ({
    gender,
    birthdate,
    height,
    weight,
    zipCode,
    tobacco,
    // This is a supported query param, and might be passed through there
    coverage,
}: // TODO: Do we need to support his? This isn't a "supported query param" so how would this ever be here?
// termChoice
PolicyGeniusUrlParams) => {
    const offer_id = 46;
    const aff_id = 1498;
    const redirect_slug = 'bestowlife';
    const external_handoff = true;
    const query = queryString.stringify({
        offer_id,
        aff_id,
        external_handoff,
        redirect_slug,
        date_of_birth: birthdate.replace(/ /g, ''),
        zip_code: zipCode,
        history_of_tobacco_use: tobacco,
        coverage_amount: coverage || DEFAULT_COVERAGE_AMOUNT,
        // TODO: Find out if we need this
        // termChoice,
        gender,
        height_inches: height,
        weight,
    });

    return `${POLICY_GENIUS_ROOT_URL}?${query}`;
};

export default PolicyGeniusDeclineScreen;
