import React from 'react';

import TextFieldLabel from '../../../components/_common/text/textFieldLabel';
import H4Sans from '../../../components/_common/text/heading/h4/sans';
import Subtitle2 from '../../../components/_common/text/heading/subtitle2';

const Options: React.FC = () => {
    return (
        <div className="flex flex-col sm:flex-row sm:justify-between lg:justify-evenly mx-8 sm:mx-0">
            <div className="bg-coral px-8 py-9 text-center rounded-md">
                <TextFieldLabel className="mb-2">
                    BETWEEN
                </TextFieldLabel>
                <H4Sans className="text-charcoal mb-2">
                    $50,000-<br />$1,500,000
                </H4Sans>
                <Subtitle2 className="mb-0">
                    In Coverage
                </Subtitle2>
            </div>
            <div className="bg-champagne px-8 sm:px-2 lg:px-8 py-9 text-center rounded-md my-6 sm:my-0">
                <TextFieldLabel className="mb-2">
                    CHOOSE FROM
                </TextFieldLabel>
                <H4Sans className="text-charcoal mb-2">
                    10–30<br />Year Terms
                </H4Sans>
                <Subtitle2 className="mb-0">
                    In 5-Year Increments
                </Subtitle2>
            </div>
            <div className="bg-sage px-8 py-9 text-center rounded-md">
                <TextFieldLabel className="mb-2">
                    AGES
                </TextFieldLabel>
                <H4Sans className="text-charcoal mb-2">
                    18–60<br />Are Eligible
                </H4Sans>
                <Subtitle2 className="mb-0">
                    For Coverage
                </Subtitle2>
            </div>
        </div>
    );
};

export default Options;
