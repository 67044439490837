import React from 'react';

import PageContent, { Props } from "../";

import './index.scss';

/**
 * Provides a blush gradient at the top of the page content
 */
const PageContentBlush: React.FC<Props> = ({ className = "", ...otherProps }) => {
    return (
        <PageContent className={`gradient-blush ${className}`} {...otherProps} />
    )
};

export default PageContentBlush;
