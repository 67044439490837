import React from 'react';
import cx from 'classnames';

import Grid from "../../grid";
import H2Serif from "../../text/heading/h2/serif";
import H4Sans from "../../text/heading/h4/sans";
import BodyText from "../../text/body-text";
import PageSection from "../../section/page";

interface Step {
    Icon?: any,
    title: any,
    text: string,
    stepTitleClasses?: string
}

export interface Props {
    className?: string,
    title: any,
    titleClasses?: string,
    stepTextClasses?: string,
    step1: Step,
    step2: Step,
    step3: Step
}

const StepModule: React.FC<Props> = ({
    className = "",
    title,
    titleClasses = "text-navy text-center mx-12 sm:mx-0",
    stepTextClasses,
    step1,
    step2,
    step3,
    children
}) => {
    const classes = cx("py-16", className);

    return (
        <PageSection className={classes}>
            <Grid>
                <H2Serif className={cx("col-span-full mb-6 sm:mb-12", titleClasses)}>
                    {title}
                </H2Serif>
                <div className="col-span-full flex flex-col sm:flex-row justify-between sm:mb-16 lg:mx-8">
                    <Grid cols={1} smCols={3} lgCols={3} smGap={9} lgGap={16}>
                        {[step1, step2, step3].map(({ Icon, title, text, stepTitleClasses }, index) => (
                            <div key={`step-${index}`} className="mb-8 mx-12 sm:mx-0 sm:mb-0">
                                {Icon &&
                                    <div className="flex justify-center mb-4">
                                        <Icon className="w-16 h-16" />
                                    </div>
                                }
                                <H4Sans
                                    className={cx("text-center mb-4", stepTitleClasses, {
                                        "text-coral-darkest": index === 0 && !stepTitleClasses,
                                        "text-spruce-darkest": index === 1 && !stepTitleClasses,
                                        "text-gold-darkest": index === 2 && !stepTitleClasses
                                    })}
                                >
                                    {title}
                                </H4Sans>
                                <BodyText className={cx("text-center", stepTextClasses)}>
                                    {text}
                                </BodyText>
                            </div>
                        ))}
                    </Grid>
                </div>
                {children &&
                    <div className="col-span-full mt-8 flex justify-center">
                        {children}
                    </div>
                }
            </Grid>
        </PageSection>
    );
};

export default StepModule;
