import React from 'react';
import cx from 'classnames';

import Button, { Props as ButtonProps } from "../index";

import './index.scss';

export interface Props extends ButtonProps {
    contentClass?: string,
    indicatorClass?: string,
    disableBorder?: boolean
}

const LinkButton: React.FC<Props> = ({ className = "", contentClass = "", indicatorClass = "bg-charcoal hover:bg-gray-600", disableBorder, children, ...otherProps }) => {
    className = `link-btn transition-all border-solid border-0 rounded-none bg-transparent focus:outline-none focus:bg-turquoise-30 ${className}`;

    // This keeps the button from being outlined when clicked, but also maintains focus outline for accessibility
    const onMouseDown = (e: any) => e.preventDefault();

    return (
        <Button className={cx(className, { "border-b-2": !disableBorder })} {...otherProps} onMouseDown={onMouseDown}>
            {children}
        </Button>
    )
};

export default LinkButton;
