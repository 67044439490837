import React from 'react';
import PageSection from "../../../components/_common/section/page";
import H4Sans from "../../../components/_common/text/heading/h4/sans";
import BodyText from "../../../components/_common/text/body-text";
import { TrustIcon, ThriftyIcon, InstantClockIcon } from '../../../images/icons/index';
import Grid from "../../../components/_common/grid";

const TrustedAffordableFast: React.FC = () => (
    <PageSection className="py-16 sm:py-24 lg:py-32 bg-mint">
        <Grid smCols={3} lgCols={3} gap={14} smGap={16} lgGap={16} className="px-12 sm:px-0 lg:px-10">
            <div className="col-span-full sm:col-span-1 flex flex-col items-center text-center">
                <TrustIcon className="mb-4" style={{height:56}} />
                <H4Sans className="text-coral-darkest mb-4">
                    Trusted
                </H4Sans>
                <BodyText className="mb-0">
                    Policies are provided by an A+ rated carrier.
                </BodyText>
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col items-center text-center">
                <ThriftyIcon className="mb-4" style={{height:56}} />
                <H4Sans className="text-spruce-darkest mb-4">
                    Affordable
                </H4Sans>
                <BodyText className="mb-0">
                    Rates start at just $10/mo, with coverage from  $50K – $1.5M
                </BodyText>
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col items-center text-center">
                <InstantClockIcon className="mb-4" style={{height:56}} />
                <H4Sans className="text-gold-darkest mb-4">
                    Fast
                </H4Sans>
                <BodyText className="mb-0">
                    Quote in seconds. Coverage in minutes, if approved. No hassles.
                </BodyText>
            </div>
        </Grid>
    </PageSection>
);

export default TrustedAffordableFast;
