import React from 'react';

import Grid from '../../../components/_common/grid';
import PageSection from '../../../components/_common/section/page';
import PrimaryButtonNavy from '../../../components/_common/button/primary/navy';
import H2Serif from '../../../components/_common/text/heading/h2/serif';
import BodyTextLarge from '../../../components/_common/text/body-text/large';

import { smoothScrollToTop } from '../../../utilities/smoothScrollToTop';

const BottomCTA: React.FC = () => {
    return (
        <PageSection className="bg-sage-light py-16 lg:pt-20 lg:pb-20">
            <Grid>
                <div className="col-span-full">
                    <div className="mb-8">
                        <H2Serif className="text-center font-bold text-navy">
                            Ready to get started?
                        </H2Serif>
                        <BodyTextLarge className="mt-4 text-center">
                            If you like what you see, apply online in just minutes.
                        </BodyTextLarge>
                    </div>
                    <div className="flex justify-center">
                        <PrimaryButtonNavy
                            onClick={smoothScrollToTop}
                        >
                            Get a quote
                        </PrimaryButtonNavy>
                    </div>
                </div>
            </Grid>
        </PageSection>
    );
};

export default BottomCTA;
