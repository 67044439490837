import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

import {fetchPageSEO} from "../redux/pages";
import {usePageSelector} from "../redux/pages/selectors";

export default function useFetchPageSEO(slug) {
    const pathname = useLocation().pathname;
    const {page, loading} = usePageSelector(pathname);
    const dispatch = useDispatch();

    // Only fetch the page if we don't already have it in Memory
    useEffect(() => {
        if (!page) {
            dispatch(fetchPageSEO(slug || pathname.replace(/\//g, ""), pathname));
        }
    }, [dispatch, slug, page, pathname]);

    return {page, loading};
}