import React from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import PageSection from "../../../components/_common/section/page";

/**
 * There are several pages that need custom hero images. For now, we are hardcoding these in the Application, but we may
 * later decide to enhance WordPress to let marketing set these on each Partner page there, since title, subtitle, and text are
 * also set in WordPress
 */
const QuoteHeroImg: React.FC = () => {
    const location = useLocation();

    return (
        <PageSection className={cx("quote-hero bg-cover sm:mt-0", _getHeroBgClass(location.pathname))} />
    );
};

function _getHeroBgClass(pathname: string) {
    switch (pathname.split("/")[1].toLowerCase()) {
        case "moms":
            return "bg-quote-partners-moms sm:bg-quote-partners-moms-sm lg:bg-quote-partners-moms-lg bg-center -mt-14";
        case "parents":
            return "bg-quote-partners-experian sm:bg-quote-partners-experian-sm lg:bg-quote-partners-experian-lg bg-center -mt-14";
        case "beforetheyknow":
            return "bg-quote-partners-btk sm:bg-quote-partners-btk-sm lg:bg-quote-partners-btk-lg bg-center -mt-14";
        case "pawsthemovie":
            return "bg-quote-partners-paws lg:bg-quote-partners-paws-lg bg-center -mt-14";
        default:
            return "bg-hero-quote sm:bg-hero-quote-sm bg-top -mt-8"
    }
}

export default QuoteHeroImg;
