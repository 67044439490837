import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import numeral from 'numeral';
import Tooltip from '@material-ui/core/Tooltip';
import querystring from 'querystring';
import { parse, format } from 'date-fns';

import Subtitle2 from '../../../../_common/text/heading/subtitle2';
import H1Sans from '../../../../_common/text/heading/h1/sans';
import ProductsButtonGroup from './products';
import CoverageSlider from './slider/coverageSlider';
import H4Sans from '../../../../_common/text/heading/h4/sans';
import TextFieldLabel from '../../../../_common/text/textFieldLabel';
import PrimaryButton from '../../../../_common/button/primary';
import MediumText from '../../../../_common/text/mediumText';
import BodyText from '../../../../_common/text/body-text';
import { DATE_PATTERN } from '../../../../_common/inputs/dob';
import { trackQuoteToStartApplication } from '../../api/analytics';
import {
    defaultProductSelector,
    productRatesSelector,
    quoteFormSelector,
    quoteSelector,
} from '../../../../../redux/quote/selectors';
import { setCookie } from '../../../../../utilities/cookies';

import './index.scss';
import {
    trackLaunchDarklyEvent,
    trackLaunchDarklyEventWithFlush,
} from '../../../../../utilities/launchDarkly.track';

// KBM - This was pulled from AQX. Looks like this value is not dynamic.
export const DEFAULT_COVERAGE_AMOUNT = '500000';

// KBM - Should eventually centralize all dynamic strings
const TOOLTIP_TEXT = {
    nonSmoker:
        'This quote uses your age, BMI, and gender to calculate a price for a generally healthy non-smoker in your state. Price may change after completing the full application. Some limitations and restrictions may apply.',
    smoker:
        'This quote uses your age, BMI, smoking status, and gender to calculate a price for your state. Price may change after completing the full application. Some limitations and restrictions may apply.',
};

const QuoteEstimate: React.FC = () => {
    const quote = useSelector(quoteSelector);
    const rates = useSelector(productRatesSelector);
    const defaultProduct = useSelector(defaultProductSelector);
    const quoteForm = useSelector(quoteFormSelector);
    const client = useLDClient();
    const [currentProduct, setCurrentProduct] = useState(
        rates[quoteForm.product] ? quoteForm.product : defaultProduct,
    );
    const [coverageAmount, setCoverageAmount] = useState(
        _getInitialCoverageAmount(quoteForm, rates[currentProduct]),
    );
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        trackLaunchDarklyEvent(client, 'received_quote');
    }, []);

    const getStartQuoteUrl = () => {
        const queryParams = getQueryParams(
            quoteForm,
            currentProduct,
            coverageAmount,
            quote.quote_id,
        );
        const paramString = querystring.stringify(queryParams);
        return `${process.env.REACT_APP_ENROLLMENT_V3_URL}/?${paramString}`;
    };

    const onStartClick = () => {
        // VWO track final pricing Dimensions
        const lastRate = rates[currentProduct][coverageAmount];
        setCookie('bestowPrice', lastRate, 30, 'bestow.com');
        // End VWO track final pricing Dimensions
        // LaunchDarkly track user through funnel
        let LDClientKey = client?.getUser().key;
        setCookie('LDClientKey', LDClientKey, null, 'bestow.com');
        // END LaunchDarkly track user through funnel
        trackQuoteToStartApplication(
            coverageAmount,
            currentProduct,
            lastRate,
            quote.quote_id,
        );
        trackLaunchDarklyEventWithFlush(client, 'quote_to_start_application');
    };

    const tooltipText = quoteForm.tobacco
        ? TOOLTIP_TEXT.smoker
        : TOOLTIP_TEXT.nonSmoker;

    return (
        <>
            <div
                className="quote-adjuster text-center"
                data-testid="quote-adjuster"
            >
                <div className="mb-8">
                    <Subtitle2>Your Quote</Subtitle2>
                </div>
                <div className="flex justify-center">
                    <sup>
                        <H4Sans className="mr-2 mt-1 text-charcoal">$</H4Sans>
                    </sup>
                    <H1Sans
                        className="text-charcoal"
                        style={{ fontSize: '5rem' }}
                    >
                        {numeral(rates[currentProduct][coverageAmount]).format(
                            '0,0',
                            Math.floor,
                        )}
                    </H1Sans>
                    <sup>
                        <H4Sans className="ml-1 mt-1 text-charcoal">
                            {numeral(
                                rates[currentProduct][coverageAmount],
                            ).format('.00')}
                        </H4Sans>
                    </sup>
                </div>
                <div className="-mt-2 flex items-center justify-center">
                    <div>
                        <MediumText className="font-normal text-gray-800">
                            Estimated Monthly Price
                        </MediumText>
                    </div>
                    <Tooltip
                        title={
                            <BodyText className="text-white font-regular p-2">
                                {tooltipText}
                            </BodyText>
                        }
                        open={showTooltip}
                        arrow
                    >
                        <div
                            className="info rounded-full bg-gray-300 hover:bg-turquoise-30 text-white text-center w-5 h-5 ml-2 cursor-pointer flex items-center justify-center"
                            onClick={() => setShowTooltip(!showTooltip)}
                        >
                            <span className="font-sans font-bold text-xs text-charcoal info-btn">
                                i
                            </span>
                        </div>
                    </Tooltip>
                </div>
                <div className="mb-3 mt-8">
                    <TextFieldLabel>YEARS OF COVERAGE</TextFieldLabel>
                </div>
                <div>
                    <ProductsButtonGroup
                        rates={rates}
                        currentProduct={currentProduct}
                        setCurrentProduct={setCurrentProduct}
                    />
                </div>
                <div className="mt-8 mb-4">
                    <TextFieldLabel>COVERAGE AMOUNT</TextFieldLabel>
                </div>
                <div className="text-center">
                    <Subtitle2 data-testid="quote-coverage-amount">
                        {numeral(coverageAmount).format('$0,0')}
                    </Subtitle2>
                </div>
                <div className="mt-4 lg:px-5">
                    <CoverageSlider
                        coverageAmount={coverageAmount}
                        product={rates[currentProduct]}
                        setCoverageAmount={setCoverageAmount}
                    />
                </div>
                <div className="mt-16">
                    <PrimaryButton
                        className="w-full"
                        onClick={onStartClick}
                        href={getStartQuoteUrl()}
                        target="_self"
                        data-testid="quote-adjuster-next-btn"
                    >
                        Next
                    </PrimaryButton>
                </div>
            </div>
        </>
    );
};

const getQueryParams = (
    quoteForm: any,
    currentProduct: string,
    coverageAmount: string,
    quoteId: string,
) => {
    // Blend together all the data needed to send forward to enrollment
    const urlData = {
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
        ...mapFormData(quoteForm),
        coverage: coverageAmount,
        product: currentProduct,
        quoteid: quoteId,
    };

    // TODO: Find out what the historical reason behind this is
    const clickIdKey =
        Object.keys(urlData).find(key => key.match(/clickid/i)) || '';
    const clickid = (urlData as any)[clickIdKey];

    return { ...urlData, clickid };
};

const mapFormData = ({
    birthdate,
    gender,
    height,
    stateCode,
    weight,
    zipCode,
    partner_referral,
    tobacco,
    street_1,
    street_2,
}: any) => ({
    date_of_birth: format(
        parse(birthdate, DATE_PATTERN, new Date()),
        'MM/dd/yyyy',
    ),
    gender,
    height,
    weight,
    state: stateCode,
    zip: zipCode,
    partner_referral,
    tobacco,
    street_1,
    street_2,
});

// TODO: Fix these types
function _getInitialCoverageAmount(quoteForm: any, currentProductRates: any) {
    // First validate that mincoverage and coverage are legit values in the product rates
    const minCoverage = currentProductRates[`${quoteForm.mincoverage}`]
        ? quoteForm.mincoverage
        : null;
    const coverage = currentProductRates[`${quoteForm.coverage}`]
        ? `${Math.max(quoteForm.coverage, minCoverage)}`
        : null;

    // If mincoverage was supplied but coverage was not, then set the default coverage amount to the middle value of the filtered rates
    if (minCoverage && !coverage) {
        const amounts = Object.keys(currentProductRates).filter(
            key => parseInt(key, 10) >= minCoverage,
        );
        return amounts[Math.floor(amounts.length / 2)];
    }

    return coverage || DEFAULT_COVERAGE_AMOUNT;
}

export default QuoteEstimate;
