import React from 'react';
import cx from 'classnames';

import './index.scss';

export interface Props {
    className?: string,
    inline?: boolean,
}

const Eyebrow: React.FC<Props> = ({children, inline, className = ""}) => {
    const classes = cx('brand-eyebrow font-normal font-sans', className);

    if (inline) return <span className={classes}>{children}</span>;

    return <p className={classes}>{children}</p>
};

export default Eyebrow;