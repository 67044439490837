import React from 'react';

import RadioButton from "../index";

import './index.scss';

const RadioButtonWhite: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
    return (
        <RadioButton className="brand-radio-button-white" {...props} />
    )
};

export default RadioButtonWhite;