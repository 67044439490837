import {useMemo} from "react";
import {useSelector} from "react-redux";
import {allPostsSelector} from "../redux/posts/selectors";
import {allLCPostsSelector} from "../redux/learningCenter/selectors";

/**
 * Returns all the Posts matching the category slug supplied
 * @param categorySlug  The slug of the category to filter by
 * @param isLearningCenter  True if the posts should be filtered from LearningCenter instead of Blog
 * @param isLandingPage  True if the posts are displayed on a page like /blog or /learningcenter. Hides any posts with this flag set from WordPress
 * @returns {Array} An array of posts
 */
const useCategoryPostsFilter = (categorySlug, isLearningCenter = false, isLandingPage = false) => {
    const allPosts = useSelector(isLearningCenter ? allLCPostsSelector : allPostsSelector);

    return useMemo(() => {
        return allPosts.filter(post => {
            return post._embedded["wp:term"][0]?.find(term => term.slug === categorySlug) && (isLandingPage ? !post.acf.hide_on_landing : true)
        });
    }, [allPosts, categorySlug, isLandingPage])
};

export default useCategoryPostsFilter;
