import React from 'react';
import { Link } from 'react-router-dom';
import PageSection from '../../../components/_common/section/page';
import Grid from '../../../components/_common/grid';
import BodyText from '../../../components/_common/text/body-text';
import H2Serif from '../../../components/_common/text/heading/h2/serif';
import BodyTextLarge from '../../../components/_common/text/body-text/large';

import {
    CareerDevIcon,
    MedBenefitsIcon,
    ScholarshipsIcon,
    FlexiblePTOIcon,
} from '../../../images/icons/index';

const CareersAction: React.FC = () => {
    return (
        <PageSection className="mb-20 sm:my-24 lg:my-32">
            <H2Serif className="text-navy text-center mb-10">
                Action over words
            </H2Serif>
            <Grid>
                <BodyTextLarge className="col-span-full lg:col-start-3 lg:col-span-8 text-center mb-6 sm:mx-16 lg:mx-7">
                    Here’s some of what we’re doing to help make Bestow a
                    fantastic place to work.
                </BodyTextLarge>
                <div className="col-span-2 lg:col-span-3 flex flex-col items-center lg:pr-10">
                    <MedBenefitsIcon />
                    <BodyText className="text-center mt-6">
                        Covering 100% of health insurance premiums for employees
                        and their families
                    </BodyText>
                </div>
                <div className="col-span-2 lg:col-span-3 flex flex-col items-center">
                    <CareerDevIcon />
                    <BodyText className="text-center mt-6">
                        Masterclasses and other career development and growth
                        opportunities
                    </BodyText>
                </div>
                <div className="col-span-2 lg:col-span-3 flex flex-col items-center mt-3 sm:mt-0">
                    <ScholarshipsIcon />
                    <BodyText className="text-center mt-6">
                        Scholarships
                        <br />
                        for education
                        <br />
                        and enrichment
                    </BodyText>
                </div>
                <div className="col-span-2 lg:col-span-3 mt-3 flex flex-col items-center sm:mt-0 lg:pl-10">
                    <FlexiblePTOIcon />
                    <BodyText className="text-center mt-6">
                        Flexible PTO and emphasis on work-life balance
                    </BodyText>
                </div>
            </Grid>
            <BodyText className="text-center mt-12">
                See Bestow's Transparency of Coverage data{' '}
                <Link to="/transparency-in-coverage" className="font-bold">
                    here
                </Link>
                .
            </BodyText>
        </PageSection>
    );
};

export default CareersAction;
