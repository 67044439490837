import React from 'react';

import PageContent from '../../components/_common/pageContent/navy';
import PageSection from "../../components/_common/section/page";
import Subtitle1 from "../../components/_common/text/heading/subtitle1/index";
import Subtitle2 from "../../components/_common/text/heading/subtitle2";
import Link from "../../components/_common/link/index";
import Divider from "../../components/_common/divider";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import H2Serif from "../../components/_common/text/heading/h2/serif";
import BodyTextLarge from '../../components/_common/text/body-text/large';
import BodyText from '../../components/_common/text/body-text';
import Grid from "../../components/_common/grid";
import NumberedSection from './numbered-section';
import ClaimsForm from './form';

import { CircledPhoneIcon } from '../../images/icons/index';

const FileAClaim: React.FC = () => {
    return (
        <PageContent className="claims-page pt-9 lg:pt-24 pb-24" includeBottomPadding>
            <PageSection>
                <Grid>
                    <div className="col-span-4 sm:col-span-3">
                        <H1Serif className="mb-8 sm:mb-0 text-center sm:text-left text-navy">
                            Claims
                        </H1Serif>
                    </div>
                    <div className="col-span-4 lg:col-span-9 sm:col-span-5">
                        {/* Mobile */}
                        <BodyText className="sm:hidden">
                            At Bestow, we take seriously our commitment to honor your
                            loved one’s wishes by helping not only gather and report
                            the information needed to file a claim, but also by providing
                            valuable information to help beneficiaries navigate this challenging time.
                        </BodyText>
                        {/* Tablet / Desktop */}
                        <BodyTextLarge className="hidden sm:block sm:pt-6 sm:pr-8 lg:px-16 lg:pt-7">
                            At Bestow, we take seriously our commitment to honor your
                            loved one’s wishes by helping not only gather and report
                            the information needed to file a claim, but also by providing
                            valuable information to help beneficiaries navigate this challenging time.
                        </BodyTextLarge>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="pt-12 lg:pt-20">
                <H2Serif className="text-center mb-12 sm:mb-16 lg:mb-14 text-navy">
                    How the Claims Process Works
                </H2Serif>
                <NumberedSection sectionNumber={1}>
                    <Subtitle1 className="text-coral-darkest mb-4 mt-2 text-center sm:text-left">
                        Submit Claim Form
                    </Subtitle1>
                    <BodyText className="mb-6">
                        Complete the claim form below. After receiving your notice, we’ll perform a policy
                        search, and we’ll notify the appropriate insurance company that issued the policy. If
                        a policy can’t be found, we’ll do our best to provide you with information to help
                        you search for coverage with other unaffiliated insurance companies.
                    </BodyText>
                </NumberedSection>
                <NumberedSection sectionNumber={2}>
                    <Subtitle1 className="text-coral-darkest mb-4 mt-2 text-center sm:text-left">
                        Receive Claim Packet
                    </Subtitle1>
                    <BodyText className="mb-6">
                        Each beneficiary of record will get a claim packet from the insurance company that
                        issued the policy. The claim packet will outline the requirements necessary to settle the claim.
                    </BodyText>
                </NumberedSection>
                <NumberedSection sectionNumber={3}>
                    <Subtitle1 className="text-coral-darkest mb-4 mt-2 text-center sm:text-left">
                        Complete Claim Packet
                    </Subtitle1>
                    <BodyText className="mb-10 sm:mb-12 lg:mb-16">
                        Each beneficiary must submit the required forms and information back to the insurance company
                        that provided the claim packet. It’s important to remember that only the insurance company that
                        issued the policy has the authority to provide beneficiaries with claim updates, to determine
                        the requirements, and to decide if, to whom, and how benefits are paid.
                    </BodyText>
                </NumberedSection>
            </PageSection>
            <ClaimsForm />
            <PageSection>
                <Grid>
                    <div className="col-span-4 sm:col-span-8 lg:col-span-10 lg:col-start-2 flex flex-col sm:flex-row">
                        <div>
                            <Subtitle2 className="text-navy mb-4 text-center sm:text-left">
                                What else should I do?
                            </Subtitle2>
                            <BodyText>
                                There may be other benefits that you are entitled to receive. Read our article about
                                what you should know about the payout as a{' '}
                                <Link href="/blog/life-insurance-beneficiary-payout/">life insurance beneficiary</Link>{' '}
                                and visit our insurance partner’s claims form that has other helpful information.
                            </BodyText>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:pt-8 sm:pb-3 sm:mx-6 lg:mx-7">
                            <Divider className="border-gray-400 my-8 sm:hidden" />
                            <div className="border-0 border-r border-gray-400 border-solid flex-1" />
                        </div>
                        <div className="flex-1">
                            <Subtitle2 className="text-navy mb-4 text-center sm:text-left">
                                Questions?
                            </Subtitle2>
                            <div className="flex" style={{minWidth: 200}}>
                                <div className="mr-4">
                                    <CircledPhoneIcon />
                                </div>
                                <BodyText>
                                    If you have any questions at all, feel free to call at <Link href="tel:8333000603">833-300-0603</Link>.
                                </BodyText>
                            </div>
                        </div>
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default FileAClaim;
