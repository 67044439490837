import React from 'react';
import cx from 'classnames';
import SmallText from '../../../../components/_common/text/small-text/index';
import TabButton from '../../../../components/_common/button/tab';

interface Props {
    numShowing: number,
    postsPerPage: number,
    onSelectPostsPerPage: Function,
    onSelectShowAll: Function,
    totalNumPosts: number
};

const PaginationFilter: React.FC<Props> = ({
    numShowing,
    postsPerPage,
    onSelectPostsPerPage,
    onSelectShowAll
}) => {
    return (
        <div className="flex sm:flex-col align-center justify-between">
            <SmallText className="sm:mb-3">Showing {numShowing} results </SmallText>
            <div className="flex items-center">
                <SmallText className="mr-3">View</SmallText>
                <TabButton
                    className="p-0"
                    selected={postsPerPage === 30}
                    onClick={() => onSelectPostsPerPage(30)}>
                    <SmallText
                        className={cx({'text-charcoal-60': postsPerPage !== 30})}>
                        30
                    </SmallText>
                </TabButton>
                <SmallText className="mx-3">|</SmallText>
                <TabButton
                    className="p-0"
                    selected={postsPerPage === 60}
                    onClick={() => onSelectPostsPerPage(60)}
                >
                    <SmallText
                        className={cx({'text-charcoal-60': postsPerPage !== 60})}>
                        60
                    </SmallText>
                </TabButton>
                <SmallText className="mx-3">|</SmallText>
                <TabButton
                    className="p-0"
                    selected={postsPerPage === -1}
                    onClick={() => { onSelectShowAll() }}
                >
                    <SmallText
                        className={cx({'text-charcoal-60': postsPerPage !== -1})}>
                        All
                    </SmallText>
                </TabButton>
            </div>
        </div>
    )
};

export default PaginationFilter;
