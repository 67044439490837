import React from 'react';

import PageContent from '../../components/_common/pageContent/navy';
import PageSection from "../../components/_common/section/page";
import ReferralTermsSection from './section';
import H1Serif from "../../components/_common/text/heading/h1/serif";
import Grid from "../../components/_common/grid";
import BodyTextLarge from "../../components/_common/text/body-text/large";
import SmallText from '../../components/_common/text/small-text';

import './index.scss';

import { referralTermsData } from './data';

const ReferralTerms: React.FC = () => {
    return (
        <PageContent className="referral-terms-page" includeBottomPadding>
            <PageSection className="pt-7 sm:pt-9 lg:pt-16">
                <H1Serif className="mb-10 sm:mb-12 lg:mb-16 text-center sm:text-left">
                    Bestow Referral Program Terms &amp; Conditions
                </H1Serif>
                <Grid className="mb-10 sm:mb-14 lg:mb-16">
                    <BodyTextLarge className="col-span-full lg:col-start-2 lg:col-span-10 text-center sm:text-left">
                        {referralTermsData.topSection.body}
                    </BodyTextLarge>
                </Grid>
                {referralTermsData.sections.map((section, idx) => (
                    <ReferralTermsSection
                        sectionData={section}
                        sectionNumber={idx+1}
                    />
                ))}
                <Grid>
                    <SmallText className="col-span-full lg:col-start-2 lg:col-span-10">
                        {referralTermsData.legal}
                    </SmallText>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default ReferralTerms;
