import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { currentTemplateSelector } from "../redux/pages/selectors";

/**
 * Returns true if the current page is set to the Agents template in WP, or if the page is nested under the /agents/ path
 */
const useIsPersonalAgent = () => {
    const currentTemplate = useSelector(currentTemplateSelector);
    const location = useLocation();
    return currentTemplate?.match(/agents.php/) || location.pathname.match(/\/agents\/.+/i);
};

export default useIsPersonalAgent;

