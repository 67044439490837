import PropTypes from 'prop-types';
import cx from 'classnames';
import './index.scss';

const Link = ({children, href, className, newTab}) => {
    const classes = cx('brand-link', className);

    return (
        <a
            className={classes}
            href={href}
            target={newTab ? '_blank' : '_self'}
            rel={newTab ? 'noopener noreferrer' : ''}
        >
            {children}
        </a>
    )
};

Link.defaultProps = {
    className: ''
};

Link.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    newTab: PropTypes.bool
};

export default Link;
