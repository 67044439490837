import React from 'react';
import cx from 'classnames';

import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import BodyText from "../../../components/_common/text/body-text";
import H2Serif from "../../../components/_common/text/heading/h2/serif";

export type QuoteTextType = string;

export interface QuoteTextProps {
    quoteText?: QuoteTextType
    headerText?: string
    classes?: string
}

const AltQuoteText: React.FC<QuoteTextProps> = ({headerText, quoteText, classes = '' }) => {
    return (
        <>
            {quoteText &&
                <div className={classes}>
                    <PageSection className="mt-14 sm:mt-24 lg:mt-24">
                        {headerText &&
                            <Grid>
                                <H2Serif className="text-navy text-center col-span-full mb-6 sm:mb-16 sm:mx-8 lg:mx-0 lg:col-start-2 lg:col-span-10">
                                    {headerText}
                                </H2Serif>
                            </Grid>
                        }
                        <Grid>
                            <BodyText
                                data-cy="quote.hero.subtitle.mobile"
                                className="text-center col-span-full sm:col-start-2 lg:col-start-2 sm:col-span-6 lg:col-span-10"
                            >
                                {quoteText}
                            </BodyText>
                        </Grid>
                    </PageSection>
                </div>
            }
        </>
    );
};

export default AltQuoteText;
