import React from 'react';
import { Link } from 'react-router-dom';

import PageSection from '../../../components/_common/section/page';
import TextFieldLabel from '../../../components/_common/text/textFieldLabel';
import Grid from '../../../components/_common/grid';
import Quote, { QuoteProps } from '../../../components/quote/new';
import BodyTextLarge from '../../../components/_common/text/body-text/large';
import H1Serif from '../../../components/_common/text/heading/h1/serif';
import H2Serif from '../../../components/_common/text/heading/h2/serif';

import { LogoBlue, FiveMinuteSeal } from '../../../images/svgs';

export interface QuoteHeaderHero {
    logo_link?: {
        url: string;
    };
    title?: any;
    sub_title?: string;
    text?: string;
    logos?: string;
    logo_large?: boolean;
}

export interface QuoteHeaderProps extends QuoteProps {
    hero: QuoteHeaderHero;
    title: any;
    bannerText?: string;
}

const QuoteHeader: React.FC<QuoteHeaderProps> = ({
    hero,
    bannerText,
    title,
    isAgent = false,
    gerberEligible = false,
}) => {
    return (
        <>
            {bannerText && (
                <PageSection className="bg-navy py-4">
                    <TextFieldLabel className="text-center text-white -mx-2 sm:mx-0">
                        {bannerText}
                    </TextFieldLabel>
                </PageSection>
            )}
            <PageSection className="pt-7 sm:pt-12 lg:pt-16">
                <Grid>
                    <div
                        className="col-span-full sm:col-span-4 lg:col-span-6 mb-12 sm:mb-32 lg:mb-24"
                        data-cy="quote.hero.section"
                    >
                        <div
                            className="flex justify-center items-center mb-8 sm:mb-6 lg:mb-14"
                            data-cy="quote.logo.container"
                        >
                            {hero?.logos && (
                                <>
                                    <div className="pr-6">
                                        <img
                                            data-cy="hero.partner.logo"
                                            className={
                                                hero.logo_large
                                                    ? 'partner-logo-large'
                                                    : 'partner-logo'
                                            }
                                            src={hero?.logos}
                                            alt={
                                                hero?.text || 'Hero Logo Image'
                                            }
                                        />
                                    </div>
                                    <div className="border-solid border-0 border-r border-navy mr-6 h-8" />
                                </>
                            )}
                            <Link to="/">
                                <LogoBlue
                                    className="h-6 lg:h-7"
                                    data-cy="logo.bestow.blue"
                                />
                            </Link>
                        </div>
                        {title?.length < 30 ? (
                            <H1Serif
                                className="mx-5 sm:mx-3 lg:mx-6 text-center mb-6 text-navy"
                                data-cy="quote.hero.title"
                            >
                                {title}
                            </H1Serif>
                        ) : (
                            <H2Serif
                                className="mx-5 sm:mx-3 lg:mx-6 text-center mb-6 text-navy"
                                data-cy="quote.hero.title"
                            >
                                {title}
                            </H2Serif>
                        )}
                        <BodyTextLarge
                            className="text-center sm:mx-4"
                            data-cy="quote.hero.text"
                            dangerouslySetInnerHTML={{
                                __html:
                                    hero?.text ||
                                    'and be covered today, if approved.',
                            }}
                        />
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 lg:px-6 relative sm:-mb-80 quote-widget">
                        <Quote
                            className="w-full filter drop-shadow-quote"
                            isAgent={isAgent}
                            gerberEligible={gerberEligible}
                        />
                        <FiveMinuteSeal className="absolute left-2 -top-8 sm:hidden" />
                    </div>
                </Grid>
            </PageSection>
        </>
    );
};

export default QuoteHeader;
