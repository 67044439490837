import React from 'react';

import EyebrowSlim from "../../../components/_common/text/eyebrow/slim";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {SocialFBDark, SocialLIDark, SocialTwitterDark} from "../../../images/svgs";

interface Props {
    title: string,
    url: string,
    tags?: string[]
}

const BlogPostShare: React.FC<Props> = ({ title, url }) => {
    return (
        <div>
            <EyebrowSlim className="mb-4 text-center">
                SHARE
            </EyebrowSlim>
            <div className="flex">
                <FacebookShareButton url={url}>
                    <SocialFBDark className="mx-1 cursor-pointer" />
                </FacebookShareButton>
                <TwitterShareButton url={url} title={title} via={'bestow_inc'}>
                    <SocialTwitterDark className="mx-1 cursor-pointer" />
                </TwitterShareButton>
                <LinkedinShareButton url={url} title={title}>
                    <SocialLIDark className="mx-1 cursor-pointer" />
                </LinkedinShareButton>
            </div>
        </div>
    )
};

export default BlogPostShare;