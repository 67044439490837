import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useGetCurrentExperimentInfo } from './useGetCurrentExperimentInfo';

export const useVariationAnalytics = () => {
    const client = useLDClient();
    const currentExperimentInfo = useGetCurrentExperimentInfo();
    // Here, we loop through all possible experiments that have the key "inExperiment"
    // indicating that it is active and the user is in the experiment.
    const activeExperiments = useMemo(() => {
        if (client) {
            return currentExperimentInfo.filter(experiment =>
                Object.keys(
                    client.variationDetail(experiment.key).reason,
                ).includes('inExperiment'),
            );
        }
    }, []);

    useEffect(() => {
        if (client && activeExperiments && activeExperiments.length > 0) {
            activeExperiments.forEach(experiment => {
                const variationId = client.variationDetail(experiment.key)
                    .variationIndex;
                window.analytics.track('User Bucketed Into Experiment', {
                    campaignName: experiment.campaignName,
                    campaignId: experiment.campaignId,
                    variation: variationId === 0,
                    control: variationId !== 0,
                });
            });
        }
    }, []);
};
