import React from 'react';

import H4Sans from "../../../components/_common/text/heading/h4/sans";
import Grid from "../../../components/_common/grid";
import PageSection from "../../../components/_common/section/page";
import BodyTextLarge from '../../../components/_common/text/body-text/large';
import SecondaryButton from '../../../components/_common/button/secondary';

import PhoneDesktop from '../../../images/insurance-101/phone-desktop.jpg';
import PhoneTablet from '../../../images/insurance-101/phone-tablet.jpg';
import PhoneMobile from '../../../images/insurance-101/phone-mobile.jpg';

import './index.scss';

const ClickClickBoom: React.FC = () => (
    <PageSection className="click-click-boom mb-16 sm:my-24 lg:my-20">
        <Grid>
            <img src={PhoneMobile} alt="white phone" className="col-span-2 sm:hidden" />
            <img src={PhoneTablet} alt="white phone" className="col-span-4 hidden sm:block lg:hidden" />
            <img src={PhoneDesktop} alt="white phone" className="col-span-6 hidden lg:block" />
            <div className="col-span-2 sm:col-span-4 lg:col-span-6 pt-12 sm:pt-14 lg:pt-16 lg:pl-6 lg:pr-28">
                <div className="mb-6 flex flex-wrap justify-center sm:justify-start">
                    <H4Sans className="text-spruce-darkest mb-0">Click.&nbsp;</H4Sans>
                    <H4Sans className="text-gold-darkest mb-0">Click.</H4Sans>
                    <H4Sans className="text-navy mb-0">&nbsp;Boom.</H4Sans>
                </div>
                <div className="hidden sm:block">
                    <BodyTextLarge className="mb-8 text-center sm:text-left">
                        With Bestow, you can apply from your phone or computer. Get an
                        instant decision and coverage today, if approved.
                    </BodyTextLarge>
                    <div className="flex justify-center sm:justify-start">
                        <SecondaryButton to="/quote">
                            Get Started
                        </SecondaryButton>
                    </div>
                </div>
            </div>
            <div className="col-span-full sm:hidden">
                <BodyTextLarge className="mb-8 text-center sm:text-left">
                    With Bestow, you can apply from your phone or computer. Get an
                    instant decision and coverage today, if approved.
                </BodyTextLarge>
                <div className="flex justify-center sm:justify-start">
                    <SecondaryButton to="/quote">
                        Get Started
                    </SecondaryButton>
                </div>
            </div>
        </Grid>
    </PageSection>
);

export default ClickClickBoom;
