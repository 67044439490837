import React from 'react';
import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import H2Serif from "../../../components/_common/text/heading/h2/serif";

import ContactForm from './form';

import './index.scss';

const Contact: React.FC = () => (
    <PageSection id="contact" className="pb-0 pt-14 sm:pt-24 lg:pt-128">
        <Grid>
            <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-3 lg:col-span-8">
                <div className="flex flex-col sm:flex-row">
                    <H2Serif className="text-center mb-6 sm:mr-12 text-navy form-title">
                        Let's<br />Connect
                    </H2Serif>
                    <div className="flex-1">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </Grid>
    </PageSection>
);

export default Contact;
