import React, { useEffect } from 'react';
import cx from 'classnames';

import './index.scss';
import useShowTopNav from "../../../hooks/useShowTopNav";
import AutoSEO from "../seo";

export interface Props {
    className?: string,
    includeBottomPadding?: boolean,
    showTopNav?: boolean,
    disableAutoSEO?: boolean
}

/**
 * This component serves the sole purpose of providing a minimum-height to all page content, in order to minimize whitespace
 * below the footer, per David P. from marketing. We can enforce other standards for page content here as-needed
 */
const PageContent: React.FC<Props> = ({
    className = "",
    includeBottomPadding,
    showTopNav = true,
    disableAutoSEO = false,
    children }) => {

    useShowTopNav(showTopNav);

    const classes = cx("page-content", className, {
        "pb-24 lg:pb-32": includeBottomPadding
    });

    return (
        <div className={classes}>
            {!disableAutoSEO &&
                <AutoSEO />
            }
            {children}
        </div>
    )
};

export default PageContent;
