import React from 'react';

import RadioGroup from '../';
import RadioButtonWhite from "../../radioBtn/white";

import { Props } from '../';

/**
 * Radio group where the radio buttons have a white background when not selected
 */
const RadioGroupWhite: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({className="", ...otherProps}) => {
    return (
        <RadioGroup {...otherProps} RadioButtonType={RadioButtonWhite} />
    );
};

export default RadioGroupWhite;
