import React, {useState, useRef, useMemo} from 'react';
import {Formik, Form} from 'formik';
import {useDispatch} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import PrimaryButton from "../../../components/_common/button/primary";
import HelperText from "../../../components/_common/text/helperText";
import Spinner from "../../../components/_common/spinner/index";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import BodyText from "../../../components/_common/text/body-text";
import Recaptcha from "../../../components/_common/recaptcha";
import SecondaryButtonCharcoal from '../../../components/_common/button/secondary/charcoal';
import BasicQuestions, { basicQuestionsSchema } from './steps/BasicQuestions';
import AgentQuestions, { agentQuestionsSchema } from './steps/AgentQuestions';
import LineOfBusiness, { lineOfBusinessSchema } from './steps/LineOfBusiness';
import ReachAndPromotion, { reachAndPromotionSchema } from './steps/ReachAndPromotion';

import { postAffiliatesForm } from '../../../redux/affiliates/';

import './index.scss';

const AffiliatesForm: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    /**
     * Dynamic yup schema will be loaded according
     * to the currentStep
     */
    const validationSchema = [
        basicQuestionsSchema,
        agentQuestionsSchema,
        lineOfBusinessSchema,
        reachAndPromotionSchema
    ];

    const submitForm = () => {
        recaptchaRef.current?.execute();
    };

    const onNextStep = (values: any, actions: any) => {
        if (currentStep === 0 && values.isLifeInsuranceAgent === 'no') {
            // if isLifeInsuranceAgent === 'no', skip the AgentQuestions step
            setCurrentStep(2);
            actions.setSubmitting(false);
        } else if (currentStep !== 3) {
            setCurrentStep(prev => prev + 1);
            actions.setSubmitting(false);
        } else if (currentStep === 3) {
            submitForm();
        }
    };

    const currentFormStep = useMemo(() => {
        switch (currentStep) {
            case 0: return <BasicQuestions />;
            case 1: return <AgentQuestions />;
            case 2: return <LineOfBusiness />;
            case 3: return <ReachAndPromotion />;
            default:
                return null;
        }
    }, [currentStep]);

    const onPreviousStep = (values: any) => {
        // if isLifeInsuranceAgent === 'no', skip the AgentQuestions step
        if (currentStep === 2 && values.isLifeInsuranceAgent === 'no') {
            setCurrentStep(0);
        } else {
            setCurrentStep(prev => prev - 1);
        }
    };

    return !complete ? (
        <Formik
            initialValues={{
                name: '',
                email: '',
                websites: '',
                isLifeInsuranceAgent: '',
                numLicensedStates: '',
                isAffiliatedWithGroups: '',
                affiliatedGroups: '',
                businessTypes: ([] as string[]),
                businessTypeOther: '',
                monthlyReach: '',
                promotionPlan: '',
                promotionPlanOther: '',
                estimatedLeadsPerMonth: '',
            }}
            validationSchema={validationSchema[currentStep]}
            onSubmit={onNextStep}
        >
            {({ values }) => (
                <Form noValidate>
                    <div className="mb-12">
                        {currentFormStep}
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="flex justify-center">
                            {(currentStep !== 0 && !loading) &&
                                <SecondaryButtonCharcoal
                                    onClick={() => { onPreviousStep(values) }}
                                    className="mr-6"
                                    type="button"
                                >
                                    Back
                                </SecondaryButtonCharcoal>
                            }
                            {loading ?
                                <Spinner /> :
                                <PrimaryButton type="submit" data-testid="affiliates-submit-button">
                                    {currentStep === 3 ? 'Submit' : 'Next' }
                                </PrimaryButton>
                            }
                        </div>
                        {error &&
                            <HelperText className="text-error mt-2">
                                {error}
                            </HelperText>
                        }
                    </div>
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                        size="invisible"
                        onChange={() => {
                            setLoading(true);

                            dispatch(postAffiliatesForm(values,
                                () => {
                                    setLoading(false);
                                    setComplete(true);
                                },
                                (error: any) => {
                                    setLoading(false);
                                    setError(error.message);
                                }));
                        }}
                    />
                </Form>
            )}
        </Formik>
    ) :
    (
        <div className="text-center lg:mb-32" data-testid="affiliates-thank-you">
            <Subtitle2 className="mb-2">
                Thanks!
            </Subtitle2>
            <BodyText>
                Your answers have been submitted successfully.
            </BodyText>
        </div>
    )
};

export default AffiliatesForm;
