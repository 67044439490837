import React, {MouseEventHandler, useMemo, useState} from 'react';

import FAQItem from '../item';
import LinkButton from "../../../button/link";

import './index.scss';

const PAGE_SIZE = 8;

interface Props {
    faqs: {
        title: {
            rendered: string
        },
        content: {
            rendered: string
        }
    }[]
}

interface ColumnProps extends Props {
    showLoadMore?: boolean,
    onShowMore?: MouseEventHandler<HTMLButtonElement>
}

const FAQList: React.FC<Props> = ({ faqs }) => {
    const [numVisible, setNumVisible] = useState(PAGE_SIZE);
    const visibleFAQs = faqs.slice(0, numVisible);

    const col1 = useMemo(() => {
        return visibleFAQs.filter((faq, index) => index % 2 === 0);
    }, [visibleFAQs]);

    const col2 = useMemo(() => {
        return visibleFAQs.filter((faq, index) => index % 2 !== 0);
    }, [visibleFAQs]);

    return (
        <div className="flex flex-col sm:flex-row">
            <div className="flex-1 sm:mr-6">
                <FAQColumn faqs={col1} />
            </div>
            <div className="flex-1">
                <FAQColumn faqs={col2} showLoadMore={numVisible < faqs.length} onShowMore={() => setNumVisible(numVisible + PAGE_SIZE)} />
            </div>
        </div>
    )
};

export const SingleFAQList: React.FC<Props> = ({ faqs }) => {
    const [numVisible, setNumVisible] = useState(PAGE_SIZE);
    const visibleFAQs = faqs.slice(0, numVisible);

    return (
        <FAQColumn faqs={visibleFAQs} showLoadMore={numVisible < faqs.length} onShowMore={() => setNumVisible(numVisible + PAGE_SIZE)} />
    )
};

const FAQColumn: React.FC<ColumnProps> = ({ faqs, showLoadMore, onShowMore }) => {
    return (
        <>
            {faqs.map(({title, content}) => (
                <FAQItem key={`faq-${title.rendered}`} question={title.rendered} answer={content.rendered} />
            ))}
            {showLoadMore &&
                <div className="mt-8 sm:mt-4 lg:mt-6 flex justify-center sm:justify-end">
                    <LinkButton onClick={onShowMore}>
                        See more questions
                    </LinkButton>
                </div>
            }
        </>
    )
};

export default FAQList;
