import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GENDER_VALUE_FEMALE, GENDER_VALUE_MALE } from './constants';
import quoteFormSchema from './schema';
import { QuoteProps } from '../../..';
import useQueryParamData from '../../../hooks/useQueryParamsData';
import { getCurrentPartner } from '../../../utils/quoteUtils';
import Subtitle2 from '../../../../../_common/text/heading/subtitle2';
import QuotePlaceholder from '../../../placeholder';
import CustomFormikField from '../../../../../_common/formik/customField';
import InsideLabelSelect from '../../../../../_common/inputs/insideLabelSelect';
import DOBInput from '../../../../../_common/inputs/dob';
import InsideLabelInput from '../../../../../_common/inputs/insideLabel';
import { trackSmokerStatus, trackStateSelect } from '../../../api/analytics';
import Checkbox from '../../../../../_common/inputs/checkbox';
import PrimaryButton from '../../../../../_common/button/primary';
import multiStateZipcodes from '../../../data/multiStateZipcodes.json';
import usStates from '../../../data/usStates.json';
import { loadCtsQuote, loadNoHealthQuote } from '../nhQuoteLogic';
import {
    ctsQuoteLoadingSelectorNoHealthExperiment,
    ctsQuoteSelectorNoHealthExperiment,
    quoteFormSelectorNoHealthExperiment,
    quoteLoadingSelectorNoHealthExperiment,
} from '../nhQuoteLogic/selectors';
import { trackLaunchDarklyEventWithFlush } from '../../../../../../utilities/launchDarkly.track';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const NoHealthQuoteForm: React.FC<QuoteProps> = () => {
    const loading = useSelector(quoteLoadingSelectorNoHealthExperiment);
    const quoteForm = useSelector(quoteFormSelectorNoHealthExperiment);
    const dispatch = useDispatch();
    const queryParamData = useQueryParamData();
    const submitRef = useRef<HTMLButtonElement | null>(null);
    const ctsQuoteLoading = useSelector(
        ctsQuoteLoadingSelectorNoHealthExperiment,
    );
    const ctsQuote = useSelector(ctsQuoteSelectorNoHealthExperiment);
    const client = useLDClient();

    // Handles auto-submit (skipform) and CTS Quotes
    useEffect(() => {
        if (queryParamData.skipform && submitRef?.current) {
            submitRef.current.click();
        }
        if (queryParamData.qid && !ctsQuoteLoading && !ctsQuote) {
            dispatch(loadCtsQuote(queryParamData.qid));
        }
    }, [queryParamData, ctsQuoteLoading, ctsQuote, dispatch]);

    const getStateOptions = (zipCode: string) => {
        const multiStateZip = (multiStateZipcodes as any)[zipCode];
        if (multiStateZip) {
            return multiStateZip.map((state: string) => ({
                label: state,
                value: (usStates as any)[state],
            }));
        }
        return Object.entries(usStates).map(([key, value]) => ({
            label: key,
            value,
        }));
    };

    const showZipField = () => {
        // Show the zip field if there is no stateCode in querystring or if a multistate zipCode was explicitly passed
        return (
            !_isValidStateCode(queryParamData.stateCode) ||
            isMultiStateZipCode(queryParamData.zipCode)
        );
    };

    const showStateField = (zipCode: string) => {
        // Show the state field if the zip supplied is multistate or if a stateCode was supplied via query string
        return (
            isMultiStateZipCode(zipCode) ||
            _isValidStateCode(queryParamData.stateCode)
        );
    };

    return (
        <div data-testid="quote-form">
            <Subtitle2 className="color-blue mb-5 text-center">
                Start with a quote
            </Subtitle2>
            {ctsQuoteLoading ? (
                <QuotePlaceholder />
            ) : (
                <>
                    <Formik
                        initialValues={{
                            gender: '',
                            birthdate: '',
                            zipCode: '',
                            stateCode: '',
                            tobacco: false,
                            partner_referral: getCurrentPartner(),
                            ...queryParamData,
                            ...(ctsQuote || {}),
                            ...quoteForm,
                        }}
                        validationSchema={quoteFormSchema(queryParamData)}
                        onSubmit={(values, { setFieldError }) => {
                            dispatch(loadNoHealthQuote(values, setFieldError));
                            trackLaunchDarklyEventWithFlush(
                                client,
                                'requested_quote',
                            );
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form noValidate>
                                    <div className="mb-5">
                                        <CustomFormikField
                                            name="gender"
                                            className="w-full bg-white"
                                            label="Gender"
                                            data-testid="gender"
                                            CustomComponent={InsideLabelSelect}
                                            options={[
                                                {
                                                    label: 'Male',
                                                    value: GENDER_VALUE_MALE,
                                                },
                                                {
                                                    label: 'Female',
                                                    value: GENDER_VALUE_FEMALE,
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <CustomFormikField
                                            label="Birthdate"
                                            name="birthdate"
                                            className="w-full bg-white shadow-outside-label-input"
                                            type="date"
                                            data-testid="birthdate"
                                            onChange={value =>
                                                setFieldValue(
                                                    'birthdate',
                                                    value,
                                                )
                                            }
                                            CustomComponent={DOBInput}
                                        />
                                    </div>
                                    <div className="mb-5 flex flex-col lg:flex-row">
                                        {showZipField() && (
                                            <div className="flex-1">
                                                <CustomFormikField
                                                    name="zipCode"
                                                    className="w-full bg-white"
                                                    type="tel"
                                                    CustomComponent={
                                                        InsideLabelInput
                                                    }
                                                    label="Zip Code"
                                                    data-testid="zipCode"
                                                    autoComplete="off"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'zipCode',
                                                            e.target.value.replace(
                                                                /[^0-9]/g,
                                                                '',
                                                            ),
                                                        );
                                                        // TODO: Test this
                                                        setFieldValue(
                                                            'stateCode',
                                                            '',
                                                        );
                                                    }}
                                                    maxLength={5}
                                                />
                                            </div>
                                        )}
                                        {showStateField(values.zipCode) &&
                                            showZipField() && (
                                                // We need a divider here, but we don't want to use Classname switches because of pre-render mismatches
                                                <div className="mr-4 hidden lg:block" />
                                            )}
                                        {showStateField(values.zipCode) && (
                                            <div className="flex-1 mt-5 lg:mt-0">
                                                <CustomFormikField
                                                    name="stateCode"
                                                    className="w-full bg-white h-12"
                                                    label="State"
                                                    data-testid="stateCode"
                                                    CustomComponent={
                                                        InsideLabelSelect
                                                    }
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'stateCode',
                                                            e.target.value,
                                                        );
                                                        trackStateSelect(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    options={getStateOptions(
                                                        values.zipCode,
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-5">
                                        <CustomFormikField
                                            name="tobacco"
                                            className="bg-white"
                                            CustomComponent={Checkbox}
                                            label="I currently use nicotine products"
                                            data-testid="tobacco"
                                            onChange={e => {
                                                setFieldValue(
                                                    'tobacco',
                                                    e.target.checked,
                                                );
                                                trackSmokerStatus(
                                                    e.target.checked,
                                                );
                                            }}
                                            checked={values.tobacco}
                                        />
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            className="w-full"
                                            disabled={loading}
                                            ref={submitRef}
                                            type="submit"
                                            data-testid="quote-submit-btn"
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : 'Get a quote!'}
                                        </PrimaryButton>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </div>
    );
};

export const isMultiStateZipCode = (zip: string) =>
    Boolean((multiStateZipcodes as any)[zip]);

// Checks to see if the State code supplied exists in our lookup file
function _isValidStateCode(stateCode: string) {
    return Object.values(usStates).includes(stateCode);
}

export default NoHealthQuoteForm;
