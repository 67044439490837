const {isNil, merge, omitBy, each, cloneDeep} = require("lodash");

function getWordPressUrl() {
    return `${process.env.REACT_APP_WP_PROTOCOL}://${process.env.REACT_APP_WP_URL}`;
}

function mergeTemplateData(globalSettings, page, keys) {
    if (page) {
        const _globalSettings = cloneDeep(globalSettings);

        // Removes empty / null values
        const omitEmptyValues = value => {
            if (!isNil(value) && typeof value === "object") {
                const omittedValue = omitBy(value, omitEmptyValues);
                return Object.keys(omittedValue).length === 0;
            }
            return isNil(value) || value === "" ;
        };

        // Merge the Page settings on top of the Global settings after omitting empty values on the page data
        return keys.reduce((ret, key) => {
            // console.log(`page.acf[key] ${key}`, page.acf[key]);
            // console.log(`globalSettings[key] ${key}`, globalSettings[key]);
            ret[key] = merge(_globalSettings[key], omitBy(page.acf[key], omitEmptyValues));

            /**
             * loop through merged and check if the current value is an array and if page data has an array there that
             * is not empty, use that whole array instead of the default data
             */
            each(ret[key], (value, currentKey) => {
                if (page.acf[key] && page.acf[key][currentKey] && Array.isArray(page.acf[key][currentKey]) && page.acf[key][currentKey].length) {
                    ret[key][currentKey] = page.acf[key][currentKey]
                }
            });

            return ret;
        }, {});
    }
    return page;
}

/**
 * Replaces all occurrences of WP urls with
 * @param string    The string to replace all occurrences in
 * @param replaceWith   Optional string to replace WP Urls with. Defaults to the deployment url
 */
function replaceWordPressUrls(string, replaceWith = process.env.REACT_APP_DEPLOYMENT_URL) {
    const pattern = new RegExp(`${getWordPressUrl()}((?!/wp-content|")[^"]+)`, "gi");
    return string.replace(pattern, `${replaceWith}$1`);
}

module.exports = {
    getWordPressUrl,
    mergeTemplateData,
    replaceWordPressUrls
};