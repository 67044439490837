import React from 'react';

import Subtitle1 from '../../../components/_common/text/heading/subtitle1/index';
import BodyTextArray from "../../../components/_common/text/body-text/array";
import TermsSubSection from '../subsection';
import { SubsectionData } from "../subsection";
import CategoryNumber from "../../../components/_common/categoryNumber";
import Grid from "../../../components/_common/grid";

interface Props {
    sectionData: {
        id: string,
        title: string,
        body?: string[]
        subSections?: SubsectionData[]
    },
    sectionNumber: number,
}

const TermsSection: React.FC<Props> = ({sectionData, sectionNumber}) => (
    <div id={sectionData.id} className="mb-6 lg:mb-5">
        <Grid>
            <div className="col-span-full sm:col-span-1 lg:col-start-2 flex justify-center">
                <CategoryNumber>
                    {sectionNumber}
                </CategoryNumber>
            </div>
            <div className="col-span-full sm:col-span-7 lg:col-span-9">
                <Subtitle1 className="text-coral-darkest mb-4 sm:mt-1 text-center sm:text-left">
                    {sectionData.title}
                </Subtitle1>
                {sectionData.body &&
                    <BodyTextArray
                        data={sectionData.body}
                        classes={sectionData.subSections ? 'mb-4' : 'mb-6'}
                    />
                }

                {/* Render 1st level subsections */}
                {sectionData.subSections && sectionData.subSections.map((subSection, subSectionIdx) => (
                    <TermsSubSection
                        subsectionData={subSection}
                        idx={subSectionIdx}
                        sectionNumber={sectionNumber}
                        subsectionsCount={sectionData.subSections?.length ?? 0}
                    />
                ))}
            </div>
        </Grid>
    </div>
);

export default TermsSection;
