import React, { useState } from 'react';

import Grid from "../../../components/_common/grid";
import BodyTextBold from "../../../components/_common/text/body-text/bold";
import SmallText from "../../../components/_common/text/small-text/index";
import BodyText from "../../../components/_common/text/body-text";
import ExpandingArrow from "../../../components/_common/arrows/expanding";

import { CareersArrowRightMobile } from "../../../images/svgs";

interface Props {
    title: string,
    location: string,
    url: string
}

const JobListing: React.FC<Props> = ({ title, location,  url }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <a href={url} target="_blank" className="no-underline" rel="noreferrer">
            <Grid smCols={6} lgCols={8} className="cursor-pointer mb-5 sm:mb-6 lg:mb-3" onMouseOver={() => setExpanded(true)} onMouseOut={() => setExpanded(false)}>
                <div className="col-span-3 sm:hidden">
                    <BodyTextBold>
                        {title}
                    </BodyTextBold>
                    {/* TODO: MediumText component here */}
                    <SmallText className="sm:hidden">
                        {location}
                    </SmallText>
                </div>
                <div className="col-span-1 flex justify-end items-center sm:hidden">
                    <CareersArrowRightMobile />
                </div>
                <div className="hidden sm:block sm:col-span-4 lg:col-span-5">
                    <BodyText>
                        {title}
                    </BodyText>
                </div>
                <div className="hidden sm:flex sm:col-span-2 lg:col-span-3 items-center" >
                    <BodyText className="flex-1">
                        {location}
                    </BodyText>
                    <ExpandingArrow expanded={expanded} />
                </div>
            </Grid>
        </a>
    )
};

export default JobListing;