import React from 'react';

import Subtitle2 from '../../../components/_common/text/heading/subtitle2';
import BodyText from '../../../components/_common/text/body-text';

interface Props {
    icon: string
    title: string
    desc: string
}

const PlatformItem: React.FC<Props> = ({icon, title, desc}) => {
    return (
        <div className="col-span-full sm:col-span-4 flex flex-col items-center text-center mb-8 sm:mb-20 lg:mb-8">
            <div className="mb-4">
                <img src={icon} alt={title} className="h-14"/>
            </div>
            <Subtitle2 className="mb-4 text-navy">
                {title}
            </Subtitle2>
            <BodyText className="text-charcoal" dangerouslySetInnerHTML={{__html: desc}}/>
        </div>
    )
};

export default PlatformItem;
