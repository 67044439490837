import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import Recaptcha from '../../../../components/_common/recaptcha';
import PageContent from '../../../../components/_common/pageContent/navy';
import PageSection from '../../../../components/_common/section/page';
import H1 from '../../../../components/_common/text/heading/h1';
import useIsPersonalAgent from '../../../../hooks/useIsPersonalAgent';
import CustomFormikField from '../../../../components/_common/formik/customField';
import OutsideLabelInput from '../../../../components/_common/inputs/outsideLabel';
import Select from '../../../../components/_common/inputs/select';
import PrimaryButton from '../../../../components/_common/button/primary';
import useQueryParamData from '../../../../components/quote/new/hooks/useQueryParamsData';
import TimeStamp from './timestamp';

const CaliforniaResidentSelectValues = [
    { label: '', value: '' },
    { label: 'Yes', value: 'Yes' },
    {
        label: 'No (please do not proceed with this form)',
        value: 'No (please do not proceed with this form)',
    },
];
const BestowInteractValues = [
    { label: '', value: '' },
    { label: 'Insurance Customer', value: 'Insurance Customer' },
    {
        label: 'Job applicant',
        value: 'Job applicant',
    },
    {
        label: 'Both',
        value: 'Both',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];
const DataPreferencesValues = [
    {
        label: 'I want to know about the categories and sources of personal information Bestow has collected about me.',
        value: 'I want to know about the categories and sources of personal information Bestow has collected about me.',
    },
    {
        label: 'I want access to the personal information Bestow has collected about me.',
        value: 'I want access to the personal information Bestow has collected about me.',
    },
    {
        label: 'I want Bestow to delete the personal information they collected about me.',
        value: 'I want Bestow to delete the personal information they collected about me.',
    },
];

const CCPAForm: React.FC = () => {
    const isPersonalAgent = useIsPersonalAgent();
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const doHtmlFormAction = (values?: any) => {
        const form = document.getElementById('salesforce-ccpa-form');
        // recaptchaRef.current?.execute();
        // @ts-ignore
        form.submit();
    };
    const { formcomplete } = useQueryParamData();

    const [recaptchaComplete, setRecaptchaComplete] = useState(false);

    return (
        <PageContent showTopNav={!isPersonalAgent} includeBottomPadding>
            <PageSection className="px-0 sm:px-20 lg:px-28 pb-14 lg:pb-12 max-w-3xl m-0 m-auto pt-16">
                <H1 className="brand-h1 h1-serif font-serif font-bold mb-8 sm:mb-12 lg:mb-16 sm:text-left m-0 m-auto">
                    Submit a Privacy Rights Request
                </H1>
                {!formcomplete ? (
                    <Formik
                        onSubmit={() => {
                            doHtmlFormAction();
                        }}
                        validationSchema={object().shape({
                            '00N8c00000cAKka': string().required(), // California resident
                            'name': string().required('Name is required.'),
                            'email': string()
                                .email('Please enter a valid email')
                                .required('Email is required.'),
                            '00N8c00000c8leH': string().required(
                                'Please enter your date of birth',
                            ), // DOB
                            'phone': string().required(
                                'Please enter your phone number',
                            ), // phone
                            '00N8c00000cAKld': string().required(
                                'Please enter the last 4 of your SSN',
                            ), //SSN
                            '00N8c00000cAKml': string().required(
                                'Please select an option',
                            ),
                            '00N8c00000cAKoI': string().required(
                                'Please select an option',
                            ),
                        })}
                        initialValues={{
                            '00N8c00000cAKka': '',
                            'name': '',
                            'email': '',
                            '00N8c00000c8leH': '',
                            'phone': '',
                            '00N8c00000cAKld': '',
                            '00N8c00000cAKml': '',
                            '00N8c00000cAKoI': '',
                            'captcha_settings':
                                '{"keyname":"CaseRecaptcha","fallback":"true","orgId":"00D8c0000024rLq","ts":""}',
                        }}
                    >
                        {() => (
                            <Form
                                action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                                method="POST"
                                id="salesforce-ccpa-form"
                            >
                                <Field type="hidden" name="captcha_settings" />
                                <input
                                    type="hidden"
                                    name="orgid"
                                    value="00D8c0000024rLq"
                                />
                                <input
                                    type="hidden"
                                    name="retURL"
                                    value="https://www.bestow.com/ccpa-form/?formcomplete=true"
                                />
                                {/* <input type="hidden" name="debug" value={1} />
                                <input
                                    type="hidden"
                                    name="debugEmail"
                                    value="tj@bestow.com"
                                /> */}{' '}
                                <TimeStamp />
                                <div className="brand-body-text font-sans mb-4">
                                    The following form is for California
                                    residents only. If you are not a California
                                    resident and want to opt out of Bestow
                                    communications, you can adjust your
                                    preferences in your customer portal or
                                    contact us at 833-300-0603.
                                </div>
                                <br />
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={Select}
                                        name="00N8c00000cAKka"
                                        type="select"
                                        className="flex-1 w-full"
                                        label="California Resident?"
                                        placeholder="Please Select"
                                        required
                                        id="00N8c00000cAKka"
                                        options={CaliforniaResidentSelectValues}
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="name"
                                        className="flex-1"
                                        label="name"
                                        placeholder="Contact Name"
                                        data-testid="name"
                                        type="text"
                                        id="name"
                                        required
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="email"
                                        className="flex-1"
                                        label="Email"
                                        placeholder="Email"
                                        data-testid="email"
                                        type="email"
                                        id="email"
                                        required
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000c8leH"
                                        className="flex-1"
                                        label="Date of Birth"
                                        placeholder="Date of Birth"
                                        id="00N8c00000c8leH"
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="phone"
                                        className="flex-1"
                                        label="Phone"
                                        placeholder="Phone"
                                        required
                                        id="phone"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cAKld"
                                        className="flex-1"
                                        label="Last 4 SSN:"
                                        placeholder="Last 4 SSN:"
                                        type="text"
                                        required
                                        id="00N8c00000cAKld"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={Select}
                                        name="00N8c00000cAKml"
                                        type="select"
                                        className="flex-1"
                                        label="How have you interacted with Bestow?:"
                                        placeholder="Please Select"
                                        required
                                        id="00N8c00000cAKml"
                                        options={BestowInteractValues}
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={Select}
                                        name="00N8c00000cAKoI"
                                        type="select"
                                        className="flex-1 w-full"
                                        label="Data Preferences"
                                        placeholder="Please Select"
                                        required
                                        id="00N8c00000cAKoI"
                                        options={DataPreferencesValues}
                                    />
                                </div>
                                <br />
                                <input
                                    type="hidden"
                                    name="recordType"
                                    id="recordType"
                                    value="0128c000001alAf"
                                />
                                <input
                                    id="subject"
                                    name="subject"
                                    value="CCPA Form Submission"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cAKNv"
                                    name="00N8c00000cAKNv"
                                    value="CCPA"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cB0ug"
                                    name="00N8c00000cB0ug"
                                    value=" Customer Interaction"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cB0v5"
                                    name="00N8c00000cB0v5"
                                    value="Policy Admin"
                                    type="hidden"
                                />
                                <PrimaryButton
                                    type="submit"
                                    disabled={!recaptchaComplete}
                                >
                                    Submit
                                </PrimaryButton>
                                <div className="brand-body-text font-sans mt-4">
                                    {' '}
                                    We will need to confirm your identity by
                                    matching information you provide to us with
                                    the Personal Information we have about you
                                    to process your request to exercise your
                                    information, access, or deletion rights. We
                                    can’t process your request if you don’t
                                    provide us with sufficient detail to allow
                                    us to understand and respond to it. We will
                                    decline your request with respect to
                                    information that is subject to the
                                    Gramm-Leach-Bliley Act (GLBA) or other CCPA
                                    exemptions.
                                </div>{' '}
                                <br></br>
                                <Recaptcha
                                    ref={recaptchaRef}
                                    sitekey={
                                        process.env
                                            .REACT_APP_SALESFORCE_RECAPTCHA_KEY as string
                                    }
                                    onErrored={() => console.log('')}
                                    onChange={() => {
                                        setRecaptchaComplete(true);
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <h2>Your request has been submitted</h2>
                )}
            </PageSection>
        </PageContent>
    );
};

export default CCPAForm;
