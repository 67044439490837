import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './index.scss';

const LegalText = ({
    children,
    inline = false,
    className = '',
    ...otherProps
}) => {
    const classes = cx('brand-legal-text font-sans font-light', className);

    if (inline) return <span className={classes} {...otherProps}>{children}</span>;

    return <p className={classes} {...otherProps}>{children}</p>
};

LegalText.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    inline: PropTypes.bool
};

export default LegalText;
