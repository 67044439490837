import React, { useEffect } from 'react';

import PageContent from '../../../../components/_common/pageContent';
import PageSection from '../../../../components/_common/section/page';
import TrustModule from '../../../../components/_common/modules/trust';
import StepModule from '../../../../components/_common/modules/step';
import ReviewSliderModule from '../../../../components/_common/modules/reviewSlider';
import CTAModule from '../../../../components/_common/modules/cta';
import PrimaryButton from '../../../../components/_common/button/primary';
import AltQuoteText from '../../alt-quote-text';
import PrimaryLargeButton from '../../../../components/_common/button/primary/large';
import SEOQuoteHeroImg from './seo-quote-hero-img';
import QuoteHeader from '../../quote-header';
import Grid from '../../../../components/_common/grid';
import Subtitle1 from '../../../../components/_common/text/heading/subtitle1';
import BodyText from '../../../../components/_common/text/body-text';
import FAQList from '../../../../components/_common/modules/faqs/list';
import H2Serif from '../../../../components/_common/text/heading/h2/serif';
import QuoteProductFeatures from '../../product-features';
import SimpleStepModule from '../../../../components/_common/modules/step/simple';
import QuoteProtect from '../../protect';
import H2SerifNavy from '../../../../components/_common/text/heading/h2/serif/navy';
import CardRound from '../../../../components/_common/card/round';
import BodyTextBold from '../../../../components/_common/text/body-text/bold';
import MediumText from '../../../../components/_common/text/mediumText';
import { QuoteProps } from '../../../../components/quote/new';

import { REVIEWS } from '../../../reviews/data';
import FAQs from './faqs';
import LightningGear from '../../../../images/svgs/lightning-gear.svg';
import PeaceOfMindIcon from '../../../../images/icons/PeaceofMind.svg';
import InstantDecisionLightningIcon from '../../../../images/icons/InstantDecisionLightning.svg';
import ProtectFamilyIcon from '../../../../images/icons/ProtectFamily.svg';
import {
    InstantHandWhiteIcon,
    AffordableWhiteIcon,
    InstantClockWhiteIcon,
} from '../../../../images/icons/index';
import { trackSegmentPageView } from '../../../../utilities/segment.track';
import QuoteMobileOptimizationHeader from '../../../../components/quote/new/experimentalComponents/MOE-335-QuoteMobileOptimization/QuoteMobileOptimizationHeader';

interface Props extends QuoteProps {
    page?: {
        acf: {
            title?: string;
            subtitle?: string;
            hero_img_mobile?: string;
            hero_img_tablet?: string;
            hero_img_desktop?: string;
            benefits: {
                title?: string;
                body?: string;
                benefit_icon_1?: string;
                benefit_icon_2?: string;
                benefit_icon_3?: string;
                benefit_title_1?: string;
                benefit_title_2?: string;
                benefit_title_3?: string;
                benefit_body_1?: string;
                benefit_body_2?: string;
                benefit_body_3?: string;
                body_2?: string;
            };
        };
    };
}

const SEOQuote: React.FC<Props> = ({
    page,
    quoteMobileOptimizationExists = false,
    gerberEligible = false,
}) => {
    const pageData = page?.acf;
    const benefitsData = pageData?.benefits;

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        trackSegmentPageView();
    }, []);

    return (
        <PageContent className="partner-quote" showTopNav={false}>
            {quoteMobileOptimizationExists ? (
                <QuoteMobileOptimizationHeader
                    title="Affordable term life"
                    bannerText="NO MEDICAL EXAM / INSTANT DECISION"
                    quoteMobileOptimizationExists={
                        quoteMobileOptimizationExists
                    }
                />
            ) : (
                <QuoteHeader
                    hero={{
                        text:
                            (pageData?.subtitle || null) ??
                            'Get your fast, free term life insurance quote in just seconds with Bestow',
                    }}
                    title={
                        (pageData?.title || null) ??
                        'Free term life insurance quotes'
                    }
                    bannerText="NO MEDICAL EXAM / INSTANT DECISION"
                    gerberEligible={gerberEligible}
                />
            )}
            <SEOQuoteHeroImg
                mobileBackgroundImage={pageData?.hero_img_mobile ?? ''}
                tabletBackgroundImage={pageData?.hero_img_tablet ?? ''}
                desktopBackgroundImage={pageData?.hero_img_desktop ?? ''}
            />
            <TrustModule className="bg-off-white" />
            <AltQuoteText
                headerText={
                    (benefitsData?.title || null) ??
                    'Your term life insurance policy made easy'
                }
                quoteText={
                    (benefitsData?.body || null) ??
                    `Searching for the right life insurance company isn’t something
                    you do for yourself. Sure, you may get some peace of mind out of it
                    (and that’s worth a lot these days), but it’s really about helping to protect your loved ones.
                    And Bestow makes it fast, easy, and affordable to go from life insurance quote to coverage in a
                    matter of minutes (if approved).`
                }
            />
            <div className="-mb-16">
                <SimpleStepModule
                    step1={{
                        icon: benefitsData?.benefit_icon_1 ?? ProtectFamilyIcon,
                        title:
                            (benefitsData?.benefit_title_1 || null) ??
                            'Help protect your family',
                        text:
                            (benefitsData?.benefit_body_1 || null) ??
                            `The death benefit from a term life insurance policy could provide some
                            income replacement for your loved ones when they need it most.`,
                    }}
                    step2={{
                        icon: benefitsData?.benefit_icon_2 ?? PeaceOfMindIcon,
                        title:
                            (benefitsData?.benefit_title_2 || null) ??
                            'Peace of mind',
                        text:
                            (benefitsData?.benefit_body_2 || null) ??
                            `Knowing you’ve taken a step to help protect your family’s future finances
                            could give you some needed peace of mind.  `,
                    }}
                    step3={{
                        icon:
                            benefitsData?.benefit_icon_3 ??
                            InstantDecisionLightningIcon,
                        title:
                            (benefitsData?.benefit_title_3 || null) ??
                            'Get your quote instantly',
                        text:
                            (benefitsData?.benefit_body_3 || null) ??
                            `Start with a fast, free quote. If eligible, you could have coverage as soon
                            as today. That’s right. Today. `,
                    }}
                />
            </div>
            <QuoteProductFeatures />
            <PageSection className="mt-16 sm:mt-20">
                <Grid>
                    <BodyText className="text-center col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10">
                        {(benefitsData?.body_2 || null) ?? (
                            <>
                                Everyone’s needs are different. Exploring all
                                your life insurance options is about searching
                                for some financial protection that could help
                                meet you and your family’s specific needs and
                                goals. Maybe you’d like an amount of coverage
                                equal to 5 years of income replacement. Maybe a
                                20-year term period would ease your mind until
                                your kids leave the nest. It’s your life, and
                                your life insurance policy should fit into it,
                                not the other way around. Get started with a
                                free quote and see what coverage could be
                                available to you.
                            </>
                        )}
                    </BodyText>
                </Grid>
                <div className="flex justify-center mt-8">
                    <PrimaryButton onClick={scrollToTop}>
                        Get a free quote
                    </PrimaryButton>
                </div>
            </PageSection>
            <div className="mt-12 sm:mt-0 mb-8 sm:mb-16">
                <ReviewSliderModule reviews={REVIEWS} />
            </div>
            <div className="bg-navy pt-8 sm:pt-10">
                <StepModule
                    title="There is a better way to apply for term life insurance."
                    titleClasses="text-white text-center lg:col-start-3 lg:col-span-8"
                    stepTextClasses="text-white"
                    step1={{
                        Icon: InstantHandWhiteIcon,
                        title: 'Easy',
                        text: `The online application process takes minutes, with no
                            exam needed. Answer a few basic health questions to apply. `,
                    }}
                    step2={{
                        Icon: InstantClockWhiteIcon,
                        title: <span className="text-sage">Speedy</span>,
                        stepTitleClasses: 'text-white',
                        text: `Life insurance in minutes? Yup. Apply in as little as five
                            minutes and get an instant decision and coverage today, if approved. `,
                    }}
                    step3={{
                        Icon: AffordableWhiteIcon,
                        title: 'Thrifty',
                        text: `Bestow aims to make affordable life insurance options fast, easy, and
                            accessible. Rates from $10/mo. Coverage from $50,000 to $1.5M, if approved.`,
                    }}
                />
            </div>
            <PageSection className="bg-mint py-16 sm:py-24">
                <Grid>
                    <div className="mb-8 mx-4 sm:mx-8 sm:mb-0 col-span-2 col-start-2 sm:col-span-3 lg:col-start-2 flex justify-center">
                        <img
                            src={LightningGear}
                            className="w-full h-auto"
                            alt="Smart Tech"
                        />
                    </div>
                    <div className="col-span-full sm:col-span-5 lg:col-span-7 flex flex-col justify-center">
                        <Subtitle1 className="hidden sm:block">
                            Smart tech = speed and savings
                        </Subtitle1>
                        <Subtitle1 className="text-center mb-6 sm:hidden">
                            Smart tech = <br />
                            speed and savings
                        </Subtitle1>
                        <BodyText className="text-center sm:text-left">
                            We’re life insurance nerds 24/7. By day, we’re
                            passionate about our insurance products and
                            policyholders. By night we’re innovators and
                            tinkerers, developing the technology that helps
                            provide instant life insurance quotes, and hopefully
                            finds you the best policy and that just-right
                            coverage amount to help give you a little peace of
                            mind.
                        </BodyText>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="pt-16 pb-8 sm:py-24 lg:pb-28">
                <Grid>
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10 mb-14">
                        <H2SerifNavy className="mb-10 text-center">
                            Term life insurance
                            <br />
                            vs. permanent life insurance
                        </H2SerifNavy>
                        <BodyText className="text-center">
                            There are plenty of options when it comes to life
                            insurance products, but the two primary types of
                            life insurance are called term life insurance and
                            whole life insurance. Which one is right for you?
                            Well, like all financial decisions, it depends!
                        </BodyText>
                    </div>
                </Grid>
                <CardRound className="col-span-full overflow-hidden">
                    <Grid
                        cols={4}
                        smCols={8}
                        lgCols={10}
                        className="bg-navy py-2"
                    >
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 flex justify-center">
                            <BodyTextBold className="text-white">
                                Term life
                            </BodyTextBold>
                        </div>
                        <div className="col-span-2 sm:col-start-6 sm:col-span-3 lg:col-start-7 lg:col-span-4 flex justify-center">
                            <BodyTextBold className="text-white">
                                Permanent life
                            </BodyTextBold>
                        </div>
                    </Grid>
                    <Grid cols={4} smCols={8} lgCols={10} className="bg-mint">
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 p-4">
                            <MediumText className="font-light">
                                A term life policy provides a set amount of
                                coverage for a period of time that you select
                                when you buy it. That’s the “term.” It can range
                                from 5, 10, even 30 years.
                            </MediumText>
                        </div>
                        <div className="p-4 -mb-8 sm:mb-0 sm:p-4 row-start-1 sm:row-start-auto col-start-1 col-span-full sm:col-start-4 sm:col-span-2 lg:col-start-5 lg:col-span-2 flex justify-center">
                            <MediumText className="font-normal">
                                Coverage
                            </MediumText>
                        </div>
                        <div className="p-4 col-span-2 sm:col-span-3 lg:col-span-4">
                            <MediumText className="font-light">
                                Permanent coverage is just that. It lasts your
                                entire life until you pass away, which, spoiler
                                alert, happens to everyone.
                            </MediumText>
                        </div>
                    </Grid>
                    <Grid cols={4} smCols={8} lgCols={10}>
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 p-4">
                            <MediumText className="font-light">
                                No cash value.
                            </MediumText>
                        </div>
                        <div className="p-4 -mb-8 sm:mb-0 sm:p-4 row-start-1 sm:row-start-auto col-start-1 col-span-full sm:col-start-4 sm:col-span-2 lg:col-start-5 lg:col-span-2 flex justify-center">
                            <MediumText className="font-normal">
                                Features
                            </MediumText>
                        </div>
                        <div className="p-4 col-span-2 sm:col-span-3 lg:col-span-4">
                            <MediumText className="font-light">
                                Some permanent life insurance also has a cash
                                value element.
                            </MediumText>
                        </div>
                    </Grid>
                    <Grid
                        cols={4}
                        smCols={8}
                        lgCols={10}
                        className="bg-almond-light"
                    >
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 p-4">
                            <MediumText className="font-light">
                                Term life insurance typically costs less than
                                whole life as a general rule, partly because the
                                coverage lasts for a specific period of time.
                            </MediumText>
                        </div>
                        <div className="p-4 -mb-8 sm:mb-0 sm:p-4 row-start-1 sm:row-start-auto col-start-1 col-span-full sm:col-start-4 sm:col-span-2 lg:col-start-5 lg:col-span-2 flex justify-center">
                            <MediumText className="font-normal">
                                Cost
                            </MediumText>
                        </div>
                        <div className="p-4 col-span-2 sm:col-span-3 lg:col-span-4">
                            <MediumText className="font-light">
                                Permanent life insurance tends to cost much more
                                than term life as a general rule.
                            </MediumText>
                        </div>
                    </Grid>
                </CardRound>
            </PageSection>
            <QuoteProtect
                items={[
                    {
                        label: 'A home',
                        text:
                            "What's more important than a home? A policy that can help pay for all or a portion of your mortgage can ensure your family stays in the home, neighborhood, and schools you choose should you pass away.",
                        imageClass:
                            'bg-quote-protect-home sm:bg-quote-protect-home-sm lg:bg-quote-protect-home-lg',
                    },
                    {
                        label: 'An education',
                        text:
                            'A life insurance policy might help your family fund higher education, private school or other training to give your children or spouse a leg up on their future.',
                        imageClass: 'bg-quote-protect-education',
                    },
                    {
                        label: 'A comfortable life',
                        text:
                            'From phone plans to back-to-school shopping, there are a lot of expenses that can add up quickly. A payout from a life insurance policy could help your loved ones stay on top of those costs should you pass away.',
                        imageClass:
                            'bg-quote-protect-life sm:bg-quote-protect-life-sm',
                    },
                ]}
            />
            <PageSection className="py-16 sm:py-20 sm:py-24">
                <Grid>
                    <H2Serif className="text-navy text-center mb-16 col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-9">
                        You've got insurance questions.
                        <br />
                        We've got answers.
                    </H2Serif>
                </Grid>
                <FAQList faqs={FAQs} />
            </PageSection>
            <CTAModule
                className="bg-off-white"
                title={
                    <>
                        Coverage today, if approved.
                        <br />
                        Yep. It's that easy.
                    </>
                }
            >
                <div className="w-full px-8 flex justify-center">
                    <PrimaryButton
                        className="w-full sm:hidden"
                        onClick={scrollToTop}
                    >
                        Get a quote
                    </PrimaryButton>
                    <PrimaryLargeButton
                        className="hidden sm:block"
                        onClick={scrollToTop}
                    >
                        Get a quote
                    </PrimaryLargeButton>
                </div>
            </CTAModule>
        </PageContent>
    );
};

export default SEOQuote;
