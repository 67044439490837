import React from 'react';

import PageSection from "../../components/_common/section/page";
import PageContent from '../../components/_common/pageContent/navy';
import H1Serif from "../../components/_common/text/heading/h1/serif";
import ThirdPartyCodeSection from './section';

import './index.scss';

import { thirdPartyCodeData } from './data';

const ThirdPartyCode: React.FC = () => {
    return (
        <PageContent className="third-party-page" includeBottomPadding>
            <PageSection className="pt-7 sm:pt-9 lg:pt-16">
                <H1Serif className="mb-8 text-center sm:text-left sm:mb-12 lg:mb-16">Third Party Code</H1Serif>
                {/* Render the sections... */}
                {thirdPartyCodeData.sections.map((section, idx) => (
                    <ThirdPartyCodeSection
                        sectionData={section}
                        key={`section-${idx}`}
                    />
                ))}
            </PageSection>
        </PageContent>
    );
};

export default ThirdPartyCode;
