import React from 'react';

import PageSection from "../../../components/_common/section/page";
import SecondaryButton from "../../../components/_common/button/secondary";
import BodyText from "../../../components/_common/text/body-text";
import H2Serif from "../../../components/_common/text/heading/h2/serif";

import { ButtonArrowRight } from "../../../images/svgs";

const CareersJoin: React.FC = () => {
    return (
        <PageSection>
            <div className="flex flex-col sm:flex-row mb-28">
                <H2Serif className="text-center sm:hidden mb-10 text-navy">
                    Join us!
                </H2Serif>
                <div className="sm:flex-1 mb-12 sm:mb-0">
                    <div className="founders-img bg-careers-main-founders bg-center bg-cover lg:bg-careers-main-founders-lg -mx-6 sm:-ml-9 sm:mr-0 lg:-ml-6" />
                </div>
                <div className="sm:flex-1 flex flex-col items-center sm:justify-center ">
                    <div className="flex flex-col items-center sm:justify-center sm:-mr-9 lg:-mr-6">
                        <H2Serif className="hidden sm:block sm:mb-12 text-navy">
                            Join us!
                        </H2Serif>
                        <BodyText className="sm:px-12 lg:px-7 text-center mb-12">
                            Bestow was founded in 2016 by Melbourne O’Banion and Jonathan Abelmann to make life insurance
                            more accessible to more people. We’d love to have you on board.
                        </BodyText>
                        <SecondaryButton Icon={ButtonArrowRight} to="/openings">
                            See current openings
                        </SecondaryButton>
                    </div>

                </div>
            </div>
        </PageSection>
    )
};

export default CareersJoin;