import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useParams} from 'react-router-dom';
import cx from 'classnames';

import PageContent from "../../components/_common/pageContent";
import PageSection from "../../components/_common/section/page";
import Grid from "../../components/_common/grid";
import BlockQuote from "./blockQuote";
import BlogPostContent from "./content";
import Spinner from "../../components/_common/spinner/index";
import SEO from "../../components/seo/seo";
import BlogPostFooter from "./footer";
import BlogPostTitleCard from "./titleCard";
import {fetchPost} from "../../redux/posts";
import {fetchPost as fetchLearningCenterPost} from "../../redux/learningCenter";
import {postSelector} from "../../redux/posts/selectors";
import {lcPostSelector} from '../../redux/learningCenter/selectors';

import './index.scss';

interface Props {
    isLearningCenter?: boolean
}

const BlogPost: React.FC<Props> = ({ isLearningCenter = false }) => {
    // @ts-ignore
    const { postId } = useParams();
    const post = useSelector(isLearningCenter ? lcPostSelector(postId) : postSelector(postId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!post) {
            dispatch(isLearningCenter ? fetchLearningCenterPost(postId) : fetchPost(postId));
        }
    }, [dispatch, post, postId, isLearningCenter]);

    const featuredMedia = post?._embedded["wp:featuredmedia"];
    const featuredImage = featuredMedia ? featuredMedia[0]?.source_url : "";
    const showExcerpt = post?.excerpt && post.acf?.show_excerpt;

    return (
        <PageContent className="blog-post" disableAutoSEO>
            {!post ?
                <Spinner/> :
                <>
                    <SEO page={post} />
                    { featuredImage && <div className="post-hero-img bg-cover bg-center" style={{backgroundImage: `url(${featuredImage})`}} /> }
                    <PageSection>
                        <Grid>
                            <BlogPostTitleCard
                                featuredImage={featuredImage}
                                title={post.title.rendered}
                                author={post._embedded.author[0].name}
                                timeToRead={post.acf.time_to_read}
                                date={new Date(post.date)}
                                isLearningCenter={isLearningCenter} />
                            <div className={cx("font-sans col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10", {
                                "pt-8": !showExcerpt
                            })}>
                                <BlogPostContent htmlString={post?.content.rendered} excerpt={post?.excerpt?.rendered} showExcerpt={showExcerpt} />
                            </div>
                            <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10 mb-28 sm:mb-24 lg:mb-32">
                                <BlogPostFooter title={post?.title.rendered} isLearningCenter={isLearningCenter} />
                            </div>
                        </Grid>
                    </PageSection>
                </>
            }
        </PageContent>
    )

};

export default BlogPost;
