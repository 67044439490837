import React from 'react';
import {string, object, array} from 'yup';
import { useFormikContext } from 'formik';
import CustomFormikField from "../../../../components/_common/formik/customField";
import Checkbox from "../../../../components/_common/inputs/checkbox";
import BodyTextBold from '../../../../components/_common/text/body-text/bold';
import Subtitle1 from '../../../../components/_common/text/heading/subtitle1';
import HelperText from "../../../../components/_common/text/helperText";
import OpenInput from '../../../../components/_common/inputs/open';

const BUSINESS_TYPES = [
    "I am a financial advisor.",
    "I have a product or app where I would like to integrate Bestow.",
    "I run a blog.",
    "I own a website(s) that I drive paid traffic to (e.g. SEM, Facebook, etc.).",
    "I work for an affiliate network with third party partners.",
    "I run a call center.",
    "I have leads that I want to buy or sell."
];

export const lineOfBusinessSchema = object().shape({
    businessTypes: array().min(1, "Please select at least one option").required().nullable(),
    businessTypeOther: string(),
});

const LineOfBusiness: React.FC = () => {
    const { setFieldValue, values, touched, errors } = useFormikContext<any>();

    return (
        <div className="mb-8" data-testid="affiliates-line-of-business-questions">
            <Subtitle1 className="mb-3 text-center sm:text-left">
                Line of Business
            </Subtitle1>
            <BodyTextBold className="mb-6 text-center sm:text-left">
                How would you describe your business (check all that apply).*
            </BodyTextBold>
            {BUSINESS_TYPES.map((businessType, idx) => (
                <div className="mb-8" key={`businessType-${idx}`}>
                    <CustomFormikField
                        name="businessType"
                        checked={values.businessTypes.includes(businessType)}
                        data-testid={businessType}
                        onChange={(event: any) => {
                            let businessTypes = [...values.businessTypes];
                            if ((event.target as HTMLInputElement).checked) {
                                businessTypes.push(businessType);
                            } else {
                                businessTypes.splice(businessTypes.indexOf(businessType, 1));
                            }
                            setFieldValue('businessTypes', businessTypes, true);
                        }}
                        CustomComponent={Checkbox}
                        label={businessType} />
                </div>
            ))}
            <div className="flex items-center -mt-2">
                <div>
                    <Checkbox
                        checked={values.businessTypes.includes("other")}
                        onChange={event => {
                            let businessTypes = [...values.businessTypes];
                            if ((event.target as HTMLInputElement).checked) {
                                businessTypes.push("other");
                            } else {
                                businessTypes.splice(businessTypes.indexOf("other", 1));
                            }
                            setFieldValue('businessTypes', businessTypes, true);
                        }}
                    />
                </div>
                <div className="ml-4 mb-1 flex-1">
                    <CustomFormikField
                        className="w-full"
                        name="businessTypeOther"
                        disabled={!values.businessTypes.includes("other")}
                        CustomComponent={OpenInput}
                        placeholder="Other..." />
                </div>
            </div>
            {touched.businessTypes && errors.businessTypes &&
                <HelperText className="mt-3 text-error">
                    {errors.businessTypes}
                </HelperText>
            }
        </div>
    );
};

export default LineOfBusiness;
