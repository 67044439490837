import {FunctionComponent} from 'react';
import Slider from 'react-slick';
import {CustomerReview} from '../types';
import Stars from './stars';

import H3Serif from "../../../components/_common/text/heading/h3/serif";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import {isPreRendering} from "../../../utilities/reactSnap";

import leftHover from '../../../images/left_arrow_hover.glowup.svg';
import rightHover from '../../../images/right_arrow_hover.glowup.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

type Props = {
    reviews: Array<CustomerReview>;
}

type ButtonProps = {
    onClick?: () => void;
}

const ReviewSlider: FunctionComponent<Props> = ({reviews}: Props) => {
    var slickSettings = {
        dots: true,
        prevArrow: <PrevButton/>,
        nextArrow: <NextButton/>,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        autoplay: !isPreRendering(),
        autoplaySpeed: 8000,
        speed: 1000,
    };

    function PrevButton({onClick}: ButtonProps) {
        return (
            <div className="slick-prev" onClick={onClick}/>
        );
    }

    function NextButton({onClick}: ButtonProps) {
        return (
            <div className="slick-next" onClick={onClick}/>
        );
    }

    return (
        <div className="customer-reviews-slider">
            <Slider {...slickSettings}>
                {reviews.map((item, i) => (
                    <div key={i}>
                        <div className="my-6 flex justify-center">
                            <Stars/>
                        </div>
                        <div className="flex justify-center text-center">
                            <H3Serif className="text-white mb-6 mx-2">
                                {item.review_content}
                            </H3Serif>
                        </div>
                        <Subtitle2 className="text-rose font-bold text-center mb-10">
                            {item.review_author}
                        </Subtitle2>
                    </div>
                ))}
            </Slider>
            {/* Do this so these images will load prior to the user hovering over the left/right controls */}
            <div className="hidden">
                <img src={leftHover} alt="left hover"/>
                <img src={rightHover} alt="right hover"/>
            </div>
        </div>
    );
};
export default ReviewSlider;
