import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import jsonp from 'jsonp';
import queryString from 'querystring';
import PageSection from '../../../components/_common/section/page';
import Grid from '../../../components/_common/grid';
import H2Serif from '../../../components/_common/text/heading/h2/serif';
import BodyText from '../../../components/_common/text/body-text';
import CustomFormikField from '../../../components/_common/formik/customField';
import OutsideLabelInput from '../../../components/_common/inputs/outsideLabel';
import SecondaryButtonCharcoal from '../../../components/_common/button/secondary/charcoal';
import H4Sans from '../../../components/_common/text/heading/h4/sans';

const SignUp = () => {
    const doHtmlFormAction = (values?: any) => {
        const url = `https://bestow.us19.list-manage.com/subscribe/post-json?u=a4044464bc7e0c1078a157df2&id=c8409d63ef&${queryString.stringify(
            values,
        )}`;

        // @ts-ignore
        jsonp(url, { param: 'c' }, (err: any, data: any) => {
            console.log('error', err);
            console.log('data', data);
            if (data.result === 'success') {
                setHasSubmittedForm(true);
            }
        });
    };

    const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
    const signupRef = useRef<HTMLDivElement>(null);
    const { hash } = useLocation();

    useEffect(() => {
        if (hash?.toLowerCase().includes('newsletter')) {
            signupRef.current?.scrollIntoView({
                block: 'start',
            });
        }
    }, [hash, signupRef]);
    return (
        <div className="pt-20 lg:pt-24" ref={signupRef}>
            <PageSection className="pb-20 lg:flex flex-col sm:block items-center text-center">
                <Grid smCols={12}>
                    <div className="col-span-full sm:col-span-6">
                        <H2Serif className="mb-6 text-navy">
                            Sign up <br /> for our <br /> Enterprise <br />{' '}
                            newsletter
                        </H2Serif>
                        <BodyText className="text-charcoal-60 text-xl">
                            Stay informed about our latest
                            <br />
                            innovations and platform
                            <br />
                            solutions.
                        </BodyText>
                    </div>
                    <div
                        id="mc_embed_signup"
                        className="col-span-full sm:col-span-6"
                    >
                        {hasSubmittedForm ? (
                            <H4Sans>Thank you for subscribing!</H4Sans>
                        ) : (
                            <Formik
                                onSubmit={values => {
                                    doHtmlFormAction(values);
                                }}
                                validationSchema={object().shape({
                                    FNAME: string().required(
                                        'Name is required.',
                                    ),
                                    LNAME: string().required(
                                        'Name is required.',
                                    ),
                                    EMAIL: string()
                                        .email('Please enter a valid email')
                                        .required('Email is required.'),
                                    CARR_COMP: string().required(
                                        'Company is required.',
                                    ),
                                    CARR_TITLE:
                                        string().required('Title is required.'),
                                })}
                                initialValues={{
                                    FNAME: '',
                                    LNAME: '',
                                    EMAIL: '',
                                    CARR_COMP: '',
                                    CARR_TITLE: '',
                                }}
                            >
                                {() => (
                                    <Form
                                        action="https://bestow.us19.list-manage.com/subscribe/post?u=a4044464bc7e0c1078a157df2&amp;id=c8409d63ef"
                                        method="POST"
                                        id="mc-embedded-subscribe-form"
                                        name="mc-embedded-subscribe-form"
                                        target="_blank"
                                    >
                                        <div className="mb-8 text-left">
                                            <CustomFormikField
                                                CustomComponent={
                                                    OutsideLabelInput
                                                }
                                                name="FNAME"
                                                className="flex-1"
                                                label="First Name"
                                                placeholder="First Name"
                                                type="text"
                                                id="mce-FNAME"
                                                required
                                            />
                                        </div>
                                        <div className="mb-8 text-left">
                                            <CustomFormikField
                                                CustomComponent={
                                                    OutsideLabelInput
                                                }
                                                name="LNAME"
                                                className="flex-1"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                id="mce-LNAME"
                                                type="text"
                                                required
                                            />
                                        </div>
                                        <div className="mb-8 text-left">
                                            <CustomFormikField
                                                CustomComponent={
                                                    OutsideLabelInput
                                                }
                                                name="EMAIL"
                                                className="flex-1"
                                                label="Email Address"
                                                placeholder="Email"
                                                type="email"
                                                id="mce-EMAIL"
                                                required
                                            />
                                        </div>
                                        <div className="mb-8 text-left">
                                            <CustomFormikField
                                                CustomComponent={
                                                    OutsideLabelInput
                                                }
                                                name="CARR_COMP"
                                                className="flex-1"
                                                label="Company"
                                                placeholder="Company"
                                                id="mce-CARR_COMP"
                                                type="text"
                                                required
                                            />
                                        </div>
                                        <div className="mb-8 text-left">
                                            <CustomFormikField
                                                CustomComponent={
                                                    OutsideLabelInput
                                                }
                                                name="CARR_TITLE"
                                                className="flex-1"
                                                label="Title"
                                                placeholder="Title"
                                                id="mce-CARR_TITLE"
                                                type="text"
                                                required
                                            />
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '-5000px',
                                            }}
                                            aria-hidden="true"
                                        >
                                            <input
                                                type="text"
                                                name="b_a4044464bc7e0c1078a157df2_c8409d63ef"
                                                tabIndex={-1}
                                                // value=""
                                            />
                                        </div>
                                        <div
                                            id="mce-responses"
                                            className="clear"
                                        >
                                            <div
                                                className="response"
                                                id="mce-error-response"
                                                style={{ display: 'none' }}
                                            ></div>
                                            <div
                                                className="response"
                                                id="mce-success-response"
                                                style={{ display: 'none' }}
                                            ></div>
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: '-5000px',
                                            }}
                                            aria-hidden="true"
                                        >
                                            <input
                                                type="text"
                                                name="b_a4044464bc7e0c1078a157df2_c8409d63ef"
                                                tabIndex={-1}
                                            />
                                        </div>
                                        <div className="flex justif-center">
                                            <SecondaryButtonCharcoal
                                                className="bg-transparent"
                                                type="submit"
                                            >
                                                Submit
                                            </SecondaryButtonCharcoal>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </div>
                </Grid>
            </PageSection>
        </div>
    );
};

export default SignUp;
