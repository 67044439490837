import React from 'react';

import PageContent from "../../components/_common/pageContent";
import PageSection from "../../components/_common/section/page";
import Grid from "../../components/_common/grid";
import Eyebrow from "../../components/_common/text/eyebrow";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import BodyTextLarge from '../../components/_common/text/body-text/large';
import LinkButton from '../../components/_common/button/link';
import ApiIntegration from './api-integration';
import ConsiderBestow from './consider-bestow';
import LogoSlider from './logo-slider';
import Testimonial from './testimonial';
import Contact from './contact';

import './index.scss';

const ApiPartners: React.FC = () => {
    return (
        <PageContent includeBottomPadding className="api-partners">
            <PageSection className="pt-7 lg:pt-11 mb-8 sm:mb-24">
                <Grid>
                    <div className="col-span-full sm:col-span-6 lg:col-span-11 mb-4">
                        <Eyebrow className="mb-2 text-center sm:text-left">
                            SEAMLESS INTEGRATION
                        </Eyebrow>
                        <H1Serif className="text-center sm:text-left mb-0">
                            Add life insurance to your site or app, in one day.
                        </H1Serif>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="mb-16 sm:mb-24">
                <Grid>
                    <div className="col-span-full sm:col-span-6 sm:col-start-2 lg:col-span-10 lg:col-start-2">
                        <BodyTextLarge className="text-center mb-6">
                            With Bestow's platform, provide more value to customers, increase retention, and grow revenue.
                            It’s practically a no-brainer.
                        </BodyTextLarge>
                        <div className="flex justify-center">
                            <LinkButton scrollTo="contact">
                                Connect with us
                            </LinkButton>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <ConsiderBestow />
            <PageSection className="bg-off-white py-16 sm:py-24">
                <Grid>
                    <div className="col-span-full lg:col-start-3 lg:col-span-8">
                        <BodyTextLarge className="text-center">
                            Choose the pricing model that meets your company’s needs - share of recurring policy premiums,
                            heaped commissions, or price per-application.
                        </BodyTextLarge>
                    </div>
                </Grid>
            </PageSection>
            <ApiIntegration />
            <LogoSlider />
            <Testimonial />
            <Contact />
        </PageContent>
    )
};

export default ApiPartners;
