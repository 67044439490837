import React, { useEffect } from 'react';

import PageContent from '../../../../components/_common/pageContent';
import PageSection from '../../../../components/_common/section/page';
import TrustModule from '../../../../components/_common/modules/trust';
import StepModule from '../../../../components/_common/modules/step';
import ReviewSliderModule from '../../../../components/_common/modules/reviewSlider';
import CTAModule from '../../../../components/_common/modules/cta';
import PrimaryButton from '../../../../components/_common/button/primary';
import AltQuoteText from '../../alt-quote-text';
import PrimaryLargeButton from '../../../../components/_common/button/primary/large';
import QuoteHeroImg from '../../heroImg/';
import QuoteHeader from '../../quote-header';
import Grid from '../../../../components/_common/grid';
import Subtitle1 from '../../../../components/_common/text/heading/subtitle1';
import BodyText from '../../../../components/_common/text/body-text';
import FAQList from '../../../../components/_common/modules/faqs/list';
import H2Serif from '../../../../components/_common/text/heading/h2/serif';
import QuoteProductFeatures from '../../product-features';
import SimpleStepModule from '../../../../components/_common/modules/step/simple';
import QuoteProtect from '../../protect';
import H2SerifNavy from '../../../../components/_common/text/heading/h2/serif/navy';
import CardRound from '../../../../components/_common/card/round';
import BodyTextBold from '../../../../components/_common/text/body-text/bold';
import MediumText from '../../../../components/_common/text/mediumText';
import { REVIEWS } from './reviews';
import FAQs from './faqs';
import LightningGear from '../../../../images/svgs/lightning-gear.svg';
import PeaceOfMindIcon from '../../../../images/icons/PeaceOfMind2.svg';
import InstantDecisionLightningIcon from '../../../../images/icons/InstantDecisionLightning.svg';
import ProtectFamilyIcon from '../../../../images/icons/ProtectFamily.svg';
import {
    InstantHandWhiteIcon,
    AffordableWhiteIcon,
    InstantClockWhiteIcon,
} from '../../../../images/icons/index';
import { trackSegmentPageView } from '../../../../utilities/segment.track';
import QuoteMobileOptimizationHeader from '../../../../components/quote/new/experimentalComponents/MOE-335-QuoteMobileOptimization/QuoteMobileOptimizationHeader';
import { QuoteProps } from '../../../../components/quote/new';

const ExperianQuote: React.FC<QuoteProps> = ({
    quoteMobileOptimizationExists = false,
}) => {
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
    useEffect(() => {
        trackSegmentPageView();
    }, []);

    return (
        <PageContent className="partner-quote" showTopNav={false}>
            {quoteMobileOptimizationExists ? (
                <QuoteMobileOptimizationHeader
                    title="Perfect for busy parents"
                    bannerText="NO MEDICAL EXAM / INSTANT DECISION"
                    quoteMobileOptimizationExists={
                        quoteMobileOptimizationExists
                    }
                />
            ) : (
                <QuoteHeader
                    hero={{
                        text:
                            'Anyone with about five free minutes can apply for term life insurance.',
                    }}
                    title="Perfect for busy parents"
                    bannerText="NO MEDICAL EXAM / INSTANT DECISION"
                />
            )}
            <QuoteHeroImg />
            <TrustModule className="bg-off-white" />
            <AltQuoteText
                headerText="Just in time for parents with no time"
                quoteText="Finally, a ridiculously fast, modern way to apply for some much-needed financial protection
                    for your family. It takes just minutes to apply, and you could have coverage today upon approval."
            />
            <div className="-mb-16">
                <SimpleStepModule
                    step1={{
                        icon: ProtectFamilyIcon,
                        title: 'Help Protect Your Family',
                        text: `Life insurance can help provide income replacement for your loved ones when they need it most.
`,
                    }}
                    step2={{
                        icon: PeaceOfMindIcon,
                        title: 'Peace of Mind',
                        text: `Knowing you’ve taken a step to help protect your family’s future finances could give 
                            you a valuable sense of security.`,
                    }}
                    step3={{
                        icon: InstantDecisionLightningIcon,
                        title: 'Get Your Instant Quote',
                        text: `Start with a fast, free quote (above). If eligible, just sign and pay and you'll have 
                            coverage today.`,
                    }}
                />
            </div>
            <QuoteProductFeatures />
            <div className="mt-12 sm:mt-0 mb-8 sm:mb-16">
                <ReviewSliderModule reviews={REVIEWS} />
            </div>
            <div className="bg-navy pt-8 sm:pt-10">
                <StepModule
                    title="The Bestow Difference"
                    titleClasses="text-white text-center lg:col-start-3 lg:col-span-8"
                    stepTextClasses="text-white"
                    step1={{
                        Icon: InstantHandWhiteIcon,
                        title: 'Easy',
                        text: `Apply online in minutes with no medical exam. A few health and medical questions help 
                            determine eligibility.`,
                    }}
                    step2={{
                        Icon: InstantClockWhiteIcon,
                        title: <span className="text-sage">Fast</span>,
                        stepTitleClasses: 'text-white',
                        text: `Everyone gets an instant decision. If approved, pay and start coverage today.`,
                    }}
                    step3={{
                        Icon: AffordableWhiteIcon,
                        title: 'Affordable',
                        text: `Healthy applicants could pay as little as $10/mo. Coverage is available from $50k up 
                            to $1.5MM.`,
                    }}
                />
            </div>
            <PageSection className="pt-16 pb-8 sm:py-24 lg:pb-28">
                <Grid>
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10 mb-14">
                        <H2SerifNavy className="mb-10 text-center">
                            Term life insurance
                            <br />
                            vs. permanent life insurance
                        </H2SerifNavy>
                        <BodyText className="text-center">
                            There are plenty of options when it comes to life
                            insurance products, but the two primary types of
                            life insurance are called term life insurance and
                            whole life insurance. Which one is right for you?
                            Well, like all financial decisions, it depends!
                        </BodyText>
                    </div>
                </Grid>
                <CardRound className="col-span-full overflow-hidden">
                    <Grid
                        cols={4}
                        smCols={8}
                        lgCols={10}
                        className="bg-navy py-2"
                    >
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 flex justify-center">
                            <BodyTextBold className="text-white">
                                Term life
                            </BodyTextBold>
                        </div>
                        <div className="col-span-2 sm:col-start-6 sm:col-span-3 lg:col-start-7 lg:col-span-4 flex justify-center">
                            <BodyTextBold className="text-white">
                                Permanent life
                            </BodyTextBold>
                        </div>
                    </Grid>
                    <Grid cols={4} smCols={8} lgCols={10} className="bg-mint">
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 p-4">
                            <MediumText className="font-light">
                                A term life policy provides a set amount of
                                coverage for a period of time that you select
                                when you buy it. That’s the “term.” It can range
                                from 5, 10, even 30 years.
                            </MediumText>
                        </div>
                        <div className="p-4 -mb-8 sm:mb-0 sm:p-4 row-start-1 sm:row-start-auto col-start-1 col-span-full sm:col-start-4 sm:col-span-2 lg:col-start-5 lg:col-span-2 flex justify-center">
                            <MediumText className="font-normal">
                                Coverage
                            </MediumText>
                        </div>
                        <div className="p-4 col-span-2 sm:col-span-3 lg:col-span-4">
                            <MediumText className="font-light">
                                Permanent coverage is just that. It lasts your
                                entire life until you pass away, which, spoiler
                                alert, happens to everyone.
                            </MediumText>
                        </div>
                    </Grid>
                    <Grid cols={4} smCols={8} lgCols={10}>
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 p-4">
                            <MediumText className="font-light">
                                No cash value.
                            </MediumText>
                        </div>
                        <div className="p-4 -mb-8 sm:mb-0 sm:p-4 row-start-1 sm:row-start-auto col-start-1 col-span-full sm:col-start-4 sm:col-span-2 lg:col-start-5 lg:col-span-2 flex justify-center">
                            <MediumText className="font-normal">
                                Features
                            </MediumText>
                        </div>
                        <div className="p-4 col-span-2 sm:col-span-3 lg:col-span-4">
                            <MediumText className="font-light">
                                Some permanent life insurance also has a cash
                                value element.
                            </MediumText>
                        </div>
                    </Grid>
                    <Grid
                        cols={4}
                        smCols={8}
                        lgCols={10}
                        className="bg-almond-light"
                    >
                        <div className="col-span-2 sm:col-span-3 lg:col-span-4 p-4">
                            <MediumText className="font-light">
                                Term life insurance typically costs less than
                                whole life as a general rule, partly because the
                                coverage lasts for a specific period of time.
                            </MediumText>
                        </div>
                        <div className="p-4 -mb-8 sm:mb-0 sm:p-4 row-start-1 sm:row-start-auto col-start-1 col-span-full sm:col-start-4 sm:col-span-2 lg:col-start-5 lg:col-span-2 flex justify-center">
                            <MediumText className="font-normal">
                                Cost
                            </MediumText>
                        </div>
                        <div className="p-4 col-span-2 sm:col-span-3 lg:col-span-4">
                            <MediumText className="font-light">
                                Permanent life insurance tends to cost much more
                                than term life as a general rule.
                            </MediumText>
                        </div>
                    </Grid>
                </CardRound>
            </PageSection>
            <QuoteProtect
                items={[
                    {
                        label: 'A home',
                        text:
                            "What's more important than home? A policy that can help pay for all or a portion of your mortgage can ensure your family stays in the home, neighborhood, and schools you choose should you pass away.",
                        imageClass:
                            'bg-quote-protect-home sm:bg-quote-protect-home-sm lg:bg-quote-protect-home-lg',
                    },
                    {
                        label: 'An education',
                        text:
                            'A life insurance policy might help your family fund higher education, private school or other training to give your children or spouse a leg up on their future.',
                        imageClass: 'bg-quote-protect-education',
                    },
                    {
                        label: 'A comfortable life',
                        text:
                            'From phone plans to back-to-school shopping, there are a lot of expenses that can add up quickly. A payout from a life insurance policy could help your loved ones stay on top of those costs should you pass away.',
                        imageClass:
                            'bg-quote-protect-life sm:bg-quote-protect-life-sm',
                    },
                ]}
            />
            <PageSection className="py-16 sm:py-20 sm:py-24">
                <Grid>
                    <H2Serif className="text-navy text-center mb-16 col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-9">
                        You've got insurance questions.
                        <br />
                        We've got answers.
                    </H2Serif>
                </Grid>
                <FAQList faqs={FAQs} />
            </PageSection>
            <CTAModule
                className="bg-off-white"
                title={
                    <>
                        Coverage today, if approved.
                        <br />
                        Yep. It's that easy.
                    </>
                }
            >
                <div className="w-full px-8 flex justify-center">
                    <PrimaryButton
                        className="w-full sm:hidden"
                        onClick={scrollToTop}
                    >
                        Get a quote
                    </PrimaryButton>
                    <PrimaryLargeButton
                        className="hidden sm:block"
                        onClick={scrollToTop}
                    >
                        Get a quote
                    </PrimaryLargeButton>
                </div>
            </CTAModule>
        </PageContent>
    );
};

export default ExperianQuote;
