import React from 'react';
import cx from 'classnames';

import H1Serif from '../../../components/_common/text/heading/h1/serif';
import Subtitle1 from '../../../components/_common/text/heading/subtitle1/index';
import Subtitle2 from '../../../components/_common/text/heading/subtitle2';
import BodyText from '../../../components/_common/text/body-text';
import LinkButton from '../../../components/_common/button/link';
import Grid from "../../../components/_common/grid";
import useCategoryPostsFilter from "../../../hooks/useCategoryPostsFilter";

import './index.scss';

interface Props {
    pageTitle: string,
    isLearningCenter: boolean
}

const BlogLandingHero: React.FC<Props> = ({ pageTitle, isLearningCenter = false }) => {
    const featuredPosts = useCategoryPostsFilter("featured", isLearningCenter);
    const featured = featuredPosts[0];
    const title = featured?.title.rendered;
    const excerpt = featured?.excerpt.rendered;
    const imageUrl = featured?._embedded[`wp:featuredmedia`][0].media_details.sizes.large?.source_url;
    const mobileImageUrl = featured?._embedded[`wp:featuredmedia`][0].media_details.sizes['large']?.source_url;
    const postUrl = `/${isLearningCenter ? "learningcenter" : "blog"}/${featured?.slug}`;

    return (
        <div className="blog-landing-hero lg:mt-16">
            <H1Serif className="sm:hidden text-center mt-3 mb-4 sm:mb-0">{pageTitle}</H1Serif>
            <Grid>
                <div className={cx("row-start-2 col-span-full sm:row-start-1 sm:col-start-1 lg:col-start-1 z-10 px-4 sm:px-0 -mt-16 sm:mt-0", {
                    "sm:col-span-3 lg:col-span-4": !isLearningCenter,
                    "sm:col-span-4 lg:col-span-5": isLearningCenter
                })}>
                    <H1Serif className="hidden sm:block mb-8 sm:mt-9 lg:mt-0">{pageTitle}</H1Serif>
                    <div className={cx("post-card-container", {
                        "sm:-mr-8 lg:mr-0": !isLearningCenter
                    })}>
                        <div className="post-card w-full bg-off-white rounded-lg z-10">
                            <div className="p-6">
                                <Subtitle1 className="hidden lg:block mb-4">
                                    {title}
                                </Subtitle1>
                                <Subtitle2 className="text-center sm:text-left lg:hidden mb-4">
                                    {title}
                                </Subtitle2>
                                <BodyText className="mb-4" dangerouslySetInnerHTML={{__html: excerpt}} />
                                <div className="flex justify-center sm:justify-start">
                                    <LinkButton to={postUrl}>
                                        Read More
                                    </LinkButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("col-span-full row-start-1 sm:col-end-9 lg:col-end-13 img-container flex lg:ml-0 sm:-mr-9 lg:-mr-6", {
                    "sm:col-start-3 sm:ml-4 lg:col-start-4": !isLearningCenter,
                    "sm:col-start-4 lg:col-start-5": isLearningCenter
                })}>
                    <img className="object-cover hidden sm:block" src={imageUrl} />
                    <img className="object-cover sm:hidden" src={mobileImageUrl} />
                </div>
            </Grid>
        </div>
    );
}

export default BlogLandingHero;
