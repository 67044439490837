import React, { useEffect, useRef, useState } from 'react';

import H4Sans from "../../../components/_common/text/heading/h4/sans";
import Grid from "../../../components/_common/grid";
import PageSection from "../../../components/_common/section/page";
import BodyTextBold from '../../../components/_common/text/body-text/bold';
import BodyText from '../../../components/_common/text/body-text';
import Subtitle2 from '../../../components/_common/text/heading/subtitle2';

interface Props {
    className?: string
}

const WhatIs: React.FC = () => {

    return (
        <PageSection className="mb-16 sm:mb-24 lg:mb-32">
            <H4Sans className="text-navy text-center mb-8 sm:mb-16">
                What is term life insurance?
            </H4Sans>
            <Grid>
                {/* Row 1 */}
                <ScrollAwareElement className="col-span-2 sm:col-span-3 lg:col-span-5 sm:mb-8">
                    <Subtitle2 className="mb-0 text-right hidden sm:block">
                        Make a monthly payment
                    </Subtitle2>
                    <BodyTextBold className="mb-0 text-right sm:hidden">
                        Make a monthly payment
                    </BodyTextBold>
                </ScrollAwareElement>
                <ScrollAwareElement className="col-span-2 sm:col-span-5 lg:col-span-7 sm:pl-8 lg:pl-16">
                    <BodyText>
                        Policies offered by Bestow start at $10/mo.
                    </BodyText>
                </ScrollAwareElement>
                {/* Row 2 */}
                <ScrollAwareElement className="col-span-2 sm:col-span-3 lg:col-span-5 sm:mb-8">
                    <Subtitle2 className="mb-0 text-right hidden sm:block">
                        If you pass away
                    </Subtitle2>
                    <BodyTextBold className="mb-0 text-right sm:hidden">
                        If you pass away
                    </BodyTextBold>
                </ScrollAwareElement>
                <ScrollAwareElement className="col-span-2 sm:col-span-5 lg:col-span-7 sm:pl-8 lg:pl-16">
                    <BodyText>
                        Your policy covers you for a chosen period of time (that’s the  term).
                    </BodyText>
                </ScrollAwareElement>
                {/* Row 3 */}
                <ScrollAwareElement className="col-span-2 sm:col-span-3 lg:col-span-5">
                    <Subtitle2 className="mb-0 text-right hidden sm:block">
                        Policy is paid out
                    </Subtitle2>
                    <BodyTextBold className="mb-0 text-right sm:hidden">
                        Policy is paid out
                    </BodyTextBold>
                </ScrollAwareElement>
                <ScrollAwareElement className="col-span-2 sm:col-span-5 lg:col-span-7 sm:pl-8 lg:pl-16">
                    <BodyText>
                        Your beneficiary(ies) receives the total coverage amount, usually tax-free.
                    </BodyText>
                </ScrollAwareElement>
            </Grid>
        </PageSection>
    );
};

const ScrollAwareElement: React.FC<Props> = ({ className, children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            const windowHeight = window.innerHeight;
            const top = ref.current?.getBoundingClientRect().top || 1000000;

            if (top < (windowHeight / 2)) {
                setOpacity(1);
            } else if (top < windowHeight) {
                setOpacity(1 - ((top - (windowHeight / 2)) / (windowHeight / 2)));
            } else {
                setOpacity(0);
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [ref]);

    return (
        <div ref={ref} className={className} style={{ opacity }}>
            {children}
        </div>
    )
}

export default WhatIs;
