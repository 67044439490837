import React from 'react';
import { Helmet } from 'react-helmet';
import { captureException } from '@sentry/react';
import PageContent from '../../components/_common/pageContent';
import PageSection from '../../components/_common/section/page';
import H1Serif from '../../components/_common/text/heading/h1/serif';
import LinkButton from '../../components/_common/button/link';

import './index.scss';

/**
 * Page Not Found (404) Template
 */
const NotFound = () => {
    if (window.location.href.includes('agents')) {
        captureException(`404 on Marketing Sites: ${window.location.href}`, {
            url: window.location.href,
        });
    }
    return (
        <PageContent disableAutoSEO>
            <Helmet>
                <title>404 - Page Not Found</title>
            </Helmet>
            <PageSection className="pt-9 sm:pt-40">
                <H1Serif className="text-navy text-center">
                    404 Page Not Found
                </H1Serif>
                <div className="flex justify-center mt-9 mb-12 sm:mb-0">
                    <LinkButton to="/">Return Home</LinkButton>
                </div>
            </PageSection>
        </PageContent>
    );
};

export default NotFound;
