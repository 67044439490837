import React from 'react';

import Navbar from "../navbar";
import Routes from "../routes";
import Footer from "../footer";
import ThirdPartyLibs from "../thirdPartyLibs";
import useOnPageChange from "../../hooks/useOnPageChange";

/**
 * Top-most component in the site, contains the main navigation and footer plus a Router to handle urls accordingly.
 */
const BestowSite = () => {
    useOnPageChange();

    return (
        <div>
            <ThirdPartyLibs/>
            <div className="sticky top-0 z-20" data-cy="header.section">
                <Navbar />
            </div>
            <div className="overflow-hidden">
                <Routes />
                <Footer />
            </div>
        </div>
    )
};

export default BestowSite;
