import React from 'react';

import Quotation from "../../../components/_common/text/quotation";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";

import {ReactComponent as Stars} from "../../../images/5-stars.svg";
import TrustPilot from "../../../images/Trustpilot_brandmark_gr-blk_RGB 2.svg";

import './index.scss';

const FaqAppTrustPilot: React.FC = () => {
    return (
        <div className="faq-app-trust-pilot">
            <div className="mt-12 sm:mt-0 mb-4 flex justify-center sm:justify-start">
                <Stars />
            </div>
            <Quotation className="text-center sm:text-left">
                “I am so impressed with the customer service at bestow. The process was incredibly easy and quick, and
                everyone I spoke with was friendly and supportive.”
            </Quotation>
            <Subtitle2 className="text-coral-darkest mt-4 mb-0 text-center sm:text-left">
                Laura
            </Subtitle2>
            <div className="date font-sans text-center sm:text-left">
                7/6/20
            </div>
            <div className="mt-4 flex justify-center sm:justify-start">
                {/* For some reason this svg displays wonky when imported as ReactComponent, so go old-school */}
                <img src={TrustPilot} alt="Trust Pilot Logo"/>
            </div>
        </div>
    )
};

export default FaqAppTrustPilot;