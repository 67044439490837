import React, {useState, useRef} from 'react';
import {Formik, Form} from 'formik';
import {object, string} from 'yup';
import {useDispatch} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import CustomFormikField from "../../../../components/_common/formik/customField";
import OutsideLabelInput from "../../../../components/_common/inputs/outsideLabel";
import SecondaryButton from "../../../../components/_common/button/secondary";
import HelperText from "../../../../components/_common/text/helperText";
import Spinner from "../../../../components/_common/spinner/index";
import Subtitle2 from "../../../../components/_common/text/heading/subtitle2";
import BodyText from "../../../../components/_common/text/body-text";
import Recaptcha from "../../../../components/_common/recaptcha";

import { postContactForm } from '../../../../redux/api-partners/';

const ContactForm: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    return !complete ? (
        <Formik
            initialValues={{
                name: '',
                email: '',
                company: '',
                comments: '',
            }}
            validationSchema={object().shape({
                name: string().required("Name is required."),
                email: string().email("Please enter a valid email").required("Email is required."),
                company: string().required("Company is required."),
                comments: string().required("Comments are required.")
            })}
            onSubmit={ () => {
                recaptchaRef.current?.execute();
            }}>
            {({ values }) => (
                <Form noValidate>
                    <div className="mb-8">
                        <CustomFormikField
                            CustomComponent={OutsideLabelInput}
                            name="email"
                            type="email"
                            className="flex-1"
                            label="Email Address"
                            placeholder="email@provider.com"
                            data-testid="email"
                            required />
                    </div>
                    <div className="mb-8">
                        <CustomFormikField
                            CustomComponent={OutsideLabelInput}
                            name="name"
                            className="flex-1"
                            label="Name"
                            placeholder="First Last"
                            data-testid="name"
                            required />
                    </div>
                    <div className="mb-8">
                        <CustomFormikField
                            CustomComponent={OutsideLabelInput}
                            name="company"
                            className="flex-1"
                            label="Company"
                            placeholder="Name of Company"
                            data-testid="company"
                            required />
                    </div>
                    <div className="mb-10">
                        <CustomFormikField
                            CustomComponent={OutsideLabelInput}
                            name="comments"
                            rows={4}
                            className="flex-1"
                            label="Comments"
                            placeholder="What would you like us to know?"
                            data-testid="comments"
                            required />
                    </div>
                    <div className="flex flex-col items-center">
                        {loading ?
                            <Spinner /> :
                            <SecondaryButton type="submit" data-testid="contact-submit-btn">
                                Submit
                            </SecondaryButton>
                        }
                        {error &&
                            <HelperText className="text-error mt-2">
                                {error}
                            </HelperText>
                        }
                    </div>
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                        size="invisible"
                        onChange={() => {
                            setLoading(true);
                            dispatch(postContactForm(values,
                                () => {
                                    setLoading(false);
                                    setComplete(true);
                                },
                                (error: any) => {
                                    setLoading(false);
                                    setError(error.message);
                                }));
                        }}
                    />
                </Form>
            )}
        </Formik>
    ) :
    (
        <div className="text-center sm:mt-8" data-testid="contact-thank-you">
            <Subtitle2 className="mb-2">
                We’ve received your submission
            </Subtitle2>
            <BodyText>
                Thanks for connecting!
            </BodyText>
        </div>
    )
};

export default ContactForm;
