export const referralTermsData = {
    topSection: {
        body: `As a Referral Program member (a “Referrer”), you are subject to Bestow’s Terms of Use and Bestow’s Privacy Policy (both available on our website), as well as the following additional Terms & Conditions for Bestow’s Referral program:`
    },
    sections: [
        {
            title: `Qualified Referral`,
            body: [`A Qualified Referral is defined as a person who is eligible for a Bestow Term Life quote (between the ages 18-60, does not reside in the state of New York (we are not currently licensed in the state of NY), has US citizenship or is a permanent residence in the US, etc.) and creates an account at bestow.com by a person (a “Referred Customer”) who arrives to our website by clicking your unique Referral Program link. You are limited to one Qualified Referral for each Referred Customer; in other words, additional/repeat purchases made by a Referred Customer are not counted as additional Qualified Referrals.`]
        },  // end Qualified Referral
        {
            title: `Referred Customer`,
            body: [`The Referred Customer and the Referrer cannot be the same person (for example, by using a different email address). A Referred Customer is defined as a person who reaches the Bestow website by clicking through a unique friend referral link and is a Qualified Referral that meets the necessary eligibility requirements.`]
        },  // end Referred Customer
        {
            title: `Referral Rewards`,
            body: [`For you to earn referral rewards as a Referrer, the Referred Customer must receive an eligible quote from Bestow and create an account at bestow.com by using your unique referral link.`]
        },  // end Referral Rewards
        {
            title: `Reward Payments`,
            body: [`Rewards are payable in gift cards in increments of $5 or $25. The maximum Qualified Referrals earned may be no more than 10 Qualified Referrals for non-policyholders and 15 for policyholders. As a Referrer, you are responsible for any and all tax liability resulting from Referral Rewards. Referral payouts can take up to 30 business days from the eligible completion date to receive. Bestow is not responsible for lost or stolen gift cards. Gift cards can not be redeemed for cash.`]
        },  // end Reward Payments
        {
            title: `Eligibility`,
            body: [`Eligibility is limited to individuals only. Bestow’s Referral Program cannot be used by businesses for affiliate lead generation as determined in Bestow’s sole discretion. (Corporations are not people, my friend!) Invite friends, family members, colleagues, and neighbors – people you know personally. The following are prohibited:`],
            subSections: [
                {
                    title: `Paying someone to create an account`
                },
                {
                    title: `Creating multiple or fake accounts`
                },
                {
                    title: `Using bulk distribution or spam emails`
                },
                {
                    title: `Referring a person more than once`
                },
                {
                    title: `Referring yourself`
                },
                {
                    title: `Encouraging people to submit inaccurate information to receive a quote (This is insurance fraud and a felony.)`
                }
            ],
            legalText: `Bestow reserves the right to terminate your referral rewards at any time.`
        },  // end Eligibility
        {
            title: `No Spam`,
            body: [`You must comply with all up-to-date “SPAM” laws. For example, emails must be created and distributed in a personal manner and bulk email distribution is strongly discouraged. Any distribution of your referral link that could constitute unsolicited commercial email or “spam” under any applicable law or regulation is expressly prohibited and will be grounds for immediate termination of your account and exclusion from Bestow’s Referral program.`]
        },  // end No Spam
        {
            title: `Right to Close Accounts`,
            body: [`Bestow reserves the right to close the referral account(s) of any Referrer and/or Referred Customer and to request proper payment if the Referrer and/or Referred Customer attempts to use the Bestow Referral program in a questionable manner or breaches any of these Terms & Conditions or is in violation of any law, statute or governmental regulation.`]
        },  // end Right to Close Accounts
        {
            title: `Right to Cancel Program or Change Terms`,
            body: [`Bestow reserves the right to cancel the Referral Program or to change these Terms & Conditions at any time in its sole discretion. Any unclaimed referral rewards will be forfeited at that time.`]
        },  // end Right to Cancel Program or Change Terms
    ],
    legal: `North American Company for Life and Health Insurance is in no way associated with this referral program. As 
        a reminder, some states have regulations and dollar amount limitations. Any questions regarding this program should 
        be directed to Bestow. Purchase of a life insurance policy is not a condition to receive a referral bonus.`
};
