import React from 'react';

import PageSection from '../../components/_common/section/page';
import PageContent from '../../components/_common/pageContent/navy';
import H1Serif from '../../components/_common/text/heading/h1/serif';
import H3Sans from '../../components/_common/text/heading/h3/sans';
import H4Sans from '../../components/_common/text/heading/h4/sans';
import BodyText from '../../components/_common/text/body-text';

// import './index.scss';

const TransparencyCoverage: React.FC = () => {
    return (
        <PageContent className="third-party-page" includeBottomPadding>
            <PageSection className="pt-7 sm:pt-9 lg:pt-16">
                <H4Sans className="mb-12 text-center sm:mb-12 lg:mb-12 pt-7 sm:pt-9 lg:pt-16">
                    Transparency of Coverage
                </H4Sans>
                <BodyText>
                    This{' '}
                    <a
                        className="font-bold"
                        href="https://www.cigna.com/legal/compliance/machine-readable-files"
                    >
                        link
                    </a>{' '}
                    leads to the machine-readable files that are made available
                    in response to the federal Transparency in Coverage Rule and
                    includes negotiated service rates and out-of-network allowed
                    amounts between health plans and healthcare providers. The
                    machine readable files are formatted to allow researchers,
                    regulators, and application developers to more easily access
                    and analyze data.
                </BodyText>
            </PageSection>
        </PageContent>
    );
};

export default TransparencyCoverage;
