import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../../../../../../redux/utils';
import {
    determineUrlType,
    getConsumedCTSQuote,
    getProductsAndOptions,
    getQuote,
    getQuotePartner,
    getUspsCityState,
    fetchAgentCodeForURLShortID,
} from '../../../api';
import { isMultiStateZipCode } from '../../../form';
import { getNoHealthQuotePayload } from '../nhQuoteForm/utils.ts';
import {
    trackIndicativeQuoteGenerated,
    trackQuoteGetQuoteClicked,
} from '../../../api/analytics';
import { getTrackQuoteGetQuoteClickedPayload } from '../../../utils/quoteUtils';

const SLICE_NAME = 'noHealthQuote';
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: false,
    quoteForm: null,
    quote: null,
    ctsQuote: null,
    ctsQuoteLoading: false,
    defaultProduct: null,
    productRates: null,
    quotePartner: null,
});

const quoteSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        quoteSuccessForNoHealthExperiment(state, action) {
            state.loading = false;
            state.bestQuote = action.payload.bestQuote;
            state.defaultProduct = action.payload.defaultProduct;
            state.bestProductRates = action.payload.productRates;
            state.goodProductRates = action.payload.goodProductRates;
            state.averageProductRates = action.payload.averageProductRates;
        },
        clearNoHealthQuote(state) {
            state.loading = false;
            state.bestQuote = state.defaultProduct = state.bestProductRates = null;
        },
        quotePartnerSuccess(state, action) {
            state.quotePartner = action.payload;
        },
        saveQuoteForm(state, action) {
            state.quoteForm = action.payload;
        },
        ctsQuoteLoading(state, action) {
            state.ctsQuoteLoading = action.payload;
        },
        ctsQuoteSuccess(state, action) {
            state.ctsQuoteLoading = false;
            state.ctsQuote = action.payload;
        },
    },
});

export default quoteSlice.reducer;

/**
 * Actions
 */

export const {
    loading,
    quoteSuccessForNoHealthExperiment,
    clearNoHealthQuote,
    quotePartnerSuccess,
    saveQuoteForm,
    ctsQuoteLoading,
    ctsQuoteSuccess,
} = quoteSlice.actions;

/**
 */
export const loadNoHealthQuote = (formData, setFieldError) => async (
    dispatch,
    getState,
    { wpApi },
) => {
    dispatch(loading(true));
    dispatch(saveQuoteForm(formData));

    let stateCode = formData.stateCode;

    // We need to lookup the user's State based on their zip, as long as it's not a multi-state Zipcode
    if (formData.zipCode && !isMultiStateZipCode(formData.zipCode)) {
        // First, transform the user's zip to a State code
        const stateResponse = await getUspsCityState(formData.zipCode);

        if (!stateResponse?.state) {
            setFieldError('zipCode', 'Invalid zip code.');
            return dispatch(loading(false));
        }

        stateCode = stateResponse.state;
    }

    // If there is a short_url_id, we need to convert to agent code before making the request
    let agentCode = formData.bestow_writing_agent;
    if (formData.short_url_id) {
        agentCode = await fetchAgentCodeForURLShortID(formData.short_url_id);
    }

    // TODO: Refactor this, ugh
    const { payload, type } = determineUrlType({
        ...formData,
        state: stateCode,
        bestow_writing_agent: agentCode,
    });

    // Ideally this would get logged on the button click, but we need to refactor the payload transformation up a layer to do that
    trackQuoteGetQuoteClicked(
        getTrackQuoteGetQuoteClickedPayload(formData, payload),
    );

    const payloadForNoHealthQuote = getNoHealthQuotePayload({
        ...formData,
        state: stateCode,
    });

    const noHealthMultiQuote = [
        getQuote(payloadForNoHealthQuote[0], type),
        getQuote(payloadForNoHealthQuote[1], type),
        getQuote(payloadForNoHealthQuote[2], type),
    ];
    return Promise.all(noHealthMultiQuote)
        .then(
            ([bestQuoteResponse, goodQuoteResponse, averageQuoteResponse]) => {
                const { data: bestQuote, payload } = bestQuoteResponse;
                const { data: goodQuote } = goodQuoteResponse;
                const { data: averageQuote } = averageQuoteResponse;
                const { defaultProduct, productRates } = getProductsAndOptions(
                    bestQuote,
                );
                const goodQuoteProductsAndOptions = getProductsAndOptions(
                    goodQuote,
                );
                const goodProductRates =
                    goodQuoteProductsAndOptions.productRates;
                const averageQuoteProductsAndOptions = getProductsAndOptions(
                    averageQuote,
                );
                const averageProductRates =
                    averageQuoteProductsAndOptions.productRates;
                dispatch(
                    quoteSuccessForNoHealthExperiment({
                        bestQuote,
                        defaultProduct,
                        productRates,
                        goodProductRates,
                        averageProductRates,
                    }),
                );
                // Track quote generated
                trackIndicativeQuoteGenerated(
                    formData,
                    payload,
                    bestQuote,
                    productRates,
                );
            },
        )
        .catch(error => {
            console.error(error);
            dispatch(loading(false));
        });
};

export const loadQuotePartner = quoteId => (dispatch, getState, { wpApi }) => {
    return getQuotePartner(quoteId)
        .then(partnerResponse => {
            dispatch(quotePartnerSuccess(partnerResponse));
        })
        .catch(err => {
            // TODO: How is this error handled now?
            console.error(err);
        });
};

export const loadCtsQuote = qid => (dispatch, getState, { wpApi }) => {
    dispatch(ctsQuoteLoading(true));
    return getConsumedCTSQuote(qid)
        .then(ctsQuoteResponse => {
            dispatch(ctsQuoteSuccess(ctsQuoteResponse));
        })
        .catch(err => {
            // Fallback to blank form if CTS quote fetch fails
            dispatch(ctsQuoteLoading(false));
            console.error(err);
        });
};
