import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha from '../../components/_common/recaptcha';
import PageContent from '../../components/_common/pageContent/navy';
import PageSection from '../../components/_common/section/page';
import useIsPersonalAgent from '../../hooks/useIsPersonalAgent';
import CustomFormikField from '../../components/_common/formik/customField';
import OutsideLabelInput from '../../components/_common/inputs/outsideLabel';
import PrimaryButton from '../../components/_common/button/primary';
import useQueryParamData from '../../components/quote/new/hooks/useQueryParamsData';
import TimeStamp from '../privacy/california/form/timestamp';

const CollateralForm: React.FC = () => {
    const isPersonalAgent = useIsPersonalAgent();
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const doHtmlFormAction = (values?: any) => {
        const form = document.getElementById('salesforce-collateral-form');
        // recaptchaRef.current?.execute();
        // @ts-ignore
        form.submit();
    };
    const { formcomplete } = useQueryParamData();

    const [recaptchaComplete, setRecaptchaComplete] = useState(false);

    return (
        <PageContent showTopNav={!isPersonalAgent} includeBottomPadding>
            <PageSection className="px-0 sm:px-20 lg:px-28 pb-14 lg:pb-12 max-w-3xl m-0 m-auto pt-16">
                {!formcomplete ? (
                    <Formik
                        onSubmit={() => {
                            doHtmlFormAction();
                        }}
                        validationSchema={object().shape({
                            '00N8c00000cBTf4': string().required(
                                'Policy number is required.',
                            ), // Policy number
                            'name': string().required('Name is required.'),
                            'email': string()
                                .email('Please enter a valid email')
                                .required('Email is required.'),
                            '00N8c00000c9r1Y': string().required(
                                // Lender's name
                                'Lender name is required.',
                            ),
                            '00N8c00000c9qi3': string().required(
                                'Lender email is required.',
                            ),
                            '00N8c00000c9qjL': string().required(
                                'Lender bank is required.',
                            ),
                            '00N8c00000cBVV1': string().required(
                                'Lender street address is required.',
                            ),
                            '00N8c00000cBVTy': string().required(
                                'Lender city is required.',
                            ),
                            '00N8c00000cBVUY': string().required(
                                'Lender state is required.',
                            ),
                            '00N8c00000cBVUI': string().required(
                                'Lender zip is required.',
                            ),
                            '00N8c00000cBVW4': string().required(
                                'Lender country code is required.',
                            ),
                        })}
                        initialValues={{
                            '00N8c00000cBTf4': '',
                            'name': '',
                            'email': '',
                            '00N8c00000c9r1Y': '',
                            '00N8c00000c9qi3': '',
                            '00N8c00000c9qjL': '',
                            '00N8c00000cBVV1': '',
                            '00N8c00000cBVTy': '',
                            '00N8c00000cBVUY': '',
                            '00N8c00000cBVUI': '',
                            '00N8c00000cBVW4': '',
                            'captcha_settings':
                                '{"keyname":"CaseRecaptcha","fallback":"true","orgId":"00D8c0000024rLq","ts":""}',
                        }}
                    >
                        {() => (
                            <Form
                                action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                                method="POST"
                                id="salesforce-collateral-form"
                            >
                                <Field type="hidden" name="captcha_settings" />
                                <input
                                    type="hidden"
                                    name="orgid"
                                    value="00D8c0000024rLq"
                                />
                                <input
                                    type="hidden"
                                    name="retURL"
                                    value="https://www.bestow.com/business-loan-contact-info/?formcomplete=true"
                                />
                                <TimeStamp />
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="email"
                                        className="flex-1"
                                        label="Email"
                                        placeholder="Email"
                                        data-testid="email"
                                        type="email"
                                        id="email"
                                        required
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cBTf4"
                                        type="text"
                                        className="flex-1 w-full"
                                        label="Policy Number"
                                        placeholder="Policy Number"
                                        required
                                        id="00N8c00000cBTf4"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="name"
                                        className="flex-1"
                                        label="name"
                                        placeholder="Contact Name"
                                        data-testid="name"
                                        type="text"
                                        id="name"
                                        required
                                    />
                                </div>
                                <span>
                                    Please provide the contact information for
                                    your loan officer (the assignee).
                                </span>
                                <br />
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000c9r1Y"
                                        className="flex-1"
                                        label="Lender's Name"
                                        placeholder="Lender's Name"
                                        id="00N8c00000c9r1Y"
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000c9qi3"
                                        className="flex-1"
                                        label="Lender's Email"
                                        placeholder="Lender's Email"
                                        required
                                        id="00N8c00000c9qi3"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000c9qjL"
                                        className="flex-1"
                                        label="Lender Bank"
                                        placeholder="Lender Bank"
                                        type="text"
                                        required
                                        id="00N8c00000c9qjL"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cBVV1"
                                        type="text"
                                        className="flex-1"
                                        label="Lender Street Address"
                                        placeholder="Lender Street Address"
                                        required
                                        id="00N8c00000cBVV1"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cBVTy"
                                        type="text"
                                        className="flex-1 w-full"
                                        label="Lender City"
                                        placeholder="Lender City"
                                        required
                                        id="00N8c00000cBVTy"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cBVUY"
                                        type="text"
                                        className="flex-1 w-full"
                                        label="Lender State"
                                        placeholder="Lender State"
                                        required
                                        id="00N8c00000cBVUY"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cBVUI"
                                        type="text"
                                        className="flex-1 w-full"
                                        label="Lender Zip"
                                        placeholder="Lender Zip"
                                        required
                                        id="00N8c00000cBVUI"
                                    />
                                </div>
                                <div className="mb-8">
                                    <CustomFormikField
                                        CustomComponent={OutsideLabelInput}
                                        name="00N8c00000cBVW4"
                                        type="text"
                                        className="flex-1 w-full"
                                        label="Lender Country Code"
                                        placeholder="Lender Country Code"
                                        required
                                        id="00N8c00000cBVW4"
                                    />
                                </div>
                                <br />
                                <input
                                    id="subject"
                                    name="subject"
                                    value=" Collateral Assignment Form Submission"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cAKNv"
                                    name="00N8c00000cAKNv"
                                    value=" Collateral Assignment"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cB0ug"
                                    name="00N8c00000cB0ug"
                                    value=" Customer Interaction"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cB0v5"
                                    name="00N8c00000cB0v5"
                                    value="Policy Admin"
                                    type="hidden"
                                />
                                <input
                                    id="00N8c00000cB0vA"
                                    name="00N8c00000cB0vA"
                                    value=" Collateral Assignment"
                                    type="hidden"
                                />
                                <input
                                    type="hidden"
                                    name="recordType"
                                    id="recordType"
                                    value="0128c000001alAf"
                                ></input>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!recaptchaComplete}
                                >
                                    Submit
                                </PrimaryButton>
                                <Recaptcha
                                    ref={recaptchaRef}
                                    sitekey={
                                        process.env
                                            .REACT_APP_SALESFORCE_RECAPTCHA_KEY as string
                                    }
                                    onErrored={() => console.log('')}
                                    onChange={() => {
                                        setRecaptchaComplete(true);
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <h2>Your request has been submitted</h2>
                )}
            </PageSection>
        </PageContent>
    );
};

export default CollateralForm;
