import React from 'react';
import cx from 'classnames';

import TextFieldLabel from '../../text/textFieldLabel';
import HelperText from '../../text/helperText';
import SmallText from '../../text/small-text';
import { FormikCustomFieldCommonProps } from '../';

import './index.scss';

export interface Props extends FormikCustomFieldCommonProps {
    options: {
        label: string;
        value: string;
        disabled?: boolean;
    }[];
}

const Select: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({
    className = '',
    label,
    error,
    helper,
    labelHelper,
    required,
    placeholder = 'Please Select',
    options,
    ...otherProps
}) => {
    const classes = cx('custom-select', className, {
        error,
    });
    // removed native required, was overriding formik required
    return (
        <div>
            {label && (
                <div className="mb-2">
                    <TextFieldLabel className="text-gray-800">
                        {label}
                        {required && <span className="text-error">*</span>}
                    </TextFieldLabel>
                    {labelHelper && (
                        <SmallText className="block mb-0 font-medium">
                            {labelHelper}
                        </SmallText>
                    )}
                </div>
            )}
            <div className="flex w-full">
                <select className={classes} {...otherProps}>
                    <>
                        <option value="" disabled>
                            {placeholder}
                        </option>
                        {/* @ts-ignore */}
                        {options.map((option, idx) => (
                            <option value={option.value} key={`country-${idx}`}>
                                {option.label}
                            </option>
                        ))}
                    </>
                </select>
            </div>
            {helper && (
                <div className="mt-1">
                    <HelperText error={error}>{helper}</HelperText>
                </div>
            )}
        </div>
    );
};

export default Select;
