import React from 'react';

import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import BodyText from "../../../components/_common/text/body-text";
import H4Serif from "../../../components/_common/text/heading/h4/serif";
import PrimaryButton from "../../../components/_common/button/primary";
import BodyTextBold from '../../../components/_common/text/body-text/bold';

import Phone from './phone.png';
import ChevronDownIcon from '../../../images/icons/chevron-down.svg';
import TenDollarsIcon from '../../../images/icons/ten-dollars.svg';
import MobileIcon from '../../../images/icons/mobile.svg';
import FineTuneIcon from '../../../images/icons/FineTune.svg';

export interface Props {
    headerText: string
}

const QuoteSteps: React.FC<Props> = ({headerText }) => {
    const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <>
            <PageSection className="mt-14 sm:mt-24 lg:mt-32 mb-16 sm:mb-0 lg:mb-8">
                {headerText &&
                    <Grid>
                        <H4Serif className="text-navy text-center col-span-full mx-4 sm:mx-0 mb-8 sm:mb-12 sm:mx-8 lg:mx-0 lg:mb-16 sm:col-start-2 sm:col-span-6 lg:col-start-4">
                            {headerText}
                        </H4Serif>
                    </Grid>
                }
                <Grid>
                    <div className="col-span-full sm:col-span-4 lg:col-start-2 lg:col-span-5">
                        {_data.map(({icon, title, text}, idx) => (
                            <div key={idx} className="flex flex-col sm:flex-row">
                                <div className="flex flex-col items-center mr-8 mb-2">
                                    <div className="mb-3 flex justify-center" style={{minWidth: 56}}>
                                        <img src={icon} alt="title" />
                                    </div>
                                    {idx+1 !== _data.length &&
                                        <>
                                            <div className="hidden sm:flex justify-center flex-1">
                                                <div
                                                    style={{
                                                        borderLeft: '1.5px solid #0B3541',
                                                        position: 'relative',
                                                    }}
                                                />
                                            </div>
                                            <div className="hidden sm:flex justify-center">
                                                <img src={ChevronDownIcon}
                                                    width="8"
                                                    alt="down chevron"
                                                    style={{
                                                        position: 'relative',
                                                        top: '-4.5px',
                                                        left: '-0.25px'
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="mb-12 text-center sm:text-left">
                                    <BodyTextBold className="mb-4">
                                        {title}
                                    </BodyTextBold>
                                    <BodyText>
                                        {text}
                                    </BodyText>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 my-6 sm:my-0">
                        <img className="w-full" src={Phone} alt="Apply for Bestow from your phone" />
                    </div>
                </Grid>
                <div className="flex justify-center">
                    <PrimaryButton onClick={scrollToTop}>
                        Start now
                    </PrimaryButton>
                </div>
            </PageSection>
        </>
    );
};

const _data = [
    {
        icon: TenDollarsIcon,
        title: '1. Start with a fast, free quote',
        text: 'With Bestow, it’s as easy as click, click, quote. Provide some basic information and get a free quote instantly.'
    },
    {
        icon: MobileIcon,
        title: '2. Apply online in minutes',
        text: 'Answer a few simple health questions and provide some additional information (health issues, lifestyle, etc). The entire term life insurance application can be completed in minutes.'
    },
    {
        icon: FineTuneIcon,
        title: '3. Fine tune your coverage',
        text: 'Build a policy that’s right for you. Consider coverage amounts, term lengths, and what makes the most sense for you and your family.'
    }
]

export default QuoteSteps;
