import React, {useMemo} from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import cx from 'classnames';

import {ProductRates} from "../../../api/interfaces";
import BodyText from "../../../../../_common/text/body-text";
import {trackProductSelect} from "../../../api/analytics";

interface Props {
    rates: ProductRates,
    currentProduct: string,
    setCurrentProduct: (product: string) => void
}

const ProductsButtonGroup: React.FC<Props> = ({ rates, currentProduct, setCurrentProduct }) => {
    const { terms, products } = useMemo(() => {
        const products = Object.keys(rates);
        const terms = products.map(productName => productName.substring(2,4));
        return { products, terms }
    }, [rates]);

    return (
        <div className="flex justify-center rounded-md">
            <div className="bg-white">
                <ToggleButtonGroup
                    value={currentProduct}
                    size="large"
                    onChange={(event: React.MouseEvent<HTMLElement>, newProduct: string | null) => {
                        if (newProduct !== null) {
                            setCurrentProduct(newProduct as string);
                            trackProductSelect(newProduct as string);
                        }
                    }}
                    aria-label="Term"
                    exclusive >
                    {terms.map((term, index) => (
                        <ToggleButton key={`term-${index}`} value={products[index]} data-testid={products[index]} aria-label={`${term} years`}>
                            <BodyText className={cx({ "font-medium": currentProduct === products[index] })}>
                                {term}
                            </BodyText>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </div>
    );
}

export default ProductsButtonGroup;