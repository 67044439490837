import React from 'react';
import cx from 'classnames';

import './index.scss';
import HelperText from '../../../../../_common/text/helperText';
import { FormikCustomFieldCommonProps } from '../../../../../_common/inputs';

const InsideLabelInputMobile = React.forwardRef<
    HTMLInputElement,
    FormikCustomFieldCommonProps
>(
    (
        {
            className = '',
            label,
            error,
            helper,
            labelHelper,
            required,
            ...otherProps
        },
        ref,
    ) => {
        return (
            <>
                <div className="inside-label-input-mobile relative flex">
                    <input
                        {...otherProps}
                        className={cx('px-4 pt-4 h-8 w-full', {
                            error: error,
                        })}
                        type="text"
                        ref={ref}
                    />
                    <span className="absolute top-0 left-0 px-4 pointer-events-none floating-label whitespace-nowrap transform transition-all flex items-center font-light font-sans">
                        {label}
                        {required && <span className="text-error">*</span>}
                    </span>
                </div>
                {helper && (
                    <div className="mt-1">
                        <HelperText error={error}>{helper}</HelperText>
                    </div>
                )}
            </>
        );
    },
);

export default InsideLabelInputMobile;
