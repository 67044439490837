import React, { useEffect, useRef } from 'react';

import YoutubeContainer from "../../../components/_common/youtube-container";
import useYouTube from "../../../hooks/useYouTube";
import { trackIt } from "../../../utilities/segment.track";

import './index.scss';

interface Props {
    id: string,
    videoId: string,
}

const PostVideo: React.FC<Props> = ({ id, videoId}) => {
    const ref = useRef<HTMLDivElement>(null);
    const ytReady = useYouTube();

    useEffect(() => {
        if (ytReady && ref) {
            // @ts-ignore
            const player = new window.YT.Player(id, {
                width: ref.current?.offsetWidth,
                height: ref.current?.offsetHeight,
                videoId,
                playerVars: {
                    fs: 1,
                    controls: 1,
                    showinfo: 0,
                    autoplay: 0,
                    rel: 0,
                    color: 'white',
                },
                events: {
                    onStateChange: (event: any) => {
                        const currentTime = player.getCurrentTime().toFixed(2);
                        switch(event.data) {
                            // @ts-ignore
                            case window.YT.PlayerState.ENDED:
                                trackIt(`${id}-end`, null);
                                break;
                            // @ts-ignore
                            case window.YT.PlayerState.PLAYING:
                                trackIt(`${id}-play`, { currentTime });
                                break;
                            // @ts-ignore
                            case window.YT.PlayerState.PAUSED:
                                trackIt(`${id}-pause`, { currentTime });
                        }
                    }
                }
            });
        }
    }, [id, videoId, ytReady, ref]);

    return (
        <div className="mt-2 mb-6 lg:mt-4 lg:mb-8">
            <YoutubeContainer id={id} aspectRatioWidth={16} aspectRatioHeight={9} />
        </div>
    )
};

export default PostVideo;