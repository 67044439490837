import React from 'react';
import cx from 'classnames';
import PageSection from '../../../../../components/_common/section/page';
import { useWindowWidth } from '@react-hook/window-size';
import { screenBreakpoints } from '../../../../../screen-breakpoints'

interface Props {
    mobileBackgroundImage?: string,
    tabletBackgroundImage?: string,
    desktopBackgroundImage?: string
}

const SEOQuoteHeroImg: React.FC<Props> = ({
    mobileBackgroundImage = '',
    tabletBackgroundImage = '',
    desktopBackgroundImage = ''
}) => {
    const windowWidth = useWindowWidth();
    const hasBackgroundImages = Boolean(mobileBackgroundImage && tabletBackgroundImage && desktopBackgroundImage);

    let bgImg: string;

    if (windowWidth < screenBreakpoints.sm) {
        bgImg = mobileBackgroundImage;
    } else if (windowWidth >= screenBreakpoints.sm && windowWidth < screenBreakpoints.lg) {
        bgImg = tabletBackgroundImage;
    } else {
        bgImg = desktopBackgroundImage;
    }

    const style = hasBackgroundImages
        ? {backgroundImage: `url(${bgImg})`}
        : {}

    return (
        <PageSection
            className={cx(
                "quote-hero bg-cover bg-center -mt-14 sm:mt-0",
                !hasBackgroundImages ? "bg-hero-quote sm:bg-hero-quote-sm lg:bg-hero-quote-lg" : ""
            )}
            style={style}
        />
    );
};

export default SEOQuoteHeroImg;
