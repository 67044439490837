import {useEffect} from "react";
import {showTopNav} from "../redux/siteInfo";
import {useDispatch} from "react-redux";

const useShowTopNav = (show) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showTopNav(show));
    }, [dispatch, show]);
};

export default useShowTopNav;