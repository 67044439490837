import { format, parse } from 'date-fns';
import { DATE_PATTERN } from '../../../../../_common/inputs/dob';
import { getHeightValues } from '../../../api';

// Just a copy of other getQuotePayload functions, but again, self contained code for a one off experiment is ideal state
export const getNoHealthQuotePayload = (formData: any) => {
    const { birthdate, gender, state, tobacco, partner_referral } = formData;
    const { feet, inches } = getHeightValues('68');
    const bestCasePayload = {
        birth_date: format(
            parse(birthdate, DATE_PATTERN, new Date()),
            'yyyy-MM-dd',
        ),
        gender,
        height_feet: feet,
        height_inches: inches,
        state: state,
        weight: Number.parseInt('175', 10),
        tobacco: tobacco ? 'yes' : 'no',
        partner_referral: partner_referral,
    };
    const goodCasePayload = {
        ...bestCasePayload,
        weight: Number.parseInt((tobacco ? '220' : '200').toString(), 10),
    };
    const averageCasePayload = {
        ...bestCasePayload,
        weight: Number.parseInt((tobacco ? '240' : '215').toString(), 10),
    };
    return [bestCasePayload, goodCasePayload, averageCasePayload];
};
