import React from 'react';
import BodyText from '../../../_common/text/body-text';
import H4Serif from "../../../_common/text/heading/h4/serif";


const InvalidURLError: React.FC<{}> = () => (
    <React.Fragment>
        <H4Serif className="text-navy my-6 font-bold text-center">
            This link is no longer valid. 
        </H4Serif>
        <BodyText className="text-center">Please ask your agent for a new one.</BodyText>
    </React.Fragment>
)

export default InvalidURLError;
