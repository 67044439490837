import {createSelector} from "@reduxjs/toolkit";

const showTopNavSelector = state => state.siteInfo.showTopNav;
const showBannerSelector = state => state.siteInfo.showBanner;
const siteInfoSelector = state => state.siteInfo.settings;

export const siteSettingsSelector = createSelector(
    showTopNavSelector,
    showBannerSelector,
    siteInfoSelector,
    (showTopNav, showBanner, siteInfo) => ({showTopNav, showBanner, siteInfo})
);

// KBM - We've hardcoded the Site Settings because it's the only call that requires authentication and this starts
// Our process of slowly getting Marketing away from being able to change _everything_ from WordPress
export const useSiteInfoSelector = () => ({
    "title": "Bestow",
    "description": "",
    "url": "https://staging-hellobestow.kinsta.cloud",
    "email": "team@bestow.com",
    "timezone": "America/Chicago",
    "date_format": "F j, Y",
    "time_format": "g:i a",
    "start_of_week": 1,
    "language": "",
    "use_smilies": true,
    "default_category": 1,
    "default_post_format": "0",
    "posts_per_page": 10,
    "default_ping_status": "closed",
    "default_comment_status": "open",
    "geolocation_api_key": "",
    "facebook_link": "https://www.facebook.com/bestowlifeinsurance",
    "twitter_link": "https://twitter.com/bestow_inc",
    "instagram_link": "https://www.instagram.com/bestow_life",
    "linkedin_link": "https://www.linkedin.com/company/bestow-insurance/",
    "angellist_link": "https://angel.co/bestowlife",
    "pinterest_link": "https://www.pinterest.com/bestow_life/",
    "netflix_cost": "$13.99",
    "spotify_cost": "$9.99",
    "prime_cost": "$12.99",
    "starbucks_cost": "$4.65",
    "callout_text": "Get started today and have coverage in a few easy steps",
    "callout_link": "https://partners.bestow.com/quote",
    "legal_copy": "Life insurance quotes provided by Bestow Agency, LLC dba Bestow Insurance Services in CA, who is the licensed agent. \r\n\r\nTerm Life Insurance Policies offered by Bestow are issued on policy form LS181 and LS182, or state version including all applicable endorsements and riders, by North American Company for Life and Health Insurance®, Administrative Office, One Sammons Plaza, Sioux Falls, SD 57193.  Products or issues ages may not be available in all jurisdictions. Limitations or restrictions may apply. Not available in New York.\r\n\r\nNorth American is rated A+ (Superior) by A.M. Best. Limitations or restrictions may apply. A+ (Superior), the second highest rating out of 15 categories, was affirmed by A.M. Best for North American Company for Life and Health Insurance as part of Sammons Financial Group on August 19, 2020. A.M. Best is a large third party independent reporting and rating company that rates an insurance company on the basis of the company’s financial strength, operating performance and ability to meet its ongoing obligations to policyholders. \r\n\r\nOur application asks about your lifestyle and health to avoid requiring a medical exam.",
    "login_link": "https://customer.bestow.com",
    "quote_link": "https://bestow.com/quote/",
    "phone_number": "1-833-300-0603",
    "banner_text": "Learn more about our response to COVID-19.",
    "banner_link": "https://bestow.com/blog/covid-19-concerns-rest-easy-bestow-life-insurance-is-here-for-you/",
    "banner_link_text": "Click for details.",
    // KBM - Disable this until we can re-design and turn back on, per Marketing
    // "banner_unique_id": "covid19-apr2020"
});