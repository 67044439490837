import React from 'react';

import QuotePage from '../../index';
import QuoteHeader from '../../quote-header';

import creditKarmaLogo from '../../../../images/quote/partners/credit-karma.svg';

/**
 * Custom Lander for CreditKarma. This page implements a custom QuoteHeader so we can pass a logo in the title
 */
const CreditKarmaLander: React.FC = () => {
    const Header = (
        <QuoteHeader
            hero={{
                text:
                    'Get your fast, free quote and be covered today, if approved.',
            }}
            title={
                <span>
                    Welcome{' '}
                    <img
                        src={creditKarmaLogo}
                        alt="Credit Karma"
                        className="w-48 mt-3 sm:w-64 lg:w-72"
                    />{' '}
                    Members
                </span>
            }
        />
    );

    return <QuotePage quoteHeader={Header} quoteText="" />;
};

export default CreditKarmaLander;
