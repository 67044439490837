import React from 'react';
import { NavLink, NavLinkProps } from "react-router-dom";

import styles from './index.module.scss';

const NavBarLink:React.FC<NavLinkProps> = (props) => {
    return (
        <NavLink
            {...props}
            className={`${styles.nav_link} bestow-track`}
            activeClassName={styles.nav_link_active} />
    )
};

export default NavBarLink;