import React, {HTMLAttributes} from 'react';
import cx from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    cols?: number,
    smCols?: number,
    lgCols?: number,
    xlCols?: number,
    gap?: number,
    smGap?: number,
    lgGap?: number,
    xlGap?: number,
}

/**
 * Tailwind grid container based on Bestow Web Design System (Marketing)
 */
const Grid: React.FC<Props> = ({
    className = "",
    children,
    cols = 4,
    smCols = 8,
    lgCols = 12,
    xlCols = 12,
    gap = 4,
    smGap = 4,
    lgGap = 6,
    xlGap = 5,
    ...otherProps
}) => {
    const classes = cx(
        `grid gap-${gap} sm:gap-${smGap} lg:gap-${lgGap} xl:gap-${xlGap}`,
        /**
         * Tailwind docs say not to use string concatenation when using Tailwind's
         * 'purge' option, but it's ok in the case because we've added the grid-cols classes
         * to the purge safe list in tailwind.config.js
         *
         * https://tailwindcss.com/docs/optimizing-for-production
         */
        `grid-cols-${cols} sm:grid-cols-${smCols} lg:grid-cols-${lgCols} xl:grid-cols-${xlCols}`,
        className,
    );

    return (
        <div className={classes} {...otherProps}>
            {children}
        </div>
    )
};

export default Grid;
