import React from 'react';

import Subtitle2 from '../../../../components/_common/text/heading/subtitle2';
import BodyText from '../../../../components/_common/text/body-text';
import LinkButton from '../../../../components/_common/button/link';
import BodyTextBold from "../../../../components/_common/text/body-text/bold";

import './index.scss';

interface Props {
    excerpt: string,
    title: string,
    imageUrl?: string,
    postSlug: string,
    isLearningCenter?: boolean
}

const FeaturedPost: React.FC<Props> = ({excerpt, title, imageUrl, postSlug, isLearningCenter}) => {
    return (
        <div className="featured-post flex flex-col">
            <div className="aspect-ratio-box mb-4">
                <div className="aspect-ratio-box-inner flex">
                    <img className="w-full object-cover" src={imageUrl ?? 'https://via.placeholder.com/325x216'} />
                </div>
            </div>
            <Subtitle2 className="hidden lg:block mb-4">{title}</Subtitle2>
            <BodyTextBold className="lg:hidden mb-4">{title}</BodyTextBold>
            <BodyText className="mb-4" dangerouslySetInnerHTML={{__html: excerpt}} />
            <div className="flex flex-1 items-end">
                <LinkButton to={`/${isLearningCenter ? "learningcenter" : "blog"}/${postSlug}`}>
                    Read More
                </LinkButton>
            </div>
        </div>
    )
};

export default FeaturedPost;
