import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from "../utils";
import { getAll } from "../utils";

const SLICE_NAME = "faqs";
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: true,
    faqMap: {}
});

const faqsSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        faqsSuccess(state, action) {
            state.loading = false;
            state.faqMap = action.payload.reduce((ret, faq) => ({
                ...ret,
                [faq.id]: faq
            }), state.faqMap);
        },
        faqsError(state) {
            state.loading = false
        }
    }
});

export default faqsSlice.reducer;

/**
 * Actions
 */

export const { loading, faqsSuccess, faqsError } = faqsSlice.actions;

/**
 * Fetches all the FAQs
 */
export const fetchFAQs = () => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));

        return getAll(() => wpApi.faqs().param('_fields', 'id,date,title,content,faq_category').perPage(20))
            .then(faqs => {
                return dispatch(faqsSuccess(faqs));
            })
            .catch(error => {
                console.error(error);
                dispatch(faqsError());
            })
    }
);
