const faqs = [
    {
        id: 1,
        title: {
            rendered: "What makes Bestow different from some of its competition?"
        },
        content: {
            rendered: `Our unique digital application process and underwriting (in as little as 5 min) is industry-leading. 
                The opportunity to offer an instant decision at the end of the application process would be a 
                differentiator in your business.`
        }
    },
    {
        id: 2,
        title: {
            rendered: "Are the rates competitive?"
        },
        content: {
            rendered: `Bestow offers competitive rates as well as a best-in-class experience.`
        }
    },
    {
        id: 3,
        title: {
            rendered: "How do we get contracted?"
        },
        content: {
            rendered: `Our Agent Experience Team will be the main contact for contracting. We will work with the Broker 
                General Agency. If interested, please contact your BGA.`
        }
    },
];

export default faqs;
