import React from 'react';

import PageContent from "../../components/_common/pageContent";
import Eyebrow from "../../components/_common/text/eyebrow";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import PageSection from "../../components/_common/section/page";
import ClickClickBoom from './click-click-boom';
import WhatIs from './what-is';
import BlogSlider from './blog-slider';
import CTAModuleOne from '../../components/_common/modules/cta/one';
import PrimaryButtonLarge from '../../components/_common/button/primary/large';
import SecondaryButtonWhite from '../../components/_common/button/secondary/white';
import AllFAQs from '../../components/_common/modules/faqs/all';
import KeenVideoSlider from "./keenVideoSlider";

import {ReactComponent as Arrow} from "../../images/svgs/btn-arrow-right.svg";

import { videos } from './data';

const Insurance101: React.FC = () => {
    return (
        <PageContent>
            <PageSection className="pt-7 lg:pt-11">
                <Eyebrow className="mb-2 text-center sm:text-left">LIFE INSURANCE BASICS</Eyebrow>
                <H1Serif className="text-navy text-center sm:text-left">Term life insurance,<br />explained.</H1Serif>
            </PageSection>
            <div className="mt-14 mb-8 sm:mb-20 sm:mt-32 lg:mb-16 lg:mt-40">
                <KeenVideoSlider videos={videos} />
            </div>
            <ClickClickBoom />
            <WhatIs />
            <CTAModuleOne
                className="bg-mint text-navy"
                title={<span>Start with a free,<br />instant quote.</span>}>
                <PrimaryButtonLarge to="/quote">
                    Let's Go
                </PrimaryButtonLarge>
            </CTAModuleOne>
            <BlogSlider />
            <PageSection className="mb-16 sm:mb-24 lg:mb-32">
                <AllFAQs />
            </PageSection>
            <PageSection className="bg-navy-light py-16">
                <div className="flex justify-center">
                    <SecondaryButtonWhite Icon={Arrow} to="/quote">
                        Apply Now
                    </SecondaryButtonWhite>
                </div>
            </PageSection>
        </PageContent>
    );
};

export default Insurance101;
