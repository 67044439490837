import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import NavDrawer from './drawer';
import NavBarLink from './link';
import PrimaryNavButton from '../_common/button/primary/nav';
import SmallText from '../_common/text/small-text';
import { ChatIcon, LogInIcon, MenuIcon, PhoneIcon } from '../../images/icons';
import { BestowNavyLogo } from '../../images/logos';
import { openSalesforceChat } from '../../utilities/salesforce';
import { siteSettingsSelector } from '../../redux/siteInfo/selectors';
import { trackQuoteCTAClicked } from '../quote/new/api/analytics';
import './index.scss';

const NavBar: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { showTopNav } = useSelector(siteSettingsSelector);

    return (
        <div
            className={cx('relative', { hidden: !showTopNav })}
            data-testid="top-nav"
        >
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <div className="nav-bar flex items-center bg-white px-4 py-5 relative lg:py-6 lg:px-6 xl:px-16">
                <MenuIcon
                    className="lg:hidden mr-4 -mt-1"
                    data-cy="mobile.menu.handler"
                    onClick={() => setDrawerOpen(true)}
                />
                <div className="hidden lg:flex items-center">
                    <div
                        className="mr-6 flex items-center"
                        data-cy="insurance.101.link"
                    >
                        <NavBarLink to={'/insurance-101'}>
                            Insurance 101
                        </NavBarLink>
                    </div>
                    <div
                        className="flex items-center"
                        data-cy="more.resources.link"
                    >
                        <NavBarLink to={'/learningcenter'}>
                            More Resources
                        </NavBarLink>
                    </div>
                </div>
                <div
                    className="lg:absolute lg:inset-0 lg:flex lg:items-center lg:justify-center pointer-events-none"
                    data-cy="header.bestow.logo"
                >
                    <Link to="/">
                        <BestowNavyLogo className="h-5 w-28 lg:h-8 lg:w-40 pointer-events-auto" />
                    </Link>
                </div>
                <div className="flex flex-1 justify-end">
                    <div className="hidden sm:flex items-center mr-7">
                        <a
                            className="engage__link bestow-track flex items-center no-underline"
                            data-cy="header.desktop.login"
                            href="https://customer.bestow.com"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <LogInIcon className="mr-4" />
                            <SmallText className="text-charcoal-60 mr-4">
                                LOG IN
                            </SmallText>
                        </a>
                        <div className="mr-2">
                            <a
                                className="engage__link bestow-track flex items-center hover-link"
                                href="https://bstow.me/my-agent"
                                data-track-name="phone_h_v1"
                                data-cy="header.desktop.phone"
                            >
                                <PhoneIcon className="mr-2" />
                                <SmallText className="hover-link-text transition-all my-0">
                                    833-300-0603
                                </SmallText>
                            </a>
                        </div>
                        <a
                            className="engage__link bestow-track flex items-center"
                            href="#"
                            onClick={openSalesforceChat}
                            data-track-name="chat_h_v1"
                            data-cy="header.desktop.chat"
                        >
                            <ChatIcon />
                        </a>
                    </div>
                    <div data-cy="header.quote">
                        <PrimaryNavButton
                            to="/quote"
                            onClick={trackQuoteCTAClicked}
                        >
                            Get Quote
                        </PrimaryNavButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
