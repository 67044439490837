import React, { useRef } from 'react';
import cx from 'classnames';

import Eyebrow from "../../text/eyebrow";
import TrustBox from "../../../trustPilot/TrustBox";
import Grid from "../../grid";
import PageSection from "../../section/page";
import Slider from "react-slick";
import {CustomerReview} from './types';
import H3Serif from "../../text/heading/h3/serif";
import Subtitle2 from "../../text/heading/subtitle2";
import BodyText from '../../text/body-text';
import {isPreRendering} from "../../../../utilities/reactSnap";

import leftHover from '../../../../images/svgs/ReviewLeft-Hover.svg';
import rightHover from '../../../../images/svgs/ReviewRight-Hover.svg';
import {ReviewsStars, ReviewsStarsDark} from "../../../../images/svgs";

import './index.scss';

type Props = {
    className?: string,
    reviews: CustomerReview[];
    dark?: boolean,
    title?: string,
    hideTrustPilot?: boolean,
}

const ReviewSliderModule: React.FC<Props> = ({
    className = "pt-8 sm:pt-24 pb-12 sm:pb-0 lg:pb-10",
    reviews,
    dark,
    title,
    hideTrustPilot,
}) => {
    const sliderRef = useRef<Slider>(null);

    var slickSettings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        autoplay: !isPreRendering(),
        autoplaySpeed: 8000,
        speed: 1000,
    };

    return (
        // <PageSection className="reviews-slider-module pt-8 sm:pt-24 pb-12 sm:pb-0 lg:pb-10">
        <PageSection className={cx("reviews-slider-module", className)}>
            <Eyebrow className={cx("uppercase mx-8 sm:mx-0 text-center", {
                "text-white": dark,
                "text-charcoal": !dark
            })}>
                {title || "Real Reviews from Real Customers"}
            </Eyebrow>
            {!hideTrustPilot &&
                <div className="mt-4">
                    <TrustBox data-theme={dark ? "dark" : ""} />
                </div>
            }
            <Grid  className={cx({ "dark-mode": dark })}>
                <div className="col-span-1 flex items-center justify-center">
                    <div className="prev-btn" onClick={() => sliderRef.current?.slickPrev()} />
                </div>
                <div className="col-span-full lg:col-start-2 lg:col-span-10">
                    <Slider ref={sliderRef} {...slickSettings}>
                        {reviews.map((item, i) => (
                            <div key={i}>
                                {item.stars &&
                                    <div className="my-6 sm:my-10 flex justify-center">
                                        <ReviewsStarsDark className={cx({"hidden": !dark})}/>
                                        <ReviewsStars className={cx({"hidden": dark})}/>
                                    </div>
                                }
                                <div className="mt-6 sm:mt-10 flex justify-center text-center">
                                    <H3Serif className={cx("mb-6 mx-2 sm:mb-10", {
                                        "text-white": dark,
                                        "text-navy": !dark
                                    })}>
                                        “{item.review_content}”
                                    </H3Serif>
                                </div>
                                <div className="mb-10 sm:mb-14">
                                    <Subtitle2 className={cx("font-bold text-center", {
                                        "text-rose": dark,
                                        "text-coral-darkest": !dark
                                    })}>
                                        {item.review_author}
                                    </Subtitle2>
                                    {item.review_title &&
                                        <BodyText className="mt-2 text-center">
                                            {item.review_title}
                                        </BodyText>
                                    }
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="col-span-1 flex items-center justify-center">
                    <div className="next-btn" onClick={() => sliderRef.current?.slickNext()} />
                </div>
            </Grid>
            {/* Do this so these images will load prior to the user hovering over the left/right controls */}
            <div className="hidden">
                <img src={leftHover} alt="left hover"/>
                <img src={rightHover} alt="right hover"/>
            </div>
        </PageSection>
    )
};

export default ReviewSliderModule;
