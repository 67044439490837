import React from 'react';
import {string, object, number} from 'yup';
import CustomFormikField from "../../../../components/_common/formik/customField";
import OutsideLabelInput from "../../../../components/_common/inputs/outsideLabel";
import RadioGroup from "../../../../components/_common/inputs/radio-group";
import TextFieldLabel from '../../../../components/_common/text/textFieldLabel';

export const basicQuestionsSchema = object().shape({
    name: string().required("Name is required."),
    email: string().email("Please enter a valid email").required("Email is required."),
    websites: string().required("Websites are required."),
    isLifeInsuranceAgent: string().required("Please choose an option."),
    numLicensedStates: number()
        .positive("Please enter a positive number.")
        .max(50, "Please enter a number <= 50.")
        .integer("Please enter a whole number.")
        .required("Please enter a number.")
});

const BasicQuestions: React.FC = () => (
    <div>
        <div className="mb-8">
            <CustomFormikField
                CustomComponent={OutsideLabelInput}
                name="email"
                type="email"
                className="flex-1"
                label="Email Address"
                data-testid="email"
                required />
        </div>
        <div className="mb-8">
            <CustomFormikField
                CustomComponent={OutsideLabelInput}
                name="name"
                className="flex-1"
                label="What is your name?"
                data-testid="name"
                required />
        </div>
        <div className="mb-8">
            <CustomFormikField
                CustomComponent={OutsideLabelInput}
                name="websites"
                className="flex-1"
                label="What is your website(s)?"
                helper="Separate each with a comma"
                data-testid="websites"
                required />
        </div>
        <div className="mb-8">
            <TextFieldLabel className="text-gray-800 mb-4">
                ARE YOU A LIFE INSURANCE AGENT?<span className="text-error">*</span>
            </TextFieldLabel>
            <CustomFormikField
                CustomComponent={RadioGroup}
                options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                ]}
                name="isLifeInsuranceAgent"
                required />
        </div>
        <div className="mb-8">
            <CustomFormikField
                CustomComponent={OutsideLabelInput}
                name="numLicensedStates"
                className="flex-1"
                label="How many states are you licensed in?"
                type="number"
                data-testid="numLicensedStates"
                required />
        </div>
    </div>
);

export default BasicQuestions;
