import React from 'react';
import cx from 'classnames';

import './index.scss';
import { FormikCustomFieldCommonProps } from '../../../../../_common/inputs';
import BodyText from '../../../../../_common/text/body-text';

const CheckboxMobile: React.FC<
    FormikCustomFieldCommonProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ className = '', label, error, helper, required, ...otherProps }) => {
    const classes = cx('checkboxMobile', className, {
        error,
    });

    return (
        <div className="flex items-center">
            <div>
                {/* Make sure we put hard-coded type at the end so it can't get overwritten */}
                <input
                    className={classes}
                    {...otherProps}
                    type="checkbox"
                    aria-label={label}
                />
            </div>

            {label && (
                <BodyText className="ml-2 whitespace-nowrap text-sm">
                    {label}
                </BodyText>
            )}
        </div>
    );
};

export default CheckboxMobile;
