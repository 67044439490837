import React from 'react';
import useFAQsByIdFilter from '../../../hooks/useFAQsByIdFilter';

import H3Serif from '../../../components/_common/text/heading/h3/serif';
import Grid from '../../../components/_common/grid';
import PageSection from '../../../components/_common/section/page';
import FAQList from '../../../components/_common/modules/faqs/list';

const FAQs: React.FC = () => {
    const faqs = useFAQsByIdFilter([509, 289, 314, 3434]);

    return (
        <PageSection className="pt-12 lg:pt-20 pb-12 lg:pb-16">
            <Grid>
                <H3Serif className="col-span-full font-bold text-navy text-center mb-16">
                    Frequently asked questions
                </H3Serif>
            </Grid>
            <FAQList faqs={faqs} />
        </PageSection>
    )
};

export default FAQs;
