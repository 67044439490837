import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../_common/spinner';
import { Link, useParams } from 'react-router-dom';

import { fetchAllPages } from '../../redux/pages';
import { usePagesSelector } from '../../redux/pages/selectors';
import { allPostsSelector } from '../../redux/posts/selectors';
import { allLCPostsSelector } from '../../redux/learningCenter/selectors';
import { fetchAllPosts } from '../../redux/posts';
import { fetchAllPosts as fetchAllLearningCenterPosts } from '../../redux/learningCenter';

const SiteMap = () => {
    const dispatch = useDispatch();
    const { loading: pagesLoading, pages } = usePagesSelector();
    const posts = useSelector(allPostsSelector);
    const lcPosts = useSelector(allLCPostsSelector);
    const { page, of } = useParams();

    const allItems = useMemo(() => {
        let items = pages.concat(posts || []).concat(lcPosts || []);

        if (page && of) {
            const pageSize = items.length / of;
            const start = (page - 1) * pageSize;
            const end = page === of ? items.length : page * pageSize;

            console.log('slicing from', start, 'to', end);

            items = items.slice(start, end);
        }

        return items;
    }, [page, of, pages, posts, lcPosts]);

    // allItems?.forEach(item => {
    //     if (item.template?.match(/agent/)) {
    //         // console.log("Agent Page", item.title, item);
    //
    //         const name = item.acf?.agent_hero?.personal_agent_tmpl_hero_name;
    //         if (name && (name.match(/<\/?br\/?>/))) {
    //             console.log("Found Bunk Agent", item);
    //         }
    //     }
    // });

    useEffect(() => {
        dispatch(fetchAllPages());
        dispatch(fetchAllPosts(true));
        dispatch(fetchAllLearningCenterPosts(true));
    }, [dispatch]);

    return (
        <div>
            {pagesLoading || !posts?.length || !lcPosts?.length ? (
                <Spinner />
            ) : (
                allItems.map(page => (
                    <div key={page.id}>
                        <Link to={new URL(page.link).pathname}>
                            {page.title.rendered}
                        </Link>
                    </div>
                ))
            )}
        </div>
    );
};

export default SiteMap;
