import React from 'react';

import { Props } from '../../../';
import H2Serif from '../';

const H2SerifNavy: React.FC<Props> = ({className = '', children, ...otherProps}) => (
    <H2Serif className={`text-navy ${className}`} {...otherProps}>
        {children}
    </H2Serif>
);

export default H2SerifNavy;
