import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from "../utils";

const SLICE_NAME = "menus";
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: true,
    menuMap: {},
});

const menusSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        menuSuccess(state, action) {
            state.loading = false;
            state.menuMap[action.payload.slug] = action.payload;
        },
        menuError(state) {
            state.loading = false
        }
    }
});

export default menusSlice.reducer;

/**
 * Actions
 */

export const { loading, menuSuccess, menuError } = menusSlice.actions;

/**
 * Fetches a single menu based on the slug supplied
 * @param slug  The slug of the menu to fetch
 */
export const fetchMenu = (slug) => (
    (dispatch, getState, {wpApi}) => {
        dispatch(loading(true));
        return wpApi.menus().id(slug).get()
            .then(menu => {
                return dispatch(menuSuccess(menu));
            })
            .catch(error => {
                console.error(error);
                dispatch(menuError());
            })
    }
);