import React from 'react';

import PageContent from '../../components/_common/pageContent/navy';
import PageSection from "../../components/_common/section/page";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import H4Serif from "../../components/_common/text/heading/h4/serif";
import Grid from "../../components/_common/grid";
import SecondaryButton from "../../components/_common/button/secondary";
import BodyTextLarge from "../../components/_common/text/body-text/large";

import {ReactComponent as Arrow} from "../../images/svgs/btn-arrow-right.svg";

const SelfReferral: React.FC = () => {
    return (
        <PageContent>
            <PageSection className="pt-7 sm:pt-9 lg:pt-16">
                <Grid className="text-center sm:text-left">
                    <div className="col-span-full sm:col-span-3 lg:col-span-4">
                        <H1Serif className="sm:mr-24">Whoops</H1Serif>
                    </div>
                    <div className="col-span-full sm:col-span-5 lg:col-span-8 sm:mt-6 lg:mt-10">
                        <H4Serif className="mb-0">You got so excited you <br className="lg:hidden"/>referred yourself.</H4Serif>
                        <BodyTextLarge className="mt-8 sm:mt-6 mb-10 mx-4 sm:mx-0">
                            It happens. Hey, let’s channel that excitement into
                            referrals for your friends and family instead. It’ll
                            be like a little nudge to say “thinkin’ boutcha.”
                        </BodyTextLarge>
                        <div className="flex justify-center sm:justify-start mb-20 sm:mb-0">
                            <SecondaryButton Icon={Arrow} className="w-full sm:w-auto" to="/refer">
                                Refer A Friend
                            </SecondaryButton>
                        </div>
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default SelfReferral;
