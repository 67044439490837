import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { trackIt } from './utilities/segment.track';
import './index.css';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { isPreRendering } from './utilities/reactSnap';

(async () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        environment: process.env.REACT_APP_ENVIRONMENT.toLowerCase(),
    });
    const rootElement = document.getElementById('root');
    let renderer = ReactDOM.hydrate;
    if (rootElement.hasChildNodes()) {
        renderer = ReactDOM.render;
    }

    const wrapLD = async childNodes => {
        if (isPreRendering()) {
            return childNodes;
        }
        const LDProvider = await asyncWithLDProvider({
            clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
            user: {
                anonymous:
                    !!process.env.REACT_APP_LAUNCH_DARKLY_DEV_USER_ID === false,
                key:
                    process.env.REACT_APP_LAUNCH_DARKLY_DEV_USER_ID ||
                    undefined,
                // sending custom attributes for targeting purposes
                custom: {
                    e2e: new URLSearchParams(window.location.search).has('e2e'),
                    pathname: window.location.pathname, // looking for agent and stash traffic here.
                    skipform: new URLSearchParams(window.location.search).has(
                        'skipform',
                    ),
                    utm_source: new URLSearchParams(window.location.search).get(
                        'utm_source',
                    ),
                    utm_medium: new URLSearchParams(window.location.search).get(
                        'utm_medium',
                    ),
                    utm_term: new URLSearchParams(window.location.search).get(
                        'utm_term',
                    ),
                    utm_campaign: new URLSearchParams(
                        window.location.search,
                    ).get('utm_campaign'),
                },
            },
            options: {
                evaluationReasons: true,
                streaming: false,
            },
        });

        return <LDProvider>{childNodes}</LDProvider>;
    };
    const ldWrapper = await wrapLD(<App />);

    renderer(<React.StrictMode>{ldWrapper}</React.StrictMode>, rootElement);

    function sendToSegment(name) {
        // Segment is not a placeholder and has loaded in
        if (window.analytics && !Array.isArray(window.analytics)) {
            // Google Analytics metrics must be integers, so the value is rounded.
            // For CLS the value is first multiplied by 1000 for greater precision
            // (note: increase the multiplier for greater precision if needed).
            const value = Math.round(
                name.name === 'CLS' ? name.delta * 1000 : name.delta,
            );

            // The 'id' value will be unique to the current page load. When sending
            // multiple values from the same page (e.g. for CLS), Google Analytics can
            // compute a total by grouping on this ID (note: requires `eventLabel` to
            // be a dimension in your report).
            const id = name.id;

            trackIt('web-vitals', {
                category: 'Web Vitals',
                action: name.name,
                value,
                label: id,
            });
        }
    }

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(sendToSegment);
})();
