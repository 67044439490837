import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Grid from '../_common/grid';
import PageSection from '../_common/section/page';
import { useSiteInfoSelector } from '../../redux/siteInfo/selectors';
import { useMenuSelector } from '../../redux/menus/selectors';
import { usePrefetchPages } from '../../hooks/usePrefetchPages';
import { fetchMenu } from '../../redux/menus/index';
import { openSalesforceChat } from '../../utilities/salesforce';
import useIsPersonalAgent from '../../hooks/useIsPersonalAgent';
import FooterLinkSection from './linkSection';
import { FOOTER_LINK_CATEGORIES, LEGAL } from './data';
import { BestowWhiteLogo } from '../../images/logos/index';

import instaIcon from '../../images/icons/footer-insta.svg';
import twitterIcon from '../../images/icons/footer-twitter.svg';
import angelIcon from '../../images/icons/footer-angel.svg';
import linkedInIcon from '../../images/icons/footer-linked-in.svg';
import chatIcon from '../../images/icons/footer-chat.svg';
import accreditationIcon from '../../images/icons/footer-accreditation.svg';

import './index.scss';

const Footer: React.FC = () => {
    const isPersonalAgent = useIsPersonalAgent();
    const isGerber = window.location.pathname.includes('guaranteed'); // MOE-461
    const menu = useMenuSelector(
        isPersonalAgent ? 'personal-agent-footer-menu' : 'footer-menu',
    );
    const dispatch = useDispatch();
    usePrefetchPages(
        menu?.items?.map((item: any) => new URL(item.url).pathname),
    );
    const linkedInfo = useSiteInfoSelector();

    useEffect(() => {
        if (!isPersonalAgent && !menu.slug) {
            dispatch(
                fetchMenu(
                    isPersonalAgent
                        ? 'personal-agent-footer-menu'
                        : 'footer-menu',
                ),
            );
        }
    }, [dispatch, menu, isPersonalAgent]);

    // We want to force /agents urls for any pages nested under /agents
    const getFooterLinkPath = (path: string) =>
        isPersonalAgent ? `/agents${path}` : path;
    const getFooterLinkTarget = () => (isPersonalAgent ? '_blank' : '_self');
    return (
        <PageSection
            className="footer bg-navy text-white py-16 xl:pt-20 xl:pb-10"
            data-testid="footer"
        >
            <Grid
                lgCols={8}
                xlCols={isPersonalAgent ? 8 : undefined}
                data-cy="footer.contact.section"
            >
                {!isPersonalAgent && (
                    <div className="col-span-full xl:col-start-5 xl:col-span-8 lg:mb-16 xl:mb-0 mb-7 sm:mb-11 xl:mb-12">
                        <Grid lgCols={8} xlCols={8}>
                            {FOOTER_LINK_CATEGORIES.map(({ title, links }) => (
                                <div className="col-span-2 mb-4" key={title}>
                                    <FooterLinkSection
                                        title={title}
                                        links={links}
                                    />
                                </div>
                            ))}
                        </Grid>
                    </div>
                )}
                <div
                    className={cx(
                        'col-span-full sm:col-span-3 font-sans font-light text-sm lg:text-base',
                        {
                            'xl:row-start-1': !isPersonalAgent,
                        },
                    )}
                >
                    <div className="mb-6">
                        <BestowWhiteLogo className="h-6 w-32 -ml-1" />
                    </div>
                    <div className="mb-6">
                        Bestow, Inc.
                        <br />
                        750 North St. Paul Street #1900
                        <br />
                        Dallas, TX 75201
                    </div>
                    <div className="mb-7" data-cy="footer.contact.section">
                        <a
                            aria-label="call us"
                            className="flex font-sans text-white font-light pb-0 no-underline"
                            href="https://bstow.me/my-agent"
                        >
                            +1 (833) 300-0603
                        </a>
                        <a
                            aria-label="email us"
                            className="flex font-sans text-white font-light no-underline"
                            href="mailto:team@bestow.com"
                        >
                            team@bestow.com
                        </a>
                    </div>
                    <div
                        className="flex pb-6 cursor-pointer sm:pb-0"
                        onClick={openSalesforceChat}
                        aria-label="chat with us"
                    >
                        <div className="mr-2">
                            <img
                                src={chatIcon}
                                alt="Live Chat"
                                className="w-5 h-5"
                            />
                        </div>
                        <div className="font-sans font-medium text-base text-white">
                            Live Chat
                        </div>
                    </div>
                </div>
                <div
                    className={cx('col-span-full sm:col-span-5', {
                        'xl:col-span-4': !isPersonalAgent,
                    })}
                >
                    <div className="mb-10 sm:mb-6">
                        <div className="font-sans text-base text-white mb-4">
                            Follow us
                        </div>
                        <div className="flex">
                            <a
                                href={linkedInfo.instagram_link}
                                className="mr-2"
                                target="_blank"
                                aria-label="Follow us on instagram"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={instaIcon}
                                    alt="Follow us on instagram"
                                    className="w-8 h-8"
                                />
                            </a>
                            <a
                                href={linkedInfo.twitter_link}
                                className="mr-2"
                                aria-label="Follow us on twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={twitterIcon}
                                    alt="Follow us on twitter"
                                    className="w-8 h-8"
                                />
                            </a>
                            <a
                                href={linkedInfo.linkedin_link}
                                aria-label="Follow us on linkedin"
                                className="mr-2"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={linkedInIcon}
                                    alt="Follow us on linkedin"
                                    className="w-8 h-8"
                                />
                            </a>
                            <a
                                href={linkedInfo.angellist_link}
                                className=""
                                target="_blank"
                                aria-label="Angel List"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={angelIcon}
                                    alt="Angel List"
                                    className="w-8 h-8"
                                />
                            </a>
                        </div>
                    </div>
                    {!isGerber && (
                        <div
                            className={cx(
                                'legal font-light opacity-50 text-white',
                                {
                                    'xl:hidden': !isPersonalAgent,
                                },
                            )}
                        >
                            {LEGAL}
                        </div>
                    )}
                </div>
                {!isGerber && (
                    <div
                        className={cx(
                            'hidden legal font-light opacity-50 text-white',
                            {
                                'xl:col-span-8 xl:block': !isPersonalAgent,
                            },
                        )}
                    >
                        {LEGAL}
                    </div>
                )}
                <div className="col-span-full my-6 sm:my-12 lg:my-6">
                    <div className="border-0 border-solid border-t border-white border-opacity-50" />
                </div>
                <div className="col-span-full">
                    <div className="flex flex-col lg:flex-row">
                        <div
                            className="flex items-center justify-center lg:justify-start"
                            data-cy="footer.privacy.section"
                        >
                            <Link
                                className="no-underline font-sans text-white font-light opacity-50 text-sm"
                                to={getFooterLinkPath('/terms')}
                                target={getFooterLinkTarget()}
                            >
                                Terms&nbsp;&nbsp;|&nbsp;&nbsp;
                            </Link>
                            <Link
                                className="no-underline font-sans text-white font-light opacity-50 text-sm"
                                to={getFooterLinkPath('/privacy')}
                                target={getFooterLinkTarget()}
                            >
                                Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;
                            </Link>
                            <Link
                                className="no-underline font-sans text-white font-light opacity-50 text-sm"
                                to={getFooterLinkPath('/licenses')}
                                target={getFooterLinkTarget()}
                            >
                                Licenses
                            </Link>
                        </div>
                        <div className="flex flex-1 justify-center my-6 lg:my-0">
                            <img
                                src={accreditationIcon}
                                alt="AM Best, BBB, Comodo Secure"
                                className="h-8"
                            />
                        </div>
                        <div className="font-sans text-white font-light opacity-50 text-sm flex items-center justify-center lg:justify-start">
                            © 2022 Bestow. All rights reserved.
                        </div>
                    </div>
                </div>
            </Grid>
        </PageSection>
    );
};

export default Footer;
