import React from 'react';

import { Props } from '../../index';
import SecondaryButton from "../";

import './index.scss';

const SecondaryButtonSmall: React.FC<Props> = ({ className = "", ...otherProps }) => {
    className = `secondary-btn-small ${className}`;

    return (
        <SecondaryButton className={className} {...otherProps} />
    )
};

export default SecondaryButtonSmall;