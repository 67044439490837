import React from 'react';
import Select from '../';
import { countryOptions } from './countryOptions';

import { Props } from '../';

/**
 * Country select with options that are specific to what was
 * on the old hubspot embed claims form
 * Reusing for salesforce
 */
const HubspotCountrySelect: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = ({ options, ...otherProps }) => {
    return <Select options={countryOptions} {...otherProps} />;
};

export default HubspotCountrySelect;
