import React from 'react';

import Heading, { Props as HeadingProps } from '../';

import './index.scss';

export interface Props extends HeadingProps {
    className?: string
}

const H1: React.FC<Props> = ({children, className = "", ...otherProps}) => {
  return (
    <Heading className={`brand-h1 ${className}`} {...otherProps}>
        {children}
    </Heading>
  );
};

export default H1;
