import Grid from '../../../components/_common/grid';
import PageSection from '../../../components/_common/section/page';
import BodyText from '../../../components/_common/text/body-text';
import H4Serif from '../../../components/_common/text/heading/h4/serif';
import MediumText from '../../../components/_common/text/mediumText';
import './index.scss';

const CostsGuaranteed: React.FC = () => (
    <PageSection className="lg:mx-28 xs:pt-12 sm:pt-24">
        <Grid>
            <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10 mb-12">
                <H4Serif className="text-center text-navy sm:text-gerberh2 xs:text-2xl">
                    Costs of guaranteed-issue life insurance
                </H4Serif>
            </div>
        </Grid>
        <div className="cost-chart mx-auto">
            <Grid
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                gap={0}
                smGap={0}
                lgGap={0}
                xlGap={0}
                className="bg-navy gerber-costs text-base gerber-table-row"
            >
                <div className="col-span-1 text-center px-2 flex justify-center border border-solid border-white py-2 text-white">
                    <BodyText className="sm:text-base xs:text-xs text-white flex items-center">
                        Coverage Amounts
                    </BodyText>
                </div>
                <div className="col-span-1 text-center px-2 flex justify-center border border-solid border-white py-2">
                    <BodyText className="sm:text-base xs:text-xs text-white flex items-center">
                        60 Year Old Man
                    </BodyText>
                </div>
                <div className="col-span-1 text-center px-2 flex justify-center border border-solid border-white py-2">
                    <BodyText className="sm:text-base xs:text-xs text-white flex items-center">
                        70 Year Old Man
                    </BodyText>
                </div>
            </Grid>
            <Grid
                gap={0}
                smGap={0}
                lgGap={0}
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                xlGap={0}
                className="bg-navy-tint3 gerber-table-row"
            >
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $5,000
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $32.40/mo
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $50.05/mo
                    </MediumText>
                </div>
            </Grid>
            <Grid
                gap={0}
                smGap={0}
                lgGap={0}
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                xlGap={0}
                className="bg-navy-tint4 gerber-table-row"
            >
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $10,000
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $63.89/mo
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $99.18/mo
                    </MediumText>
                </div>
            </Grid>
            <Grid
                gap={0}
                smGap={0}
                lgGap={0}
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                xlGap={0}
                className="bg-navy-tint5 gerber-table-row"
            >
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $25,000
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $158.36/mo
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $246.59/mo
                    </MediumText>
                </div>
            </Grid>
        </div>
        <div className="cost-chart sm:mt-16 xs:mt-6 mx-auto">
            <Grid
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                gap={0}
                smGap={0}
                lgGap={0}
                xlGap={0}
                className="bg-coral gerber-costs text-base gerber-table-row"
            >
                <div className="col-span-1 text-center px-2  flex justify-center border border-solid border-white py-2">
                    <BodyText className="sm:text-base xs:text-xs text-charcoal flex items-center">
                        Coverage Amounts
                    </BodyText>
                </div>
                <div className="col-span-1 text-center px-2  flex justify-center border border-solid border-white py-2">
                    <BodyText className="sm:text-base xs:text-xs text-charcoal flex items-center">
                        60 Year Old Woman
                    </BodyText>
                </div>
                <div className="col-span-1 text-center px-2  flex justify-center border border-solid border-white py-2">
                    <BodyText className="sm:text-base xs:text-xs text-charcoal flex items-center">
                        70 Year Old Woman
                    </BodyText>
                </div>
            </Grid>
            <Grid
                gap={0}
                smGap={0}
                lgGap={0}
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                xlGap={0}
                className="bg-rose-lightest gerber-table-row"
            >
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $5,000
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $25.99/mo
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $38.23/mo
                    </MediumText>
                </div>
            </Grid>
            <Grid
                gap={0}
                smGap={0}
                lgGap={0}
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                xlGap={0}
                className="bg-rose-lighter gerber-table-row"
            >
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $10,000
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $51.06/mo
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $75.53/mo
                    </MediumText>
                </div>
            </Grid>
            <Grid
                gap={0}
                smGap={0}
                lgGap={0}
                cols={3}
                smCols={3}
                lgCols={3}
                xlCols={3}
                xlGap={0}
                className="bg-rose gerber-table-row"
            >
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $25,000
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $126.27/mo
                    </MediumText>
                </div>
                <div className="col-span-1 text-center border border-solid border-white py-2 flex items-center justify-center">
                    <MediumText className="sm:text-base xs:text-xs font-medium text-base">
                        $187.46/mo
                    </MediumText>
                </div>
            </Grid>
            <div className="disclaimer text-charcoal font-light mx-auto mt-12">
                Monthly rates shown include ACH payment discount — up to 8%.
                Limitations and exclusions may apply. The maximum face amount
                available in South Dakota is $15,000.
            </div>
        </div>
    </PageSection>
);

export default CostsGuaranteed;
