import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

// This hook pulls down all the LD flags and filters the list into
// a list of experiment flags.  From that list, we pull the experiment key
// and manipulate that key to build an object for each and push it into an array.
// This ultimately creates an array of experiment objects.
export const useGetCurrentExperimentInfo = () => {
    const client = useLDClient();
    return useMemo(() => {
        if (client) {
            const LDExperimentKeys = Object.keys(
                client.allFlags(),
            ).filter(key => key.includes('moe-'));
            return LDExperimentKeys.map(ldkey => {
                return {
                    key: ldkey,
                    campaignName: getCampaignInfo(ldkey, 'name'),
                    campaignId: getCampaignInfo(ldkey, 'id'),
                };
            });
        }
    }, []);
};

export const EXPERIMENT_INFO = {
    id: 'id',
    name: 'name',
};

/**
 * Quick helper to format experiment key into campaignId format.
 * @param {string} ldKey - experiment key pulled from LaunchDarkly
 * @param {keyof typeof EXPERIMENT_INFO} typeOfInfo - type of info to format (name or id)
 * @returns {string} in new format
 * */
const getCampaignInfo = (
    ldKey: string,
    typeOfInfo: keyof typeof EXPERIMENT_INFO,
): string | undefined => {
    if (typeOfInfo === 'id') {
        return ldKey.split('-').splice(0, 2).join('-').toUpperCase();
    } else if (typeOfInfo === 'name') {
        return ldKey
            .split('-')
            .splice(2)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
};
