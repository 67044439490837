import React from 'react';

import H2 from '../index';
import { Props } from '../../';

import './index.scss';

const H2Serif:React.FC<Props> = ({className = "", children, ...otherProps}) => {
    return (
        <H2 className={`h2-serif font-serif ${className}`} {...otherProps}>
            {children}
        </H2>
    )
};

export default H2Serif;