import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    quoteSelector,
    productRatesSelector,
} from '../../../../redux/quote/selectors';
import { clearQuote } from '../../../../redux/quote';
import Decline from './decline';
import LinkButton from '../../../_common/button/link';
import { trackQuoteBackButtonClicked } from '../api/analytics';
import useQueryParamData from '../hooks/useQueryParamsData';
import { QuoteProps } from '../';

import { ReactComponent as BackIcon } from '../../../../images/icons/back-arrow.svg';
import QuoteEstimateExperimentRouter from '../experimentalComponents/components/QuoteEstimateExperimentRouter/QuoteEstimateExperimentRouter';
import {
    bestProductRatesSelectorNoHealthExperiment,
    bestQuoteSelectorNoHealthExperiment,
} from '../experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic/selectors';
import { clearNoHealthQuote } from '../experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic';

const QuoteResults: React.FC<QuoteProps> = ({
    isAgent,
    quoteMobileOptimizationExists = false,
    gerberEligible = false,
}) => {
    const originalQuote = useSelector(quoteSelector);
    const originalRates = useSelector(productRatesSelector);
    const noHealthQuote = useSelector(bestQuoteSelectorNoHealthExperiment); //MOE-404
    const noHealthRates = useSelector(
        bestProductRatesSelectorNoHealthExperiment,
    ); //MOE-404
    const dispatch = useDispatch();
    const queryParamData = useQueryParamData();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const { moe404NoHealthQuote } = useFlags();
    const quote = moe404NoHealthQuote ? noHealthQuote : originalQuote; //MOE-404
    const rates = moe404NoHealthQuote ? noHealthRates : originalRates; //MOE-404
    return (
        <div>
            {!queryParamData.skipform && (
                <LinkButton
                    disableBorder
                    data-testid="quote-back-btn"
                    onClick={() => {
                        dispatch(
                            moe404NoHealthQuote
                                ? clearNoHealthQuote()
                                : clearQuote(),
                        );
                        trackQuoteBackButtonClicked();
                    }}
                >
                    <BackIcon className="mr-2 w-3 h-3" />
                    Back
                </LinkButton>
            )}
            <div>
                {!quote?.ineligible && Object.keys(rates)?.length ? (
                    <QuoteEstimateExperimentRouter // MOE-335 && MOE-404
                        quoteMobileOptimizationExists={
                            quoteMobileOptimizationExists
                        }
                    />
                ) : (
                    <Decline
                        isAgent={isAgent}
                        gerberEligible={gerberEligible}
                    />
                )}
            </div>
        </div>
    );
};

export default QuoteResults;
