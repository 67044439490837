export const FOOTER_LINK_CATEGORIES = [
    {
        title: 'Term Life',
        links: [
            { to: '/no-exam-life-insurance', text: 'No Exam Life Insurance' },
            { to: '/file-a-claim', text: 'Claims' },
        ],
    },
    {
        title: 'Resources',
        links: [
            { to: '/insurance-101', text: 'Insurance 101' },
            { to: '/learningcenter', text: 'Learning Center' },
            { to: '/customer-reviews', text: 'Reviews' },
            { to: '/insurance-101/#faq-section', text: 'FAQ' },
        ],
    },
    {
        title: 'Partnerships',
        links: [
            { to: '/affiliates', text: 'Affiliates' },
            { to: '/carriers', text: 'Carriers' },
            { to: '/agents', text: 'Agents' },
            { to: '/protect', text: 'Protect API' },
            // { to: '/refer', text: 'Referrals' },
        ],
    },
    {
        title: 'Company',
        links: [
            { to: '/about', text: 'About Us' },
            { to: '/foundation', text: 'Foundation' },
            { to: '/careers', text: 'Careers' },
        ],
    },
];

export const LEGAL = `
    Life insurance quotes provided by Bestow Agency, LLC dba Bestow Insurance Services in CA, who is 
    the licensed agent. Term Life Insurance Policies offered by Bestow are issued on policy form LS181 and LS182, or state 
    version including all applicable endorsements and riders, by North American Company for Life and Health Insurance®, 
    Administrative Office, One Sammons Plaza, Sioux Falls, SD 57193. Products or issue ages may not be available in all 
    jurisdictions. Limitations or restrictions may apply. Not available in New York. North American is rated A+ (Superior) 
    by A.M. Best. A+ (Superior), the second highest rating out of 15 categories, was affirmed by A.M. Best for North American 
    Company for Life and Health Insurance as part of Sammons Financial Group on July 30, 2021. A.M. Best is a large third 
    party independent reporting and rating company that rates an insurance company on the basis of the company’s financial 
    strength, operating performance and ability to meet its ongoing obligations to policyholders. Our application asks about 
    your lifestyle and health to determine eligibility in order to avoid requiring a medical exam.
`;
