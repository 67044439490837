import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../utils';
import {
    determineUrlType,
    getConsumedCTSQuote,
    getProductsAndOptions,
    getQuote,
    getQuotePartner,
    getUspsCityState,
    fetchAgentCodeForURLShortID,
} from '../../components/quote/new/api';
import { isMultiStateZipCode } from '../../components/quote/new/form';
import {
    trackIndicativeQuoteGenerated,
    trackQuoteGetQuoteClicked,
} from '../../components/quote/new/api/analytics';
import { getTrackQuoteGetQuoteClickedPayload } from '../../components/quote/new/utils/quoteUtils';

const SLICE_NAME = 'quote';
const INITIAL_STATE = getInitialState(SLICE_NAME, {
    loading: false,
    quoteForm: null,
    quote: null,
    ctsQuote: null,
    ctsQuoteLoading: false,
    defaultProduct: null,
    productRates: null,
    quotePartner: null,
});

const quoteSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        quoteSuccess(state, action) {
            state.loading = false;
            state.quote = action.payload.quote;
            state.defaultProduct = action.payload.defaultProduct;
            state.productRates = action.payload.productRates;
        },
        clearQuote(state) {
            state.loading = false;
            state.quote = state.defaultProduct = state.productRates = null;
        },
        quotePartnerSuccess(state, action) {
            state.quotePartner = action.payload;
        },
        saveQuoteForm(state, action) {
            state.quoteForm = action.payload;
        },
        ctsQuoteLoading(state, action) {
            state.ctsQuoteLoading = action.payload;
        },
        ctsQuoteSuccess(state, action) {
            state.ctsQuoteLoading = false;
            state.ctsQuote = action.payload;
        },
    },
});

export default quoteSlice.reducer;

/**
 * Actions
 */

export const {
    loading,
    quoteSuccess,
    clearQuote,
    quotePartnerSuccess,
    saveQuoteForm,
    ctsQuoteLoading,
    ctsQuoteSuccess,
} = quoteSlice.actions;

/**
 */
export const loadQuote = (formData, setFieldError) => async (
    dispatch,
    getState,
    { wpApi },
) => {
    dispatch(loading(true));
    dispatch(saveQuoteForm(formData));

    let stateCode = formData.stateCode;

    // We need to lookup the user's State based on their zip, as long as it's not a multi-state Zipcode
    if (formData.zipCode && !isMultiStateZipCode(formData.zipCode)) {
        // First, transform the user's zip to a State code
        const stateResponse = await getUspsCityState(formData.zipCode);

        if (!stateResponse?.state) {
            setFieldError('zipCode', 'Invalid zip code.');
            return dispatch(loading(false));
        }

        stateCode = stateResponse.state;
    }

    // If there is a short_url_id, we need to convert to agent code before making the request
    let agentCode = formData.bestow_writing_agent;
    if (formData.short_url_id) {
        agentCode = await fetchAgentCodeForURLShortID(formData.short_url_id);
    }

    // TODO: Refactor this, ugh
    const { payload, type } = determineUrlType({
        ...formData,
        state: stateCode,
        bestow_writing_agent: agentCode,
    });

    // Ideally this would get logged on the button click, but we need to refactor the payload transformation up a layer to do that
    trackQuoteGetQuoteClicked(
        getTrackQuoteGetQuoteClickedPayload(formData, payload),
    );

    return getQuote(payload, type)
        .then(quoteResponse => {
            const { data: quote, payload } = quoteResponse;

            const { defaultProduct, productRates } = getProductsAndOptions(
                quote,
            );

            // Dispatch all the data to the store
            dispatch(quoteSuccess({ quote, defaultProduct, productRates }));

            // Track quote generated
            trackIndicativeQuoteGenerated(
                formData,
                payload,
                quote,
                productRates,
            );
        })
        .catch(error => {
            console.error(error);
            dispatch(loading(false));
        });
};

export const loadQuotePartner = quoteId => (dispatch, getState, { wpApi }) => {
    return getQuotePartner(quoteId)
        .then(partnerResponse => {
            dispatch(quotePartnerSuccess(partnerResponse));
        })
        .catch(err => {
            // TODO: How is this error handled now?
            console.error(err);
        });
};

export const loadCtsQuote = qid => (dispatch, getState, { wpApi }) => {
    dispatch(ctsQuoteLoading(true));
    return getConsumedCTSQuote(qid)
        .then(ctsQuoteResponse => {
            dispatch(ctsQuoteSuccess(ctsQuoteResponse));
        })
        .catch(err => {
            // Fallback to blank form if CTS quote fetch fails
            dispatch(ctsQuoteLoading(false));
            console.error(err);
        });
};
