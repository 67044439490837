/**
 * Quote widget analytics
 */

export const trackQuoteWidgetVisited = (initialData: any = {}) => {
    _getAnalytics()?.track('quote_widget_visited', initialData);
};

export const trackStateSelect = (state: string) => {
    _getAnalytics()?.track('state_select', state);
};

export const trackCoverageSelect = (coverageAmount: string) => {
    _getAnalytics()?.track('coverage_select', coverageAmount);
};

export const trackProductSelect = (product: string) => {
    _getAnalytics()?.track('product_select', product);
};

export const trackSmokerStatus = (isSmoker: boolean) => {
    // KBM - Not sure I understand this one, but this is how it was in AQX...
    _getAnalytics()?.track('smoker_status', !isSmoker);
};

export const trackQuoteBackButtonClicked = () => {
    _getAnalytics()?.track('quote_back_button_clicked');
};

// Customer clicks on the button to start an application.
export const trackQuoteToStartApplication = (
    coverageAmount: string,
    product: string,
    price: string,
    quoteId: string,
) => {
    _getAnalytics()?.track('quote_to_start_application', {
        coverageAmount,
        product,
        price,
        quoteId,
    });
};

// Tracks a returned quote.
export const trackIndicativeQuoteGenerated = (
    quoteForm: any,
    quotePayload: any,
    quote: any,
    rates: any,
) => {
    // This is not ideal, why are we tracking slightly different data here than in other similar methods?
    const { bestow_writing_agent } = quoteForm;
    const { quote_id } = quote;
    const eligible =
        !quote?.ineligible && Boolean(Object.keys(rates || {}).length);

    _getAnalytics()?.track('indicative_quote_generated', {
        ...quotePayload,
        bestow_writing_agent,
        eligible,
        quote_id,
    });
};

// Tracks customer clicking button to request a quote.
export const trackQuoteGetQuoteClicked = (payload: any) => {
    _getAnalytics()?.track('quote_get_quote_clicked', payload);
};

export const trackDeclinePageView = (partnerData: any, quote_id: string) => {
    // TODO: KBM - this was misspelled in AQX... Leave this or fix it?
    _getAnalytics()?.track('decline_page_viewe', {
        ...(partnerData || {}),
        quote_id,
    });
};

function _getAnalytics() {
    // @ts-ignore
    return window.analytics;
}

// Analytics team wants us to track button clicks (i.e. not the get quote clicked fired with the payload)
// This tracks CTA button in nav
export const trackQuoteCTAClicked = () => {
    _getAnalytics()?.track('get_quote_clicked_sticky');
};
