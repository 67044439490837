import {createSelector} from "@reduxjs/toolkit";

const postMapSelector = state => state.posts.postMap;
export const postSelector = postId => state => state.posts.postMap[postId];

export const allPostsSelector = createSelector(
    postMapSelector,
    (postMap) => {
        return Object.values(postMap)
    }
);