import React from 'react';
import Eyebrow from "../../../components/_common/text/eyebrow";
import H3Serif from "../../../components/_common/text/heading/h3/serif";
import {format} from "date-fns";
import CardRound from "../../../components/_common/card/round";
import SmallText from "../../../components/_common/text/small-text/index";

interface Props {
    featuredImage?: string,
    title: string,
    author: string,
    timeToRead?: string,
    date: Date,
    isLearningCenter?: boolean
}

const BlogPostTitleCard: React.FC<Props> = ({ title, featuredImage, author, date, timeToRead, isLearningCenter }) => {
    return (
        <CardRound className={`${featuredImage ? "-mt-10 lg:-mt-20" : "mt-8 sm:mt-12 lg:mt-16"} px-4 py-6 sm:p-8 mx-4 sm:mx-8 lg:mx-0 col-span-full sm:col-span-6 sm:col-start-2 lg:col-start-2 lg:col-span-10 z-10 bg-off-white text-center`}>
            <Eyebrow className="mb-2 sm:mb-4">
                {isLearningCenter ? "FROM THE LEARNING CENTER" : "BLOG POST"}
            </Eyebrow>
            <H3Serif dangerouslySetInnerHTML={{__html: title}} />
            <div className="flex flex-col sm:flex-row justify-center mt-4 sm:mt-8">
                <SmallText>
                    by {author} | {format(date, "MMMM dd, yyyy")}
                </SmallText>
                {timeToRead &&
                    <SmallText className="mt-2 sm:mt-0 sm:ml-10">
                        {timeToRead} Minute Read
                    </SmallText>
                }
            </div>

        </CardRound>
    )
};

export default BlogPostTitleCard;

