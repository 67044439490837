import React from 'react';
import Slider from 'react-slick';

import Eyebrow from '../../../components/_common/text/eyebrow';
import Grid from '../../../components/_common/grid';
import useCategoryPostsFilter from "../../../hooks/useCategoryPostsFilter";
import FeaturedPost from './featured-post';
import {isPreRendering} from "../../../utilities/reactSnap";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

interface Props {
    isLearningCenter?: boolean
}

const FeaturedPosts: React.FC<Props> = ({ isLearningCenter }) => {
    const featuredPosts = useCategoryPostsFilter("featured", isLearningCenter).slice(1, 4);
    const slickSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        arrows: false,
        autoplay: !isPreRendering(),
        autoplaySpeed: 5000,
        speed: 500,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: '16px',
    };

    return (
        <div className="blog-landing-featured-posts">
            <Eyebrow className="text-center mb-12 sm:mb-10 lg:mb-12 sm:font-normal">
                FEATURED POSTS
            </Eyebrow>

            {/* tablet/desktop */}
            <div className="hidden sm:block">
                <Grid smCols={3} lgCols={3}>
                    {featuredPosts.map(post => (
                        <FeaturedPost
                            title={post.title.rendered}
                            excerpt={post.excerpt.rendered}
                            postSlug={post.slug}
                            imageUrl={post._embedded[`wp:featuredmedia`]?.[0].media_details.sizes['image-tablet'].source_url}
                            isLearningCenter={isLearningCenter}
                        />
                    ))}
                </Grid>
            </div>

            {/* Mobile */}
            <div className="sm:hidden mb-6">
                <Slider {...slickSettings}>
                    {featuredPosts.map((post, idx) => (
                        <FeaturedPost
                            key={`post-${idx}`}
                            title={post.title.rendered}
                            excerpt={post.excerpt.rendered}
                            postSlug={post.slug}
                            imageUrl={post._embedded[`wp:featuredmedia`]?.[0].media_details?.sizes['image-mobile'].source_url}
                            isLearningCenter={isLearningCenter}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default FeaturedPosts;
