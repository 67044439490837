import React from 'react';
import { Link } from 'react-router-dom';

import BodyText from '../../components/_common/text/body-text';
import SmallText from '../../components/_common/text/small-text/index';
import PageContent from '../../components/_common/pageContent/navy';
import PageSection from "../../components/_common/section/page";
import LicenseCard from './card';
import H1Serif from "../../components/_common/text/heading/h1/serif";
import H4Serif from "../../components/_common/text/heading/h4/serif";
import Grid from "../../components/_common/grid";
import useIsPersonalAgent from "../../hooks/useIsPersonalAgent";

// styles
import './index.scss';

// data
import { licensesData } from './data';

const LicensesPage: React.FC = () => {
    const isAgentPage = useIsPersonalAgent();

    return (
        <PageContent className="licenses-page" showTopNav={!isAgentPage} includeBottomPadding>
            <PageSection className="pt-8 sm:pt-9 lg:pt-16">
                <H1Serif className="mb-8 sm:mb-12 lg:mb-16 text-center sm:text-left lg:mt-0">
                    Licenses
                </H1Serif>
                <Grid>
                    <BodyText className="col-span-full lg:col-start-2 lg:col-span-7 text-center sm:text-left">
                        At Bestow, we are committed to comply with all laws and regulations in life insurance.
                        Here are the licenses for Bestow Agency, LLC as a Producer and Third Party Administrator.
                    </BodyText>
                    <BodyText className="col-span-full lg:col-span-3 text-center sm:text-left border-gray-400 lg:border-solid lg:border-0 lg:border-l lg:pl-6">
                        If you have any concerns, reach out to us at
                        <a className="ml-1 font-medium text-charcoal" href="mailto:legal@bestow.com">legal@bestow.com</a>
                    </BodyText>
                </Grid>
                <div className="flex justify-center">
                    <H4Serif className="max-w-484 mt-11 sm:mt-14 mb-8 text-center">
                        Producer and Third Party Administrator Licenses
                    </H4Serif>
                </div>
                <div className="flex flex-col items-center">
                    <BodyText className="text-center max-w-680 mb-6">
                        A producer license means we can help you buy life
                        insurance.
                    </BodyText>
                    <BodyText className="mb-6 text-center max-w-680">
                        A third party administrator license means we can help you to make changes to your policy,
                        file a claim, or anything else you need after you buy a policy.
                    </BodyText>
                    <BodyText className="mb-16 lg:mb-16 text-center max-w-680">
                        Not all states require both licenses.
                    </BodyText>
                </div>
                <Grid smCols={3}>
                    {licensesData.map((license) => (
                        <LicenseCard
                            key={license.state}
                            className="col-span-2 sm:col-span-1 lg:col-span-3"
                            data={license}
                        />
                    ))}
                </Grid>
                <div className="flex justify-center sm:justify-start">
                    <SmallText className="text-center sm:text-left mt-12 mb-12 max-w-226 text-charcoal">
                        Our software includes third-party code,&nbsp;
                        <Link to="/third-party-code" className="text-charcoal">
                            <SmallText inline className="font-medium text-charcoal">
                                listed here
                            </SmallText>
                        </Link>.
                    </SmallText>
                </div>
            </PageSection>
        </PageContent>
    )
};

export default LicensesPage;
