export const licensesData = [
  {
    state: 'Alabama',
    status: 'Active',
    producerLicense: '784857',
    tpaLicense: 'Not required'
  },
  {
    state: 'Alaska',
    status: 'Active',
    producerLicense: '100143734',
    tpaLicense: '100143734'
  },
  {
    state: 'Arizona',
    status: 'Active',
    producerLicense: '1800016396',
    tpaLicense: '501963086'
  },
  {
    state: 'Arkansas',
    status: 'Active',
    producerLicense: '3000081138',
    tpaLicense: 'Not required'
  },
  {
    state: 'California',
    status: 'Active',
    producerLicense: 'OL75933',
    tpaLicense: 'CL75933'
  },
  {
    state: 'Colorado',
    status: 'Active',
    producerLicense: '533016',
    tpaLicense: 'Not required'
  },
  {
    state: 'Connecticut',
    status: 'Active',
    producerLicense: '2553482',
    tpaLicense: '2569287'
  },
  {
    state: 'Delaware',
    status: 'Active',
    producerLicense: '3000081097',
    tpaLicense: '502141328'
  },
  {
    state: 'District of Columbia',
    status: 'Active',
    producerLicense: '3000081139',
    tpaLicense: 'Not required'
  },
  {
    state: 'Florida',
    status: 'Active',
    producerLicense: 'L099437',
    tpaLicense: '35640'
  },
  {
    state: 'Georgia',
    status: 'Active',
    producerLicense: '194337',
    tpaLicense: '197222'
  },
  {
    state: 'Hawaii',
    status: 'Active',
    producerLicense: '451556',
    tpaLicense: 'Not required'
  },
  {
    state: 'Idaho',
    status: 'Active',
    producerLicense: '610634',
    tpaLicense: '659281'
  },
  {
    state: 'Illinois',
    status: 'Active',
    producerLicense: '3000081095',
    tpaLicense: '3000081095'
  },
  {
    state: 'Indiana',
    status: 'Active',
    producerLicense: '3228964',
    tpaLicense: '3292890'
  },
  {
    state: 'Iowa',
    status: 'Active',
    producerLicense: '1002288969',
    tpaLicense: '1002296098'
  },
  {
    state: 'Kansas',
    status: 'Active',
    producerLicense: '364859427-0',
    tpaLicense: '902888'
  },
  {
    state: 'Kentucky',
    status: 'Active',
    producerLicense: 'DOI-949030',
    tpaLicense: 'DOI-949030'
  },
  {
    state: 'Louisiana',
    status: 'Active',
    producerLicense: '726591',
    tpaLicense: 'Approved'
  },
  {
    state: 'Maine',
    status: 'Active',
    producerLicense: 'AGN284506',
    tpaLicense: 'TAF291882'
  },
  {
    state: 'Maryland',
    status: 'Active',
    producerLicense: '3000081143',
    tpaLicense: '3000081143'
  },
  {
    state: 'Massachusetts',
    status: 'Active',
    producerLicense: '2034897',
    tpaLicense: 'Not required'
  },
  {
    state: 'Michigan',
    status: 'Active',
    producerLicense: '112244',
    tpaLicense: 'Not required'
  },
  {
    state: 'Minnesota',
    status: 'Active',
    producerLicense: '40517738',
    tpaLicense: 'Not required'
  },
  {
    state: 'Mississippi',
    status: 'Active',
    producerLicense: '15031289',
    tpaLicense: '15031289'
  },
  {
    state: 'Missouri',
    status: 'Active',
    producerLicense: '8405662',
    tpaLicense: 'Approved'
  },
  {
    state: 'Montana',
    status: 'Active',
    producerLicense: '3000081142',
    tpaLicense: 'Approved'
  },
  {
    state: 'Nebraska',
    status: 'Active',
    producerLicense: '100265863',
    tpaLicense: '160005'
  },
  {
    state: 'Nevada',
    status: 'Active',
    producerLicense: '3228914',
    tpaLicense: '3273093'
  },
  {
    state: 'New Hampshire',
    status: 'Active',
    producerLicense: '2365735',
    tpaLicense: 'T3419'
  },
  {
    state: 'New Jersey',
    status: 'Active',
    producerLicense: '1636661',
    tpaLicense: 'Not required'
  },
  {
    state: 'New Mexico',
    status: 'Active',
    producerLicense: '1800012442',
    tpaLicense: '1800012442'
  },
  {
    state: 'North Carolina',
    status: 'Active',
    producerLicense: '1000520422',
    tpaLicense: 'Approved'
  },
  {
    state: 'North Dakota',
    status: 'Active',
    producerLicense: '3000081100',
    tpaLicense: '3000081100'
  },
  {
    state: 'Ohio',
    status: 'Active',
    producerLicense: '1143275',
    tpaLicense: 'Not required'
  },
  {
    state: 'Oklahoma',
    status: 'Active',
    producerLicense: '100289115',
    tpaLicense: '503104723'
  },
  {
    state: 'Oregon',
    status: 'Active',
    producerLicense: '3000084120',
    tpaLicense: '3000084120'
  },
  {
    state: 'Pennsylvania',
    status: 'Active',
    producerLicense: '807828',
    tpaLicense: '820776'
  },
  {
    state: 'Rhode Island',
    status: 'Active',
    producerLicense: '3000081096',
    tpaLicense: '3000081096'
  },
  {
    state: 'South Carolina',
    status: 'Active',
    producerLicense: '1912652634',
    tpaLicense: '212882'
  },
  {
    state: 'South Dakota',
    status: 'Active',
    producerLicense: '10019578',
    tpaLicense: '10019578'
  },
  {
    state: 'Tennessee',
    status: 'Active',
    producerLicense: '2361546',
    tpaLicense: 'Approved'
  },
  {
    state: 'Texas',
    status: 'Active',
    producerLicense: '2173025',
    tpaLicense: '13765572'
  },
  {
    state: 'Utah',
    status: 'Active',
    producerLicense: '610518',
    tpaLicense: '632290'
  },
  {
    state: 'Vermont',
    status: 'Active',
    producerLicense: '3231005',
    tpaLicense: 'Not required'
  },
  {
    state: 'Virginia',
    status: 'Active',
    producerLicense: '141551',
    tpaLicense: 'Not required'
  },
  {
    state: 'Washington',
    status: 'Active',
    producerLicense: '100143734',
    tpaLicense: 'Not required'
  },
  {
    state: 'West Virginia',
    status: 'Active',
    producerLicense: '100143734',
    tpaLicense: '100143734'
  },
  {
    state: 'Wisconsin',
    status: 'Active',
    producerLicense: '3000081149',
    tpaLicense: 'Not required'
  },
  {
    state: 'Wyoming',
    status: 'Active',
    producerLicense: '334669',
    tpaLicense: '346632'
  }
];
