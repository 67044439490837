import React, { useState } from 'react';
import cx from 'classnames';

import PageSection from "../../../components/_common/section/page";
import H2Serif from "../../../components/_common/text/heading/h2/serif";
import H4Serif from "../../../components/_common/text/heading/h4/serif";
import BodyText from "../../../components/_common/text/body-text";
import TabButtonDark from "../../../components/_common/button/tab/dark";

import './index.scss';
import TabButton from "../../../components/_common/button/tab";

interface Props {
    className?: string,
    items: {
        label: string,
        text: string,
        imageClass: string
    }[]
}

const QuoteProtect: React.FC<Props> = ({ className = "", items }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <PageSection className={className}>
            <div className="flex flex-col flex-col-reverse sm:flex-row sm:-mx-9 lg:-mx-6">
                <div className="mt-8 mb-12 sm:hidden">
                    <BodyText className="text-charcoal text-center sm:text-white">
                        {items[selectedIndex].text}
                    </BodyText>
                </div>
                <div className={cx("protect-img sm:flex-1 bg-center bg-cover bg-no-repeat", items[selectedIndex].imageClass)} />
                <div className="flex sm:bg-spruce-darkest flex-1 justify-center -mx-4 sm:mx-0">
                    <div className="pt-16 px-11 sm:pt-8 lg:pt-20">
                        <H2Serif className="text-white text-center hidden lg:block">
                            What are you<br/> looking to protect?
                        </H2Serif>
                        <H4Serif className="text-navy sm:text-white text-center sm:px-4 lg:hidden">
                            What are you<br/> looking to protect?
                        </H4Serif>
                        <div className="hidden sm:flex sm:mt-9 sm:mb-3 lg:mt-20 lg:mb-12 flex-col lg:flex-row justify-center items-center">
                            {items.map((item, index) => (
                                <TabButtonDark
                                    key={`protect-tab-${index}`}
                                    className="mb-5 mx-5"
                                    selected={selectedIndex === index}
                                    onClick={() => setSelectedIndex(index)}>
                                    {item.label}
                                </TabButtonDark>
                            ))}
                        </div>
                        <div className="flex flex-wrap justify-around items-center w-full mt-5 mb-1 sm:hidden">
                            {items.map((item, index) => (
                                <div key={`protect-tab-${index}`} className="mx-4">
                                    <TabButton
                                        className="mb-4"
                                        selected={selectedIndex === index}
                                        onClick={() => setSelectedIndex(index)}>
                                        {item.label}
                                    </TabButton>
                                </div>
                            ))}
                        </div>
                        <BodyText className="text-white text-center hidden sm:block">
                            {items[selectedIndex].text}
                        </BodyText>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default QuoteProtect;