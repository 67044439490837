import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-scroll';
import './index.scss';

const ScrollLink = ({children, scrollTo, className}) => {
    const classes = cx('brand-scroll-link cursor-pointer underline hover:text-coral-darkest', className);

    return (
        <Link to={scrollTo} smooth offset={-120} duration={500}>
            <span className={classes}>{children}</span>
        </Link>
    );
};

ScrollLink.defaultProps = {
    className: '',
};

ScrollLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    scrollTo: PropTypes.string.isRequired, // id of the section to scroll to
};

export default ScrollLink;
