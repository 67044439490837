import React from 'react';

import Card from "../../../components/_common/card";
import InlineVideo from "../../../components/_common/inlineVideo";
import Grid from '../../../components/_common/grid';
import H3Serif from '../../../components/_common/text/heading/h3/serif';
import BodyTextLarge from '../../../components/_common/text/body-text/large';
import PrimaryButton from '../../../components/_common/button/primary';
import PageSection from '../../../components/_common/section/page';

import { smoothScrollToTop } from '../../../utilities/smoothScrollToTop';

interface Props {
    videoDesktop: any,
    videoMobile: any,
}

const TraditionalVsBestow: React.FC<Props> = ({videoDesktop, videoMobile}) => {
    return (
        <PageSection className="bg-off-white py-12 lg:py-16">
            <Grid>
                <div className="col-span-full lg:order-2 lg:col-span-7 mb-8 lg:mb-12">
                    <Card className="-ml-1 -mr-1 sm:-ml-0 sm:-mr-0">
                        <InlineVideo className="w-full hidden sm:block" video={videoDesktop} />
                        <InlineVideo className="w-full sm:hidden" video={videoMobile} />
                    </Card>
                </div>
                <div className="col-span-full lg:col-span-5">
                    <H3Serif className="font-bold mb-8 text-center lg:text-left">
                        Incredibly fast
                    </H3Serif>
                    <BodyTextLarge className="mb-8 text-center lg:text-left">
                        With traditional underwriting, it could take weeks to purchase life insurance. With Bestow, applying
                        takes as little as 5 minutes. Minutes instead of weeks? Yes please.
                    </BodyTextLarge>
                    <div className="flex justify-center lg:justify-start">
                        <PrimaryButton  onClick={smoothScrollToTop}>
                            Get Started Now
                        </PrimaryButton>
                    </div>
                </div>
            </Grid>
        </PageSection>
    )
};

export default TraditionalVsBestow
