import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Subtitle2 from '../../../_common/text/heading/subtitle2';
import CustomFormikField from '../../../_common/formik/customField';
import Checkbox from '../../../_common/inputs/checkbox';
import PrimaryButton from '../../../_common/button/primary';
import DOBInput from '../../../_common/inputs/dob';
import { loadCtsQuote, loadQuote } from '../../../../redux/quote';
import multiStateZipcodes from '../data/multiStateZipcodes.json';
import usStates from '../data/usStates.json';
import { trackSmokerStatus, trackStateSelect } from '../api/analytics';
import useQueryParamData from '../hooks/useQueryParamsData';
import {
    HEIGHT_OPTIONS,
    GENDER_VALUE_FEMALE,
    GENDER_VALUE_MALE,
} from './constants';
import QuotePlaceholder from '../placeholder';
import { QuoteProps } from '../';
import {
    ctsQuoteLoadingSelector,
    ctsQuoteSelector,
    quoteFormSelector,
    quoteLoadingSelector,
} from '../../../../redux/quote/selectors';
import quoteFormSchema from './schema';
import MediumText from '../../../_common/text/mediumText';
import NicotineGroup from '../experimentalComponents/MOE-89-NicotineButtonGroup';
import BodyText from '../../../_common/text/body-text';
import BodyTextBold from '../../../_common/text/body-text/bold';
import InsideLabelInput from '../../../_common/inputs/insideLabel';
import InsideLabelSelect from '../../../_common/inputs/insideLabelSelect';
import { getCurrentPartner } from '../utils/quoteUtils';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { trackLaunchDarklyEventWithFlush } from '../../../../utilities/launchDarkly.track';

const TOBACCO_TOOLTIP =
    'In the past 12 months, have you used cigarettes, E-cigarettes, pipes, vapor products, snuff, chewing tobacco, nicotine gum or nicotine patches?';

const QuoteForm: React.FC<QuoteProps> = ({ agentCode }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const loading = useSelector(quoteLoadingSelector);
    const quoteForm = useSelector(quoteFormSelector);
    const dispatch = useDispatch();
    const queryParamData = useQueryParamData();
    const submitRef = useRef<HTMLButtonElement | null>(null);
    const ctsQuoteLoading = useSelector(ctsQuoteLoadingSelector);
    const ctsQuote = useSelector(ctsQuoteSelector);
    const client = useLDClient();
    const { moe89NicotineQuestionAtQuote } = useFlags();

    // This informs our Validation schema about acceptable height inputs
    const heightValues = useMemo(
        () => HEIGHT_OPTIONS.map(option => option.value),
        [],
    );

    // Handles auto-submit (skipform) and CTS Quotes
    useEffect(() => {
        if (queryParamData.skipform && submitRef?.current) {
            submitRef.current.click();
        }
        if (queryParamData.qid && !ctsQuoteLoading && !ctsQuote) {
            dispatch(loadCtsQuote(queryParamData.qid));
        }
    }, [queryParamData, ctsQuoteLoading, ctsQuote, dispatch]);

    const getStateOptions = (zipCode: string) => {
        const multiStateZip = (multiStateZipcodes as any)[zipCode];
        if (multiStateZip) {
            return multiStateZip.map((state: string) => ({
                label: state,
                value: (usStates as any)[state],
            }));
        }
        return Object.entries(usStates).map(([key, value]) => ({
            label: key,
            value,
        }));
    };

    const showZipField = () => {
        // Show the zip field if there is no stateCode in querystring or if a multistate zipCode was explicitly passed
        return (
            !_isValidStateCode(queryParamData.stateCode) ||
            isMultiStateZipCode(queryParamData.zipCode)
        );
    };

    const showStateField = (zipCode: string) => {
        // Show the state field if the zip supplied is multistate or if a stateCode was supplied via query string
        return (
            isMultiStateZipCode(zipCode) ||
            _isValidStateCode(queryParamData.stateCode)
        );
    };

    return (
        <div data-testid="quote-form">
            <Subtitle2 className="color-blue mb-5 text-center">
                Start with a quote
            </Subtitle2>
            {ctsQuoteLoading ? (
                <QuotePlaceholder />
            ) : (
                <>
                    <Formik
                        initialValues={{
                            gender: '',
                            birthdate: '',
                            height: '',
                            weight: '',
                            zipCode: '',
                            stateCode: '',
                            tobacco: moe89NicotineQuestionAtQuote ? '' : false,
                            // KBM - This is kinda terrible...
                            partner_referral: getCurrentPartner(),
                            // MOE-20, this allows us to pass in a short_url_id from outside of the query params
                            short_url_id: agentCode,
                            ...queryParamData,
                            ...(ctsQuote || {}),
                            ...quoteForm,
                        }}
                        validationSchema={quoteFormSchema(
                            heightValues,
                            queryParamData,
                        )}
                        onSubmit={(values, { setFieldError }) => {
                            dispatch(loadQuote(values, setFieldError));
                            trackLaunchDarklyEventWithFlush(
                                client,
                                'requested_quote',
                            );
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form noValidate>
                                    <div className="mb-5">
                                        <CustomFormikField
                                            name="gender"
                                            className="w-full bg-white"
                                            label="Gender"
                                            data-testid="gender"
                                            CustomComponent={InsideLabelSelect}
                                            options={[
                                                {
                                                    label: 'Male',
                                                    value: GENDER_VALUE_MALE,
                                                },
                                                {
                                                    label: 'Female',
                                                    value: GENDER_VALUE_FEMALE,
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <CustomFormikField
                                            label="Birthdate"
                                            name="birthdate"
                                            className="w-full bg-white shadow-outside-label-input"
                                            type="date"
                                            data-testid="birthdate"
                                            onChange={value =>
                                                setFieldValue(
                                                    'birthdate',
                                                    value,
                                                )
                                            }
                                            CustomComponent={DOBInput}
                                        />
                                    </div>
                                    <div className="mb-5 flex-1">
                                        <CustomFormikField
                                            name="height"
                                            className="w-full bg-white"
                                            label="Height"
                                            data-testid="height"
                                            CustomComponent={InsideLabelSelect}
                                            options={HEIGHT_OPTIONS}
                                        />
                                    </div>
                                    <div className="mb-5 flex-1">
                                        <CustomFormikField
                                            name="weight"
                                            className="w-full bg-white"
                                            type="tel"
                                            CustomComponent={InsideLabelInput}
                                            label="Weight (lbs)"
                                            data-testid="weight"
                                            onChange={e => {
                                                const weight = parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        '',
                                                    ),
                                                    10,
                                                );
                                                // KBM - AQX has biz logic to max the user's weight out at 1000 lbs.
                                                setFieldValue(
                                                    'weight',
                                                    weight
                                                        ? Math.min(
                                                              weight || 0,
                                                              1000,
                                                          )
                                                        : '',
                                                );
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="mb-5 flex flex-col lg:flex-row">
                                        {showZipField() && (
                                            <div className="flex-1">
                                                <CustomFormikField
                                                    name="zipCode"
                                                    className="w-full bg-white"
                                                    type="tel"
                                                    CustomComponent={
                                                        InsideLabelInput
                                                    }
                                                    label="Zip Code"
                                                    data-testid="zipCode"
                                                    autoComplete="off"
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'zipCode',
                                                            e.target.value.replace(
                                                                /[^0-9]/g,
                                                                '',
                                                            ),
                                                        );
                                                        // TODO: Test this
                                                        setFieldValue(
                                                            'stateCode',
                                                            '',
                                                        );
                                                    }}
                                                    maxLength={5}
                                                />
                                            </div>
                                        )}
                                        {showStateField(values.zipCode) &&
                                            showZipField() && (
                                                // We need a divider here, but we don't want to use Classname switches because of pre-render mismatches
                                                <div className="mr-4 hidden lg:block" />
                                            )}
                                        {showStateField(values.zipCode) && (
                                            <div className="flex-1 mt-5 lg:mt-0">
                                                <CustomFormikField
                                                    name="stateCode"
                                                    className="w-full bg-white h-12"
                                                    label="State"
                                                    data-testid="stateCode"
                                                    CustomComponent={
                                                        InsideLabelSelect
                                                    }
                                                    onChange={e => {
                                                        setFieldValue(
                                                            'stateCode',
                                                            e.target.value,
                                                        );
                                                        trackStateSelect(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    options={getStateOptions(
                                                        values.zipCode,
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={'mb-10'}>
                                        {moe89NicotineQuestionAtQuote ? (
                                            <>
                                                <div className="pb-2.5 flex items-center justify-start">
                                                    <MediumText className="pt-1">
                                                        Have you used nicotine
                                                        in the past 12 months?
                                                        <Tooltip
                                                            title={
                                                                <BodyText className="text-white font-regular">
                                                                    {
                                                                        TOBACCO_TOOLTIP
                                                                    }
                                                                </BodyText>
                                                            }
                                                            open={showTooltip}
                                                            arrow
                                                            className="inline-block"
                                                        >
                                                            <div
                                                                className="info rounded-full bg-gray-300 hover:bg-turquoise-30 text-white text-center w-5 h-5 ml-2 cursor-pointer inline-flex items-center justify-center"
                                                                onClick={() =>
                                                                    setShowTooltip(
                                                                        !showTooltip,
                                                                    )
                                                                }
                                                            >
                                                                <BodyTextBold
                                                                    className="font-sans font-bold text-xs text-charcoal pt-0.5"
                                                                    ElementType="span"
                                                                >
                                                                    i
                                                                </BodyTextBold>
                                                            </div>
                                                        </Tooltip>
                                                    </MediumText>
                                                </div>
                                                <CustomFormikField
                                                    name="tobacco"
                                                    className="bg-white"
                                                    CustomComponent={
                                                        NicotineGroup
                                                    }
                                                    data-testid="tobacco"
                                                    onChange={e => {
                                                        const val =
                                                            e.currentTarget
                                                                .value;
                                                        setFieldValue(
                                                            'tobacco',
                                                            val,
                                                        );
                                                        // @ts-ignore
                                                        trackSmokerStatus(val);
                                                    }}
                                                    required
                                                />
                                            </>
                                        ) : (
                                            <CustomFormikField
                                                name="tobacco"
                                                className="bg-white"
                                                CustomComponent={Checkbox}
                                                label="I currently use nicotine products"
                                                data-testid="tobacco"
                                                onChange={e => {
                                                    setFieldValue(
                                                        'tobacco',
                                                        e.target.checked,
                                                    );
                                                    trackSmokerStatus(
                                                        e.target.checked,
                                                    );
                                                }}
                                                checked={values.tobacco}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            className="w-full"
                                            disabled={loading}
                                            ref={submitRef}
                                            type="submit"
                                            data-testid="quote-submit-btn"
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : 'Get a quote!'}
                                        </PrimaryButton>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </div>
    );
};

export const isMultiStateZipCode = (zip: string) =>
    Boolean((multiStateZipcodes as any)[zip]);

// Checks to see if the State code supplied exists in our lookup file
function _isValidStateCode(stateCode: string) {
    return Object.values(usStates).includes(stateCode);
}

export default QuoteForm;
