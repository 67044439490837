import PageSection from '../../../components/_common/section/page';
import BodyText from '../../../components/_common/text/body-text';
import Grid from '../../../components/_common/grid';
import H2Serif from '../../../components/_common/text/heading/h2/serif';
import './index.scss';

const Guaranteed: React.FC = () => (
    <PageSection className="guaranteed-container sm:bg-off-white sm:pb-24 xs:pb-14">
        <H2Serif className="text-center text-primary sm:mb-16 xs:mb-10 sm:text-48 xs:text-32">
            Guaranteed Life Insurance
        </H2Serif>
        <Grid
            smCols={3}
            lgCols={3}
            xlCols={3}
            gap={14}
            smGap={16}
            lgGap={16}
            className="xs:px-0 lg:px-10"
        >
            <div className="col-span-full sm:col-span-1 text-center">
                <h4 className="guaranteed-h4 text-coral-darkest font-bold font-sans mb-4 mt-0 text-2xl">
                    Made with <br /> seniors in mind
                </h4>
                <BodyText className="mb-0">
                    Applicants aged 50 to 80 <br /> are eligible.
                </BodyText>
            </div>
            <div className="col-span-full sm:col-span-1 text-center">
                <h4 className="guaranteed-h4 text-spruce-darkest font-bold font-sans mb-4 mt-0 text-2xl xs:hidden sm:block">
                    Fast <br /> and easy
                </h4>
                <h4 className="guaranteed-h4 text-spruce-darkest font-bold font-sans mb-4 mt-0 text-2xl xs:block sm:hidden">
                    Fast and easy
                </h4>
                <BodyText className="mb-0">
                    Apply in minutes for <br /> guaranteed coverage.
                </BodyText>
            </div>
            <div className="col-span-full sm:col-span-1 text-center">
                <h4 className="guaranteed-h4 text-gold-darkest font-bold font-sans mb-4 mt-0 text-2xl">
                    No health <br /> screenings
                </h4>
                <BodyText className="mb-0">
                    Get coverage with no <br /> medical exams.
                </BodyText>
            </div>
        </Grid>
    </PageSection>
);

export default Guaranteed;
