import { boolean, lazy, object, string } from 'yup';
import { isValid, parse } from 'date-fns';
import { GENDER_VALUE_FEMALE, GENDER_VALUE_MALE } from './constants';
import { DATE_PATTERN } from '../../../../../_common/inputs/dob';
import multiStateZipcodes from '../../../data/multiStateZipcodes.json';
import usStates from '../../../data/usStates.json';

/**
 * Provides the schema object for Formik validation
 * @param queryParamData    An object containing all of the parsed query param data
 */
const quoteFormSchema = (queryParamData: any) => {
    const validator = object().shape({
        gender: string()
            .oneOf(
                [GENDER_VALUE_MALE, GENDER_VALUE_FEMALE],
                'Please select a valid gender.',
            )
            .required('Please select your gender.'),
        // dob comes through as "MM / dd / yyyy", hence length 14
        birthdate: string()
            .test({
                name: 'birthdate-test',
                message: 'Enter a valid date.',
                test: (value: any) => {
                    try {
                        const date = parse(value, DATE_PATTERN, new Date());
                        return isValid(date);
                    } catch (err) {
                        console.error(err);
                    }
                    return false;
                },
            })
            .test({
                name: 'birthyear-test',
                message: 'Please enter a year later than 1920.',
                test: (value: any) => {
                    try {
                        const date = parse(value, DATE_PATTERN, new Date());
                        const year = date.getFullYear();
                        return year >= 1920;
                    } catch (err) {
                        console.error(err);
                    }
                    return false;
                },
            })
            .required('Enter your birthdate.')
            .length(14, 'Enter a valid date.'),
        zipCode: lazy(() => {
            const schema = string();
            if (queryParamData.stateCode) {
                return schema;
            }
            return schema
                .required('Invalid zip code.')
                .length(5, 'Invalid zip code.');
        }),
        stateCode: string().when('zipCode', function (value: string) {
            // If the entered zip is a multi-state zip code, then we need to prompt them to select their state from a list
            const multiStateZip = (multiStateZipcodes as any)[value];
            if (multiStateZip) {
                return string()
                    .oneOf(
                        multiStateZip.map(
                            (state: string) => (usStates as any)[state],
                        ),
                        'Select a valid state of residence.',
                    )
                    .required('Select your state of residence.');
            }
        }),
        tobacco: boolean().required('Please select yes or no.'),
    });

    return validator;
};

export default quoteFormSchema;
