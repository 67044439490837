import React from 'react';
import PageSection from "../../../components/_common/section/page";
import BodyTextLarge from '../../../components/_common/text/body-text/large';
import { NewsweekLogo } from '../../../images/logos/index';

import './index.scss';

const BusinessSlider: React.FC = () => {
    return (
        <PageSection className="bg-off-white business-slider">
            <div className="sm:mx-28">
                <div className="py-14 sm:py-20 text-center">
                    <BodyTextLarge className="mb-12">
                        “While the future may be uncertain, we have the
                        power to protect our family and property at a
                        nominal cost—let’s take it.”
                    </BodyTextLarge>
                    <NewsweekLogo className="w-40 h-auto" />
                </div>
            </div>
        </PageSection>
    );
};

export default BusinessSlider;
