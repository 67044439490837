import React from 'react';
import cx from 'classnames';

import { Props as ButtonProps } from "../../link";
import TabButton from "../index";

// @ts-ignore
import styles from './index.module.scss';

interface Props extends ButtonProps {
    selected?: boolean
}

const TabButtonDark: React.FC<Props> = ({ className, selected, ...otherProps }) => {
    const classes = cx(styles.tabBtnDark, className, {
        [styles.tabBtnDarkSelected]: selected
    });

    return (
        <TabButton
            className={classes}
            contentClass={selected ? styles.selectedText : styles.text}
            indicatorClass={selected ? "indicator-selected" : ""}
            {...otherProps} />
    )
};

export default TabButtonDark;