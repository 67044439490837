import React from 'react';

import PageSection from "../../../components/_common/section/page";
import H3Serif from "../../../components/_common/text/heading/h3/serif";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import BodyText from "../../../components/_common/text/body-text";

const Testimonial: React.FC = () => (
    <PageSection className="bg-rose py-16 sm:py-24 lg:py-32 sm:px-16 lg:px-32 text-center">
        <H3Serif className="text-charcoal mb-10">
            “Life insurance can be a vital way to invest in the future. Bestow
            helps us provide access to inclusive and affordable options for
            our customers, so they can better protect themselves and their loved ones”
        </H3Serif>
        <div className="mb-10">
            <Subtitle2 className="mb-2">
                – Brandon Kreig
            </Subtitle2>
            <BodyText>
                CEO and Co-Founder of Stash
            </BodyText>
        </div>
        <div>
            <span className="text-xs italic font-light">Stash is a paid partner of Bestow</span>
        </div>
    </PageSection>
);

export default Testimonial;
