import React from 'react';

import H4 from '../';
import { Props } from '../../';

const H4Serif:React.FC<Props> = ({className = "", children, ...otherProps}) => {
    return (
        <H4 className={`font-serif ${className}`} {...otherProps}>
            {children}
        </H4>
    )
};

export default H4Serif;