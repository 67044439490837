import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';

import BodyTextBold from "../../../components/_common/text/body-text/bold";
import Quotation from "../../../components/_common/text/quotation";
import SmallText from "../../../components/_common/text/small-text/index";
import CardRound from "../../../components/_common/card/round";
import H2Serif from "../../../components/_common/text/heading/h2/serif";
import Grid from "../../../components/_common/grid";
import EmployeeCard from "./employeeCard";
import {isPreRendering} from "../../../utilities/reactSnap";

import { Number2Icon, AwardIcon } from "../../../images/icons/index";
import { BuiltInATXLogo, CNBCLogo } from "../../../images/logos/index";
import { Forbes } from "../../../images/svgs";

import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CareersSlider: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef<Slider>(null);

    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '30%',
        slidesToScroll: 1,
        accessibility: true,
        arrows: false,
        autoplay: !isPreRendering(),
        autoplaySpeed: 5000,
        speed: 500,
        swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1651,
                settings: {
                    centerPadding: '26%',
                }
            },
            {
                breakpoint: 1401,
                settings: {
                    centerPadding: '22%',
                }
            },
            {
                breakpoint: 1201,
                settings: {
                    centerPadding: '20%',
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: '16%',
                }
            },
            {
                breakpoint: 851,
                settings: {
                    centerPadding: '12%',
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '0%',
                    slidesToShow: 1,
                },
            },
        ],
        beforeChange: (oldIndex: number, newIndex: number) => {
            setCurrentSlide(newIndex);
        }
    };

    return (
        <div>
            <H2Serif className="text-navy text-center mb-8 sm:mt-20 sm:mb-12 lg:mt-32 lg:mb-14">
                Life at Bestow
            </H2Serif>
            <Slider {...slickSettings} ref={sliderRef}>
                <div className="careers-slider-item lg:pl-6">
                    <Grid className="mx-4 sm:mx-6">
                        <div className="row-start-1 row-span-2 col-start-1 bg-coral col-span-full sm:row-start-1 sm:row-span-1 sm:col-start-1 sm:col-span-6 lg:col-start-1 lg:col-span-9 award-card-bg mt-4 sm:mt-0 sm:h-full" />
                        <div className="row-start-2 sm:row-start-1 col-start-1 col-span-full sm:col-start-1 sm:col-span-4 lg:col-start-1 lg:col-span-6 flex flex-col items-center sm:justify-center award-card">
                            <div className="flex items-center sm:mb-6 lg:mb-7">
                                <AwardIcon />
                                <BodyTextBold className="ml-3 mb-0 hidden lg:block">
                                    Award
                                </BodyTextBold>
                                <SmallText className="ml-3 mb-0 font-medium lg:hidden">
                                    Award
                                </SmallText>
                            </div>
                            <Number2Icon className="hidden sm:block mb-6"/>
                            <Quotation className="text-center mt-6 mb-5 text-charcoal sm:hidden">
                                #2 Best StartUp<br/>Employers2021
                            </Quotation>
                            <Quotation className="hidden sm:block text-center mb-4 sm:mb-7 text-charcoal">
                                Best StartUp <br className="lg:hidden"/>Employers<br className="hidden lg:block"/>2021
                            </Quotation>
                            <Forbes className="h-6 w-20 sm:h-9 sm:w-28" />
                        </div>
                        <CardRound className="row-start-1 col-span-full sm:col-start-5 sm:col-span-4 lg:col-start-7 lg:col-span-5 h-44 sm:h-auto bg-center bg-cover bg-careers-main-candid mx-4 sm:mb-0 sm:mx-0 sm:mt-7 sm:-mb-7" />
                    </Grid>
                </div>
                <div className="careers-slider-item">
                    <EmployeeCard
                        className="bg-spruce"
                        imageClassName="bg-careers-main-sylvia-headshot"
                        quote="There are so many interesting problems to solve. Our Engineers and Product Managers are
                                constantly seeking to move the needle to open up new market opportunities."
                        employeeName="Sylvia Cento"
                        employeeTitle={<>Director,<br className="sm:hidden"/> Technical Program Management</>} />
                </div>
                <div className="careers-slider-item relative lg:pl-6">
                    <Grid className="mx-4 sm:mx-6">
                        <div className="row-start-1 row-span-2 col-start-1 bg-mint col-span-full sm:row-start-1 sm:row-span-1 sm:col-start-1 sm:col-span-6 lg:col-start-1 lg:col-span-9 mt-4 sm:mt-0 h-full sm:mt-7 sm:-mb-7 award-card-bg" />
                        <div className="row-start-2 col-start-1 col-span-full sm:row-start-1 sm:col-start-1 sm:col-span-4 lg:col-start-1 lg:col-span-6 flex flex-col items-center sm:justify-center award-card transform sm:translate-y-7">
                            <div className="flex items-center sm:mb-6 lg:mb-7">
                                <AwardIcon />
                                <BodyTextBold className="ml-3 mb-0 hidden lg:block">
                                    Award
                                </BodyTextBold>
                                <SmallText className="ml-3 mb-0 font-medium lg:hidden">
                                    Award
                                </SmallText>
                            </div>
                            <Quotation className="text-center mt-6 mb-5 text-charcoal sm:hidden">
                                Best small companies to<br/> work for in 2020.
                            </Quotation>
                            <Quotation className="hidden sm:block text-center mb-4 sm:mb-7 text-charcoal">
                                Best small companies<br/> to work for in 2020.
                            </Quotation>
                            <BuiltInATXLogo className="h-8 w-40 sm:h-12 sm:w-32" />
                        </div>
                        <CardRound className="row-start-1 col-span-full sm:col-start-5 sm:col-span-4 lg:col-start-7 lg:col-span-5 h-44 sm:h-auto mx-4 sm:mx-0 bg-center bg-cover bg-careers-main-bestow-office-2" />
                    </Grid>
                </div>
                <div className="careers-slider-item">
                    <EmployeeCard
                        className="bg-champagne"
                        imageClassName="bg-careers-main-desiree-headshot"
                        quote="It truly is an egoless environment where we all work towards the common goal of doing
                                what is right for the business, our clients, and one another."
                        employeeName="Desiree Belmarez"
                        employeeTitle="Underwriter" />
                </div>
                <div className="careers-slider-item relative lg:pl-6">
                    <Grid className="mx-4 sm:mx-6">
                        <div className="row-start-1 row-span-2 col-start-1 bg-coral col-span-full sm:row-start-1 sm:row-span-1 sm:col-start-1 sm:col-span-6 lg:col-start-1 lg:col-span-9 h-full mt-4 sm:mt-7 sm:-mb-7 award-card-bg" />
                        <div className="row-start-2 col-start-1 col-span-full sm:row-start-1 sm:col-start-1 sm:col-span-4 lg:col-start-1 lg:col-span-5 flex flex-col items-center sm:justify-center award-card transform sm:translate-y-7">
                            <div className="flex items-center sm:mb-6 lg:mb-7">
                                <AwardIcon />
                                <BodyTextBold className="ml-3 mb-0 hidden lg:block">
                                    Award
                                </BodyTextBold>
                                <SmallText className="ml-3 mb-0 font-medium lg:hidden">
                                    Award
                                </SmallText>
                            </div>
                            <Quotation className="text-center mt-6 mb-5 sm:mt-4 sm:mb-12 text-charcoal">
                                2021 CNBC<br/>Disruptor 50
                            </Quotation>
                            <CNBCLogo className="h-8" />
                        </div>
                        <CardRound className="row-start-1 col-span-full sm:col-start-5 sm:col-span-4 lg:col-start-6 lg:col-span-6 bg-center bg-cover bg-careers-main-bestow-office-dallas h-44 sm:h-auto mx-4 sm:mx-0" />
                    </Grid>
                </div>
                <div className="careers-slider-item">
                    <EmployeeCard
                        className="bg-navy-tint2"
                        imageClassName="bg-careers-main-jack-l-headshot"
                        quote="Colleagues within each org display our company principle of ‘customer-obsessed.’
                                At Bestow, ‘thinking like a customer’ has multiple meanings - whether we’re building
                                tools for our carrier partners, our distribution partners, or our actual policyholders."
                        employeeName="Jack Lazarus"
                        employeeTitle="Senior Product Manager" />
                </div>
            </Slider>
            <div className="flex justify-center mt-8 mb-10 sm:mt-8 lg:mt-14">
                {["1", "2", "3", "4", "5", "6"].map((number, index) => (
                    <div className={cx("transition-all careers-slider-indicator border border-solid font-sans font-light flex justify-center items-center h-9 w-9 mx-1 sm:mx-4", {
                        "careers-slider-indicator-active": index === currentSlide
                    })} onClick={() => sliderRef.current?.slickGoTo(index)}>
                        {number}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default CareersSlider;