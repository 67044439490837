import React from 'react';

import BodyText, { Props } from "../index";

const BodyTextBold: React.FC<Props> = ({ className, ...otherProps }) => (
    <BodyText className={`font-medium ${className}`} {...otherProps} />
);


export default BodyTextBold;
