import React, { useEffect, useRef } from 'react';

import H2Serif from "../../text/heading/h2/serif";
import Card from "../../card";

import './index.scss';
import useScript from '../../../../hooks/useScript';

export interface Props {
    locale?: string,
    templateId?: string,
    businessunitId?: string,
    styleHeight?: string,
    stars?: string,
    reviewLanguages?: string,
    selectedReview?: boolean
}

const TrustPilot: React.FC<Props> = ({
    locale = 'en-US',
    templateId = '54ad5defc6454f065c28af8b',
    businessunitId = '5a37f049b894c90dd491312c',
    styleHeight = '240px',
    stars = '1,2,3,4,5',
    reviewLanguages = 'en',
    selectedReview = false
}) => {
    const scriptStatus = useScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    const ref = useRef(null);

    return (
        <div className="trust-pilot-container">
            {scriptStatus === 'ready' &&
                <Card className="pt-4 pb-5 -ml-2 -mr-2 sm:ml-0 sm:mr-0 sm:pr-3 sm:pl-3 bg-white">
                    <div ref={ref}
                        className="trustpilot-widget"
                        data-locale={locale}
                        data-template-id={templateId}
                        data-businessunit-id={businessunitId}
                        data-style-height={styleHeight}
                        data-style-width="100%"
                        data-theme="light"
                        data-stars={stars}
                        data-review-languages={reviewLanguages}
                        data-tags={selectedReview ? "SelectedReview" : ""}
                    >
                        <a href="https://www.trustpilot.com/review/bestow.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div>
                </Card>
            }
        </div>
    )
};

export default TrustPilot;
