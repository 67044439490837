const faqs = [
    {
        id: 1,
        title: {
            rendered: "What is term life insurance?"
        },
        content: {
            rendered: `It’s all in the name. The “term” refers to a specific period of time that you want to be covered. So, if an insurer
                approves you for a 10 year term, you’ll pay a monthly premium and have coverage through the end of the term (10
                years, in this example). If you were to pass away during your term, your beneficiaries would receive a lump sum
                payment of the coverage amount.`
        }
    },
    {
        id: 2,
        title: {
            rendered: "Who should buy life insurance?"
        },
        content: {
            rendered: `Life insurance is a way to help provide financial resilience to your loved ones should you pass away. 
                Remember, if you’re not around, your dependable income isn’t either. The best life insurance candidates are 
                probably people who may not have vast assets to leave a legacy for their family. If that sounds like you, term 
                life insurance could be a helpful tool.`
        }
    },
    {
        id: 3,
        title: {
            rendered: "How much life insurance do I need?"
        },
        content: {
            rendered: `This is a personal choice that depends on a lot of factors. How many people depend on your income? Do 
                you have a mortgage? Will you be funding your child’s college education? Take stock of current and planned 
                expenses and then factor in how many years of income replacement you might want to leave behind to accommodate 
                those expenses. Think of it like providing your family a financial runway should they find themselves without you.`
        }
    },
    {
        id: 4,
        title: {
            rendered: "What are the advantages of term life insurance?"
        },
        content: {
            rendered: `Term life insurance is just one of many coverage options, but it does have advantages. When it 
                comes to finding the best rates, term life insurance offers a typically more affordable alternative to 
                permanent life, which can sometimes cost hundreds of dollars a month. Term life insurance offered by 
                Bestow has a level premium, which means you lock in your rate when you buy your policy, and it stays the 
                same for the entire life of your policy.`
        }
    },
    {
        id: 5,
        title: {
            rendered: "Should I get 20 or 30-year term life insurance?"
        },
        content: {
            rendered: `This depends on your financial obligations. Maybe you just took out a 30-year mortgage. Perhaps 
                you want to help ensure your child is taken care of even if you pass away. Take a good long look at your 
                circumstances and your plan for the future, and that should help you arrive at some answers.`
        }
    },
    {
        id: 6,
        title: {
            rendered: "At what age does term life insurance end?"
        },
        content: {
            rendered: `Your policy ends when your term ends (as long as you continue to pay your premiums). So if you’ve 
                got a 10-year term life insurance policy, your coverage ends after 10 years. Simple, right? Some insurers 
                offer what’s known as “renewable term,” which gives you the option to add time to your policy for an added price.`
        }
    },
    {
        id: 7,
        title: {
            rendered: "What happens if I outlive my term life insurance?"
        },
        content: {
            rendered: `Unlike permanent life insurance, which lasts your entire life, term life insurance coverage ends 
                when your term ends. This is one of the reasons it’s typically a more affordable option than permanent life.`
        }
    },
    {
        id: 8,
        title: {
            rendered: "What happens after I get my quote?"
        },
        content: {
            rendered: `Once you get your quote, the underwriting process will determine your eligibility and what your premium 
                rate will be. This process can take a while with some insurers, but Bestow’s application takes just minutes and 
                you’ll get an instant decision. You could have coverage today, if approved. Pretty cool, right?`
        }
    },
    {
        id: 9,
        title: {
            rendered: "How do I get started?"
        },
        content: {
            rendered: `Getting started is as simple as a fast, free quote. Provide some very basic info and receive your quote, 
                and from there you can complete the entire application process in as little as 5 minutes. It really is that easy.`
        }
    },
];

export default faqs;
