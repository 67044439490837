import React from 'react';
import cx from 'classnames';

import CircleCard from "../../../../components/_common/card/circle";
import {TestimonialIcon} from "../../../../images/icons";
import BodyTextBold from "../../../../components/_common/text/body-text/bold";
import SmallText from "../../../../components/_common/text/small-text";
import Quotation from "../../../../components/_common/text/quotation";

import './index.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    imageClassName: string,
    quote: string,
    employeeName: string,
    employeeTitle: any
}

const EmployeeCard: React.FC<Props> = ({ className, imageClassName, quote, employeeName, employeeTitle }) => {
    return (
        <div className={cx("employee-card relative mt-4 mx-5 px-4 sm:px-0 sm:mx-6 sm:mt-0 sm:flex", className)}>
            <div className="absolute -top-4 left-0 sm:relative sm:mr-12 sm:mt-12">
                <CircleCard className={cx("careers-item-photo bg-center bg-cover -ml-4 sm:-ml-10 lg:-ml-10", imageClassName)} />
            </div>
            <div className="flex flex-col h-full justify-center">
                <div className="flex justify-center items-center mb-6 sm:justify-start">
                    <TestimonialIcon className="w-5 h-6 sm:w-6 mr-3" />
                    <BodyTextBold className="hidden lg:block">
                        Employee Spotlight
                    </BodyTextBold>
                    <SmallText className="font-medium lg:hidden">
                        Employee<br className="sm:hidden"/> Spotlight
                    </SmallText>
                </div>
                <Quotation className="text-charcoal text-center sm:text-left mx-4 sm:ml-0 sm:mr-8 mb-6">
                    “{quote}”
                </Quotation>
                <SmallText className="font-medium text-center sm:text-left">
                    {employeeName}
                </SmallText>
                <SmallText className="text-center sm:text-left">
                    {employeeTitle}
                </SmallText>
            </div>
        </div>
    )
};

export default EmployeeCard;