import React from 'react';
import {string, object, array} from 'yup';
import CustomFormikField from "../../../../components/_common/formik/customField";
import OutsideLabelInput from "../../../../components/_common/inputs/outsideLabel";
import Subtitle1 from '../../../../components/_common/text/heading/subtitle1';
import RadioGroup from '../../../../components/_common/inputs/radio-group';
import TextFieldLabel from '../../../../components/_common/text/textFieldLabel';
import BodyTextBold from '../../../../components/_common/text/body-text/bold';
import Checkbox from '../../../../components/_common/inputs/checkbox';
import {useFormikContext} from 'formik';
import OpenInput from '../../../../components/_common/inputs/open';
import HelperText from '../../../../components/_common/text/helperText';

export const reachAndPromotionSchema = object().shape({
    monthlyReach: string().required("Monthly Reach is required."),
    promotionPlan: array().min(1, "Please select at least one option").required("Promotion Plan is required.").nullable(),
    promotionPlanOther: string(),
    estimatedLeadsPerMonth: string().required("Estimated leads per month is required."),
});

const PROMOTION_TYPES = [
    "Content (Organic)",
    "Content (Paid)",
    "Email/Newsletter",
    "Display",
    "Native",
    "Publisher Network",
    "Social Media",
    "In-app",
    "Influencer",
];

const ReachAndPromotion: React.FC = () => {
    const {setFieldValue, values, errors, touched} = useFormikContext<any>();

    return (
        <div data-testid="affiliates-reach-and-promotion">
            <Subtitle1 className="mb-6 text-center sm:text-left">
                Reach and Promotion
            </Subtitle1>
            <div className="mt-8 mb-8">
                <CustomFormikField
                    CustomComponent={OutsideLabelInput}
                    name="monthlyReach"
                    className="w-full"
                    label="How many unique monthly website visitors do you have?"
                    data-testid="monthlyReach"
                    required
                />
            </div>
            <div className="mb-8">
                <TextFieldLabel className="text-gray-800 mb-4">
                    PLEASE PROVIDE AN ESTIMATED AMOUNT OF LEADS PER MONTH.<span className="text-error">*</span>
                </TextFieldLabel>
                <CustomFormikField
                    CustomComponent={RadioGroup}
                    options={[
                        {label: '1-10', value: '1-10'},
                        {label: '11-20', value: '11-20'},
                        {label: '21-40', value: '21-40'},
                        {label: '50+', value: '50+'},
                    ]}
                    name="estimatedLeadsPerMonth"
                    data-testid="estimatedLeadsPerMonth"
                    required/>
            </div>
            <div className="mb-8">
                <BodyTextBold className="mb-6 text-center sm:text-left">
                    How do you plan on promoting Bestow (check all that apply).*
                </BodyTextBold>
                {PROMOTION_TYPES.map((promotionType, idx) => (
                    <div className="mb-8" key={`promotionType-${idx}`}>
                        <CustomFormikField
                            name="promotionPlan"
                            checked={values.promotionPlan.includes(promotionType)}
                            data-testid={promotionType}
                            onChange={(event: any) => {
                                let promotionPlan = [...values.promotionPlan];
                                if ((event.target as HTMLInputElement).checked) {
                                    promotionPlan.push(promotionType);
                                } else {
                                    promotionPlan.splice(promotionPlan.indexOf(promotionType, 1));
                                }
                                setFieldValue('promotionPlan', promotionPlan, true);
                            }}
                            CustomComponent={Checkbox}
                            label={promotionType}/>
                    </div>
                ))}
                <div className="flex items-center -mt-2">
                    <div>
                        <Checkbox
                            checked={values.promotionPlan.includes("other")}
                            onChange={event => {
                                let promotionPlan = [...values.promotionPlan];
                                if ((event.target as HTMLInputElement).checked) {
                                    promotionPlan.push("other");
                                } else {
                                    promotionPlan.splice(promotionPlan.indexOf("other", 1));
                                }
                                setFieldValue('promotionPlan', promotionPlan, true);
                            }}
                        />
                    </div>
                    <div className="ml-4 mb-1 flex-1">
                        <CustomFormikField
                            className="w-full"
                            name="promotionPlanOther"
                            disabled={!values.promotionPlan.includes("other")}
                            CustomComponent={OpenInput}
                            placeholder="Other..." />
                    </div>
                </div>
                {touched.promotionPlan && errors.promotionPlan &&
                    <HelperText className="mt-3 text-error" error={Boolean(errors.promotionPlan)}>
                        {errors.promotionPlan}
                    </HelperText>
                }
            </div>
        </div>
    );
};

export default ReachAndPromotion;
