import React from 'react';
import { Link } from 'react-router-dom';

import PageSection from '../../components/_common/section/page';
import H1Serif from "../../components/_common/text/heading/h1/serif";
import H2Serif from "../../components/_common/text/heading/h2/serif";
import BodyTextLarge from '../../components/_common/text/body-text/large';
import BodyText from '../../components/_common/text/body-text';
import Grid from "../../components/_common/grid";
import Card from "../../components/_common/card/round";
import AffiliatesForm from "./form";
import PageContent from "../../components/_common/pageContent";
import Subtitle2 from '../../components/_common/text/heading/subtitle2';
import SecondaryButton from '../../components/_common/button/secondary';
import Subtitle1 from '../../components/_common/text/heading/subtitle1';
import BodyTextBold from '../../components/_common/text/body-text/bold';
import Reviews from './reviews';

import audienceImg from '../../images/affiliates/affiliates_audience.jpeg';
import { ArrowRightIcon } from '../../images/icons';

import './index.scss';

const Affiliates: React.FC = () => {
    return (
        <PageContent className="affiliates-page">
            <PageSection className="bg-off-white pt-7 sm:pt-4 lg:pt-16">
                <div>
                    <div>
                        <H1Serif className="text-center sm:text-left">Become an affiliate partner</H1Serif>
                    </div>
                </div>
                <div className="sm:hidden flex flex-col items-center mx-2">
                    <BodyTextLarge className="sm:hidden text-center mt-8">
                        Earn competitive referral fees promoting life insurance to your audience. Chances are, they’re already thinking about it.<br/><br/>
                        Let’s help them protect those they love the most.
                    </BodyTextLarge>
                    <div className="flex mt-16">
                        <SecondaryButton scrollTo="form" scrollToOffset={-100}>
                            Get Started
                        </SecondaryButton>
                    </div>
                </div>
            </PageSection>
            <PageSection className="bg-off-white pt-12 sm:pt-6">
                <Grid>
                    <Card className="affiliates-img-card col-span-full sm:col-span-4 lg:col-span-5 bg-coral sm:bg-white overflow-hidden sm:mx-4 lg:mx-12 -mb-24 sm:-mb-16" />
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 sm:px-4 lg:px-6">
                        <div className="hidden sm:block">
                            <BodyTextLarge className="text-charcoal text-center sm:text-left sm:mt-4 flex flex-col items-center">
                                Earn competitive referral fees promoting life insurance to your audience. Chances are, they’re already thinking about it.<br/><br/>
                                Let’s help them protect those they love the most.
                            </BodyTextLarge>
                            <div className="flex sm:my-12">
                                <SecondaryButton scrollTo="form" scrollToOffset={-100}>
                                    Get Started
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="benefits pt-36 sm:pt-32 lg:pt-40">
                <H2Serif className="mb-6 sm:mb-12 text-center text-navy ">
                    Partnership has its benefits
                </H2Serif>
                <Grid smCols={3} className="text-center">
                    <div className="col-span-full sm:col-span-1 lg:col-span-4 bg-coral rounded-md p-6 mb-3 sm:mb-0 sm:p-8 lg:py-12">
                        <Subtitle2 className="mb-6 sm:mb-4">
                            Easy to sign up. And free!
                        </Subtitle2>
                        <BodyText>
                            We can set you up quickly, using reliable tracking to ensure you get credit for every sale.
                        </BodyText>
                    </div>
                    <div className="col-span-full sm:col-span-1 lg:col-span-4 bg-champagne rounded-md p-6 mb-3 sm:mb-0 sm:p-8 lg:py-12">
                        <Subtitle2 className="mb-6 sm:mb-4">
                            Competitive referral fees
                        </Subtitle2>
                        <BodyText>
                            The more people you help find life insurance, the more referral fees you can earn.
                        </BodyText>
                    </div>
                    <div className="col-span-full sm:col-span-1 lg:col-span-4 bg-sage rounded-md p-6 sm:p-8 lg:py-12">
                        <Subtitle2 className="mb-6 sm:mb-4">
                            Support
                        </Subtitle2>
                        <BodyText>
                            From customer landing pages to marketing messaging tips, we’re here to help you succeed.
                        </BodyText>
                    </div>
                </Grid>
                <Grid>
                    <div className="col-span-full sm:hidden">
                        <Subtitle1 className="text-center mb-2 mt-16 mx-14 text-navy">
                            Is my audience a good fit?
                        </Subtitle1>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 sm:py-24 lg:py-28">
                        <img src={audienceImg} alt="Affiliates Audience" className="w-full sm:mt-6" />
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 pb-8 sm:py-24 lg:py-28">
                        <Subtitle1 className="hidden sm:block sm:mb-4 lg:mb-9 text-navy">
                            Is my audience a good fit?
                        </Subtitle1>
                        <BodyText className="mb-7 lg:mb-12">
                            You’ve worked hard to build a loyal audience and want to maintain their trust. We get that.
                            Life insurance might be a great fit for the following types of audiences.
                        </BodyText>
                        <BodyTextBold>
                            <ul>
                                <li>
                                    Parents or soon-to-be-parents
                                </li>
                                <li>
                                    Newlyweds and young couples
                                </li>
                                <li>
                                    New or existing homeowners
                                </li>
                                <li>
                                    Small business owners
                                </li>
                                <li>
                                    Anyone beginning to build out a financial plan for themselves or their families
                                </li>
                            </ul>
                        </BodyTextBold>
                    </div>
                </Grid>
            </PageSection>
            <Reviews />
            <PageSection className="pt-8 pb-20 sm:pt-12 lg:pt-16">
                <Grid>
                    <H2Serif className="col-span-full text-center text-navy">Interested?<br className="sm:hidden"/> Get in touch!</H2Serif>
                    <BodyText className="col-span-full lg:col-start-2 lg:col-span-10 text-charcoal text-center py-6 sm:py-8 sm:mx-14 lg:mx-6">
                        Already registered with Impact?<br/>
                        Apply directly to our program <a href="http://app.impact.com/campaign-mediapartner-signup/Bestow.brand?type=dm&io=pUUAuor8%2BzPFNHT8suLf8zDr7NmCoRK0jjw48vc7Xn8UgMbELUU8qE94SpUPlNTR" target="_blank" rel="noopener noreferrer">here</a>.
                    </BodyText>
                    <div className="col-span-full lg:col-start-3 lg:col-span-8 sm:mx-14 lg:mx-6" id="form">
                        <AffiliatesForm />
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="bg-mint py-16 lg:py-14">
                <Grid>
                    <div className="col-span-full sm:col-span-5 lg:col-span-9 flex items-center">
                        <Subtitle1 className="text-navy text-center mb-4 sm:mb-0 sm:text-left">
                            Licensed agents:<br className="lg:hidden"/> Check out our advisor program
                        </Subtitle1>
                    </div>
                    <div className="col-span-full sm:col-span-3 flex justify-center sm:justify-end lg:justify-center">
                        <SecondaryButton to="/agents" Icon={ArrowRightIcon}>
                            Learn more
                        </SecondaryButton>
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default Affiliates;
