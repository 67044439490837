import PageSection from '../../../components/_common/section/page';
import H4Sans from '../../../components/_common/text/heading/h4/sans';
import BodyText from '../../../components/_common/text/body-text';
import Grid from '../../../components/_common/grid';
import H4Serif from '../../../components/_common/text/heading/h4/serif';
import GerberImg from '../../../images/gerber/GerberLogo@2x.jpg';
import './index.scss';

const FutureHands: React.FC = () => (
    <PageSection className="future-hands lg:pb-24 xs:pb-16">
        <Grid
            smCols={2}
            lgCols={2}
            xlCols={2}
            gap={0}
            smGap={0}
            lgGap={0}
            className="xs:px-4 sm:px-0 lg:px-10"
        >
            <div className="col-span-full sm:col-span-1 flex flex-col items-left text-left xs:block sm:hidden xs:mb-6">
                <H4Serif className="text-navy xs:text-32 text-center">
                    Your future is in good hands
                </H4Serif>
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col justify-center items-center text-center xs:mb-6">
                <img
                    src={GerberImg}
                    alt="Gerber logo"
                    className="gerber-image"
                />
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col items-left text-left">
                <H4Serif className="text-spruce-darkest mb-4 xs:hidden sm:block">
                    Your future is in good hands
                </H4Serif>
                <BodyText className="mb-0 lg:text-2xl xs:text-xl font-thin">
                    Bestow has teamed up with Gerber Life Insurance to help
                    people access the coverage their loved ones deserve.
                </BodyText>
            </div>
        </Grid>
        <Grid
            smCols={3}
            lgCols={3}
            xlCols={3}
            gap={14}
            smGap={16}
            lgGap={16}
            className="xs:px-4 sm:px-0 lg:px-10 mt-16"
        >
            <div className="col-span-full sm:col-span-1 flex flex-col items-center text-center">
                <H4Sans className="mb-4 sm:text-xl xs:text-lg font-medium text-charcoal">
                    Competitive Rates
                </H4Sans>
                <BodyText className="lg:block sm:hidden xs:block mb-0">
                    Rates starting at $33/mo.
                </BodyText>
                <BodyText className="mb-0 sm:block lg:hidden xs:hidden">
                    Rates starting <br /> at $33/mo.
                </BodyText>
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col items-center text-center">
                <H4Sans className="mb-4 sm:text-xl xs:text-lg text-xl font-medium text-charcoal">
                    Experienced
                </H4Sans>
                <BodyText className="mb-0">
                    Helping families since 1967
                </BodyText>
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col items-center text-center">
                <H4Sans className="mb-4 sm:text-xl xs:text-lg text-xl font-medium text-charcoal">
                    Trusted
                </H4Sans>
                <BodyText className="mb-0 lg:block sm:hidden xs:block">
                    "Excellent" financial strength <br /> rating by A.M. Best
                </BodyText>
                {/* tablet below */}
                <BodyText className="mb-0 sm:block lg:hidden xs:hidden">
                    "Excellent" financial <br /> strength rating by A.M. Best
                </BodyText>
            </div>
        </Grid>
    </PageSection>
);

export default FutureHands;
