import React from 'react';

import './index.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    className?: string,
}

const Card = React.forwardRef<HTMLDivElement, Props>(({ className = "", children, ...otherProps }, ref) => {
    return (
        <div className={`card ${className}`} ref={ref} {...otherProps}>
            {children}
        </div>
    )
});

export default Card;