import React from 'react';

import Card, { Props } from "../";

import './index.scss';

const CircleCard: React.FC<Props> = ({className = "", ...otherProps}) => {
    return (
        <Card className={`circle-card ${className}`} {...otherProps} />
    )
};

export default CircleCard;