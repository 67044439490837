import React from 'react';

import PageContent from '../../components/_common/pageContent/navy';
import PageSection from "../../components/_common/section/page";
import Grid from "../../components/_common/grid";
import BodyTextBold from "../../components/_common/text/body-text/bold";
import BodyText from "../../components/_common/text/body-text";
import TitleLarge from "../../components/_common/text/titleLarge";

import {BestowFoundation} from "../../images/svgs";

const Foundation: React.FC = () => {
    return (
        <PageContent>
            <PageSection className="pt-12 pb-24 lg:pb-32 sm:pt-24 lg:pt-32">
                <Grid className="text-center sm:text-left">
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 mb-8 sm:mb-0 flex justify-center sm:justify-end sm:pr-12">
                        <BestowFoundation />
                    </div>
                    <div className="col-span-full sm:col-span-3 lg:col-span-4 flex justify-center sm:justify-start mx-12 sm:mx-0 mb-8 sm:pl-10 lg:mr-14">
                        <div className="text-center">
                            <BodyTextBold className="mb-4">
                                About the Foundation
                            </BodyTextBold>
                            <BodyText>
                                The Bestow Foundation was established in 2017 as a 501(c)(3) in alignment with our purpose:
                            </BodyText>
                        </div>
                    </div>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10 lg:mx-6">
                        <TitleLarge className="text-center text-charcoal">Make hard things easier.</TitleLarge>
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default Foundation;
