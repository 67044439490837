import React from 'react';
import cx from 'classnames';

import Button, { Props } from "../index";

import './index.scss';

const PrimaryButton = React.forwardRef<HTMLButtonElement, Props>(({ className = "", ...otherProps }, ref) => {
    const classes = cx("primary-btn border-transparent focus:outline-none focus:ring-2 focus:ring-primary-light", className);
    return (
        <Button className={classes} {...otherProps} ref={ref} />
    )
});

export default PrimaryButton;

