import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import PageContent from "../../components/_common/pageContent/navy";
import PageSection from "../../components/_common/section/page";
import Grid from "../../components/_common/grid";
import Eyebrow from "../../components/_common/text/eyebrow";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import BodyTextLarge from "../../components/_common/text/body-text/large";
import H4Serif from "../../components/_common/text/heading/h4/serif";
import Spinner from "../../components/_common/spinner/index";
import H2Serif from "../../components/_common/text/heading/h2/serif";
import Department from "./department";
import BodyText from "../../components/_common/text/body-text";
import {fetchJobs} from "../../redux/careers/index";
import {jobsLoadingSelector, jobsSelector} from "../../redux/careers/selectors";

import './index.scss';

const CareersListing: React.FC = () => {
    const loading = useSelector(jobsLoadingSelector);
    const jobs = useSelector(jobsSelector);
    const dispatch = useDispatch();

    useEffect(() =>  {
        dispatch(fetchJobs());
    }, [dispatch]);

    return (
        <PageContent includeBottomPadding>
            <PageSection className="pt-7 lg:pt-11">
                <div className="flex justify-center sm:justify-start">
                    <Eyebrow className="mb-2">
                        CAREERS
                    </Eyebrow>
                </div>
                <Grid>
                    <H1Serif className="col-span-full sm:col-span-7 lg:col-span-10 mb-4 text-center sm:text-left sm:mb-8">
                        Changing the status quo one hire at a time
                    </H1Serif>
                    <BodyTextLarge className="hidden sm:block col-span-full lg:col-start-2 lg:col-span-10 sm:mb-8">
                        At Bestow, we believe in people over products, data over conjecture, and excellence over conformity.
                        Because solving centuries-old problems requires more than just an idea — we’re reimagining the industry
                        from the ground up.
                    </BodyTextLarge>
                    <BodyText className="col-span-full sm:hidden mb-4">
                        At Bestow, we believe in people over products, data over conjecture, and excellence over conformity.
                        Because solving centuries-old problems requires more than just an idea — we’re reimagining the industry
                        from the ground up.
                    </BodyText>
                    <H4Serif className="hidden sm:block col-span-full lg:col-start-2 lg:col-span-10 text-navy mb-0">
                        Current Openings
                    </H4Serif>
                    <H2Serif className="text-center sm:hidden col-span-full text-navy">
                        Current Openings
                    </H2Serif>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10">
                        {loading || !jobs ?
                            <Spinner /> :
                            <div className="-mt-8 sm:-mt-4 lg:mt-0">
                                {Object.keys(jobs).map(department => (
                                    <Department
                                        key={`department-${department}`}
                                        name={department}
                                        jobs={Object.values(jobs[department])} />
                                ))}
                            </div>
                        }
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default CareersListing;