import React, {useState} from 'react';
import cx from 'classnames';

import {FormikCustomFieldCommonProps} from '../';

import './index.scss';

interface Props extends FormikCustomFieldCommonProps {
    EndAdornment?: React.FC
}

const OpenInput: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
    className = "",
    value,
    EndAdornment,
    error,
    ...otherProps
}) => {
    const [focused, setFocused] = useState(false);
    const inputClassName = cx(`bg-transparent flex-1 border-0 border-solid border-b-2 font-sans text-charcoal
        transition-all hover:bg-off-white`, {
        "pl-10": EndAdornment
    });

    return(
        <div className={`open-input flex w-full relative ${className}`}>
            <input
                type="text"
                className={inputClassName}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                {...otherProps}
            />
            {EndAdornment}
        </div>
    )
};

export default OpenInput;
