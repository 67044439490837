import defaultDeclineStrings from './defaultDeclineStrings.json';
import tbgDeclineStrings from './tbgDeclineStrings.json';
import noPartnerNameDeclineStrings from './noPartnerNameDeclineStrings.json';
import agentDeclineStrings from './agentDeclineStrings.json';
import gerberDeclineStrings from './gerberDeclineStrings.json';

//Keys that map to each partner. These are also what shows up in the url to trigger a partner knockout
//Originally this was in partnerConstants.js but caused some cyclical dependency issues when I imported them into partner icons
//So now these are their own separate file
export const PARTNER_KEYS = {
    policyGenius: "policyGenius" as 'policyGenius',
    theBaileyGroup: "tbg" as "tbg",
    hubInternational: "hub" as 'hub',
    quotacy: "quotacy" as 'quotacy',
    woodforest: "woodforest" as 'woodforest',
    tavenner: "tavenner" as 'tavenner',
    safeAuto: "safeauto" as 'safeauto',
    rapidLifeInsurance: "RLIQ" as 'RLIQ',
    isthmus: "isthmus" as 'isthmus',
    sproutt: "sproutt" as 'sproutt',
    transcendbg: "transcendbg" as 'transcendbg',
    agent: "agent" as "agent",
    zanderinsurance: "zanderinsurance" as "zanderinsurance",
    gerber: "gerber" as "gerber",
};

// KBM - This is what was hard-coded in AQX, except it was packaged inside PARTNER_CONSTANTS, so we pulled it out
export const DEFAULT_PARTNER = PARTNER_KEYS.policyGenius;

//Generic constant values like phone numbers and emails
export const PARTNER_CONSTANTS = {
    [PARTNER_KEYS.theBaileyGroup]: {
        telNumber: "866-207-8988",
        email: "concierge@thebaileygp.com",
        name: "The Bailey Group",
        declineStrings: tbgDeclineStrings
    },
    [PARTNER_KEYS.hubInternational]: {
        telNumber: "1-(855) 604-8483",
        name: "HUB International",
        declineStrings: defaultDeclineStrings
    },
    [PARTNER_KEYS.policyGenius]: {
        name: "Policygenius",
        declineStrings: defaultDeclineStrings
    },
    [PARTNER_KEYS.quotacy]: {
        telNumber: "(844) 786-8229",
        name: "Quotacy",
        declineStrings: defaultDeclineStrings
    },
    [PARTNER_KEYS.woodforest]: {
        telNumber: "1-866-659-6703",
        name: "Woodforest",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.rapidLifeInsurance]: {
        telNumber: "1-866-658-6184",
        name: "Rapid Life Insurance",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.safeAuto]: {
        telNumber: "1-866-658-6117",
        name: "SafeAuto",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.isthmus]: {
        telNumber: "1-866-658-6184",
        name: "Isthmus",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.tavenner]: {
        telNumber: "1-800-543-6922",
        name: "Tavenner",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.sproutt]: {
        telNumber: "1-888-786-3917",
        name: "Sproutt",
        url: "https://www.sproutt.com",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.transcendbg]: {
        telNumber: "1-954-663-1376",
        name: "TranscendBG",
        email: "acortesio@transcendbg.com",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.agent]: {
        declineStrings: agentDeclineStrings
    },
    [PARTNER_KEYS.zanderinsurance]: {
        telNumber: "1-615-850-3316",
        name: "Zander Insurance",
        email: "rmk@zanderins.com",
        declineStrings: noPartnerNameDeclineStrings
    },
    [PARTNER_KEYS.gerber]: {
        declineStrings: gerberDeclineStrings
    },
};

// The following partners are agents, but have their own bestow.com/{partner} page
export const AGENT_PARTNERS = [
    "megfinancial",
    "taber",
    "cornett",
    "billcook",
    "markwall",
    "pinney",
    "insurancedivision",
    "kellysanner",
    "melschlesinger",
    "creativefinancialstrategies",
    "ipa-ok",
    "leblanc",
    "patternlife",
    "jrc",
    "iketrotter",
    "agentquote",
    "aimcor",
    "aimcorenterprise",
    "tickit",
    "tickitdirect",
    "efinancial",
    "covr",
    "brokerscentral",
    "navyfederalcreditunion",
    "bbva"
];

