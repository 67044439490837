// import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     site {
  //       siteMetadata {
  //         title
  //         titleAlt
  //         shortName
  //         siteLanguage
  //         siteUrl
  //         description
  //         banner
  //       }
  //     }
  //   }
  // `);
  // return data.site.siteMetadata;
  return {};
};

export default useSiteMetadata;
