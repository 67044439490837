import React from 'react';

import Grid from "../../../components/_common/grid";
import Subtitle2 from "../../../components/_common/text/heading/subtitle2";
import JobListing from "../job";
import H4Sans from "../../../components/_common/text/heading/h4/sans";

interface Props {
    name: string,
    jobs: {
        id: string,
        title: string,
        location: string,
        url: string
    }[]
}

const Department: React.FC<Props> = ({ name, jobs }) => {
    return (
        <div className="pt-12 pb-8 sm:pt-10 sm:pb-4 lg:py-12 sm:py-10 border-solid border-0 border-b border-gray-300">
            <Grid lgCols={10}>
                <Subtitle2 className="col-span-full sm:col-span-2 hidden sm:block mb-4">
                    {name}
                </Subtitle2>
                <H4Sans className="col-span-full sm:hidden text-center mb-4">
                    {name}
                </H4Sans>
                <div className="col-span-full sm:col-span-6 lg:col-span-8">
                    {jobs.map(({title, location, url}, idx) => (
                        <JobListing key={`job-listing-${idx}`} title={title} location={location} url={url} />
                    ))}
                </div>
            </Grid>
        </div>
    )
};

export default Department;
