import React, { useState, useCallback } from 'react';

import PageContent from "../../components/_common/pageContent";
import PageSection from "../../components/_common/section/page";
import H1Serif from "../../components/_common/text/heading/h1/serif";
import Grid from "../../components/_common/grid";
import Eyebrow from "../../components/_common/text/eyebrow";
import H3Serif from "../../components/_common/text/heading/h3/serif";
import BodyText from "../../components/_common/text/body-text";
import VideoCard from "../../components/_common/card/video";
import BodyTextBold from "../../components/_common/text/body-text/bold";
import H2Serif from "../../components/_common/text/heading/h2/serif";
import Subtitle1 from "../../components/_common/text/heading/subtitle1/index";
import LinkButton from "../../components/_common/button/link";
import DividerCoral from "../../components/_common/divider/coral";
import CTAModuleTwo from "../../components/_common/modules/cta/two";
import Subtitle2 from "../../components/_common/text/heading/subtitle2";
import {Link} from "react-router-dom";
import PrimaryLargeButton from "../../components/_common/button/primary/large";
import MediumText from "../../components/_common/text/mediumText";
import SmallText from "../../components/_common/text/small-text";
import TinyText from "../../components/_common/text/tinyText";

import { VC8, BreyerCap, CoreInnovationCap, Morpheus, NEA, Valar } from "../../images/logos/index";
import videoPlaceholder from '../../images/about/about-video-placeholder.jpeg';
import aboutLeaveToUs from '../../images/about/about-leave-to-us.jpg';
import aboutPeopleBaby from '../../images/about/about-people-baby.jpeg';
import aboutPeopleFamily from '../../images/about/about-people-family.jpg';
import aboutPeopleFamilyLg from '../../images/about/about-people-family-lg.jpeg';
import aboutPeoplePhone from '../../images/about/about-people-phone.jpeg';
import { NorthAmericanLogo, MunichReLogo } from '../../images/logos/index';

import './index.scss'; 

const About: React.FC = () => {
    const [videoPlaying, setVideoPlaying] = useState(false);
    const onPlay = useCallback(() => setVideoPlaying(true), []);

    return (
        <PageContent className="about">
            <PageSection className="bg-almond pt-9 pb-20 sm:pb-32 sm:pt-7 lg:pb-36 lg:pt-11">
                <Eyebrow className="text-center mb-6 sm:text-left sm:mb-2">
                    ABOUT US
                </Eyebrow>
                <H1Serif className="mb-6 sm:mb-0 text-center sm:text-left text-navy">
                    Hi. We're Bestow.
                </H1Serif>
                <H3Serif className="hidden sm:block sm:mb-8 lg:mb-12">
                    (verb | be·​stow | to give as a gift)
                </H3Serif>
                <BodyTextBold className="sm:hidden text-center mb-6">
                    (verb | be·​stow | to give as a gift)
                </BodyTextBold>
                <BodyText className="text-center sm:text-left">
                    We create cutting-edge life insurance products and technologies<br className="hidden sm:block"/> that
                    help people every day.
                </BodyText>
            </PageSection>
            <PageSection className="pb-16 sm:pb-24 lg:pb-28">
                <Grid>
                    <div className="col-span-full -mt-12 sm:-mt-20 sm:px-10 lg:col-start-2 lg:col-span-10 lg:px-9">
                        <div className="relative">
                            <VideoCard id="54sHIwKKWms" videoId="54sHIwKKWms" placeholderImage={videoPlaceholder} onPlay={onPlay} />
                            {!videoPlaying &&
                                <>
                                    <div className="hidden absolute sm:block sm:top-20 sm:left-8 lg:left-10 text-center">
                                        <MediumText className="text-charcoal">
                                            Melbourne O'Banion
                                        </MediumText>
                                        <SmallText className="text-white font-medium">
                                            Co-Founder & CEO
                                        </SmallText>
                                    </div>
                                    <div className="hidden absolute sm:block sm:top-11 sm:right-7 lg:right-11 text-center">
                                        <MediumText className="text-charcoal">
                                            Jonathan Abelmann
                                        </MediumText>
                                        <SmallText className="text-white font-medium">
                                            Co-Founder & President
                                        </SmallText>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="sm:hidden flex text-center mt-4">
                            <div className="flex-1">
                                <SmallText className="font-medium text-charcoal mb-1">
                                    Melbourne O’Banion
                                </SmallText>
                                <TinyText className="text-charcoal">
                                    Co-Founder & CEO
                                </TinyText>
                            </div>
                            <div className="flex-1">
                                <SmallText className="font-medium text-charcoal mb-1">
                                    Jonathan Abelmann
                                </SmallText>
                                <TinyText className="text-charcoal">
                                    Co-Founder & President
                                </TinyText>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10">
                        <H2Serif className="text-navy mt-10 sm:mt-16 text-center mb-6">
                            How will you Bestow?
                        </H2Serif>
                        <BodyText className="text-center mb-8 sm:mb-10 sm:mx-6">
                            We’re building technology to make everything about life insurance easier. But what does that
                            mean exactly? Well, it all depends on who you are.
                        </BodyText>
                    </div>
                    <div className="col-span-2 lg:col-span-3 flex flex-col">
                        <Subtitle1 className="text-spruce-dark text-center mb-4 sm:mb-6">
                            Customers
                        </Subtitle1>
                        <BodyText className="text-center mx-2 sm:mx-0 lg:mx-2 mb-4 sm:flex-1">
                            The only done-in-minutes life insurance application that never requires a medical exam.
                        </BodyText>
                        <div className="flex justify-center">
                            <LinkButton to="/quote">
                                Learn More
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-span-2 lg:col-span-3 flex flex-col">
                        <Subtitle1 className="text-gold-dark text-center mb-4 sm:mb-6">
                            Partners
                        </Subtitle1>
                        <BodyText className="text-center mx-2 sm:mx-0 lg:mx-2 mb-4 sm:flex-1">
                            Seamless digital solutions that easily add value for customers.
                        </BodyText>
                        <div className="flex justify-center">
                            <LinkButton to="/protect">
                                Learn More
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-span-2 lg:col-span-3 pt-10 sm:pt-0 flex flex-col">
                        <Subtitle1 className="text-coral-dark text-center mb-4 sm:mb-6">
                            Advisors
                        </Subtitle1>
                        <BodyText className="text-center mx-2 sm:mx-0 lg:mx-2 mb-4 sm:flex-1">
                            100% digital application with no case management.
                        </BodyText>
                        <div className="flex justify-center">
                            <LinkButton to="/agents">
                                Learn More
                            </LinkButton>
                        </div>
                    </div>
                    <div className="col-span-2 lg:col-span-3 pt-10 sm:pt-0 flex flex-col">
                        <Subtitle1 className="text-navy text-center mb-4 sm:mb-6">
                            Carriers
                        </Subtitle1>
                        <BodyText className="text-center mx-2 sm:mx-0 lg:mx-2 mb-4 sm:flex-1">
                            Technology and ops solutions that move your business forward.
                        </BodyText>
                        <div className="flex justify-center">
                            <LinkButton to="/carriers">
                                Learn More
                            </LinkButton>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="bg-off-white sm:bg-transparent">
                <Grid>
                    <div className="hidden sm:flex sm:col-span-4 lg:col-span-6 justify-end relative">
                        <div className="-ml-80 pr-2 lg:pr-0">
                            <img className="leave-to-us-img" src={aboutLeaveToUs} alt="Leave the hard stuff to us" />
                        </div>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 px-10 sm:pt-10 sm:pr-8 sm:pl-2 lg:pt-20 lg:px-16">
                        <H3Serif className="text-center mt-16 mb-8 sm:mt-0 sm:text-left sm:mb-9 lg:mb-12">
                            Leave the hard stuff to us
                        </H3Serif>
                        <div className="flex justify-center sm:hidden mb-8">
                            <DividerCoral className="w-12" />
                        </div>
                        <BodyText>
                            It shouldn’t be hard to buy life insurance. Or to lead your clients through the underwriting
                            process. Or to offer life insurance products through your existing software. Everything else
                            in the world seems to be getting faster and easier, and we think life insurance should be no
                            exception.
                        </BodyText>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 px-10 mb-16 sm:mb-0 sm:pl-0 sm:pt-4 sm:pr-8 lg:pt-12 lg:px-16">
                        <H3Serif className="text-center mt-10 mb-8 sm:mt-0 sm:text-left sm:mb-9 lg:mb-12">
                            It's about people
                        </H3Serif>
                        <div className="flex justify-center sm:hidden mt-8 mb-8">
                            <DividerCoral className="w-12" />
                        </div>
                        <BodyText>
                            We know the peace of mind that can come from feeling prepared for an uncertain future. After
                            all, we have families, too. That’s why we do what we do. We’ve reimagined life insurance,
                            driving innovations in technology and data to expand access and opportunity to more families.
                        </BodyText>
                    </div>
                    <div className="hidden sm:flex sm:col-span-4 lg:col-span-6 relative">
                        <div className="-mr-80 pl-2 sm:-mt-10 lg:-mt-24 lg:pl-0">
                            <img className="people-baby-img" src={aboutPeopleBaby} alt="Leave the hard stuff to us" />
                        </div>
                    </div>
                    <div className="hidden sm:flex sm:col-span-4 lg:col-span-6 justify-end relative">
                        <div className="-ml-80 pr-2 sm:-mt-16 lg:-mt-24 lg:pr-0">
                            <img className="people-family-img lg:hidden" src={aboutPeopleFamily} alt="Leave the hard stuff to us" />
                            <img className="people-family-img hidden lg:block" src={aboutPeopleFamilyLg} alt="Leave the hard stuff to us" />
                        </div>
                    </div>
                    <div className="hidden sm:block col-span-full sm:col-span-4 lg:col-span-6">
                        <div className="-mr-80 pl-2 lg:pl-0">
                            <img className="people-phone-img" src={aboutPeoplePhone} alt="Leave the hard stuff to us" />
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="py-16 sm:py-24 lg:py-32">
                <Grid>
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-3 lg:col-span-8 sm:px-5 lg:px-6">
                        <H2Serif className="text-navy text-center mb-6">
                            Trusted
                        </H2Serif>
                        <BodyText className="mb-12">
                            Bestow offers the best of both worlds: fast, modern technology combined with the big insurance
                            names you know and trust.
                        </BodyText>
                        <div className="flex justify-center">
                            <MunichReLogo className="mr-4 sm:mr-16"/>
                            <NorthAmericanLogo/>
                        </div>
                        <BodyText className="mt-12">
                            We work with industry giants like Munich Re® and North American Company for Life and Health
                            Insurance®. With over 250 years of combined experience and A+ ratings from A.M. Best, you
                            can rest easy knowing that they’ll be there for the long haul.
                        </BodyText>
                    </div>
                </Grid>
            </PageSection>
            <CTAModuleTwo
                className="bg-off-white"
                title="Start with a fast, free quote."
                text="Get coverage today, if approved.">
                <PrimaryLargeButton to="/quote">
                    Get Started
                </PrimaryLargeButton>
            </CTAModuleTwo>
            <PageSection className="bg-navy-light py-16 sm:py-24 lg:py-32">
                <Grid>
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-4 lg:col-span-6 flex justify-center">
                        <div className="max-w-xs sm:max-w-none">
                            <Subtitle2 className="text-white tracking-widest text-center mb-2">
                                PARTNERS
                            </Subtitle2>
                            <BodyText className="text-white text-center mx-6 mb-12">
                                Bestow is backed by the following investors:
                            </BodyText>
                            <div className="flex justify-center items-center flex-wrap">
                                <Valar className="hidden sm:block sm:mr-16" />
                                <NEA className="mr-6 sm:mr-16" />
                                <BreyerCap className="" />
                                <Morpheus className="h-4 sm:h-5 w-40 sm:w-48 mr-6 sm:mr-16 sm:mt-8" />
                                <VC8 className="sm:hidden my-9" />
                                <Valar className="sm:hidden mr-6" />
                                <CoreInnovationCap className="sm:mr-16 sm:mt-8" />
                                <VC8 className="hidden sm:block sm:mt-8" />
                            </div>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="flex items-center justify-center py-6">
                <BodyText className="text-center mb-0">
                    Interested in joining us?<br className="sm:hidden" /> <Link className="text-charcoal font-medium" to="/careers">See Open Roles</Link>
                </BodyText>
            </PageSection>
        </PageContent>
    )
};

export default About;