import React, { useState } from 'react';
import cx from 'classnames';
import useInterval from '../../../hooks/useInterval';
import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import Eyebrow from "../../../components/_common/text/eyebrow";
import {
    LemonadeLogo,
    StashLogo,
    CreditSesameLogo,
    BestowNavyLogo
} from "../../../images/logos/index";

import './index.scss';

const LogoSlider: React.FC = () => {
    const [logoIdx, setLogoIdx] = useState(0);

    const logos = [
        LemonadeLogo,
        StashLogo,
        CreditSesameLogo,
    ];

    const nextLogoIdx = logoIdx === logos.length - 1 ? 0 : logoIdx + 1;
    const prevLogoIdx = logoIdx === 0 ? logos.length - 1 : logoIdx - 1;


    useInterval(() => {
        if (logoIdx === logos.length -1 ) {
            setLogoIdx(0);
        } else {
            setLogoIdx(prev => prev + 1);
        }
    }, 2000);

    return (
        <PageSection className="bg-off-white py-16 sm:py-24 lg:py-32 logo-slider">
            <Grid>
                <div className="col-span-full mb-12 logo-slider">
                    <Eyebrow className="text-sm text-center mb-0">PREFERRED PARTNER FOR LEADING FINTECHS</Eyebrow>
                </div>
            </Grid>
            <Grid>
                <div className="col-span-full flex justify-center items-center px-4 sm:px-0">
                    <div className="flex-1 flex justify-end relative">
                        {logos.map((Logo, idx) => (
                            <Logo
                                key={`logo-${idx}`}
                                className={cx('logo max-w-full', {
                                    'active': idx === logoIdx,
                                    'next-up': idx === nextLogoIdx,
                                    'previous': idx === prevLogoIdx
                                })}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col mx-6 sm:mx-14 self-stretch">
                        <div className="border-0 border-r border-navy border-solid flex-1" />
                    </div>
                    <div className="flex-1">
                        <BestowNavyLogo className="max-w-full" />
                    </div>
                </div>
            </Grid>
        </PageSection>
    );
};

export default LogoSlider;
