import React from 'react';
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';

import { isPreRendering } from '../../../utilities/reactSnap';

const Recaptcha = React.forwardRef<ReCAPTCHA, ReCAPTCHAProps>((props, ref) => {
    if (isPreRendering()) {
        return <></>;
    }
    return (
        <ReCAPTCHA
            ref={ref}
            {...props}
            sitekey={
                props.sitekey || (process.env.REACT_APP_RECAPTCHA_KEY as string)
            }
            data-sitekey={
                props.sitekey || (process.env.REACT_APP_RECAPTCHA_KEY as string)
            }
        />
    );
});

export default Recaptcha;
