import React from 'react';

import PageSection from "../../components/_common/section/page";
import PageContent from '../../components/_common/pageContent/navy';
import H1Serif from "../../components/_common/text/heading/h1/serif";
import PrivacySection from './section';
import Grid from "../../components/_common/grid";
import useIsPersonalAgent from "../../hooks/useIsPersonalAgent";
import BodyTextArray from "../../components/_common/text/body-text/array";

import './index.scss';

import { privacyData } from './data';

export interface SubSection {
    title: string,
    body?: string[],
}

export interface Section {
    title: string,
    body?: string[],
    subSections?: SubSection[]
}

interface Props {
    title?: any,
    data?: {
        body?: string[],
        sections: Section[]
    }
}

const Privacy: React.FC<Props> = ({ title, data = privacyData }) => {
    const isPersonalAgent = useIsPersonalAgent();

    return (
        <PageContent className="privacy-page" showTopNav={!isPersonalAgent} includeBottomPadding>
            <PageSection className="pt-7 sm:pt-9 lg:pt-16">
                <H1Serif className="mb-8 sm:mb-12 lg:mb-16 text-center sm:text-left">
                    {title || <>Bestow's <br className="sm:hidden"/>Privacy Policy</>}
                </H1Serif>
                <Grid>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10">
                        {data.body &&
                            <BodyTextArray
                                data={data.body}
                                classes="mb-4"
                            />
                        }
                        {/* Render the numbered sections... */}
                        {data.sections.map((section, idx) => (
                            <PrivacySection
                                sectionData={section}
                                sectionNumber={idx+1}
                                key={`section-${idx}`}
                            />
                        ))}
                    </div>
                </Grid>
            </PageSection>
        </PageContent>
    );
};

export default Privacy;
