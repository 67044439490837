import React, { useState } from 'react';
import { useKeenSlider } from "keen-slider/react";
import cx from 'classnames';

import VideoSliderItem, { Video } from "./video-slider-item";
import useYouTube from "../../../hooks/useYouTube";

import './index.scss';
import "keen-slider/keen-slider.min.css"

interface Props {
    videos: Video[]
}

const KeenVideoSlider: React.FC<Props> = ({ videos }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const ytReady = useYouTube();
    const [sliderRef, slider] = useKeenSlider({
        slidesPerView: 1.5,
        mode: "snap",
        spacing: 15,
        centered: true,
        // initial: currentIndex,
        loop: true,
        beforeChange: slider => {
            const details = slider.details();
            if (!isNaN(details.relativeSlide) && details.direction !== 0) {
                let nextSlide = (details.relativeSlide + details.direction) % videos.length;

                if (nextSlide < 0) {
                    nextSlide += videos.length;
                }

                setCurrentIndex(nextSlide);
            }
        },
        breakpoints: {
            "(min-width: 768px)": {
                slidesPerView: 3.5,
            },
        }
    });

    const onItemClick = (index: number) => {
        if (index === videos.length - 1 && currentIndex === 0) {
            slider.prev();
            setCurrentIndex(videos.length - 1);
        } else if (index === 0 && currentIndex === videos.length - 1) {
            slider.next();
            setCurrentIndex(0);
        } else if (index > currentIndex) {
            slider.next();
            setCurrentIndex(currentIndex + 1);
        } else {
            slider.prev();
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        // @ts-ignore
        <div ref={sliderRef} className="keen-slider">
            {videos.map((video, index) => (
                <div key={`video-${index}`} className={cx("keen-slider__slide", {
                    "keen-slider__slide-current": index === currentIndex
                    //@ts-ignore
                })} onClick={() => onItemClick(index)}>
                    <VideoSliderItem ytReady={ytReady} index={index} currentIndex={currentIndex} item={video} />
                </div>
            ))}
        </div>
    )
};

export default KeenVideoSlider;
