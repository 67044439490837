import {useState, useEffect} from "react";

const TIMEOUT = 5000;

/**
 * Delays rendering its children until the page is scrolled or a timeout period has passed, whichever happens first
 * @param children  The child elements to render
 * @param timeout   Number of milliseconds to for the render delay to timeout
 */
const DelayRender = ({ children, timeout = TIMEOUT }) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        const onTrigger = function() {
            window.removeEventListener("scroll", this);
            window.clearTimeout(timer);

            setShouldRender(true);
        };

        window.addEventListener("scroll", onTrigger);
        const timer = window.setTimeout(onTrigger, timeout);

        return () => {
            window.removeEventListener("scroll", onTrigger);
            window.clearTimeout(timer);
        }
    }, [timeout]);

    return shouldRender ? children : <></>;
};

export default DelayRender;