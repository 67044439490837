const HIDDEN = 'hidden';

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const setCookie = (name, value, days, domain) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/' + (domain ? '; domain=' + domain : '');
};

export const setBannerCookie = (bannerId) => {
  return setCookie(bannerId, HIDDEN, 30);
};

export const hasBannerCookie = (bannerId) => {
  if (bannerId) {
    return getCookie(bannerId) === HIDDEN;
  }
  return true;
};