import { combineReducers } from 'redux';
import { useSelector, shallowEqual } from 'react-redux';

import careers from './careers';
import faqs from './faqs';
import learningCenter from './learningCenter';
import menus from './menus';
import pages from './pages';
import posts from './posts';
import quote from './quote';
import siteInfo from './siteInfo';

//MOE-404
import noHealthQuote from '../components/quote/new/experimentalComponents/MOE-404-NohealthQuote/nhQuoteLogic';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    careers,
    faqs,
    learningCenter,
    menus,
    pages,
    posts,
    quote,
    noHealthQuote, //MOE-404
    siteInfo,
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = selector => {
    return useSelector(selector, shallowEqual);
};

export default RootReducer;
