import React from 'react';

import PageContent from '../../../../components/_common/pageContent';
import PageSection from '../../../../components/_common/section/page';
import TrustModule from '../../../../components/_common/modules/trust';
import StepModule from '../../../../components/_common/modules/step';
import ReviewSliderModule from '../../../../components/_common/modules/reviewSlider';
import CTAModule from '../../../../components/_common/modules/cta';
import PrimaryButton from '../../../../components/_common/button/primary';
import AltQuoteText from '../../alt-quote-text';
import PrimaryLargeButton from '../../../../components/_common/button/primary/large';
import QuoteHeroImg from '../../heroImg/';
import QuoteHeader from '../../quote-header';
import QuoteSteps from '../../quote-steps';
import Grid from '../../../../components/_common/grid';
import Subtitle1 from '../../../../components/_common/text/heading/subtitle1';
import BodyText from '../../../../components/_common/text/body-text';
import FAQList from '../../../../components/_common/modules/faqs/list';
import H2Serif from '../../../../components/_common/text/heading/h2/serif';

import FAQs from './faqs';
import REVIEWS from './reviews';

import {
    QuoteInstantHandIcon,
    ThriftyIcon,
    MobileIcon,
} from '../../../../images/icons/index';

const NoExamLifeQuotePage: React.FC = () => {
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
        <PageContent className="partner-quote" showTopNav={false}>
            <QuoteHeader
                hero={{
                    text:
                        'Skip the medical exams and get your instant quote with Bestow. Coverage today, if approved.',
                }}
                title={
                    <>
                        No med exam
                        <br />
                        life insurance
                    </>
                }
                bannerText="NO MEDICAL EXAM / INSTANT DECISION"
            />
            <QuoteHeroImg />
            <TrustModule className="bg-off-white" />
            <AltQuoteText
                headerText="Term life insurance policies with your medical history only"
                quoteText="No medical exam life insurance is as simple as it sounds. Instead
                    of the usual medical exam often required by some insurers, Bestow asks a few
                    medical questions online and technology does the rest. It takes as little as 5
                    minutes to apply, and you’ll get an instant decision. If approved, you could
                    enjoy some financial protection for your loved ones today. That’s right. Today."
            />
            <QuoteSteps headerText="How to get term life insurance without a medical exam" />
            <div className="mb-8 sm:mb-16">
                <ReviewSliderModule reviews={REVIEWS} />
            </div>
            <div className="bg-off-white pt-8 sm:pt-10">
                <StepModule
                    title={
                        <>
                            Term life insurance{' '}
                            <br className="hidden sm:block" />
                            with no exam required. Ever.
                        </>
                    }
                    step1={{
                        Icon: MobileIcon,
                        title: 'Convenient',
                        text: `Explore your life insurance options without leaving the couch. No health exam. No
                            hassles. No drama. Just answer a few simple questions online.`,
                    }}
                    step2={{
                        Icon: QuoteInstantHandIcon,
                        title: 'Instant',
                        text: `Not only is your initial quote instant, but our cool technology means the
                            underwriting process is also super fast. Apply in minutes and get an instant decision.`,
                    }}
                    step3={{
                        Icon: ThriftyIcon,
                        title: 'Affordable',
                        text: `Bestow offers affordable life insurance rates starting at just $10/mo. Get coverage
                            from $50,000 to $1.5 million, if approved.`,
                    }}
                />
            </div>
            <PageSection className="bg-spruce-darkest py-16 sm:py-24">
                <Grid className="mb-16">
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10">
                        <Subtitle1 className="text-white text-center mb-6">
                            Tech-enabled life insurance for lower rates
                        </Subtitle1>
                        <BodyText className="text-center text-white">
                            Our mission is to help expand access to life
                            insurance coverage, and to make it as easy as we can
                            to apply. Some life insurance providers require
                            medical exams, blood work, test results and more,
                            but our technology lets you skip those steps,
                            hopefully helping you find a better rate in a
                            fraction of the time you might expect.
                        </BodyText>
                    </div>
                </Grid>
                <Grid>
                    <div className="col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-10">
                        <Subtitle1 className="text-white text-center mb-6">
                            Benefits of no-medical exam insurance
                        </Subtitle1>
                        <BodyText className="text-center text-white">
                            Depending on the type of coverage you’re looking
                            for, there could be a number of steps involved in
                            applying for life insurance. One step that some
                            might prefer to skip is the medical exam. We get it.
                            Maybe getting an appointment is a hassle. Maybe you
                            don’t like needles (and really, who does?). More
                            than anything, this step takes time. Not just the
                            appointment, but waiting for results and including
                            those in your application can take weeks. Bestow’s
                            no medical exam term life insurance application
                            means just that. Apply online in minutes and never
                            take a medical exam. Apply for between a 10 and
                            30-year term policy and you could have coverage
                            today, if approved. Yeah, you read that right.
                            Today.
                        </BodyText>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="py-16 sm:py-20 sm:py-24">
                <Grid>
                    <H2Serif className="text-navy text-center mb-16 col-span-full sm:col-start-2 sm:col-span-6 lg:col-start-3 lg:col-span-8">
                        No-medical exam <br className="hidden sm:block" />
                        life insurance questions
                    </H2Serif>
                </Grid>
                <FAQList faqs={FAQs} />
            </PageSection>
            <CTAModule
                className="bg-off-white"
                title={
                    <>
                        Coverage today, if approved.
                        <br />
                        Yep. It's that easy.
                    </>
                }
            >
                <div className="w-full px-8 flex justify-center">
                    <PrimaryButton
                        className="w-full sm:hidden"
                        onClick={scrollToTop}
                    >
                        Get a quote
                    </PrimaryButton>
                    <PrimaryLargeButton
                        className="hidden sm:block"
                        onClick={scrollToTop}
                    >
                        Get a quote
                    </PrimaryLargeButton>
                </div>
            </CTAModule>
        </PageContent>
    );
};

export default NoExamLifeQuotePage;
