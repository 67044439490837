import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {fetchPost} from "../../../../redux/learningCenter";
import {lcPostSelector} from "../../../../redux/learningCenter/selectors";

import './index.scss';

interface Props {
    slug: string,
}

const PostImage: React.FC<Props> = ({slug}) => {
    const dispatch = useDispatch();
    const post = useSelector(lcPostSelector(slug));

    const imageUrl = post?._embedded[`wp:featuredmedia`]?.[0].media_details?.sizes['large'].source_url;

    useEffect(() => {
        if (!post) {
            dispatch(fetchPost(slug));
        }
    }, [dispatch, post, slug]);

    return (
        <div className="mr-4 lg:mr-6">
            <div className="post-image bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${imageUrl})`}} />
        </div>
    )
};

export default PostImage;
