import React from 'react';
import cx from 'classnames';

import './index.scss';

interface Props {
    className?: string,
    error?: boolean
}

const HelperText: React.FC<Props> = ({ className = "", error, children }) => {
    const classes = cx("helper-text text-xs font-sans leading-tight font-light text-gray-600", className, {
        "text-error": error
    });

    return (
        <div className={classes} data-testid={error ? "error" : ""}>
            {children}
        </div>
    )
};

export default HelperText;