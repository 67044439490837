export const trackIt = (event, object) => {
    if (!window.analytics && process.env.REACT_APP_LOCAL_ANALYTICS_FLAG) {
        window.analytics = {};
        window.analytics.testbin = [];
        window.analytics.track = (event, object) => {
            const eventToTrack = `Received event ${event} and object of ${JSON.stringify(
                object,
            )}`;
            window.analytics.testbin.push(eventToTrack);
        };
    }
    if (typeof window !== `undefined` && window.analytics) {
        window.analytics.track(event, object);
    }

    window?.utag?.link({ tealium_event: event, ...object });
};

export const trackApplyNowClicked = payload => {
    trackIt('apply_now_clicked', payload);
};

export const trackQuoteGetQuoteClicked = payload => {
    trackIt('quote_get_quote_clicked', {
        gender: payload.gender,
        birthdate: payload.birth_date,
        height_feet: payload.height_feet,
        height: payload.height_inches,
        state: payload.state,
        weight: payload.weight,
        tobacco: payload.tobacco,
    });
};

export const trackIndicativeQuoteGenerated = payload => {
    trackIt('indicative_quote_generated', {
        gender: payload.gender,
        birth_date: payload.birth_date,
        height_feet: payload.height_feet,
        height_inches: payload.height_inches,
        state: payload.state,
        weight: payload.weight,
        tobacco: payload.tobacco,
        eligible: payload.eligible,
    });
};

export const trackProductSelect = data => trackIt('product_select', data);
export const trackCoverageSelect = data => trackIt('coverage_slider', data);
export const trackStartApp = data =>
    trackIt('quote_to_start_application', data);
export const trackBackButtonClick = () =>
    trackIt('quote_back_button_clicked', null);
export const trackStateSelect = value =>
    trackIt('state_select', { selected_state: value });
export const trackWidgetVisited = () => trackIt('quote_widget_visited', null);

export const trackSegmentPageView = payload => {
    if (window && window.analytics) {
        window.analytics.page();
    }
};

export const trackGerberApplyClicked = payload => {
    trackIt('gerber_apply_now_clicked', payload);
};
