import {useSelector} from 'react-redux';

import {quotePartnerSelector} from '../../../../redux/quote/selectors';
import {PARTNER_KEYS} from '../data/partnerConstants';

/**
 * Returns the Partner name to use for Decline screen referrals
 * @param declineReason The ineligible reason
 * @param gerberEligible    Flag to indicate whether the current page / quote widget is opted into Gerber referrals
 */
export default function useDeclineQuotePartnerName(declineReason: string, gerberEligible: boolean = false) {
    const quotePartner = useSelector(quotePartnerSelector);
    const quotePartnerName = quotePartner?.partner?.name;

    // MOE-458 - Force Gerber decline screen for age declines
    if (declineReason === "age" && gerberEligible) {
        return PARTNER_KEYS.gerber
    }

    return quotePartnerName;
}
