import { useEffect, useCallback } from 'react';

import { getCookie, setCookie } from "../utilities/cookies";

const COOKIE_NAME = "segment-aliases";

/**
 * Grabs the user's anonymousId from Segment and stores it in a cookie so it can be used during Account Registration
 * to tie pre-registration events to users in Iterable post-registration.
 *
 * The cookie is stored as a JSON array, so we can potentially capture multiple aliases from multiple applications
 * (subdomains) if desired.
 */
function useTrackSegmentAlias() {
    const setSegmentAnonymousId = useCallback(() => {
        if (window.analytics && window.analytics.user) {
            // First, grab the user's anonymousId from Segment
            const anonymousId = window.analytics.user().anonymousId();

            // Next, grab all the existing aliases from the cookies
            const aliases = new Set(JSON.parse(getCookie(COOKIE_NAME) || "[]"));

            // Add the anonymousId to the cookie if it's not already tracked and store for 90 days.
            if (!aliases.has(anonymousId)) {
                aliases.add(anonymousId);

                // If we're running under a *.bestow.com domain then make sure we save the cookie with the main bestow.com domain
                const domain = document.domain.match(/bestow/) ? "bestow.com" : document.domain;
                setCookie(COOKIE_NAME, JSON.stringify(Array.from(aliases)), 90, domain);
            }
        } else {
            // user object might not be initialized yet, so wait and try again in 2s
            window.setTimeout(setSegmentAnonymousId, 2000);
        }
    }, []);

    useEffect(() => {
        setSegmentAnonymousId();
    }, [setSegmentAnonymousId]);
}

export default useTrackSegmentAlias;