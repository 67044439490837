import React from 'react';
import cx from 'classnames';

import NavBarLink from '../link';
import Card from '../../_common/card';
import PrimaryNavButton from '../../_common/button/primary/nav';
import SmallText from '../../_common/text/small-text';
import { openSalesforceChat } from '../../../utilities/salesforce';

import {
    ChatIcon,
    CloseIcon,
    LogInIcon,
    PhoneIcon,
} from '../../../images/icons';
import { BestowNavyLogo } from '../../../images/logos';

import './index.scss';
import { trackQuoteCTAClicked } from '../../quote/new/api/analytics';

interface Props {
    open: boolean;
    onClose: () => void;
}

const NavDrawer: React.FC<Props> = ({ open, onClose }) => {
    return (
        <Card
            className={cx(
                'px-4 py-6 transform transition-all bg-white absolute top-0 left-0 right-0 z-20 lg:hidden',
                {
                    'translate-y-0': open,
                    '-translate-y-full': !open,
                },
            )}
        >
            <div className="flex justify-center relative">
                <CloseIcon
                    className="absolute top-2 left-0"
                    data-cy="mobile.close.icon"
                    onClick={onClose}
                />
                <BestowNavyLogo />
            </div>
            <div
                className="mt-24 flex flex-col items-center"
                data-cy="mobile.menu.section"
            >
                <div className="mb-12" data-cy="mobile.insurance.101">
                    <NavBarLink to={'/insurance-101'} onClick={onClose}>
                        Insurance 101
                    </NavBarLink>
                </div>
                <div className="mb-12">
                    <NavBarLink to={'/learningcenter'} onClick={onClose}>
                        More Resources
                    </NavBarLink>
                </div>
                <div data-cy="mobile.get.quote">
                    <PrimaryNavButton
                        className="w-full mb-24"
                        to="/quote"
                        onClick={trackQuoteCTAClicked}
                    >
                        Get Quote
                    </PrimaryNavButton>
                </div>
                <div className="flex justify-center">
                    <a
                        className="engage__link bestow-track flex items-center mr-6 no-underline"
                        href="https://customer.bestow.com"
                        rel="noopener noreferrer"
                        target="_blank"
                        data-cy="mobile.login"
                    >
                        <LogInIcon className="mr-2" />
                        <SmallText className="text-charcoal-60">
                            LOG IN
                        </SmallText>
                    </a>
                    <div className="mr-2">
                        <a
                            className="engage__link bestow-track flex items-center mr-6 no-underline"
                            href="https://bstow.me/my-agent"
                            data-track-name="phone_h_v1"
                            data-cy="mobile.phone"
                        >
                            <PhoneIcon className="mr-2" />
                            <SmallText className="text-charcoal-60">
                                833-300-0603
                            </SmallText>
                        </a>
                    </div>
                    <a
                        className="engage__link bestow-track flex items-center no-underline"
                        href="#"
                        onClick={openSalesforceChat}
                        data-track-name="chat_h_v1"
                        data-cy="mobile.live.chat"
                    >
                        <ChatIcon className="mr-2" />
                        <SmallText className="text-charcoal-60">CHAT</SmallText>
                    </a>
                </div>
            </div>
        </Card>
    );
};

export default NavDrawer;
