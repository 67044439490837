export const privacyData = {
    body: [
        `You can print this document by clicking “Print” in your browser settings.`
    ],
    sections: [
        {
            title: `At Bestow, we use data to modernize the life insurance application process`,
            body: [
                `This notice describes how Bestow Inc., Bestow Agency LLC dba Bestow Insurance Services in CA, and Bestow Life Insurance Company (known as Centurion Life Insurance Company in some states) (“<span class='font-medium'>Bestow</span>,” “<span class='font-medium'>we</span>”, “<span class='font-medium'>us</span>” or “<span class='font-medium'>our</span>”) collect, use, and disclose the Personal Information of California residents.  “Personal Information” is any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular California resident or household.`,
                `This notice and the rights described in it only apply to Personal Information that is subject to the CCPA. To understand how Bestow handles all personal information, see the <a href='/privacy' class='font-medium'>Bestow Privacy Policy.</a>`,
            ]
        },
        {
            title: `What categories of Personal Information do we collect?`,
            body: [
                `We collect data to do things like streamline the life insurance application process, verify your identity, improve your customer experience, and for recruiting and hiring. The categories of Personal Information we collect may vary. In the past 12 months, we’ve collected the following Personal Information relating to California residents:`,
                `<ul>
                    <li>Identifiers like name, address, and Social Security number.</li>
                    <li>Commercial Information, such as life products purchased.</li>
                    <li>Characteristics of protected classifications under California or federal law, such as sex and age. </li>
                    <li>Internet or network activity information like browsing history and website interactions.</li>
                    <li>Audio, electronic, or visual information, such as call recordings.</li>
                    <li>Professional Information or employment information, such as work history and prior employer.</li>
                    <li>Education Information, such as school and date of graduation.</li>
                    <li>Inferences drawn from any of the Personal Information listed above to create a profile about, for example, an individual’s preferences and characteristics.</li>
                </ul>`
            ],
        },
        {
            title: `From where do we collect your Personal Information? `,
            body: [
                `The information we collect depends on business purposes and user relationships. We may collect personal information from:`,
                `<ul>
                    <li>Website interactions</li>
                    <li>California residents or affiliates directly</li>
                    <li>Operating Systems, platforms, and ISPs</li>
                    <li>Public records</li>
                    <li>Employment, consumer reporting, or credit agencies (for job applicants)</li>
                    <li>Employers (for job applicants)</li>
                    <li>Service providers</li>
                    <li>Other references</li>
                </ul>`
            ],
        },
        {
            title: `How do we use your Personal Information?`,
            body: [
                `For information about how we use your Personal Information, see our <a href='/privacy' class='font-medium'>Bestow Privacy Policy</a>.`,
                `In addition, for prospective employees, we may use your Personal Information for recruiting, hiring, managing, and evaluating job applicants, including:`,
                `<ul>
                    <li>Scheduling and conducting interviews</li>
                    <li>Identifying Applicants, including by working with external recruiters</li>
                    <li>Reviewing, assessing and verifying information provided, to conduct criminal and background checks (where relevant and pursuant to applicable law), and to otherwise screen or evaluate Applicants’ qualifications, suitability and relevant characteristics</li>
                    <li>Extending offers, negotiating the terms of offers and assessing salary and compensation matters</li>
                    <li>Satisfying legal and regulatory obligations</li>
                    <li>Communicating with Applicants regarding their applications and about other similar position(s) for which they may be interested</li>
                    <li>Maintaining Applicant personal information for future consideration</li>
                    <li>Supporting our equal opportunity employment policy and practices</li>
                </ul>`
            ],
        },
        {
            title: `Do we sell your Personal Information?`,
            body: [
                `We do not sell your personal information. We may share your Personal Information with our subsidiaries, affiliates, and service providers to:`,
                `<ul>
                    <li>Provide information about our products and services</li>
                    <li>Perform various business functions, such as data analytics, marketing and advertising, market research, mailings, recruiting and hiring, fraud prevention, and other IT and administrative support functions</li>
                 </ul>`,
                `The personal information disclosed may include Identifiers, Characteristics, Commercial information, Internet or network activity, Professional information, or Education information. We do not authorize our service providers to use or disclose this information except as necessary to perform services or functions on our behalf, or to comply with legal requirements.`
            ],
        },
        {
            title: `What are your California Privacy Rights?`,
            body: [
                `If you are a California resident, you may have certain rights regarding personal information covered by the CCPA; however, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. `,
            ],
            subSections: [
                {
                    title: 'Right to know',
                    body: [
                        `You can request the following information from the past 12 months:`,
                        `<ul>
                            <li>Categories of Personal Information we have collected and used.</li>
                            <li>Categories of sources from which we collected Personal Information.</li>
                            <li>Business or commercial purposes for collecting or sharing personal information.</li>
                            <li>Categories of third parties with whom we share Personal Information.</li>
                            <li>Whether we have disclosed your personal information for a business purpose, and if so, the categories of Personal Information received by each category of third party recipient.</li>
                            <li>Whether we’ve sold your Personal Information, and if so, the categories of Personal Information received by each category of third party recipient.</li>
                        </ul>`
                    ]
                },
                {
                    title: 'Right to access',
                    body: [
                        `You can request a copy of the Personal Information we have collected about you during the past 12 months. `
                    ]
                },
                {
                    title: 'Right to delete',
                    body: [
                        `You can ask us to delete the Personal Information we have collected from you.`
                    ]
                },
                {
                    title: 'Nondiscrimination',
                    body: [
                        `You are entitled to exercise the rights described above free from discrimination. This means that we will not penalize you for exercising your rights by taking actions such as denying you services; increasing the price or rate of services; decreasing service quality; or suggesting that we may penalize you as described above for exercising your rights.`
                    ]
                }
            ]
        },
        {
            title: `How can you exercise your rights?`,
            body: [
                `You may exercise your California privacy rights described above by:`,
                `<ul>
                    <li>visiting <a href="/ccpa-form" class="font-medium">https://www.bestow.com/ccpa-form</a></li>
                    <li>calling us toll free at 833-300-0603</li>
                </ul>`,
                `We will need to confirm your identity by matching information you provide to us with the Personal Information we have about you to process your request to exercise your information, access, or deletion rights.  We can’t process your request if you don’t provide us with sufficient detail to allow us to understand and respond to it. We will decline your request with respect to information that is subject to the Gramm-Leach-Bliley Act (GLBA) or other CCPA exemptions.`,
            ],
            subSections: [
                {
                    title: 'Changes to this California Consumer Privacy Act Notice',
                    body: [
                        `We may change or update this notice from time to time. When we do, we will post the revised Notice on our site with a new “Last Updated” date.`,
                        `Effective Date: Jan 1, 2022<br/>
                         Last Updated: Jan 1, 2022`,
                    ]
                }
            ]
        },

    ]
};
