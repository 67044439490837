import React from 'react';

import PageContent, { Props } from "../";

import './index.scss';

const PageContentNavy: React.FC<Props> = ({ className = "", ...otherProps }) => {
    return (
        <PageContent className={`gradient-navy ${className}`} {...otherProps} />
    )
};

export default PageContentNavy;
