import React, { useState, useMemo } from 'react';
import TabButton from "../button/tab";

import {ReactComponent as DownArrow} from "../../../images/Down Arrow.svg";

import './index.scss';

interface Props {
    className?: string,
    title: string,
    tabs: {
        value: any,
        label: string
    }[],
    defaultTab?: number,
    onChange?: Function,
    value?: number
}

const TabBar: React.FC<Props> = ({ className, title, tabs, defaultTab = 0, onChange, value }) => {
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const [expanded, setExpanded] = useState(false);

    const onTabClick = (index: number, isExpanded: boolean) => {
        if (isExpanded) {
            setCurrentTab(index);
            onChange && onChange(index);
        }
        setExpanded(!expanded);
    };

    const current = value || currentTab;

    const mobileTabs = useMemo(() => {
        const theTabs = [tabs[currentTab]];

        if (!expanded) {
            return theTabs;
        }

        const otherTabs = [...tabs];
        otherTabs.splice(currentTab, 1);
        return theTabs.concat(otherTabs);
    }, [currentTab, expanded, tabs]);

    return (
        <div className={`tab-bar ${className}`}>
            <div className="tab-bar-title font-bold uppercase font-sans text-charcoal text-center mb-4 sm:mb-6">
                {title}
            </div>
            <div className="hidden sm:flex justify-center">
                {tabs.map(({label, value}, index) => (
                    <TabButton
                        selected={current === index}
                        onClick={() => onTabClick(index, true)}
                        className="mx-4"
                        key={`tab-button-${index}`}
                    >
                        {label}
                    </TabButton>
                ))}
            </div>
            <div className="tabs-mobile sm:hidden bg-off-white mx-4">
                {mobileTabs.map((tab, index) => (
                    <div
                        className="tab-mobile flex items-center"
                        onClick={() => onTabClick(tabs.indexOf(tab), expanded)}
                        key={`mobile-tab-${index}`}
                    >
                        <div className="label text-center text-gray-800">
                            {tab.label}
                        </div>
                        {index === 0 &&
                            <div className="flex flex-1 justify-end">
                                <DownArrow className="mr-5 ml-7" />
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
};

export default TabBar;
