import React from 'react';

import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import SecondaryButton from "../../../components/_common/button/secondary";
import Eyebrow from "../../../components/_common/text/eyebrow";
import Subtitle1 from "../../../components/_common/text/heading/subtitle1/index";
import LinkButton from "../../../components/_common/button/link";
import H1Serif from "../../../components/_common/text/heading/h1/serif";
import BodyText from "../../../components/_common/text/body-text";
import SecondaryButtonSmall from "../../../components/_common/button/secondary/small";

import { ButtonArrowRight } from "../../../images/svgs";

const CareersHero: React.FC = () => {
    return (
        <>
            <PageSection className="bg-center bg-cover bg-careers-main-hero sm:bg-careers-main-hero-lg pt-7 pb-72 sm:py-7 lg:pt-11 lg:pb-32">
                <Eyebrow className="mb-2 text-center sm:text-left">
                    CAREERS
                </Eyebrow>
                <Grid>
                    <H1Serif className="col-span-full sm:col-span-4 lg:col-span-6 mb-0 text-center sm:text-left">
                        We're Hiring!
                    </H1Serif>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 flex items-center justify-center sm:items-end lg:items-center">
                        <SecondaryButton className="hidden sm:flex" Icon={ButtonArrowRight} to="/openings">
                            See our current openings
                        </SecondaryButton>
                        <SecondaryButtonSmall className="sm:hidden" Icon={ButtonArrowRight} to="/openings">
                            See our current openings
                        </SecondaryButtonSmall>
                    </div>
                    <div className="hidden sm:block sm:col-start-5 sm:col-span-4 lg:col-start-7 lg:col-span-6 sm:pt-9 lg:pt-12 sm:pl-2">
                        <Subtitle1 className="mb-4 text-center">
                            Our purpose is to<br/>
                            make hard things easier
                        </Subtitle1>
                        <BodyText className="mb-6">
                            Life insurance is an old industry. Like really old. And the historic names have been doing it one
                            way for a long time. Not us. We’re here to reimagine the entire industry, creating game-changing
                            tech, AI, ML, and platforms. Our goal is to simplify a traditionally complicated product, and give
                            more families than ever before access to affordable financial tools. Our incredible team is devoted
                            to craft, cooperation, and making hard things easier.
                        </BodyText>
                        <div className="flex justify-center">
                            <LinkButton to="/openings">
                                Come join us!
                            </LinkButton>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="sm:hidden py-14">
                <Subtitle1 className="text-center mb-4">
                    Our purpose is to<br/>
                    make hard things easier
                </Subtitle1>
                <BodyText className="mb-6">
                    Life insurance is an old industry. Like really old. And the historic names have been doing it one
                    way for a long time. Not us. We’re here to reimagine the entire industry, creating game-changing
                    tech, AI, ML, and platforms. Our goal is to simplify a traditionally complicated product, and give
                    more families than ever before access to affordable financial tools. Our incredible team is devoted
                    to craft, cooperation, and making hard things easier.
                </BodyText>
                <div className="flex justify-center">
                    <LinkButton to="/openings">
                        Come join us!
                    </LinkButton>
                </div>
            </PageSection>
        </>
    )
};

export default CareersHero;