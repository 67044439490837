import {useShallowEqualSelector} from "../index";
import {createSelector} from "@reduxjs/toolkit";
import {forceTrailingSlash} from "./index";

export const partnerSettingsSelector = state => state.pages.partnerGlobalSettings;
export const agentSettingsSelector = state => state.pages.agentGlobalSettings;
export const currentTemplateSelector = state => state.pages.currentPage?.template;
export const currentHeroTypeSelector = state => state.pages.currentPage?.acf?.hero_type;
const pageMapSelector = state => state.pages.pageMap;
export const loadingSelector = state => state.pages.loading;

export const allSlugsSelector = createSelector(
    pageMapSelector,
    pageMap => Object.keys(pageMap)
);

/**
 * Returns the given page
 */
export const usePageSelector = (path) => {
    return useShallowEqualSelector(({ pages: { loading, pageMap } }) => {
        let page = null;
        path = forceTrailingSlash(path);
        if (pageMap[path]) {
            page = pageMap[path];
        }

        return {
            loading,
            page
        };
    });
};

export const usePagesSelector = () => {
    return useShallowEqualSelector(({ pages: { loading, pageMap } }) => {
        return {
            loading,
            pages: Object.values(pageMap)
        };
    });
};

/**
 * Returns the flag for whether to show the banner or not
 */
export const usePageBannerSelector = () => {
    return useShallowEqualSelector(({ pages: { showBanner } }) => {
        return showBanner;
    });
};