import { useEffect } from 'react';
import trackPageView from '../utilities/iterable';
import { useLocation } from 'react-router-dom';

/**
 * Listens to pathname and hash updates and executes code as needed
 */
const useOnPageChange = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const hash = location.hash;

    useEffect(() => {
        trackPageView();
        window.analytics?.page(); // Track page view in Segment

        // Tealium page view, replaced with logger in omnichannel
        if (window.navigator !== 'ReactSnap') {
            // This breaks our pre-render for some reason
            window?.utag?.view({
                tealium_event: 'page_view',
                page_name: pathname,
            });
        }
        if (!hash) {
            window.scrollTo({ top: 0 });
        }
    }, [pathname, hash]);
};

export default useOnPageChange;
