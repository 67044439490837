import React from 'react';

import PageContentNavyFifty from '../../components/_common/pageContent/navy/fifty';
import PageSection from '../../components/_common/section/page';
import Grid from '../../components/_common/grid';
import H1Serif from '../../components/_common/text/heading/h1/serif';
import BodyTextLarge from '../../components/_common/text/body-text/large';
import PrimaryButton from '../../components/_common/button/primary';
import H4Sans from '../../components/_common/text/heading/h4/sans';
import BodyTextBold from '../../components/_common/text/body-text/bold';
import H2Serif from '../../components/_common/text/heading/h2/serif';
import Subtitle1 from '../../components/_common/text/heading/subtitle1';
import Subtitle2 from '../../components/_common/text/heading/subtitle2';
import BodyText from '../../components/_common/text/body-text';
import PrimaryLargeButton from '../../components/_common/button/primary/large';
import CTAModuleTwo from '../../components/_common/modules/cta/two';
import SecondaryButton from '../../components/_common/button/secondary';
import { SingleFAQList } from '../../components/_common/modules/faqs/list';

import laptopImg from '../../images/agents/agents-laptop.jpeg';
import phonesImg from '../../images/agents/agents-phones.jpeg';
import contractedImg from '../../images/agents/TrackingLink2.svg';
import sharingImg from '../../images/agents/Advisor.svg';
import commissionsImg from '../../images/agents/Coins.svg';

import faqs from './faqs';

import './index.scss';

const Agents: React.FC = () => {
    return (
        <PageContentNavyFifty className="agents p-0">
            <PageSection className="pt-7 sm:pt-12 lg:pt-20">
                <Grid>
                    <H1Serif className="col-span-full lg:col-span-11 mb-12 sm:mb-24 lg:mb-20 text-center sm:text-left text-navy">
                        Term life in minutes with zero case managment
                    </H1Serif>
                    <BodyTextLarge className="col-span-full lg:col-start-2 lg:col-span-10 text-center mb-12 sm:mb-8 sm:px-8 lg:px-0 text-charcoal">
                        No forms, no files, no following up. Your clients get an instant decision. You collect a
                        commission and keep on selling.
                    </BodyTextLarge>
                    <div className="col-span-full flex justify-center mb-16 sm:mb-24 lg:mb-32">
                        <PrimaryButton href="https://vimeo.com/596788978">
                            Learn more
                        </PrimaryButton>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 pr-8 sm:pr-4 lg:pr-12">
                        <img src={laptopImg} alt="Your unique URL is now your easiest way to sell." className="w-full" />
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6">
                        <H4Sans className="my-4 sm:mt-0 lg:mb-9">
                            Your unique URL is now your easiest way to sell.
                        </H4Sans>
                        <BodyTextLarge className="mb-7 lg:mb-12 text-charcoal">
                            Clients can go from quote to covered in as little as 5 minutes.
                        </BodyTextLarge>
                        <BodyTextBold className="text-charcoal">
                            <ul>
                                <li>
                                    Clients answer simple health and lifestyle questions
                                </li>
                                <li>
                                    Get underwriting results in about 60 seconds
                                </li>
                                <li>
                                    If approved, coverage begins upon payment
                                </li>
                            </ul>
                        </BodyTextBold>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="pt-16 sm:pt-24 lg:pt-32">
                <Grid>
                    <div className="col-span-full sm:col-start-5 sm:col-span-4 lg:col-start-1 lg:col-span-full mb-8 lg:mb-24 text-center flex items-center">
                        <H2Serif className="w-full text-navy">
                            100% digital, 0% paperwork
                        </H2Serif>
                    </div>
                    <div className="col-span-full lg:col-span-5 mt-8 sm:mt-0">
                        <Subtitle1 className="mb-6 text-center sm:text-left text-navy">
                            No medical exam, ever
                        </Subtitle1>
                        <BodyTextLarge className="mb-12 lg:mb-14">
                            With pricing comparable to fully underwritten policies, Bestow is changing the game with its
                            instant issue experience.
                        </BodyTextLarge>
                        <Subtitle1 className="mb-6 text-center sm:text-left text-navy">
                            Coverage today
                        </Subtitle1>
                        <BodyTextLarge className="mb-12 lg:mb-14">
                            If approved, your client can start coverage immediately upon payment.
                        </BodyTextLarge>
                        <Subtitle1 className="mb-6 text-center sm:text-left text-navy">
                            Credit card payments
                        </Subtitle1>
                        <BodyTextLarge>
                            We accept major credit cards, Apple Pay&reg;, & Google Pay&reg;.
                        </BodyTextLarge>
                    </div>
                    <div className="row-start-2 col-span-full sm:row-start-1 sm:col-start-1 sm:col-span-4 lg:row-start-2 lg:col-start-7 lg:col-span-6 px-6 sm:pr-0 sm:pl-6 lg:pl-0 sm:mb-8 lg:mb-0">
                        <img src={phonesImg} alt="100% digital, 0% paperwork" className="w-full" />
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="my-24 lg:my-32">
                <H2Serif className="text-center mb-12 sm:mb-16 lg:mb-12 text-navy">
                    Getting started<br className="sm:hidden"/> is easy.
                </H2Serif>
                <Grid smCols={3}>
                    <div className="col-span-full sm:col-span-1 lg:col-span-4 mb-8 sm:mb-0">
                        <div className="flex flex-col items-center text-center px-4 sm:px-0">
                            <img src={contractedImg} alt="Get contracted" className="w-14 h-14 mb-4" />
                            <Subtitle2 className="mb-4 text-navy">
                                Get contracted
                            </Subtitle2>
                            <BodyText>
                                Join and receive your custom, trackable sales link to your own landing page.
                            </BodyText>
                        </div>
                    </div>
                    <div className="col-span-full sm:col-span-1 lg:col-span-4 mb-8 sm:mb-0">
                        <div className="flex flex-col items-center text-center px-4 sm:px-0">
                            <img src={sharingImg} alt="Start sharing" className="w-14 h-14 mb-4" />
                            <Subtitle2 className="mb-4 text-navy">
                                Start sharing
                            </Subtitle2>
                            <BodyText>
                                Share your link with clients. They’ll use it to complete a paperless application.
                            </BodyText>
                        </div>
                    </div>
                    <div className="col-span-full sm:col-span-1 lg:col-span-4">
                        <div className="flex flex-col items-center text-center px-4 sm:px-0">
                            <img src={commissionsImg} alt="Get comissions" className="w-14 h-14 mb-4" />
                            <Subtitle2 className="mb-4 text-navy">
                                Get comissions
                            </Subtitle2>
                            <BodyText>
                                There’s no case management. Earn commission payments for purchases made through your link.
                                It’s that easy.
                            </BodyText>
                        </div>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="bg-navy text-center py-16 sm:py-24 lg:py-32">
                <H2Serif className="text-white mb-16">
                    Agents love Bestow
                </H2Serif>
                <Grid>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10 sm:px-8 lg:px-0">
                        <BodyTextLarge className="mb-6 text-white">
                            “I've been selling life insurance for over 25 years. I had to try Bestow before I would recommend it
                            to my agents or clients. The Bestow process was the slickest and most impressive system I've ever seen.
                            I had my policy in my email instantly. I was approved in minutes for $1 Million and I’m now a Bestow
                            policyholder.”
                        </BodyTextLarge>
                        <Subtitle2 className="mb-2 text-coral">
                            Jeremy M.
                        </Subtitle2>
                        <BodyText className="text-white">
                            BGA Principal
                        </BodyText>
                        <BodyTextLarge className="mb-6 mt-16 text-white">
                            “This was the easiest policy process I’ve seen and there’s no comparison. Client LOVED it.”
                        </BodyTextLarge>
                        <Subtitle2 className="mb-2 text-coral">
                            Daniel O.
                        </Subtitle2>
                        <BodyText className="text-white">
                            Individual Agent
                        </BodyText>
                        <BodyTextLarge className="mb-6 mt-16 text-white">
                            “Woo-hoo! The best part was that I could do everything online and didn't end up with 15
                            voicemails from agents afterward. Everything was clear and easy to understand.”
                        </BodyTextLarge>
                        <Subtitle2 className="mb-2 text-coral">
                            Emily E.
                        </Subtitle2>
                        <BodyText className="text-white">
                            Direct Marketer
                        </BodyText>
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="py-24">
                <H2Serif className="text-center mb-12 sm:mb-14 lg:mb-12 text-navy">
                    Got questions?
                </H2Serif>
                <Grid>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10 sm:px-8 pg:px-0">
                        <SingleFAQList faqs={faqs} />
                    </div>
                </Grid>
                <div className="flex justify-center mt-12">
                    <SecondaryButton href="https://agents.bestow.com/faq/">
                        More FAQs
                    </SecondaryButton>
                </div>
            </PageSection>
            <CTAModuleTwo
                className="bg-off-white"
                title={<span>Ready to offer Bestow<br className="hidden sm:block"/> to your clients?</span>}
                text="Get in touch, get contracted, and start earning.">
                <PrimaryLargeButton href="mailto:sales@bestow.com">
                    Get in touch
                </PrimaryLargeButton>
            </CTAModuleTwo>
        </PageContentNavyFifty>
    );
};

export default Agents;
