import React from 'react';
import cx from 'classnames';

import Grid from "../../../grid";
import H2Serif from "../../../text/heading/h2/serif";
import BodyText from "../../../text/body-text";
import PageSection from "../../../section/page";
import Subtitle2 from '../../../text/heading/subtitle2';

interface Step {
    icon?: string,
    title: string,
    text: string
}

interface Props {
    className?: string,
    title?: string,
    step1: Step,
    step2: Step,
    step3: Step
}

const SimpleStepModule: React.FC<Props> = ({ className = "", title, step1, step2, step3, children }) => {
    const classes = cx("py-16", className);

    return (
        <PageSection className={classes}>
            <Grid>
                {title &&
                    <H2Serif className="col-span-full text-navy text-center mb-6 mx-12 sm:mx-0 sm:mb-12">
                        {title}
                    </H2Serif>
                }
                <div className="col-span-full lg:col-start-2 lg:col-span-10 flex flex-col sm:flex-row justify-between">
                    <Grid cols={1} smCols={3} lgCols={3} smGap={9} lgGap={16}>
                        {[step1, step2, step3].map(({ icon, title, text }, index) => (
                            <div key={`step-${index}`} className="mb-8 mx-12 sm:mx-0 sm:mb-0">
                                {icon &&
                                    <div className="flex justify-center mb-4">
                                        <img src={icon} className="w-16 h-16" alt={title} />
                                    </div>
                                }
                                <Subtitle2 className="text-center mb-4">
                                    {title}
                                </Subtitle2>
                                <BodyText className="text-center">
                                    {text}
                                </BodyText>
                            </div>
                        ))}
                    </Grid>
                </div>
                {children &&
                    <div className="col-span-full mt-8 flex justify-center">
                        {children}
                    </div>
                }
            </Grid>
        </PageSection>
    );
};

export default SimpleStepModule;
