import { useMemo } from 'react';
import { parse, format, parseISO, isValid } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { DATE_PATTERN } from '../../../_common/inputs/dob';
import {
    GENDER_VALUE_FEMALE,
    GENDER_VALUE_MALE,
    HEIGHT_OPTIONS,
} from '../form/constants';

// List of all the query parameters that are currently supported
const SUPPORTED_KEYS = [
    'date_of_birth',
    'gender',
    'email',
    'weight',
    'height',
    'state',
    'zip',
    'tobacco',
    'coverage',
    'products',
    'product',
    'skipform',
    'mincoverage',
    'date_of_birth|yyyymmdd',
    'birthday',
    'bgcolor', // TODO: Do we need this, simply from the standpoint of passing it forward?
    'fontcolor', // TODO: Do we need this, simply from the standpoint of passing it forward?
    'utm_term',
    'utm_source',
    'utm_medium',
    'utm_name',
    'utm_content',
    'utm_campaign',
    'fbuy',
    'isIframe',
    'widget',
    'qid', // TODO: Do we need to support this?
    'bestow_writing_agent',
    'partner_referral',
    'u',
    'hier',
    'formcomplete',
];

// Union type of the supported keys: "date_of_birth" | "gender" | "email" | ...
type SupportedQueryParamKey = typeof SUPPORTED_KEYS[number];

// Structure for mapping and formatting query param keys to FormData keys/format
type KeyMappingItem = {
    toKey?: string;
    format?: (value: string) => any;
};

// This allows us to support legacy query params and convert them to match our FormData, so we can maintain some consistency
// with data across the Quote form
const SUPPORTED_KEY_MAPPINGS: Record<SupportedQueryParamKey, KeyMappingItem> = {
    'zip': {
        toKey: 'zipCode',
        format: zip => zip?.replace(/[^0-9]/g, '').substring(0, 5),
    },
    'date_of_birth': {
        toKey: 'birthdate',
        format: dob => _formatDate(dob, DATE_PATTERN.replace(/ /g, '')),
    },
    'date_of_birth|yyyymmdd': {
        toKey: 'birthdate',
        format: dob => _formatDate(dob, 'yyyyMMdd'),
    },
    'birthday': {
        toKey: 'birthdate',
        format: dob => _formatDate(dob, DATE_PATTERN.replace(/ /g, '')),
    },
    'state': {
        toKey: 'stateCode',
        format: stateCode => stateCode.toUpperCase(),
    },
    'weight': {
        format: weight => weight?.replace(/[^0-9]/g, ''),
    },
    'height': {
        format: height =>
            HEIGHT_OPTIONS.map(option => option.value).includes(height)
                ? height
                : '',
    },
    'gender': {
        format: gender =>
            [GENDER_VALUE_MALE, GENDER_VALUE_FEMALE].includes(
                gender.toLowerCase(),
            )
                ? gender.toLowerCase()
                : '',
    },
    'tobacco': {
        format: tobacco => tobacco.toLowerCase() === 'yes',
    },
    'coverage': {
        format: coverage => {
            const coverageAmount = parseInt(coverage, 10);
            return _isValidCoverageAmount(coverageAmount) ? coverageAmount : '';
        },
    },
    'mincoverage': {
        format: coverage => {
            const coverageAmount = parseInt(coverage, 10);
            return _isValidCoverageAmount(coverageAmount) ? coverageAmount : '';
        },
    },
    'u': {
        toKey: 'short_url_id',
    },
    'hier': {
        toKey: 'hierarchy',
    },
    'formcomplete': {
        toKey: 'formcomplete',
    },
};

/**
 * Returns an object containing all the supported data in the current url's query param
 * TODO: Support Products
 */
const useQueryParamData = (): Partial<Record<SupportedQueryParamKey, any>> => {
    // Use location from react-router so we can test this
    const location = useLocation();

    return useMemo(() => {
        // First, parse the Query String
        const data = new URLSearchParams(location.search);
        // Next, reduce the set of supported keys down to an object containing all of the keys that are present in the querystring
        return SUPPORTED_KEYS.reduce((ret, key) => {
            if (data.has(key)) {
                let value = data.get(key);
                let mapping = SUPPORTED_KEY_MAPPINGS[key];

                // Format any of the data that needs to get passed to the QuoteForm
                if (value && mapping?.format) {
                    value = mapping.format(value);
                }

                // Map the keys if applicable
                return {
                    ...ret,
                    [mapping?.toKey || key]: value,
                };
            }
            return ret;
        }, {});
    }, [location]);
};

function _formatDate(dateVal: string, parseFormat: string) {
    // KBM - This UTC check was in AQX. I don't like it, but better safe than sorry...
    if (dateVal.includes('UTC')) {
        dateVal = dateVal.split(' ')[0];
    }

    // First try to parse it with a known format
    let date = _parseSafe(dateVal, parseFormat);

    // Then, try ISO if that didn't work
    if (!isValid(date)) {
        try {
            date = parseISO(dateVal);
        } catch (e) {
            console.error(e);
        }
    }

    if (isValid(date)) {
        return format(date!!, DATE_PATTERN);
    }

    return '';
}

function _parseSafe(dateVal: string, parseFormat: string) {
    try {
        return parse(dateVal, parseFormat, 0);
    } catch (e) {
        console.error(e);
    }
    return null;
}

/**
 * Validates that the coverage amount is a factor of 50k
 * TODO: Pulled from AQX, move this elsewhere
 * @param coverageAmount    The number to check
 * @private
 */
function _isValidCoverageAmount(coverageAmount: number) {
    return !isNaN(coverageAmount) && coverageAmount % 50000 === 0;
}

export default useQueryParamData;
