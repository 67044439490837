const REVIEWS = [
    {
        "review_author": "Joe",
        "review_content": "All done online within 15 minutes without having to talk to anyone or do any medical exams.",
        "review_title": "Best decision...wish I would have done this sooner!",
        "stars": "5"
    },
    {
        "review_author": "Jonathan",
        "review_content": "Simple, fast and affordable. No physical needed. Excellent service.",
        "review_title": "Easy, fair and fast.",
        "stars": "5"
    },
    {
        "review_author": "Juan",
        "review_content": "Wow!!! I completed my application online & was approved within minutes without the hassle of never ending medical questions & long waiting process.",
        "review_title": "Easy and quick for many years of security",
        "stars": "5"
    },
];

export default REVIEWS;