import React from 'react';

import DeclineTemplate, { Props } from "../../index";
import LinkButton from "../../../../../../../_common/button/link";

import Quotacy from '../../../../../img/partners/quotacy.svg';

const QuotacyDeclineScreen: React.FC<Props> = (props) => {
    return (
        <DeclineTemplate {...props}>
            <div>
                <div className="flex justify-center">
                    <a href="https://www.quotacy.com/bestow" rel="noopener noreferrer" target="_blank">
                        <img src={Quotacy} alt="Quotacy" className="w-44" />
                    </a>
                </div>
                <div className="flex justify-center mt-2 mb-4">
                    <LinkButton href="https://www.quotacy.com/bestow">
                        Find coverage now &gt;
                    </LinkButton>
                </div>
            </div>
        </DeclineTemplate>
    )
};

export default QuotacyDeclineScreen;