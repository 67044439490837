const faqs = [
    {
        id: 1,
        title: {
            rendered: "How many people can I refer?"
        },
        content: {
            rendered: `You can refer as many friends or family members as you like, however you are only eligible to receive a reward for 15 (fifteen) referrals.`
        }
    },
    {
        id: 2,
        title: {
            rendered: "How/when do I get paid?"
        },
        content: {
            rendered: `Once your referred friend creates an account, you’ll get a confirmation email. This confirmation email will allow you to choose your reward. Rewards make take up to 7 days to receive. If you have any issues using or redeeming your digital gift card, please reach out to help@Tremendous.com. For all other inquiries about the status of your referral or status of receiving your gift card, refer to the referral dashboard found in your customer portal. If your question cannot be resolved, reach out to one of our Customer Service Associates via the chat on the website or emailing team@bestow.com for further assistance. Purchase of a policy is not needed for receipt of a reward.`
        }
    },
    {
        id: 3,
        title: {
            rendered: "Who should I refer?"
        },
        content: {
            rendered: `
                <ul>
                    <li>Anyone between the ages of 18-60</li>
                    <li>Must have US citizenship or permanent residences in the US</li>
                    <li>Is not a resident of the state of New York (We are not currently licensed in NY state.)</li>
                </ul>
                `
        }
    },
    {
        id: 4,
        title: {
            rendered: "Do my friends get anything?"
        },
        content: {
            rendered: `If approved, they might get a great term life insurance product. Unfortunately, insurance regulations prevent us from giving them a reward as well. `
        }
    },
    {
        id: 5,
        title: {
            rendered: "Who CAN'T I refer?"
        },
        content: {
            rendered: `
                <ul>
                    <li>You should not refer yourself, fake accounts, or the same person more than once</li>
                    <li>A resident of the state of New York</li>
                    <li>Someone outside the range of 18-60 yrs old</li>
                    <li>Non-US residents or someone that does not have permanent residence in the US.</li>
                    <li>People that you don't know</li>
                </ul>
            `
        }
    },
];

export default faqs;
