import React from 'react';

import PageSection from "../../../components/_common/section/page";
import EyebrowSlim from "../../../components/_common/text/eyebrow/slim";

import {CNN, Fastco, TechCrunch, WSJ, Forbes} from '../../../images/svgs';

import './index.scss';

const AsSeenIn: React.FC = () => {
    return (
        <PageSection className="as-seen-in sm:bg-off-white pt-7 pb-9 sm:pt-10 sm:pb-12 lg:pt-12 lg:pb-14">
            <EyebrowSlim className="text-center mb-6 sm:mb-4">
                AS SEEN IN
            </EyebrowSlim>
            <div className="hidden sm:flex justify-center flex-wrap">
                {[CNN, Fastco, TechCrunch, WSJ, Forbes].map((Icon, index) => (
                    <div key={`as-seen-in-icon-${index}`} className="mx-4">
                        <Icon />
                    </div>
                ))}
            </div>
            <div className="flex sm:hidden justify-center flex-wrap">
                {[CNN, Fastco, Forbes, TechCrunch, WSJ].map((Icon, index) => (
                    <div key={`as-seen-in-icon-${index}`} className="mb-6">
                        <Icon />
                    </div>
                ))}
            </div>
        </PageSection>
    );
};

export default AsSeenIn;
