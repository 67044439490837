import { useShallowEqualSelector } from "../index";

const FAKE_MENU = { items: [] };

/**
 * Returns the menu corresponding to the given slug
 * @param slug  Human-readable identifier of the menu to grab
 * @returns {*}
 */
export const useMenuSelector = (slug) => {
    return useShallowEqualSelector(({ menus: { menuMap } }) => {
        return menuMap[slug] || FAKE_MENU;
    });
};