import React, { useEffect, useRef, useState } from 'react';
import Grid from '../../../components/_common/grid';
import PageSection from '../../../components/_common/section/page';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import Recaptcha from '../../../components/_common/recaptcha';
import CustomFormikField from '../../../components/_common/formik/customField';
import OutsideLabelInput from '../../../components/_common/inputs/outsideLabel';
import BinarySelector from '../../../components/_common/inputs/binary-selector';
import HubspotCountrySelect from '../../../components/_common/inputs/select/hubspot-country';
import StateSelect from '../../../components/_common/inputs/select/state';
import RadioGroupAlt from '../../../components/_common/inputs/radio-group/white';
import RadioGroupWhite from '../../../components/_common/inputs/radio-group/white';
import CardRound from '../../../components/_common/card/round';
import H4Serif from '../../../components/_common/text/heading/h4/serif';
import DividerCoral from '../../../components/_common/divider/coral';
import BodyText from '../../../components/_common/text/body-text';
import PrimaryButton from '../../../components/_common/button/primary';
import Spinner from '../../../components/_common/spinner';
import Subtitle2 from '../../../components/_common/text/heading/subtitle2';
import useQueryParamData from '../../../components/quote/new/hooks/useQueryParamsData';
import TimeStamp from '../../privacy/california/form/timestamp';

const ClaimsForm: React.FC = () => {
    const [recaptchaComplete, setRecaptchaComplete] = useState(false);
    const [error, setError] = useState(false);
    const [complete, setComplete] = useState(false);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const fieldWrapperClasses = 'mb-4 sm:px-3';
    const doHtmlFormAction = (errors?: Record<string, string>) => {
        const form = document.getElementById('salesforce-claim-form');
        // @ts-ignore
        form.submit();
    };
    const { formcomplete } = useQueryParamData();

    // Pre-render does not respect query params, and will display <PageSection> with the thank you message
    // So need a way to force a re-render else formatting is all messed up on prod.
    useEffect(() => {
        if (formcomplete === 'true') {
            setComplete(true);
        }
    }, [formcomplete]);

    return !complete ? (
        <PageSection>
            <Formik
                initialValues={{
                    '00N8c00000c8koB': '', // first name
                    '00N8c00000c8ksd': '', // last name
                    '00N8c00000c8kss': '', // birth_date
                    '00N8c00000c8ksx': '', // date of death
                    '00N8c00000cBTf4': '', // policy number
                    '00N8c00000c8kuZ': '', // cause of death
                    '00N8c00000c8kuj': '', // manner of death
                    '00N8c00000c8l09': '', // country of death
                    '00N8c00000cBMss': '',
                    '00N8c00000cBMst': '',
                    '00N8c00000c8l8t': '', // bene gender
                    '00N8c00000c8leH': '', // bene dob
                    '00N8c00000c8E1p': '', // bene relationship
                    '00N8c00000c8l93': '', // street address
                    '00N8c00000c8lUL': '', // bene city
                    '00N8c00000c8lay': '', // bene state
                    '00N8c00000c8lbN': '', // bene zip
                    'email': '', // bene email
                    'phone': '', // bene phone
                    '00N8c00000c8E2n': '', // bene has death cert
                    '00N8c00000c8E2x': '', // name of funeral home
                    '00N8c00000c8E3C': '', // city of funeral home
                    '00N8c00000c8E3M': '', // state of funeral home
                    'captcha_settings':
                        '{"keyname":"CaseRecaptcha","fallback":"true","orgId":"00D8c0000024rLq","ts":""}',
                }}
                validationSchema={Yup.object({
                    '00N8c00000c8koB': Yup.string().required(
                        'First Name is required',
                    ),
                    '00N8c00000c8ksd': Yup.string().required(
                        'Last Name is required',
                    ),
                    '00N8c00000c8kss': Yup.string().required(
                        'Date of Birth is required',
                    ),
                    '00N8c00000c8ksx': Yup.string().required(
                        'Date of Death is required',
                    ),
                    '00N8c00000cBTf4': Yup.string(),
                    '00N8c00000c8kuZ': Yup.string().required(
                        'Cause of Death is required',
                    ),
                    '00N8c00000c8kuj': Yup.string().required(
                        'Manner of Death is required',
                    ),
                    '00N8c00000c8l09': Yup.string().optional(),
                    '00N8c00000cBMss': Yup.string().required(
                        'First Name is required',
                    ),
                    '00N8c00000cBMst': Yup.string().required(
                        'Last Name is required',
                    ),
                    '00N8c00000c8l8t': Yup.string().required(
                        'Gender is required',
                    ),
                    '00N8c00000c8leH': Yup.string().required(
                        'Date of Birth is required',
                    ),
                    '00N8c00000c8E1p': Yup.string().required(
                        'Relationship to Deceased is required',
                    ),
                    '00N8c00000c8l93': Yup.string().required(
                        'Street Address is required',
                    ),
                    '00N8c00000c8lUL': Yup.string().required(
                        'City is required',
                    ),
                    '00N8c00000c8lay': Yup.string().required(
                        'State is required',
                    ),
                    '00N8c00000c8lbN': Yup.string().required(
                        'Zip Code is required',
                    ),
                    'email': Yup.string()
                        .email('Please enter a valid email address')
                        .required('Email is required'),
                    'phone': Yup.string().required('Phone is required'),
                    '00N8c00000c8E2n': Yup.string().required(
                        'Please select Yes or No',
                    ),
                    '00N8c00000c8E2x': Yup.string(),
                    '00N8c00000c8E3C': Yup.string(),
                    '00N8c00000c8E3M': Yup.string(),
                })}
                onSubmit={values => {
                    doHtmlFormAction();
                }}
            >
                {({ isValid, submitCount }) => (
                    <Form
                        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                        method="POST"
                        id="salesforce-claim-form"
                    >
                        <Field type="hidden" name="captcha_settings" />
                        <input
                            type="hidden"
                            name="orgid"
                            value="00D8c0000024rLq"
                        />
                        <input
                            type="hidden"
                            name="retURL"
                            value="https://www.bestow.com/file-a-claim/?formcomplete=true"
                        />
                        {/* <input type="hidden" name="debug" value={1} />
                        <input
                            type="hidden"
                            name="debugEmail"
                            value="tj@bestow.com"
                        /> */}
                        <TimeStamp />
                        <div className="pb-24">
                            <Grid className="lg:mt-11">
                                <div className="hidden lg:block lg:row-start-1 lg:col-span-4 lg:col-start-1 z-10 text-center lg:px-6 lg:-mt-11">
                                    <H4Serif>
                                        Policyholder (Decedent) Information
                                    </H4Serif>
                                    <DividerCoral className="my-4" />
                                    <BodyText>
                                        Please fill in the information about the
                                        deceased policyholder.
                                    </BodyText>
                                </div>
                                <div className="col-span-full lg:row-start-1 lg:col-start-3 lg:col-span-10">
                                    <CardRound className="w-full bg-off-white pt-12 pb-8 sm:pb-16">
                                        <Grid
                                            cols={4}
                                            smCols={8}
                                            lgCols={10}
                                            className="px-4 sm:px-6 lg:pl-0 lg:pr-6"
                                        >
                                            <div className="lg:hidden col-span-full mb-8 sm:mb-12 text-center -mt-16">
                                                <H4Serif>
                                                    Policyholder (Decedent)
                                                    Information
                                                </H4Serif>
                                                <div
                                                    className="mx-auto"
                                                    style={{ maxWidth: 200 }}
                                                >
                                                    <DividerCoral className="my-4" />
                                                </div>
                                                <BodyText>
                                                    Please fill in the
                                                    information about the
                                                    deceased policyholder.
                                                </BodyText>
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8koB"
                                                    name="00N8c00000c8koB"
                                                    className="w-full bg-white"
                                                    label="FIRST NAME OF DECEASED"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8ksd"
                                                    name="00N8c00000c8ksd"
                                                    className="w-full bg-white"
                                                    label="LAST NAME OF DECEASED"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    name="00N8c00000c8leH"
                                                    id="00N8c00000c8leH"
                                                    className="w-full bg-white"
                                                    label="DATE OF BIRTH"
                                                    placeholder="MM/DD/YYYY"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    name="00N8c00000c8ksx"
                                                    id="00N8c00000c8ksx"
                                                    className="w-full bg-white"
                                                    label="DATE OF DEATH"
                                                    placeholder="MM/DD/YYYY"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    name="00N8c00000cBTf4"
                                                    id="00N8c00000cBTf4"
                                                    className="w-full bg-white"
                                                    label="Supplied Policy Number"
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'sm:col-start-1 lg:col-start-3 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    name="00N8c00000c8kuZ"
                                                    id="00N8c00000c8kuZ"
                                                    className="w-full bg-white"
                                                    label="What was the cause of death?"
                                                    placeholder="Enter answer here"
                                                    labelHelper="The injury or illness printed on the death certificate"
                                                    rows={4}
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        RadioGroupWhite
                                                    }
                                                    options={[
                                                        {
                                                            label: 'Natural',
                                                            value: 'Natural',
                                                        },
                                                        {
                                                            label: 'Accidental',
                                                            value: 'Accidental',
                                                        },
                                                        {
                                                            label: 'Homicide',
                                                            value: 'Homicide',
                                                        },
                                                        {
                                                            label: 'Suicide',
                                                            value: 'Suicide',
                                                        },
                                                        {
                                                            label: 'Unknown',
                                                            value: 'Unknown',
                                                        },
                                                    ]}
                                                    id="00N8c00000c8kuj"
                                                    name="00N8c00000c8kuj"
                                                    label="What was the manner of death?"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-full',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        HubspotCountrySelect
                                                    }
                                                    name="00N8c00000c8l09"
                                                    id="00N8c00000c8l09"
                                                    label="In what country did death occur?"
                                                    className="w-full bg-white"
                                                    required
                                                />
                                            </div>
                                        </Grid>
                                    </CardRound>
                                </div>
                            </Grid>
                        </div>

                        <div className="pb-24">
                            <Grid className="lg:mt-11">
                                <div className="col-span-full lg:row-start-1 lg:col-start-1 lg:col-span-10">
                                    <CardRound className="w-full bg-off-white pt-12 pb-8 sm:pb-16">
                                        <Grid
                                            cols={4}
                                            smCols={8}
                                            lgCols={10}
                                            className="px-4 sm:px-6 lg:pr-0 lg:pl-6"
                                        >
                                            <div className="lg:hidden col-span-full mb-8 sm:mb-12 text-center -mt-16">
                                                <H4Serif>
                                                    Informant (Your) Information
                                                </H4Serif>
                                                <div
                                                    className="mx-auto"
                                                    style={{ maxWidth: 200 }}
                                                >
                                                    <DividerCoral className="my-4" />
                                                </div>
                                                <BodyText>
                                                    Please fill out your
                                                    information and relationship
                                                    to the policyholder.
                                                </BodyText>
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-1 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    name="00N8c00000cBMss"
                                                    className="w-full bg-white"
                                                    label="FIRST NAME"
                                                    id="00N8c00000cBMss"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-5 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    name="00N8c00000cBMst"
                                                    id="00N8c00000cBMst"
                                                    className="w-full bg-white"
                                                    label="LAST NAME"
                                                    required
                                                />
                                            </div>
                                            <div className="col-start-1 col-span-4 sm:col-span-8 lg:col-span-8">
                                                <Grid
                                                    cols={1}
                                                    smCols={12}
                                                    lgCols={12}
                                                >
                                                    <div
                                                        className={cx(
                                                            'col-span-full sm:col-span-3 sm:pr-0',
                                                            fieldWrapperClasses,
                                                        )}
                                                    >
                                                        <CustomFormikField
                                                            CustomComponent={
                                                                BinarySelector
                                                            }
                                                            name="00N8c00000c8l8t"
                                                            id="00N8c00000c8l8t"
                                                            className=""
                                                            label="GENDER"
                                                            options={[
                                                                {
                                                                    label: 'M',
                                                                    value:
                                                                        'Male',
                                                                },
                                                                {
                                                                    label: 'F',
                                                                    value:
                                                                        'Female',
                                                                },
                                                            ]}
                                                            required
                                                        />
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'col-span-full sm:col-span-4 sm:pl-0 sm:pr-3',
                                                            fieldWrapperClasses,
                                                        )}
                                                    >
                                                        <CustomFormikField
                                                            CustomComponent={
                                                                OutsideLabelInput
                                                            }
                                                            id="00N8c00000c8kss"
                                                            name="00N8c00000c8kss"
                                                            className="w-full bg-white"
                                                            label="DATE OF BIRTH"
                                                            placeholder="MM/DD/YYYY"
                                                            required
                                                        />
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'col-span-full sm:col-span-5 sm:pl-0 sm:pr-3',
                                                            fieldWrapperClasses,
                                                        )}
                                                    >
                                                        <CustomFormikField
                                                            CustomComponent={
                                                                OutsideLabelInput
                                                            }
                                                            id="00N8c00000c8E1p"
                                                            name="00N8c00000c8E1p"
                                                            className="w-full bg-white"
                                                            label="RELATIONSHIP TO DECEASED"
                                                            required
                                                        />
                                                    </div>
                                                </Grid>
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-1 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8l93"
                                                    name="00N8c00000c8l93"
                                                    className="w-full bg-white"
                                                    label="STREET ADDRESS"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-5 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8lUL"
                                                    name="00N8c00000c8lUL"
                                                    className="w-full bg-white"
                                                    label="CITY"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-1 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        StateSelect
                                                    }
                                                    id="00N8c00000c8lay"
                                                    name="00N8c00000c8lay"
                                                    className="w-full bg-white"
                                                    label="STATE"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-5 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8lbN"
                                                    name="00N8c00000c8lbN"
                                                    className="w-full bg-white"
                                                    label="ZIP CODE"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="w-full bg-white"
                                                    label="EMAIL"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-5 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="phone"
                                                    name="phone"
                                                    className="w-full bg-white"
                                                    label="PHONE"
                                                    required
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-5 col-span-4 lg:mt-16',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        RadioGroupAlt
                                                    }
                                                    options={[
                                                        {
                                                            label: 'Yes',
                                                            value: 'Yes',
                                                        },
                                                        {
                                                            label: 'No',
                                                            value: 'No',
                                                        },
                                                    ]}
                                                    id="00N8c00000c8E2n"
                                                    name="00N8c00000c8E2n"
                                                    label="Do you have a copy of the Death Certificate?"
                                                    required
                                                />
                                            </div>
                                        </Grid>
                                    </CardRound>
                                </div>
                                <div className="hidden lg:block lg:row-start-1 lg:col-span-4 lg:col-start-9 z-10 text-center lg:px-6 lg:-mt-11">
                                    <H4Serif>
                                        Informant (Your) Information
                                    </H4Serif>
                                    <DividerCoral className="my-4" />
                                    <BodyText>
                                        Please fill in the information about the
                                        primary beneficiary.
                                    </BodyText>
                                </div>
                            </Grid>
                        </div>

                        <div>
                            <Grid className="lg:mt-11">
                                <div className="hidden lg:block lg:row-start-1 lg:col-span-4 lg:col-start-1 z-10 text-center lg:px-6 lg:-mt-11">
                                    <H4Serif>Helpful Information</H4Serif>
                                    <DividerCoral className="my-4" />
                                    <BodyText>
                                        Please add additional information that
                                        may help with this claim.
                                    </BodyText>
                                </div>
                                <div className="col-span-full lg:row-start-1 lg:col-start-3 lg:col-span-10">
                                    <CardRound className="w-full bg-off-white pt-12 pb-8 sm:pb-16">
                                        <Grid
                                            cols={4}
                                            smCols={8}
                                            lgCols={10}
                                            className="px-4 sm:px-6 lg:pl-0 lg:pr-6"
                                        >
                                            <div className="lg:hidden col-span-full mb-8 sm:mb-12 text-center -mt-16">
                                                <H4Serif>
                                                    Helpful Information
                                                </H4Serif>
                                                <div
                                                    className="mx-auto"
                                                    style={{ maxWidth: 200 }}
                                                >
                                                    <DividerCoral className="my-4" />
                                                </div>
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-4 sm:col-span-8',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8E2x"
                                                    name="00N8c00000c8E2x"
                                                    className="w-full bg-white"
                                                    label="NAME OF FUNERAL HOME"
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8E3C"
                                                    name="00N8c00000c8E3C"
                                                    className="w-full bg-white"
                                                    label="CITY"
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'col-span-4',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8E3M"
                                                    name="00N8c00000c8E3M"
                                                    className="w-full bg-white"
                                                    label="STATE"
                                                />
                                            </div>
                                            <div
                                                className={cx(
                                                    'lg:col-start-3 col-span-4 sm:col-span-8',
                                                    fieldWrapperClasses,
                                                )}
                                            >
                                                <BodyText className="text-gray-800 mb-2">
                                                    Do you have additional
                                                    information that may help
                                                    with this claim?
                                                </BodyText>
                                                <CustomFormikField
                                                    CustomComponent={
                                                        OutsideLabelInput
                                                    }
                                                    id="00N8c00000c8E3g"
                                                    name="00N8c00000c8E3g"
                                                    className="w-full bg-white"
                                                    placeholder="Example: Information regarding a funeral home assignment and/or the name(s) and phone number(s) of beneficiaries, family members, the attorney representing an estate, the trustee(s) representing a trust beneficiary, the guardian(s) of minor beneficiaries, etc."
                                                    rows={5}
                                                />
                                            </div>
                                        </Grid>
                                    </CardRound>
                                </div>
                            </Grid>
                        </div>
                        <div className="py-16 lg:py-24 flex flex-col items-center justify-center items">
                            <PrimaryButton disabled={!recaptchaComplete}>
                                Submit Claim
                            </PrimaryButton>
                            {!isValid && submitCount > 0 && (
                                <BodyText className="mt-4 mb-0 text-error">
                                    Some fields have errors above
                                </BodyText>
                            )}
                            {error && (
                                <BodyText className="text-error mt-4 mb-0">
                                    {error}
                                </BodyText>
                            )}
                            <Recaptcha
                                ref={recaptchaRef}
                                sitekey={
                                    process.env
                                        .REACT_APP_SALESFORCE_RECAPTCHA_KEY as string
                                }
                                onChange={() => {
                                    setRecaptchaComplete(true);
                                }}
                            />
                        </div>
                        <input
                            type="hidden"
                            name="recordType"
                            id="recordType"
                            value="0128c000001aYdm"
                        />
                        <input
                            id="subject"
                            name="subject"
                            value=" Claim Form Submission"
                            type="hidden"
                        />
                        <input
                            id="00N8c00000cAKNv"
                            name="00N8c00000cAKNv"
                            value=" Claims"
                            type="hidden"
                        />
                        <input
                            id="type"
                            name="type"
                            value="Claim"
                            type="hidden"
                        />
                    </Form>
                )}
            </Formik>
        </PageSection>
    ) : (
        <div className="text-center lg:mb-32">
            <Subtitle2 className="mb-2">Thanks!</Subtitle2>
            <BodyText>Your claim has been submitted successfully.</BodyText>
        </div>
    );
};

export default ClaimsForm;
