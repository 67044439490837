import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { lcPostSelector } from "../../../redux/learningCenter/selectors";

import PageSection from '../../../components/_common/section/page';
import Subtitle2 from '../../../components/_common/text/heading/subtitle2';
import Subtitle1 from '../../../components/_common/text/heading/subtitle1/index';
import SmallText from '../../../components/_common/text/small-text/index';
import BodyTextLarge from '../../../components/_common/text/body-text/large';
import BodyText from '../../../components/_common/text/body-text';
import LinkButton from '../../../components/_common/button/link';
import Grid from '../../../components/_common/grid';
import PostImage from './post-image';
import Slider from 'react-slick';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../images/icons/index';

import './index.scss';
import SecondaryButton from "../../../components/_common/button/secondary";

const BlogSlider: React.FC = () => {
    const [currentPostIdx, setCurrentPostIdx] = useState(0);
    const sliderRef = useRef<Slider>(null);
    const sliderPosts = ['bestow-top-life-insurance-questions', 'no-medical-exam-life-insurance', 'how-much-life-insurance-do-you-need'];
    const currentPost = useSelector(lcPostSelector(sliderPosts[currentPostIdx]));

    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 5000,
        speed: 500,
        swipeToSlide: true,
        beforeChange: (oldIdx: number, newIdx: number) => setCurrentPostIdx(newIdx),
    };

    return (
        <PageSection className="insurance101-blog-slider py-16 sm:py-24 lg:py-32">
            <Subtitle2 className="text-center mb-10 sm:mb-12 font-medium text-gray-800 section-title">
                FROM THE LEARNING CENTER
            </Subtitle2>
            <Grid className="">
                <div className="col-span-full sm:col-span-3 lg:col-span-5">
                    <div className="current-post-top">
                        <div className="mb-8">
                            <Subtitle1 className="text-center sm:text-left mb-2">{currentPost?.title.rendered}</Subtitle1>
                            {currentPost?.acf.time_to_read &&
                                <SmallText className="text-center sm:text-left">{currentPost.acf.time_to_read} Minute Read</SmallText>
                            }
                        </div>
                        <div className="hidden sm:block mt-9">
                            <BodyTextLarge className="mb-10" dangerouslySetInnerHTML={{__html: currentPost?.excerpt.rendered}} />
                            <div className="flex">
                                <LinkButton to={`/learningcenter/${currentPost?.slug}`}>
                                    Read More
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col col-span-full sm:col-span-5 lg:col-span-7 -mr-4 sm:-mr-9 lg:-mr-6">
                    <div className="relative mb-4">
                        <Slider {...slickSettings} ref={sliderRef}>
                            {sliderPosts.map((slug) => (
                                <PostImage
                                    key={`post-${slug}`}
                                    slug={slug} />
                            ))}
                        </Slider>

                        <div className="absolute right-9 sm:right-16 lg:right-11 -bottom-1 bg-white border-0">
                            <button
                                className="arrow-btn arrow-left-btn mr-4 lg:mr-6"
                                onClick={() => sliderRef.current?.slickPrev()}>
                                <ArrowLeftIcon />
                            </button>
                            <button
                                className="arrow-btn arrow-right-btn"
                                onClick={() => sliderRef.current?.slickNext()}>
                                <ArrowRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-span-full sm:hidden">
                    <div className="current-post-top">
                        <BodyText className="mb-8" dangerouslySetInnerHTML={{__html: currentPost?.excerpt.rendered}} />
                        <div className="flex justify-center">
                            <LinkButton to={`/learningcenter/${currentPost?.slug}`}>
                                Read More
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </Grid>
            <div className="flex justify-center mt-12">
                <SecondaryButton to="/learningcenter">
                    Visit the Learning Center
                </SecondaryButton>
            </div>
        </PageSection>
    );
};

export default BlogSlider;
