import React from 'react';
import PageSection from '../../../components/_common/section/page';
import BodyText from '../../../components/_common/text/body-text';
import phoneImg from '../../../images/gerber/Gerber_Senior_OnPhone.jpg';
import H4Serif from '../../../components/_common/text/heading/h4/serif';
import H4Sans from '../../../components/_common/text/heading/h4/sans';
import PrimaryButton from '../../../components/_common/button/primary';
import './index.scss';
import { trackGerberApplyClicked } from '../../../utilities/segment.track';

const PhoneSection: React.FC = () => {
    const trackAndRedirect = () => {
        trackGerberApplyClicked({});
        // @ts-ignore
        window.location.href =
            'https://gerberlife.com/gl/ng/entry/agencyDirect/guarlife?aKeycode=B0770611&userId=B0770611&partnerName=Bestow';
    };
    return (
        <PageSection className="phone-section pt-14 sm:pt-20">
            <H4Serif className="sm:mb-16 xs:mb-6 text-center xs:text-32">
                Getting life insurance as a senior is easy
            </H4Serif>
            <div className="flex flex-col sm:flex-row">
                <div>
                    <img
                        src={phoneImg}
                        className="phone-img sm:w-full sm:h-auto"
                        alt="Why bestow mobile phone"
                    />
                </div>
                <div className="text-left sm:pl-2 lg:pl-0 text-charcoal max-w-sm sm:ml-9 xs:mt-10">
                    <div className="flex mb-8">
                        <H4Sans className="mr-4 text-xl">1.</H4Sans>
                        <div>
                            <H4Sans className="text-xl mb-2">
                                Complete your application
                            </H4Sans>
                            <BodyText>
                                {' '}
                                There are no medical exams; so you'll qualify
                                regardless of age or health.*
                            </BodyText>
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <H4Sans className="mr-4 text-xl">2.</H4Sans>
                        <div>
                            <H4Sans className="text-xl mb-2">
                                Pay your premium
                            </H4Sans>
                            <BodyText>
                                {' '}
                                Your policy becomes active once you make your
                                first premium payment.
                            </BodyText>
                        </div>
                    </div>
                    <div className="flex mb-8">
                        <H4Sans className="mr-4 text-xl">3.</H4Sans>
                        <div>
                            <H4Sans className="text-xl mb-2">Relax</H4Sans>
                            <BodyText>
                                {' '}
                                Put your feet up and relax (this step is
                                optional, but it feels good).
                            </BodyText>
                            <div className="flex justify-start mt-8 xs:hidden sm:flex lg:flex">
                                <PrimaryButton onClick={trackAndRedirect}>
                                    Apply
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                    <div className="sm:hidden xs:block lg:hidden">
                        <PrimaryButton
                            className="bestow-track w-full"
                            onClick={trackAndRedirect}
                        >
                            Apply
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default PhoneSection;
