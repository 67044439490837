import React from 'react';
import Divider from '../';

interface Props {
    className?: string
}

const DividerCoral: React.FC<Props> = ({className = '', ...otherProps}) => (
    <Divider className={`${className} border-coral`} {...otherProps} />
);

export default DividerCoral;
