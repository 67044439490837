import React from 'react';
import BodyTextArray from "../../../components/_common/text/body-text/array";

export interface SubSubsectionData {
    body: string[],
}

interface Props {
    subsectionsCount: number,
    subSubsectionData: SubSubsectionData,
    idx: number
}

const TermsSubSection: React.FC<Props> = ({subSubsectionData, subsectionsCount, idx}) => (
    <div className="flex" key={`section-${idx}`}>
        <div style={{marginTop: -1}}>
            <span className="subsection-number mr-6 text-gray-700">{idx+1}.</span>
        </div>
        <div>
            <BodyTextArray
                data={subSubsectionData.body}
                classes={`${subsectionsCount - 1 !== idx ? 'mb-4' : ''}`}
            />
        </div>
    </div>
);

export default TermsSubSection;
