import React from 'react';

import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";

import {
    QuoteProductFeaturesTablet,
    QuoteProductFeaturesMobile,
    QuoteProductFeaturesDesktop,
} from "../../../images/svgs";

const QuoteProductFeatures: React.FC = () => {
    return (
        <PageSection>
            <Grid>
                <div className="col-span-full lg:col-start-2 lg:col-span-10 pt-6 sm:pt-24">
                    <QuoteProductFeaturesMobile className="sm:hidden w-full" />
                    <QuoteProductFeaturesTablet className="hidden sm:block lg:hidden w-full" />
                    <QuoteProductFeaturesDesktop className="hidden lg:block w-full" />
                </div>
            </Grid>
        </PageSection>
    );
};

export default QuoteProductFeatures;
