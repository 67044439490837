import { initializeApp } from "firebase/app"
import { initializePerformance } from "firebase/performance";

import { isPreRendering } from "../utilities/reactSnap";

export const AFFILIATES_COLLECTION = "affiliates";
export const API_PARTNERS_COLLECTION = "apiPartnersContacts";

const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

// Start capturing performance metrics
if (!isPreRendering()) {
    initializePerformance(firebaseApp);
}

// Start a crude API. Make this proper if this grows
const api = {
    saveAffiliateSubmission: (formData) => {
        return _securelyAddDoc(AFFILIATES_COLLECTION, formData);
    },
    saveApiPartnersContactSubmission: (formData) => {
        return _securelyAddDoc(API_PARTNERS_COLLECTION, formData);
    },
};

/**
 * Dynamically imports Firebase dependencies to keep bundle size down and then anonymously signs in and creates a document
 * in Firestore
 * @param collectionName    The name of the collection to create the document in
 * @param data  The data to save to the document
 * @private
 */
function _securelyAddDoc(collectionName, data) {
    return import("firebase/auth")
        .then(({ getAuth, signInAnonymously }) => signInAnonymously(getAuth(firebaseApp)))
        .then(() => import("firebase/firestore"))
        .then(({ getFirestore, collection, addDoc }) => addDoc(collection(getFirestore(firebaseApp), collectionName), data))
        .catch(e => {
            console.error(e);
            return null;
        });
}

export default api;
