import React, {useRef, useEffect, ElementType} from 'react';

interface Props {
    className?: string,
    video: any,
    loop?: boolean,
    onTimeUpdate?: any
}

/**
 * TODO: Placeholder image, so the size of the video doesn't change before/after it's loaded
 */
const InlineVideo: React.FC<Props> = ({ className, video, loop, onTimeUpdate }) => {
    const ref = useRef<HTMLVideoElement>(null);

    // KBM - We need to explicitly set muted once because React does not actually render the muted attribute out to video, so the videos will not autoplay when pre-rendered
    useEffect(() => {
        if (ref.current) {
            ref.current.defaultMuted = true;
            ref.current.muted = true;
        }
    }, []);

    return (
        <video
            ref={ref}
            className={className}
            src={video}
            onTimeUpdate={() => {}}
            loop={loop}
            muted={true}
            autoPlay={true}
            playsInline={true}
        />
    )
};

InlineVideo.defaultProps = {
    loop: false
};

export default InlineVideo;