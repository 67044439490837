import React from 'react';
import cx from 'classnames';

import './index.scss';

interface Props {
    className?: string
}

const TitleLarge: React.FC<Props> = ({ className = "", children }) => {
    const classes = cx("title-large font-serif", className);

    return (
        <p className={classes}>
            {children}
        </p>
    );
};

export default TitleLarge