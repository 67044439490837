import React from 'react';
import { Link } from 'react-router-dom';
import { LogoBlue } from '../../../../../../images/svgs';
import Grid from '../../../../../_common/grid';
import PageSection from '../../../../../_common/section/page';
import H2Serif from '../../../../../_common/text/heading/h2/serif';
import TextFieldLabel from '../../../../../_common/text/textFieldLabel';
import Quote, { QuoteProps } from '../../..';

interface QuoteMobileOptimizationProps extends QuoteProps {
    title: any;
    bannerText?: string;
    quoteMobileOptimizationExists: boolean;
}

const QuoteHeaderMobile: React.FC<QuoteMobileOptimizationProps> = ({
    bannerText,
    title,
    isAgent = false,
    quoteMobileOptimizationExists = false,
}) => {
    return (
        <>
            {bannerText && (
                <PageSection className="bg-navy py-2">
                    <TextFieldLabel className="text-center text-white -mx-2 sm:mx-0">
                        {bannerText}
                    </TextFieldLabel>
                </PageSection>
            )}
            <PageSection className="pt-4 sm:pt-12 lg:pt-16">
                <Grid>
                    <div
                        className="col-span-full sm:col-span-4 lg:col-span-6 sm:mb-32 sm:mt-16 lg:mb-24"
                        data-cy="quote.hero.section"
                    >
                        <div
                            className="flex justify-center items-center mb-3 sm:mb-6 lg:mb-14"
                            data-cy="quote.logo.container"
                        >
                            <Link to="/">
                                <LogoBlue
                                    className="h-6 lg:h-7"
                                    data-cy="logo.bestow.blue"
                                />
                            </Link>
                        </div>
                        <H2Serif
                            className="sm:mx-3 lg:mx-6 text-center text-navy whitespace-nowrap sm:whitespace-normal sm:text-7xl"
                            data-cy="quote.hero.title"
                        >
                            {title}
                        </H2Serif>
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 lg:px-6 relative sm:-mb-80 sm:-mt-4">
                        <Quote
                            className="w-full filter drop-shadow-quote"
                            quoteMobileOptimizationExists={
                                quoteMobileOptimizationExists
                            }
                            isAgent={isAgent}
                        />
                    </div>
                </Grid>
            </PageSection>
        </>
    );
};

export default QuoteHeaderMobile;
