import React from 'react';
import { Link } from "react-router-dom";

import PageContentNavy from "../../../components/_common/pageContent/navy";
import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import Eyebrow from "../../../components/_common/text/eyebrow";
import H1Serif from "../../../components/_common/text/heading/h1/serif";
import BodyText from "../../../components/_common/text/body-text";
import BodyTextBold from "../../../components/_common/text/body-text/bold";
import H2Serif from "../../../components/_common/text/heading/h2/serif";
import SecondaryButton from "../../../components/_common/button/secondary";
import HowItWorksItem from "../howItWorksItem";
import CardRound from "../../../components/_common/card/round";
import H4Serif from "../../../components/_common/text/heading/h4/serif";
import FAQList from "../../../components/_common/modules/faqs/list";
import SmallText from "../../../components/_common/text/small-text";

import cardMobile from '../../../images/refer/card-mobile.jpg';
import cardTablet from '../../../images/refer/card-tablet.jpg';
import cardDesktop from '../../../images/refer/card-desktop.jpg';
import { ArrowRightIcon } from "../../../images/icons/index";

import faqs from './faqs';

import '../index.scss';

const PolicyholderRefer: React.FC = () => {
    return (
        <PageContentNavy includeBottomPadding>
            <PageSection className="pt-7 lg:pt-11">
                <Grid>
                    <div className="col-span-full sm:col-span-5 lg:col-span-8 mb-4">
                        <Eyebrow className="mb-2 text-center sm:text-left">
                            BESTOW REFERRAL PROGRAM
                        </Eyebrow>
                        <H1Serif className="text-center sm:text-left">
                            Spread the word, earn rewards
                        </H1Serif>
                    </div>
                    <BodyText className="col-span-full lg:col-span-9">
                        Want to spread the word to your friends and family about Bestow? Our referral program means they
                        could get the coverage they need, and you’ll get a little something, too.
                    </BodyText>
                </Grid>
            </PageSection>
            <PageSection>
                <H2Serif className="text-center text-navy mt-20 mb-4 sm:mb-8 lg:mb-16">
                    Here's how it works
                </H2Serif>
                <Grid>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6">
                        <img src={cardMobile} className="w-full sm:hidden" alt="refer gift card" />
                        <img src={cardTablet} className="w-full hidden sm:block lg:hidden" alt="refer gift card" />
                        <img src={cardDesktop} className="w-full hidden lg:block" alt="refer gift card" />
                    </div>
                    <div className="col-span-full sm:col-span-4 lg:col-span-6 mb-0 lg:mb-32 flex flex-col justify-center">
                        <HowItWorksItem
                            number={1}
                            title="Share a Link"
                            text="Share your unique referral link with friends and family.">
                            <div className="flex justify-center sm:justify-start">
                                <SecondaryButton Icon={ArrowRightIcon} className="mt-6" scrollTo="friendbuy25pg" scrollToOffset={-100}>
                                    Get Started
                                </SecondaryButton>
                            </div>
                        </HowItWorksItem>
                        <div className="sm:hidden lg:block">
                            <HowItWorksItem
                                number={2}
                                title="Get Rewarded"
                                text="Policyholders earn a $25 reward via digital gift cards for each friend that uses your unique referral link, receives an eligible quote, and creates an account with Bestow." />
                            <HowItWorksItem
                                number={3}
                                title="Pick Your Gift"
                                text="Once your friend creates an account, you’ll be able to select a digital gift card from a large selection of US retailers or donate to a charity of your choice from the selection provided." />
                            <HowItWorksItem
                                number={4}
                                title="Repeat!"
                                text="You can earn up to 15 referral rewards total." />
                        </div>
                    </div>
                    <div className="col-span-full hidden sm:block lg:hidden mb-48">
                        <Grid smCols={3} className="mt-2">
                            <div className="col-span-1">
                                <HowItWorksItem
                                    number={2}
                                    title="Get Rewarded"
                                    text="Earn rewards via digital gift cards for each friend that uses your unique referral link, receive an eligible quote and creates an account with Bestow." />
                            </div>
                            <div className="col-span-1">
                                <HowItWorksItem
                                    number={3}
                                    title="Pick Your Gift"
                                    text="Once your friend creates an account you’ll be able to select a digital gift card from a large selection of US retailers or donate to a charity of your choice from the selection provided." />
                            </div>
                            <div className="col-span-1">
                                <HowItWorksItem
                                    number={4}
                                    title="Repeat!"
                                    text="You can earn up to 10 referral rewards total." />
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </PageSection>
            <div id="friendbuy25pg" className="bg-mint" />
            <PageSection className="bg-sage program-rules relative">
                <Grid>
                    <div className="col-span-full row-start-2 lg:row-start-1 sm:col-start-2 sm:col-span-6 lg:col-span-6">
                        <H2Serif className="hidden lg:block text-navy lg:mt-12 lg:text-center">
                            Program Rules
                        </H2Serif>
                        <BodyText className="text-center sm:text-left mt-5 sm:mx-4 lg:mx-0" ElementType="div">
                            Invite friends, family members, colleagues and neighbors. The following are prohibited:
                            <div className="mx-4">
                                <ul className="text-left mx-4 mt-8">
                                    <li className="mb-6">
                                        Posting referral link to websites, sharing codes with people you don’t know personally
                                    </li>
                                    <li className="mb-6">
                                        Paying someone to create an account
                                    </li>
                                    <li className="mb-6">
                                        Creating multiple or fake accounts
                                    </li>
                                    <li className="mb-6">
                                        Using bulk distribution or spam emails
                                    </li>
                                    <li className="mb-6">
                                        Referring a person more than once
                                    </li>
                                    <li className="mb-6">
                                        Encouraging people to submit inaccurate information to receive a quote (This is insurance fraud, and a felony.)
                                    </li>
                                </ul>
                            </div>
                        </BodyText>
                        <SmallText className="mx-4 lg:mx-0 mb-4">
                            We reserve the right to terminate your referral rewards at any time.
                        </SmallText>
                        <BodyTextBold className="mb-12 mx-4 lg:mx-0">
                            Find full terms and conditions <Link to="/referralterms" className="text-charcoal">here.</Link>
                        </BodyTextBold>
                    </div>
                    <div className="col-span-full row-start-1 sm:col-start-2 sm:col-span-6 lg:col-span-6">
                        <CardRound className="program-rules-hero bg-refer-rules bg-top-center bg-cover lg:bg-refer-rules-lg flex items-center justify-center mt-4    sm:-mt-10 lg:-mt-9">
                            <H2Serif className="text-white text-center lg:hidden">
                                Program Rules
                            </H2Serif>
                        </CardRound>
                    </div>
                </Grid>
            </PageSection>
            <PageSection>
                <H4Serif className="sm:hidden mt-10 mb-8 sm:mt-20 lg:mt-28 sm:mb-10 text-center text-navy">
                    You've got questions.<br/>We've got answers.
                </H4Serif>
                <H2Serif className="hidden sm:block mt-10 mb-8 sm:mt-20 lg:mt-28 sm:mb-10 lg:mb-16 text-center text-navy">
                    You've got questions.<br/>We've got answers.
                </H2Serif>
                <FAQList faqs={faqs} />
            </PageSection>
        </PageContentNavy>
    )
};

export default PolicyholderRefer;
