export default [{
    "id": 10103,
    "date": "2020-11-03T16:51:43",
    "title": {"rendered": "Why does an A+ rated carrier matter?"},
    "content": {
        "rendered": "<p>Life insurance company ratings indicate the carrier’s ability to pay their claims, much like your personal credit score. Bestow not only offers policies from North American Company for Life and Health Insurance® (rated A.M. Best as an A+ rated carrier), but those policies are reinsured by Munich Re (also an A.M. Best A+ rated reinsurer).</p>\n",
        "protected": false
    },
    "faq_category": [19],
    "_paging": {"totalPages": 7}
}, {
    "id": 10102,
    "date": "2020-11-03T16:50:41",
    "title": {"rendered": "Can I trust your reviews are unbiased and real?"},
    "content": {
        "rendered": "<p>Trustpilot is an established and trusted name in company reviews and never allows Bestow or anyone working for Bestow to alter or edit reviews in any way. We often ask our customers to rate us if they’ve enjoyed their experience, but we never offer an incentive. Read our reviews <a href=\"https://www.bestow.com/customer-reviews/\">here</a>.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 10101,
    "date": "2020-11-03T16:49:18",
    "title": {"rendered": "Are there any riders on the policies you offer?"},
    "content": {
        "rendered": "<p>At this time, there are no riders on the policies we offer. We’re working hard to expand our options for you and your family, so check back often.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 10100,
    "date": "2020-11-03T16:48:43",
    "title": {"rendered": "Do you have a referral program?"},
    "content": {
        "rendered": "<p>Yes. We offer both current policyholders as well as other individuals a small token of our gratitude for referring a potential new customer. See full program details <a href=\"https://www.bestow.com/refer/\">here</a>.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 10099,
    "date": "2020-11-03T16:47:10",
    "title": {"rendered": "Does a policy offered by Bestow cover death by COVID-19?"},
    "content": {
        "rendered": "<p>We want to assure you that a pandemic is not among the limited exclusions (reasons a claim would not be paid) in the life insurance policies offered by Bestow. The two primary exclusions are death by suicide within the first two years of the policy, or providing untruthful information during the application process. Please refer to your policy for a full list of exclusions.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 7340,
    "date": "2020-08-12T14:52:23",
    "title": {"rendered": "Do my friends get anything?"},
    "content": {
        "rendered": "<p>If approved, they might get a great term life insurance product. Unfortunately, insurance regulations prevent us from giving them a reward as well.</p>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 7339,
    "date": "2020-08-12T14:52:00",
    "title": {"rendered": "How/when do I get paid?"},
    "content": {
        "rendered": "<p>Once your referred friend creates an account, you’ll get a confirmation email. This confirmation email will allow you to choose your reward. Rewards make take up to 30 days to receive. If you have any issues using or redeeming your digital gift card, please reach out to <a href=\"mailto:help@Tremendous.com\">help@Tremendous.com</a>. For all other inquiries about the status of your referral or status of receiving your gift card, refer to the referral dashboard found in <a href=\"https://customer.bestow.com/\">your customer portal</a>. If your question cannot be resolved, reach out to one of our Customer Service Associates via the chat on the website or emailing <a href=\"mailto:team@bestow.com\">team@bestow.com</a> for further assistance.</p>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 7338,
    "date": "2020-08-12T14:48:30",
    "title": {"rendered": "Who CAN’T I refer?"},
    "content": {
        "rendered": "<ul>\n<li>You should not refer yourself, fake accounts, or the same person more than once.</li>\n<li>A resident of the state of New York</li>\n<li>Someone outside the range of 18-60 yrs old</li>\n<li>Non-US residents or someone that does not have permanent residence in the US</li>\n</ul>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 7337,
    "date": "2020-08-12T14:47:58",
    "title": {"rendered": "Who CAN I refer?"},
    "content": {
        "rendered": "<ul>\n<li>Anyone between the ages of 18-60</li>\n<li>Must have US citizenship or permanent residences in the US</li>\n<li>Is not a resident of the state of New York (We are not currently licensed in NY state.)</li>\n</ul>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 7334,
    "date": "2020-08-12T14:44:20",
    "title": {"rendered": "How many people can I refer?"},
    "content": {
        "rendered": "<p>You can refer as many friends or family members as you like, however you are only eligible to receive a reward for 10 (ten) referrals.</p>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 7333,
    "date": "2020-08-12T14:43:53",
    "title": {"rendered": "Who should I refer?"},
    "content": {
        "rendered": "<p>You can refer anyone you know personally who could benefit from term life insurance.</p>\n<ul>\n<li>Married couples &#8211; life insurance can protect family finances</li>\n<li>Parents &#8211; life insurance can cover college tuition, cost of living for children</li>\n<li>Homeowners &#8211; life insurance can ensure a family can keep their home</li>\n<li>Caregivers &#8211; life insurance can support elderly parents or young children</li>\n</ul>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 7326,
    "date": "2020-08-12T11:57:23",
    "title": {"rendered": "Where can I find my referral link?"},
    "content": {
        "rendered": "<ul>\n<li><strong>Bestow policy-holders:</strong> Find your unique referral link in the customer portal. Simply <a href=\"https://customer.bestow.com/\">log in to your account</a> and find it under the Friend Referral tab.</li>\n<li><strong>Non-Bestow policy-holders:</strong> Can receive a unique referral link in bullet number one beneath “How to Earn” on bestow.com/refer</li>\n</ul>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 3466,
    "date": "2020-01-02T12:41:14",
    "title": {"rendered": "Secondary Beneficiary"},
    "content": {
        "rendered": "<p>The person who will receive your life insurance benefits if your primary beneficiary dies before you. This is also known as a contingent beneficiary.</p>\n",
        "protected": false
    },
    "faq_category": []
}, {
    "id": 3436,
    "date": "2019-12-30T17:58:38",
    "title": {"rendered": "Does Bestow offer anything longer than 10 or 20 year policies?"},
    "content": {
        "rendered": "<p>Yes! We now offer 10, 15, 20, 25, and 30 year term policies.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 3435,
    "date": "2019-12-30T17:57:10",
    "title": {"rendered": "How can I get a quote?"},
    "content": {
        "rendered": "<p>To start a quote, simply click <a href=\"https://www.bestow.com/quote/\" target=\"_blank\" rel=\"noopener noreferrer\">here</a>.</p>\n<p>We&#8217;ve created a simple, online quote tool where you can get started. Enter your gender, birthdate, height, weight, and state of residence, to receive an estimate. If you like what you see, you can continue to apply.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 3434,
    "date": "2019-12-30T17:56:22",
    "title": {"rendered": "How do I know if I’m a good fit to apply through Bestow?"},
    "content": {
        "rendered": "<p>If you're between the ages 18 and 60 years old, have never had a felony, and are generally in good health, then we think you should <a href=\"https://enroll.bestow.com/get-started\" target=\"_blank\" rel=\"noopener noreferrer\">apply</a>. (It takes only minutes.)</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 3433,
    "date": "2019-12-30T17:55:44",
    "title": {"rendered": "What ages are eligible for insurance through Bestow?"},
    "content": {
        "rendered": "<p>Anyone between the ages of 18 and 60 may apply for coverage.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 3427,
    "date": "2019-12-30T17:32:26",
    "title": {"rendered": "Can I take out a policy on someone else?"},
    "content": {
        "rendered": "<p>It is against the law to buy life insurance on someone else without their knowledge. And at this time, Bestow’s online application is unable to verify their knowledge. </p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 3426,
    "date": "2019-12-30T17:31:50",
    "title": {"rendered": "Can I still get life insurance if I have cancer?"},
    "content": {
        "rendered": "<p>You can still apply, however we will not be able to offer you coverage if you have been medically diagnosed or treated for cancer in the past 10 years. But that doesn’t mean you can’t get coverage elsewhere, as each company has its own underwriting rules.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 3425,
    "date": "2019-12-30T17:30:53",
    "title": {"rendered": "Does my policy stop at age 60?"},
    "content": {
        "rendered": "<p>Your policy expires at the end of its term. If you buy a 10-year policy at the age of 53, then you would have coverage until age 63, assuming all your premiums were paid on time.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 3424,
    "date": "2019-12-30T17:29:56",
    "title": {"rendered": "What happens if I outlive my policy?"},
    "content": {
        "rendered": "<p>At the end of a policy’s term, the policy will simply expire. In order to offer the most simple and affordable life insurance possible, we don’t offer a return of premiums paid when the policy’s term ends. This helps make the process simpler and the cost more affordable for you.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 3408,
    "date": "2019-12-30T15:08:39",
    "title": {"rendered": "Why do you need my SSN?"},
    "content": {
        "rendered": "<p>We understand your concern about providing your social security number. Your social security number is used to verify your identity, to help prevent fraud, and is required by every life insurance company in the underwriting process. We take data protection very seriously and use the latest encryption technology to safeguard your personal information.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 509,
    "date": "2018-11-13T19:05:28",
    "title": {"rendered": "How do I know Bestow will be around in 5+ years? What will happen to my policy?"},
    "content": {
        "rendered": "<p>Policies purchased through Bestow are issued by North American Company for Life and Health Insurance® — an A+ rated insurer. Bestow just makes the application experience simple and convenient.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 508,
    "date": "2018-11-13T19:04:54",
    "title": {"rendered": "Does Bestow require a long-term commitment? What if I can’t afford my premium one month?"},
    "content": {
        "rendered": "<p>Each policy has a 30-day free look period. This means that you are eligible for a full refund if you cancel within the first 30 days after purchasing. If you ever can’t afford to make a premium payment, your coverage will continue through a “grace period” (60 days) from the date of your last payment. As long as your premium is paid during the grace period, coverage remains uninterrupted. Any missed premiums throughout the term are deducted from the death benefit in the event of a claim.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 507,
    "date": "2018-11-13T18:42:46",
    "title": {"rendered": "Underwriting"},
    "content": {
        "rendered": "<p>The process by which a life insurance company determines whether it can accept an application for life insurance, and if so, on what basis so that the proper premium is charged.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 506,
    "date": "2018-11-13T18:42:30",
    "title": {"rendered": "Underwriter"},
    "content": {
        "rendered": "<p>Another name for a life insurance company or a person that underwrites an insurance risk.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 505,
    "date": "2018-11-13T18:42:10",
    "title": {"rendered": "Term life"},
    "content": {
        "rendered": "<p>Life insurance which pays a benefit if the insured dies during a specified period.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 504,
    "date": "2018-11-13T18:41:54",
    "title": {"rendered": "Reinstatement"},
    "content": {
        "rendered": "<p>Restoring a lapsed policy to its original premium paying status, upon payment by the policy owner, possibly with interest, of all unpaid premiums, and presentation of satisfactory evidence of insurability by the insured.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 503,
    "date": "2018-11-13T18:39:53",
    "title": {"rendered": "Policy schedule"},
    "content": {
        "rendered": "<p>Part of the insurance policy that identifies the policyholder and details of the person covered, the amount of coverage, the exclusions, and the payment mode and schedule.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 502,
    "date": "2018-11-13T18:39:26",
    "title": {"rendered": "Policy owner"},
    "content": {
        "rendered": "<p>The policy owner is responsible for paying the premium on the policy and it can be a person, trust, or estate.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 501,
    "date": "2018-11-13T18:39:10",
    "title": {"rendered": "Policy"},
    "content": {
        "rendered": "<p>The printed legal document stating the terms of insurance contract that is issued to the policyowner by the insurance company.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 500,
    "date": "2018-11-13T18:38:56",
    "title": {"rendered": "Policy period"},
    "content": {
        "rendered": "<p>Time period during which insurance coverage is in effect as long as payments are made.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 499,
    "date": "2018-11-13T18:38:30",
    "title": {"rendered": "Premium"},
    "content": {
        "rendered": "<p>The payment, or one of the periodic payments, a policy owner agrees to make for an insurance policy. Depending on the terms of the policy, the premium may be paid in one payment or a series of regular payments, e.g., annually or monthly.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 498,
    "date": "2018-11-13T18:38:08",
    "title": {"rendered": "Misstatement of age and/or sex"},
    "content": {
        "rendered": "<p>If, while the Insured is alive, We learn that the age or sex of the Insured has been misstated, We will adjust the Face Amount to the amount which would have been purchased by the most recent Premium paid at the correct age or sex. Thereafter, Premium due will be based upon the adjusted Face Amount and the Insured’s correct age and sex.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 497,
    "date": "2018-11-13T18:37:47",
    "title": {"rendered": "Lapse"},
    "content": {
        "rendered": "<p>The termination of an insurance policy if premium is not paid by the end of the grace period.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 496,
    "date": "2018-11-13T18:37:29",
    "title": {"rendered": "Life insurance"},
    "content": {
        "rendered": "<p>Insurance that pays out a sum of money (death benefit) upon the death of an insured person.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 495,
    "date": "2018-11-13T18:37:12",
    "title": {"rendered": "Level term"},
    "content": {
        "rendered": "<p>Whether you purchased a 10, 15, 20, 25, or 30 year term, the premium (payment amount) will stay the same during that period of the term.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 494,
    "date": "2018-11-13T18:36:53",
    "title": {"rendered": "Insurer"},
    "content": {"rendered": "<p>The company providing the life insurance.</p>\n", "protected": false},
    "faq_category": [18]
}, {
    "id": 493,
    "date": "2018-11-13T18:36:36",
    "title": {"rendered": "Insured"},
    "content": {"rendered": "<p>The person whose life is covered under the policy.</p>\n", "protected": false},
    "faq_category": [18]
}, {
    "id": 492,
    "date": "2018-11-13T18:36:09",
    "title": {"rendered": "Insurable interest"},
    "content": {
        "rendered": "<p>When any person would suffer a financial, emotional, or any other type of loss because of the death of the person insured. An insurable interest is required when purchasing life insurance on another person.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 491,
    "date": "2018-11-13T18:35:39",
    "title": {"rendered": "Grace period"},
    "content": {
        "rendered": "<p>The length of time (60 days) after a premium is due and unpaid during which the policy remains in force.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 490,
    "date": "2018-11-13T18:35:20",
    "title": {"rendered": "Free look provision"},
    "content": {
        "rendered": "<p>A certain amount of time provided (usually between 10-30 days) to an insured person in order to examine the insurance policy and if not satisfied, to return it to the company for a full refund. At Bestow, we offer a 30-day free look period. </p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 489,
    "date": "2018-11-13T18:35:01",
    "title": {"rendered": "Face amount"},
    "content": {
        "rendered": "<p>The value of a policy to be provided upon death of the insured, also referred to as death benefit.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 488,
    "date": "2018-11-13T18:34:45",
    "title": {"rendered": "Expiry Date"},
    "content": {
        "rendered": "<p>The date on which the insurance coverage under your policy ends.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 487,
    "date": "2018-11-13T18:34:20",
    "title": {"rendered": "Evidence of Insurability"},
    "content": {
        "rendered": "<p>A statement or proof of your health, finances, or job, which helps the insurer decide if you are approved for life insurance.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 486,
    "date": "2018-11-13T18:34:01",
    "title": {"rendered": "Exclusions"},
    "content": {
        "rendered": "<p>The cases for which the insurance company does not provide coverage.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 485,
    "date": "2018-11-13T18:33:43",
    "title": {"rendered": "Death benefit"},
    "content": {
        "rendered": "<p>The payment given to the beneficiary upon the death of the insured.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 484,
    "date": "2018-11-13T18:33:24",
    "title": {"rendered": "Dependent"},
    "content": {
        "rendered": "<p>A person who relies on another, especially a family member, for financial support.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 483,
    "date": "2018-11-13T18:33:01",
    "title": {"rendered": "Contestable Period"},
    "content": {
        "rendered": "<p>Life insurance policies have a 2 year contestable period. If you die within this period, the company has the right to investigate the cause of death and review information.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 482,
    "date": "2018-11-13T18:32:44",
    "title": {"rendered": "Claim"},
    "content": {
        "rendered": "<p>A formal request to an insurance company asking for a payment based on the terms of the insurance policy. The insurance company reviews the claim for its validity and then pays out the beneficiary once approved.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 481,
    "date": "2018-11-13T18:32:23",
    "title": {"rendered": "Contingent beneficiary"},
    "content": {
        "rendered": "<p>The person who will receive your life insurance benefits if your primary beneficiary dies before you. This is also known as a secondary beneficiary.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 480,
    "date": "2018-11-13T18:31:51",
    "title": {"rendered": "Body Mass Index (BMI)"},
    "content": {
        "rendered": "<p>According to the World Health Organization, BMI is a simple index of weight-for-height that is commonly used to classify underweight, overweight, and obesity in adults.</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 479,
    "date": "2018-11-13T18:31:26",
    "title": {"rendered": "Bestow"},
    "content": {"rendered": "<p>To give, or grant someone something of value.</p>\n", "protected": false},
    "faq_category": [18]
}, {
    "id": 478,
    "date": "2018-11-13T18:30:57",
    "title": {"rendered": "Beneficiary"},
    "content": {
        "rendered": "<p>The person named in the policy to receive the insurance proceeds at the death of the insured. Anyone can be named as a beneficiary as long as they have insurable interest. (see definition for insurable interest)</p>\n",
        "protected": false
    },
    "faq_category": [18]
}, {
    "id": 477,
    "date": "2018-11-13T18:30:17",
    "title": {"rendered": "Application"},
    "content": {
        "rendered": "<p>A statement of information made by a person applying for life insurance. It helps the life insurance company assess the acceptability of risk. Statements made in the application are used to decide on an applicant’s underwriting classification and premium rates.</p>\n",
        "protected": false
    },
    "faq_category": [17, 18]
}, {
    "id": 476,
    "date": "2018-11-13T18:30:00",
    "title": {"rendered": "Agent"},
    "content": {
        "rendered": "<p>An individual who sells, services, or negotiates insurance policies either on behalf of a company or independently.</p>\n",
        "protected": false
    },
    "faq_category": [17, 18]
}, {
    "id": 475,
    "date": "2018-11-13T18:29:16",
    "title": {"rendered": "If I cancel, does my coverage end immediately?"},
    "content": {
        "rendered": "<p>If you decide to cancel your policy within the first 30 days after buying your policy, your coverage will end immediately and you will receive a full refund of your first month’s premium. However, if you cancel anytime after the first 30 days, your coverage will continue through a “grace period” (60 days) from the date of your last payment.</p>\n<p>So what happens to your coverage? Your coverage continues during the grace period. So if you were to pass away during this time your beneficiary could file a claim and possibly receive the death benefit minus the unpaid premiums. At the end of your grace period you will receive a letter in the mail letting you know that your coverage has ended.</p>\n<p>If you decide to cancel your policy, give us a call at 1-833-300-0603 and a Customer Care Advisor will walk you through the process in detail. This may not apply in some states.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 474,
    "date": "2018-11-13T18:28:42",
    "title": {"rendered": "Do I get a refund if I cancel my policy?"},
    "content": {
        "rendered": "<p>You are eligible for a full refund if you cancel within the first 30 days. After the 30-day mark you do not receive a refund but future payments are cancelled and there are no termination or early-cancellation fees.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 473,
    "date": "2018-11-13T18:27:36",
    "title": {"rendered": "How do I cancel my policy?"},
    "content": {
        "rendered": "<p>While we would be sad to see you go, we get that sometimes it just doesn’t work out. You can cancel your policy at any time. If you need to do this, just call us at 1-833-300-0603, and we’ll be happy to assist you. And don’t worry we won’t guilt trip you to stay… you do you!</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 471,
    "date": "2018-11-13T18:27:17",
    "title": {"rendered": "I’ve been locked out of my account, how do I reset?"},
    "content": {
        "rendered": "<p>The most likely reason that your account was locked out is that you’ve entered an incorrect password five times. If this happens, just give us a call at 1-833-300-0603, or shoot us a message via live chat and one our Customer Care Advisors will be happy to help you reset it.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 470,
    "date": "2018-11-13T18:26:57",
    "title": {"rendered": "I forgot my password, what do I do?"},
    "content": {
        "rendered": "<p>Forgetting your password is the worst! But we’ve got you covered. Simply click the “forgot password?” link at the bottom of the login page or call 1-833-300-0603, and we’ll be happy to help you reset your password. You’ll be emailed a link by which you can reset and create a new password.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 469,
    "date": "2018-11-13T18:26:42",
    "title": {"rendered": "How do I log out?"},
    "content": {
        "rendered": "<p>To log out of your Bestow Member Portal, click your name in the top right-hand corner and press the “log out” button.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 468,
    "date": "2018-11-13T18:26:27",
    "title": {"rendered": "How do I sign-in?"},
    "content": {
        "rendered": "<p>To sign-in to your Bestow dashboard, simply go to <a href=\"https://login.bestow.com\">https://login.bestow.com</a> and enter the username and password that you created during the application process.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 467,
    "date": "2018-11-13T18:24:22",
    "title": {"rendered": "I am a beneficiary, and need to file a claim outside of business hours. What do I do?"},
    "content": {
        "rendered": "<p>We are so sorry for your loss. Please call 1-833-300-0603 and leave a detailed voicemail or email us at team@bestow.com and a Customer Care Advisor will get back to you as quickly as possible. You can also begin the online claim form <a href=\"https://www.bestow.com/file-a-claim/\" target=\"_blank\" rel=\"noopener noreferrer\">here</a>.</p>\n<p>Details should include: Policy owner’s name, date of birth, current address, beneficiary name, and an email and phone number where you can be reached.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 370,
    "date": "2018-11-13T15:44:21",
    "title": {"rendered": "How can a claim be denied?"},
    "content": {
        "rendered": "<p>We hope that this never happens to you but a claim could be denied if:</p>\n<ol>\n<li>Cause of death wasn’t covered in the policy.</li>\n<li>Suicide occurring during the contestable period.</li>\n</ol>\n<p>During this 2-year period, life insurance companies have the right to investigate the cause of death and review the information provided on the insured’s application. If the company finds that inaccurate or false information was provided, the claim may be denied.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 369,
    "date": "2018-11-13T15:40:10",
    "title": {"rendered": "How does my beneficiary obtain a death certificate?"},
    "content": {
        "rendered": "<p>A death certificate needs to be obtained by the Office of Vital Records in the state that your loved one passed away. Each state has different requirements so make sure you are authorized to make the request, have all required information, and are aware of possible fees associated.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 368,
    "date": "2018-11-13T15:39:53",
    "title": {"rendered": "How will my beneficiary get paid?"},
    "content": {
        "rendered": "<p>Once your notifier reaches out to a Customer Care Advisor regarding the death claim, Bestow will send the policy information to North American, the A+ insurer who provides Bestow policies. North American will then send the beneficiary a claims kit (or packet) to fill out and return. Once North American validates the claim they will wire the money.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 367,
    "date": "2018-11-13T15:39:37",
    "title": {"rendered": "How does my beneficiary file a claim?"},
    "content": {
        "rendered": "<p>We’re here for you. To <a href=\"https://www.bestow.com/file-a-claim/\" target=\"_blank\" rel=\"noopener noreferrer\">file a claim</a>, you can contact us at 1-833-300-0603 to speak with one of our Customer Care Advisors. They’ll guide you through the next steps in order to process your claim as quickly as possible.</p>\n<p><a href=\"https://www.northamericancompany.com/documents/434862/8347368/1038NM+Life+Claim+FAQs.pdf/2e8d4dfc-edac-7e87-fb4b-262f3078c4a6\" target=\"_blank\" rel=\"noopener noreferrer\">Click here</a> to learn more about how North American Company’s claims process works.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 366,
    "date": "2018-11-13T15:39:02",
    "title": {"rendered": "What exclusions are there on my term life insurance policy offered by Bestow?"},
    "content": {
        "rendered": "<p>The exclusions for all policies sold by Bestow include death from suicide (with in the first 2 years of coverage).</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 365,
    "date": "2018-11-13T15:38:32",
    "title": {"rendered": "Where is my credit card information stored?"},
    "content": {
        "rendered": "<p>Your payment information is encrypted and stored securely with Stripe, our payment processor who is leading the industry in electronic payments and security. Bestow doesn’t store your credit card information but rather utilizes a Token, a piece of computer code in layman’s terms, to securely process your payment through Stripe.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 364,
    "date": "2018-11-13T15:37:58",
    "title": {"rendered": "How do I change my credit card information?"},
    "content": {
        "rendered": "<p>You can edit your credit card information through the customer portal. <a href=\"https://customer.cms.bestowlabs.com/login\" target=\"_blank\" rel=\"noopener noreferrer\">Log in</a> and click “Billing” on the left-hand navigation bar. You will be taken to the billing screen which will display the payment schedule and your current payment method. If you click the “Edit” button beneath your current payment method, you will be able to change your credit card information. Simple as that!</p>\n<p>If you are updating your payment information due to a missed payment, please contact us at team@bestow.co or 833-300-0603 once your credit card is updated to let us know we can charge your new card for the missed payment.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 363,
    "date": "2018-11-13T15:37:25",
    "title": {"rendered": "What if I can’t pay my premium for an extended period of time?"},
    "content": {
        "rendered": "<p>Bestow understands that life happens sometimes and missing a payment shouldn’t be a big deal, that is why Bestow will allow you to make up that payment up to 60 days past the billing date. If you are unable to make the payment and 60 days pass, unfortunately, your policy will be cancelled for nonpayment. Don’t worry. We’ll mail you a reminder just in case. You can always come back and apply to reinstate your policy.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 362,
    "date": "2018-11-13T15:37:06",
    "title": {"rendered": "What happens if I miss a payment?"},
    "content": {
        "rendered": "<p>If you happen to miss a payment, your policy status will be “Overdue” and you have 60 days to pay your past-due premium(s) to keep your coverage in effect. On the 61st day your coverage will be terminated. Fortunately, our automatic billing will keep your payments up to date (so long as we have current billing information), so you can rest assured that your coverage is in effect.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 361,
    "date": "2018-11-13T15:35:46",
    "title": {"rendered": "How do I look up my billing information?"},
    "content": {
        "rendered": "<p>To view your billing information including payment method, and transaction history simply login to the <a href=\"https://login.bestow.com\" target=\"_blank\" rel=\"noopener noreferrer\">Bestow Member Portal</a>, and click on “Billing” on the left side navigation. To make any changes to your billing information, please call us at 1-833-300-0603.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 360,
    "date": "2018-11-13T15:35:19",
    "title": {"rendered": "What should I tell or give my beneficiaries after I buy a policy?"},
    "content": {
        "rendered": "<p>It would be smart to tell your beneficiaries that you have listed them as such for your term life insurance policy purchased through Bestow. That way they know to reach out to us should you pass away during your policy term.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 359,
    "date": "2018-11-13T15:35:02",
    "title": {"rendered": "What happens if both my beneficiary and I are in the same tragic accident, who would then receive the death benefit?"},
    "content": {
        "rendered": "<p>Most states have adopted a law known as the Uniform Simultaneous Death Act. Under this Act, if there’s no clear evidence of who died first — you or your beneficiary — then your life insurance policy is distributed as though you survived the beneficiary, and the life insurance proceeds (death benefit) would go to your contingent beneficiaries. However, if clear evidence exists that your primary beneficiary survived you, then the proceeds would go to the beneficiary’s estate.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 358,
    "date": "2018-11-13T15:34:18",
    "title": {"rendered": "Does my beneficiary have to live in the US?"},
    "content": {
        "rendered": "<p>Your beneficiary does not have to live in the United States. Just make sure to finish as much of the beneficiary details as possible and make your beneficiary aware that Bestow should be contacted in the event that you pass away.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 357,
    "date": "2018-11-13T15:34:00",
    "title": {"rendered": "What is a beneficiary? Who can be my beneficiary?"},
    "content": {
        "rendered": "<p>As a life insurance policyholder, you’ll choose beneficiaries. A beneficiary is the person (or people) who will receive the death benefit payment in the event of the insured’s death. You can have up to 8 primary beneficiaries and 2 secondary (contingent) beneficiaries. The secondary beneficiaries are given the death benefit if none of the primary beneficiaries are alive. Currently, beneficiary(s) must be family, spouse, domestic partner, child, or someone with insurable interest.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 356,
    "date": "2018-11-13T15:30:44",
    "title": {"rendered": "Can I change my beneficiary?"},
    "content": {
        "rendered": "<p>Absolutely! To change your beneficiaries simply login to the <a href=\"https://login.bestow.com\" target=\"_blank\" rel=\"noopener noreferrer\">Bestow Member Portal</a>, and click on “Beneficiaries”. Once there you can view all beneficiary information, modify existing and add new beneficiaries.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 355,
    "date": "2018-11-13T15:30:03",
    "title": {"rendered": "Can I renew my policy once the term is over?"},
    "content": {
        "rendered": "<p>Policies offered by Bestow are non-renewable. This means that at the end of your policy, you’ll need to re-apply for another great policy offered by Bestow.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 354,
    "date": "2018-11-13T15:29:39",
    "title": {"rendered": "How often should I revisit my coverage needs?"},
    "content": {
        "rendered": "<p>It’s important to revisit your coverage needs at major life changes like marriage, kids, moving, job changes, income changes, new home, and others.</p>\n<p>We recommend reviewing your coverage at least once a year. We’ll send you an occasional reminder just in case.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 349,
    "date": "2018-11-13T15:17:26",
    "title": {"rendered": "How do I change my address or phone number?"},
    "content": {
        "rendered": "<p>Our Customer Care Advisors can help with that. Give us a call at 1-833-300-0603.</p>\n",
        "protected": false
    },
    "faq_category": [20]
}, {
    "id": 348,
    "date": "2018-11-13T15:15:35",
    "title": {"rendered": "I need to change my name, how do I do that?"},
    "content": {
        "rendered": "<p>Give our Customer Care Advisors a call at 1-833-300-0603, and we can get the process started.</p>\n",
        "protected": false
    },
    "faq_category": [17, 20]
}, {
    "id": 347,
    "date": "2018-11-13T15:15:17",
    "title": {"rendered": "How do I access my policy details?"},
    "content": {
        "rendered": "<p>All of your policy information including beneficiaries, billing, and contact information is accessible in your Bestow Member Portal. <a href=\"https://login.bestow.com\" target=\"_blank\" rel=\"noopener noreferrer\">Login</a> using your username (your email address) and password that you set up in your application.</p>\n",
        "protected": false
    },
    "faq_category": [17, 20]
}, {
    "id": 346,
    "date": "2018-11-13T15:12:56",
    "title": {"rendered": "Why was my information unable to be confirmed?"},
    "content": {
        "rendered": "<p>Not sure why Bestow was unable to confirm your information? You can request a consumer report at <a href=\"https://www.consumerdisclosure.com/\" target=\"_blank\" rel=\"noopener noreferrer\">www.consumerdisclosure.com</a> or by telephone at (800) 456-6004. Upon review of your report, you may dispute any information you find to be inaccurate. <a href=\"https://consumer-solutions.custhelp.com/app/answers/detail/a_id/814/kw/request%20report/related/1\" target=\"_blank\" rel=\"noopener noreferrer\">Click here</a> to learn more.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 345,
    "date": "2018-11-13T15:11:34",
    "title": {"rendered": "Why was I declined because of my “build”?"},
    "content": {
        "rendered": "<p>Insurance companies take a look at an applicant’s “build”, meaning height and weight, in conjunction with BMI (body mass index), to assess a person’s risk. Unfortunately, because of your build you would not be a good fit for a policy. If you have any other questions, call us at 1-833-300-0603.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 344,
    "date": "2018-11-13T15:11:01",
    "title": {"rendered": "Why was I declined because of the amount of coverage I already have?"},
    "content": {
        "rendered": "<p>We have a way of calculating the amount of coverage you can have based on occupation, income, replacement or existing coverage amounts. So for now it seems as though you have enough coverage, but if your circumstances change, you can always try again at a later date. If you have any other questions, call us at 1-833-300-0603.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 343,
    "date": "2018-11-13T15:10:45",
    "title": {"rendered": "Why was I declined because of my age?"},
    "content": {
        "rendered": "<p>If you are currently under the age of 18, don’t fret! You can apply on your 18th birthday. We believe there’s no better gift than providing your loved ones with financial protection (and peace of mind). Now that’s the ultimate definition of adulting, amirite?</p>\n<p>If you are more than 60 years old, you are not eligible to get coverage through Bestow at this time. However, we’re constantly working on expanding insurability, so if you don’t qualify for a policy offered by Bestow right now, check back with us again in the future.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 342,
    "date": "2018-11-13T15:10:17",
    "title": {"rendered": "Why can&#8217;t I purchase a policy in my state?"},
    "content": {
        "rendered": "<p>Sorry New York, we’re unable to offer policies in your state at this time. But we’re working on it! Sign up for early access, and we’ll let you know when we’re in your state.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 341,
    "date": "2018-11-13T15:09:57",
    "title": {"rendered": "I was declined because of incorrect information, how do I move forward in fixing the problem?"},
    "content": {
        "rendered": "<p>Please talk to our Customer Care Advisor at 1-833-300-0603. We will work with you to correct mistakes and walk you through your options.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 340,
    "date": "2018-11-13T15:09:40",
    "title": {"rendered": "I was declined, do I have other options?"},
    "content": {
        "rendered": "<p>Yes! Even if Bestow isn’t a good fit for you, there are other companies, carriers, and services that could help you find the coverage you’re looking for. An insurance agent can help you explore your options, as well.</p>\n",
        "protected": false
    },
    "faq_category": [17, 15]
}, {
    "id": 339,
    "date": "2018-11-13T15:09:15",
    "title": {"rendered": "Why was my application denied or declined?"},
    "content": {
        "rendered": "<p>Unfortunately, we can&#8217;t continue with every application received at Bestow. In some cases, we are not able to offer coverage.<br />\nYour application may not have been approved because of your health, lifestyle or credit history.</p>\n<p>If your application was denied as a result of one of our data vendors, then you should have received an email with the reason and how you can follow up. Please don’t hesitate to reach out to our Customer Support team at team@bestow.co for details on the reason for the denial.</p>\n",
        "protected": false
    },
    "faq_category": [17, 15]
}, {
    "id": 335,
    "date": "2018-11-13T15:06:07",
    "title": {"rendered": "What data is being used?"},
    "content": {
        "rendered": "<p>The products that Bestow offers rely on data vendors (identify verification, prescription history databases, motor vehicle reports and financial data) to make a decision about how risky you would be as a policyholder (super healthy, above average, average, or below average). This risk assignment then determines your price.</p>\n<p>The data vendors that products offered by Bestow work with adhere to and are compliant with regulations that make sure your data is used securely and responsibly (FCRA, HIPAA, MIB). During your application process, you will be presented disclosure notices that ask for your consent before any data is ordered. We encourage you to read these notices and reach out to our Customer Care Advisors if you have any questions.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 334,
    "date": "2018-11-13T15:05:41",
    "title": {"rendered": "What is algorithmic underwriting?"},
    "content": {
        "rendered": "<p>Algorithmic underwriting is a fast and inexpensive approach to understanding what type of risk the insurance company will assign to you (super healthy, above average, average, or below average). Instead of an actual person, called an underwriter, reviewing your application and waiting for lab results, a rules-driven decision engine (the “algorithm”) reads your application answers together with available outside data to assign what type of risk you are. This algorithm is much faster and can remove any potential human underwriting bias, which translates to a more convenient experience and more accurate price for you. You can learn more about risk and underwriting <a href=\"https://www.bestow.com/blog/life-insurance-underwriting/\" target=\"_blank\" rel=\"noopener noreferrer\">right here</a>.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 320,
    "date": "2018-11-13T15:05:09",
    "title": {"rendered": "Will my premiums increase over time?"},
    "content": {
        "rendered": "<p>Nope! The amount you agree to when you purchase is the amount you will pay until your policy term ends or you apply for more coverage and are approved for a new policy.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 319,
    "date": "2018-11-13T15:03:16",
    "title": {"rendered": "How do I pay my premium?"},
    "content": {
        "rendered": "<p>Currently, we only offer payment by credit card. Payments will be automatically drafted every scheduled payment day until the term is done or the policy is cancelled or replaced. Your recurring payment date will be the same date as the original date of purchase. Premiums for policies purchased on the 31st of a month will be due on the final day of each month.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 314,
    "date": "2018-11-13T15:02:04",
    "title": {"rendered": "Will applying with Bestow have any effect on my credit score?"},
    "content": {
        "rendered": "<p>Bestow follows the Fair Credit Reporting Act and requests a soft credit inquiry, which will not impact your credit score. </p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 313,
    "date": "2018-11-13T15:01:08",
    "title": {"rendered": "Can I talk to a human about my life insurance needs?"},
    "content": {
        "rendered": "<p>Of course! We have Customer Care Advisors available to assist you with any questions or concerns that you may have. Give us a call or text at 1-833-300-0603. </p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 312,
    "date": "2018-11-13T15:00:49",
    "title": {"rendered": "When does my life insurance begin?"},
    "content": {
        "rendered": "<p>Your life insurance begins as soon as you are approved and your first payment is collected. Then continues for as long as you make regular payments, cancel, or complete the length of your policy term.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 311,
    "date": "2018-11-13T14:59:08",
    "title": {"rendered": "Is a medical exam required to get life insurance?"},
    "content": {
        "rendered": "<p>Not with Bestow. A <a href=\"https://www.bestow.com/blog/no-medical-exam-life-insurance/\" target=\"_blank\" rel=\"noopener noreferrer\">medical exam</a> helps an insurance company double check how healthy you are. Instead, Bestow checks your health with datasets and predictive models, which saves you time and money. Winning!</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 310,
    "date": "2018-11-13T14:56:02",
    "title": {"rendered": "How long does the application process take?"},
    "content": {
        "rendered": "<p>As fast as 5 minutes! We’ve worked hard to make the application process quick and seamless so apply today!</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 309,
    "date": "2018-11-13T14:55:48",
    "title": {"rendered": "What can I expect during the application process?"},
    "content": {
        "rendered": "<p>We worked with our insurance partners, designers, and engineers to design as delightful of an application process as an application for life insurance could be. The application begins by checking if you are a real person (no robots), followed by questions about your health and lifestyle. We make sure your information is protected with encryption, and we do not sell your personal information to third parties. Once we have enough information, if approved, we recommend a coverage amount and monthly price that makes the most sense for you.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 308,
    "date": "2018-11-13T14:55:26",
    "title": {"rendered": "Why do I have to sign disclosure forms?"},
    "content": {
        "rendered": "<p>Signing the disclosure forms unlocks the beauty of algorithmic underwriting. It allows us to find the appropriate information from our data sources (identity verification, prescription history databases, motor vehicle reports, and financial data) to give you an instant decision without you having to get a medical exam.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 307,
    "date": "2018-11-13T14:55:05",
    "title": {"rendered": "Can I buy life insurance for my spouse/partner or children?"},
    "content": {
        "rendered": "<p>Currently, Bestow only issues policies to the person who will own the insurance. Your spouse or partner would have to apply for their own individual policy. Keep checking back as this may change in the future.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 306,
    "date": "2018-11-13T14:54:42",
    "title": {"rendered": "Once I complete my application, can I go back and change my answers?"},
    "content": {
        "rendered": "<p>Once you have submitted your application, we will be hard at work processing that information through our underwriting machine to give you an instant decision! Because of that, you won’t be able to edit your answers.</p>\n",
        "protected": false
    },
    "faq_category": [17, 15]
}, {
    "id": 305,
    "date": "2018-11-13T14:54:18",
    "title": {"rendered": "My application froze at checkout! Was my application processed?"},
    "content": {
        "rendered": "<p>The internet is a finicky thing sometimes! We are sorry for the added complication, but don’t fret! Call us at 1-833-300-0603 or email us at team@bestow.com, and a Customer Care Advisor will either confirm it was processed or help you complete your application.</p>\n",
        "protected": false
    },
    "faq_category": [17, 15]
}, {
    "id": 304,
    "date": "2018-11-13T14:53:03",
    "title": {"rendered": "Why did I get higher prices because of my prescription history?"},
    "content": {
        "rendered": "<p>Sometimes our big data and technology find information that affects your pricing.<br />\nLet’s say you’re filling out our application and state that you don’t use tobacco, but our behind-the-scenes database search finds a record for a drug (like Chantix®) that suggests you are or were a tobacco smoker — your monthly rate will increase.</p>\n<p>Because of the nature of our <a href=\"https://www.bestow.com/blog/how-bestow-works/\" target=\"_blank\" rel=\"noopener noreferrer\">underwriting process</a> (i.e. no medical exam), we rely on data from our technology partners for <a href=\"https://www.bestow.com/blog/life-insurance-underwriting/\" target=\"_blank\" rel=\"noopener noreferrer\">risk factors</a> in addition to any information you provide in our online form. If your prescription history indicates that you have a medical condition, you may be considered a higher risk to insure, which means you’ll likely have a higher monthly rate.</p>\n<p>In these situations, sometimes applicants can get a lower monthly rate through the traditional means of buying a life insurance policy (i.e. a medical exam and lab tests) to get a holistic view of your health and medical history. We’re constantly working on improving and expanding insurability to better serve all of our customers. Feel free to contact us if you have any questions about your rate results, and we can take a closer look.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 303,
    "date": "2018-11-13T14:50:10",
    "title": {"rendered": "I’ve had some health issues. Can I still get life insurance?"},
    "content": {
        "rendered": "<p>It depends. If you haven’t been diagnosed or treated for certain medical conditions, then you may be eligible to get life insurance through Bestow. Eligibility is determined based on a combination of your medical history, age and other factors.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 302,
    "date": "2018-11-13T14:49:54",
    "title": {"rendered": "Which states can we not allow policy replacements?"},
    "content": {
        "rendered": "<p>Unfortunately, we are not able to offer coverage for replacement of an existing policy in the following states: Florida, Georgia, Indiana, Kansas, Michigan, Nevada, Oklahoma, and Wyoming.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 301,
    "date": "2018-11-13T14:49:39",
    "title": {"rendered": "I already have a policy with another insurance company, what do I do with it?"},
    "content": {
        "rendered": "<p>You have two options. You can add a policy offered by Bestow (and manage two policies from different companies) or you can replace the one you already have with a Bestow policy. Keep in mind that adding too much life insurance from separate companies may make your application ineligible for additional coverage. Replacing insurance can be a little tricky but our enrollment application will walk you through everything you need to do. </p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 300,
    "date": "2018-11-12T21:23:29",
    "title": {"rendered": "How is life insurance paid out?"},
    "content": {
        "rendered": "<p>Life insurance is paid out after a beneficiary submits a certified copy of the death certificate and the insurance carrier completes its due diligence on the policy. Call a Customer Care Advisor at 1-833-300-0603 and they will help you get the process started. Typically, the money is paid in a lump-sum payment. If you’re looking for information on how to file a claim, <a href=\"https://www.bestow.com/file-a-claim/\" target=\"_blank\" rel=\"noopener noreferrer\">click here</a>.</p>\n",
        "protected": false
    },
    "faq_category": [17, 19]
}, {
    "id": 299,
    "date": "2018-11-12T21:23:11",
    "title": {"rendered": "I have term life insurance through my employer. Is it enough?"},
    "content": {
        "rendered": "<p>While any life insurance is better than none, there are two issues with only having insurance through an employer:</p>\n<ol>\n<li>You probably don’t own the insurance so if you lose your job or your employer closes its doors you may not be able to take the policy with you. And if you have the option to continue coverage after your job ends, the premium will most likely be much higher.</li>\n<li>Your employer based insurance is usually only 1-2 times your salary which may not be adequate for your family’s financial needs.</li>\n</ol>\n<p>We recommend buying a policy that meets your families needs, regardless of your employee status.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 298,
    "date": "2018-11-12T21:21:45",
    "title": {"rendered": "When should I get term life insurance?"},
    "content": {
        "rendered": "<p>You need life insurance as soon as someone relies on you financially. Really. It’s a good idea to start shopping during major life events like getting married, having a child, buying a home, starting a business, even becoming widowed, separated, or divorced. But you don’t have to wait for a life event of course. With Bestow, you can apply for coverage whenever is most convenient for you so you’re covered even before a life event occurs.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 297,
    "date": "2018-11-12T21:21:22",
    "title": {"rendered": "How much life insurance do I need?"},
    "content": {
        "rendered": "<p>This is a tough question to answer since it’s personal. You want to give your loved ones enough coverage for a soft landing while at the same time not giving them an incentive to off you (just kidding). You should consider your current financial situation (debt, mortgage, income) and then imagine how much your loved ones need for the next few years.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 296,
    "date": "2018-11-12T21:20:57",
    "title": {"rendered": "How is term life insurance different than whole life insurance?"},
    "content": {
        "rendered": "<p>Term life insurance is easy to understand, affordable, and more commonly purchased than whole life. It provides coverage for a specific length of time (this is called a “term”) at a level premium (meaning the price doesn’t change) throughout the life of the policy. This type of life insurance is great for people that need either a small or large amount of coverage at a low cost.</p>\n<p>Whole life insurance covers you for life as long as you pay the premium. Some policies include the potential to build up cash value, which is tax deferred, you can be borrowed from it, or you can surrender your policy for the cash value. For these reasons a whole life policy can be more difficult to understand and more expensive than a term life policy.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 294,
    "date": "2018-11-12T21:19:38",
    "title": {"rendered": "Who needs life insurance?"},
    "content": {
        "rendered": "<p>If you have a partner, children, aging parents, shared debts, a business — anyone whose livelihood depends on you — life insurance may be worth looking into. It can help offer financial support to your beneficiaries should you pass away. To learn more on this, <a href=\"https://www.bestow.com/learningcenter/who-needs-life-insurance/#section-2\" target=\"_blank\" rel=\"noopener noreferrer\">read on.</a></p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 293,
    "date": "2018-11-12T21:18:44",
    "title": {"rendered": "Does term life insurance expire?"},
    "content": {
        "rendered": "<p>Yes. Term life insurance expires at the end of the time period covered (as long as premiums are paid). For example, a 20-year term will expire 20 years from the issue date.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 292,
    "date": "2018-11-12T21:18:20",
    "title": {"rendered": "What is term life insurance?"},
    "content": {
        "rendered": "<p>Term life insurance is life insurance that lasts for a specific period of time (that’s the term). Bestow offers terms from 10 to 30 years, in 5-year increments. You make a monthly payment (Bestow’s rates start around $10/mo.), and if you pass away while the policy is active, your chosen beneficiary (like your spouse, for example) receives your total coverage amount, usually tax-free.</p>\n",
        "protected": false
    },
    "faq_category": [17, 19]
}, {
    "id": 291,
    "date": "2018-11-12T21:17:48",
    "title": {"rendered": "Do you look at my credit score?"},
    "content": {
        "rendered": "<p>Insurers look at the data behind your credit score (like timeliness of payments or if you’ve filed for bankruptcy), but not your credit score itself. Instead, they use this data to assign their own score, called a “mortality score”, to determine your level of risk. To be clear, the request for this data is only a soft credit inquiry, so this doesn’t impact your credit.</p>\n",
        "protected": false
    },
    "faq_category": [19]
}, {
    "id": 289,
    "date": "2018-11-12T21:04:24",
    "title": {"rendered": "Why doesn't Bestow require a medical exam?"},
    "content": {
        "rendered": "<p>We know many companies require a medical exam to determine how much you’ll pay for coverage — not Bestow. Provide some basic information, answer a few questions, and Bestow’s technology will help take it from there.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 288,
    "date": "2018-11-12T21:04:05",
    "title": {"rendered": "Are there any differences between Bestow and other online life insurance agencies?"},
    "content": {
        "rendered": "<p>We sure think so. Bestow’s application process is entirely online. Seriously, 100%. Many companies offer an indicative quote online but then refer applicants to an agent or doctor in order to finish the application process. Other online life insurance companies may still require a medical exam for certain coverage amounts or applicants. With Bestow, every applicant gets a decision in minutes.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 287,
    "date": "2018-11-12T21:03:21",
    "title": {"rendered": "What is the application process like with Bestow?"},
    "content": {
        "rendered": "<p>Bestow’s 100% online application process has four simple parts:</p>\n<ol>\n<li>Your basic identifying information: email, address, and Social Security Number.</li>\n<li>Your life. This includes citizenship, medical history, lifestyle, and hobbies.</li>\n<li>Beneficiaries. This is whoever would receive the death benefit in the event of the insured’s death.</li>\n<li>Policy and payment details. If approved, choose the coverage amount and term length, then enter credit card info to purchase and bind the policy.</li>\n</ol>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 286,
    "date": "2018-11-12T21:02:05",
    "title": {"rendered": "Will my policy auto-renew?"},
    "content": {
        "rendered": "<p>Nope. When it’s done, it’s done. You’re invited and encouraged to apply for a new policy before yours expires to ensure uninterrupted coverage.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 285,
    "date": "2018-11-12T21:01:32",
    "title": {"rendered": "Why only term?"},
    "content": {
        "rendered": "<p><a href=\"https://www.bestow.com/blog/how-does-term-life-insurance-work/\" target=\"_blank\" rel=\"noopener noreferrer\">Term life insurance</a> is the simplest and most affordable type of life insurance protection. It’s meant to protect your dependents should you pass away unexpectedly within the term period. Because it does not build a cash value or fund investments, it is often the least expensive type of life insurance. Term life insurance is also the product of choice by many financial experts, including Dave Ramsey, Suze Orman, and Clark Howard. (Read more on why <a href=\"https://www.bestow.com/blog/financial-experts-life-insurance-advice/\" target=\"_blank\" rel=\"noopener noreferrer\">right here</a>.)</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 284,
    "date": "2018-11-12T21:00:49",
    "title": {"rendered": "How are rates customized?"},
    "content": {
        "rendered": "<p>Applicants answer questions about their lifestyle and health through our simple online form. Bestow’s in-house underwriting platform instantly processes that data through our encrypted channels in a method called accelerated underwriting. This intelligent framework enables us to fully assess the risk of each applicant individually, without a medical exam, to get an underwriting decision instantly. Approved applicants get real rates, not quotes, at an affordable monthly price.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 283,
    "date": "2018-11-12T21:00:34",
    "title": {"rendered": "How does Bestow make money?"},
    "content": {
        "rendered": "<p>Bestow generates revenue through its agency commissions and administrative fees. This income allows us to work with our partners to develop innovative products, add more products and features to the website, and ensure that customers have the best experience possible with the highest quality support.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 282,
    "date": "2018-11-12T21:00:00",
    "title": {"rendered": "Who will pay my claim?"},
    "content": {
        "rendered": "<p>Bestow is an independent agent and third-party administrator which handles underwriting, application processing, sales, and customer support. Our partner, North American Company for Life and Health Insurance® provides and issues the policy and will process and pay the claim if everything verifies.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 280,
    "date": "2018-11-12T20:55:50",
    "title": {"rendered": "What exactly does Bestow offer?"},
    "content": {
        "rendered": "<p>Life insurance in 10, 15, 20, 25, or 30 year terms, with coverage amount from $50K to $1.5 Million.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 278, "date": "2018-11-12T20:51:58", "title": {"rendered": "Who are Bestow’s partners?"}, "content": {
        "rendered": "<p>Bestow’s customers are covered by one of the most trusted names in the industry, North American Company for Life and Health Insurance®, who is backed by Munich Re, all of which are rated A+(Superior) by A.M. Best.</br></p>\n<p><strong>About North American Company for Life and Health Insurance®</strong></br></p>\n<p>North American is a member of Sammons® Financial Group, Inc. since 1886, and has established a tradition of providing quality insurance products to consumers throughout the U.S. and offers a comprehensive portfolio of term, universal life and indexed universal life insurance products.</br></p>\n<p><strong>About Sammons Financial Group</strong></br></p>\n<p>Sammons® Financial Group is comprised of North American Company for Life and Health Insurance®, Midland National® Life Insurance Company, Sammons Retirement Solutions® Inc., and Sammons Financial Network® LLC, member FINRA.</br></p>\n<p><strong>About Munich Re Group</strong></br></p>\n<p>Munich Re, U.S. (Life), founded in 1959, is one of the largest reinsurers in the U.S. offering life and disability reinsurance to insurance companies throughout the United States. The company also writes group, credit, and other reinsurance products. Headquartered in Atlanta, with offices in Chicago and New York, the company is licensed, accredited or authorized in all fifty states, Washington, D.C., Guam, and Puerto Rico.</br></p>\n<p>A+ (Superior), the second highest rating out of 15 categories, was affirmed by A.M. Best for North American as part of Sammons Financial Group on August 7, 2019. A.M. Best is a large third-party independent reporting and rating company that rates an insurance company on the basis of the company’s financial strength, operating performance and ability to meet its ongoing obligations to policyholders.</p>\n",
        "protected": false
    }, "faq_category": [14]
}, {
    "id": 274,
    "date": "2018-11-12T20:19:43",
    "title": {"rendered": "How is Bestow different than other insurance companies?"},
    "content": {
        "rendered": "<p>We think about this as the 5 Ps: Product, Platform, Partners, Purpose and People.</p>\n<ul>\n<li><strong>Product:</strong> We completely re-engineered the buying experience for term life insurance to be super simple and more affordable. Apply and get an instant decision in less than 5 minutes.</li>\n<li><strong>Platform:</strong> Unlike other online life insurance providers, we underwrite the policies we offer in-house with our own technology — accelerated underwriting. Our data and technology allows us to help our customers with just about any request.</li>\n<li><strong>Partners:</strong> We’re an online life insurance agency backed by two of the largest industry giants rated A+, so your coverage is financially sound.</li>\n<li><strong>Purpose:</strong> We believe that great life insurance should be affordable, convenient, and smart, and every bit as straightforward as buying any other gift for your family.</li>\n<li><strong>People:</strong> We’re a band of finance experts, tech specialists, and licensed agents united in a common belief that when life insurance is done right, the beneficiary is everyone.</li>\n</ul>\n",
        "protected": false
    },
    "faq_category": [14, 17]
}, {
    "id": 8031,
    "date": "2018-09-09T12:48:42",
    "title": {"rendered": "Do I have to be a U.S. citizen to apply?"},
    "content": {
        "rendered": "<p>We’re able to offer coverage to U.S. citizens or green card holders at this time.</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 8030,
    "date": "2018-09-09T12:47:12",
    "title": {"rendered": "Can I speak with someone in Spanish?"},
    "content": {
        "rendered": "<p>Yes, we have several Spanish-speaking life insurance agents available to help answer any questions or walk you through the application process. Give us a call at 1-833-300-0603 to get started!</p>\n",
        "protected": false
    },
    "faq_category": [14]
}, {
    "id": 394837,
    "date": "2018-09-09T12:47:12",
    "title": {"rendered": "What if my gender today doesn’t match my gender at birth?"},
    "content": {
        "rendered": "<p>Please apply using the gender you identify as today. </p>\n",
        "protected": false
    },
    "faq_category": [15]
}, {
    "id": 394836,
    "date": "2018-09-09T12:47:12",
    "title": {"rendered": "Why does my gender matter?"},
    "content": {
        "rendered": "<p>Insurance rates are determined by a variety of factors including an individual’s health risks and family history. We use your gender to customize the questions we ask you in order to evaluate these factors. We encourage you to complete the application using the gender you identify as today.</p>\n",
        "protected": false
    },
    "faq_category": [15]
}];
