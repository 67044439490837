import React, { useState } from 'react';

import BodyText from "../../../text/body-text";
import BodyTextBold from "../../../text/body-text/bold";

import arrow from "../../../../../images/FAQ-Right-Arrow.svg";

import './index.scss';

export interface Props {
    question: string,
    answer: string
}

const FAQItem: React.FC<Props> = ({ question, answer }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="faq-item cursor-pointer border-solid border-0 border-b border-gray-300 mb-4" data-testid="faq.item" onClick={() => setExpanded(!expanded)}>
            <div className="flex">
                <div className="flex-1">
                    <div className="flex">
                        <BodyTextBold data-testid="faq.question" className="flex-1 mb-4" dangerouslySetInnerHTML={{__html: question}} />
                        <div>
                            <img src={arrow} className={`transform max-w-none w-4 ml-3 ${expanded ? 'rotate-180' : ''}`} alt="expand arrow" />
                        </div>
                    </div>
                    <BodyText data-testid="faq.answer" className={`text-gray-6 mb-5 ${!expanded ? 'hidden' : ''}`} dangerouslySetInnerHTML={{__html: answer}} />
                </div>
            </div>
        </div>
    )
};

export default FAQItem;