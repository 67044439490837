import React from 'react';
import BodyTextArray from "../../../components/_common/text/body-text/array";
import LegalText from '../../../components/_common/text/legal-text/index';
import Grid from "../../../components/_common/grid";

export interface SectionData {
    body: string[],
    title: string,
    legal?: string
}

interface Props {
    sectionData: SectionData
}

const ThirdPartyCodeSection: React.FC<Props> = ({sectionData}) => (
    <Grid className="mb-10 sm:mb-6">
        <div className="col-span-full sm:col-span-2 lg:col-span-2 lg:col-start-2 section-title mb-2 sm:mb-4 text-center sm:text-left">
            <span className="font-medium lg:mr-6 font-sans">{sectionData.title}</span>
        </div>
        <div className="col-span-full sm:col-span-5 lg:col-span-8">
            <BodyTextArray data={sectionData.body} />
            {sectionData.legal &&
                <LegalText>
                    {sectionData.legal}
                </LegalText>
            }
        </div>
    </Grid>
);

export default ThirdPartyCodeSection;
