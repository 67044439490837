import React, { MouseEventHandler } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';

import './index.scss';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    className?: string;
    Icon?: any;
    iconClass?: string;
    LeftIcon?: any;
    leftIconClass?: string;
    href?: string;
    to?: string;
    scrollTo?: string; // an id on the page to scroll to
    scrollToOffset?: number;
    scrollToDuration?: number;
    onClick?: MouseEventHandler;
    target?: string;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            className = '',
            href,
            target = '_blank',
            to,
            scrollTo,
            scrollToOffset = 0,
            scrollToDuration = 500,
            onClick = () => null,
            disabled = false,
            Icon,
            iconClass = 'ml-4 sm:-mt-1',
            LeftIcon,
            leftIconClass = 'mr-4',
            children,
            ...otherProps
        },
        ref,
    ) => {
        className = `brand-btn flex items-center justify-center font-sans cursor-pointer transition-all ${className}`;

        const buttonContent = (
            <>
                {LeftIcon && (
                    <div className="flex items-center">
                        <LeftIcon className={leftIconClass} />
                    </div>
                )}
                <div className="font-sans flex items-center">{children}</div>
                {Icon && (
                    <div className="flex items-center">
                        <Icon className={iconClass} />
                    </div>
                )}
            </>
        );

        if (href) {
            return (
                <a
                    className={className}
                    onClick={onClick}
                    href={href}
                    target={target}
                    rel="noopener noreferrer"
                    data-testid={(otherProps as any)['data-testid']}
                >
                    {buttonContent}
                </a>
            );
        } else if (to) {
            return (
                <Link className={className} to={to} onClick={onClick}>
                    {buttonContent}
                </Link>
            );
        } else if (scrollTo) {
            return (
                <ScrollLink
                    className={className}
                    to={scrollTo}
                    smooth
                    offset={scrollToOffset}
                    duration={scrollToDuration}
                >
                    {buttonContent}
                </ScrollLink>
            );
        }
        return (
            <button
                className={className}
                onClick={onClick}
                disabled={disabled}
                ref={ref}
                {...otherProps}
            >
                {buttonContent}
            </button>
        );
    },
);

export default Button;
