import React from 'react';
import PageSection from "../../../components/_common/section/page";
import Grid from "../../../components/_common/grid";
import H2Serif from "../../../components/_common/text/heading/h2/serif";
import BodyText from "../../../components/_common/text/body-text";
import SecondaryButtonCharcoal from '../../../components/_common/button/secondary/charcoal';
import { ReactComponent as Arrow } from "../../../images/svgs/btn-arrow-right.svg";

import phoneImg from '../../../images/why-bestow/why-bestow-phone.png';

import './index.scss';

const PhoneSection: React.FC = () => (
    <PageSection className="phone-section pt-14 pb-16 sm:pt-20">
        <Grid>
            <div className="col-span-full sm:col-start-5 sm:col-span-4 lg:col-start-8 lg:col-span-5 flex justify-center px-12 sm:px-0 sm:-mt-24 lg:-mt-12 lg:px-12">
                <img src={phoneImg} className="phone-img sm:w-full sm:h-auto" alt="Why bestow mobile phone" />
            </div>
            <div className="col-span-full sm:col-start-1 sm:row-start-1 sm:row-start-1 sm:col-span-4 lg:col-start-2 lg:col-span-6 text-center sm:text-left sm:pl-2 lg:pl-0">
                <H2Serif className="text-navy mb-8 lg:mb-10">
                    Instant coverage upon approval
                </H2Serif>
                <BodyText className="mb-8 lg:mb-12">
                    Get a quote in seconds and apply online in minutes. No medical exam ever. If approved, activate your
                    coverage today.
                </BodyText>
                <div className="flex justify-center sm:justify-start">
                    <SecondaryButtonCharcoal Icon={Arrow} to="/quote">
                        Start Now
                    </SecondaryButtonCharcoal>
                </div>
            </div>
        </Grid>
    </PageSection>
);

export default PhoneSection;
