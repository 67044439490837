import './index.scss';

const Disclaimer: React.FC = () => (
    <div className="lg:px-24 sm:px-8 sm:py-24 xs:px-4 xs:py-16">
        <p className="disclaimer">
            *To be eligible for coverage you must be a U.S. citizen residing in
            the US or permanent legal resident. Total limit of all combined
            Gerber Life Guaranteed Life policies is $25,000. Gerber Life
            Insurance Company will not issue insurance for any proposed insured
            when a POA or Guardianship exists on the proposed insured, except
            legal guardians of children.
        </p>{' '}
        <p className="disclaimer">
            **Premiums, cash value and death benefits are guaranteed, and
            policies will remain in force as long as premiums are paid on time
            and within the Grace Period specified in the policies. The cash
            value and death benefit will be reduced by any outstanding policy
            loans. The policy loan interest rate is 8%.
        </p>{' '}
        <p className="disclaimer">
            Guaranteed Life is issued in all states except MT. Guaranteed Life
            Insurance Plan Policy Form ICC12-GWLP and Policy Form Series GWLP-12
            issued by Gerber Life Insurance Company, White Plains, NY 10605.
            Marketed by Bestow, Dallas, TX. Bestow is licensed in all states and
            may earn a Commission if you purchase something through the links on
            our website. NY law requires us to inform you, compensation may vary
            by the type of policy applied for and the volume of business
            generated. Upon request, Bestow can send you additional information
            about compensation structure by contacting us at 1-833-300-0603.
        </p>{' '}
        <p className="disclaimer">
            South Dakota residents, maximum coverage of $15,000
        </p>{' '}
        <p className="disclaimer">
            Terms, conditions, exclusions and limitations can vary by state.
            Please refer to the policy for terms, conditions, limitations and
            exclusions that may apply. If death is by suicide within two years
            from the Issue Date (one year in ND), the only amount payable will
            be all premiums paid for the policy plus an additional 10%of earned
            premium, less any debt against the policy. Policy Form ICC12-GWLP
            and Policy Form Series GWLP-12. In New York: Policy Form GWLP-20-NY.
        </p>
        <p className="disclaimer">
            All of the information and link in this post are from our sponsor,
            Gerber, who provides compensation to Bestow. Furthermore, this
            article does not ensure your eligibility for any specific product.
        </p>{' '}
        <p className="disclaimer">
            Gerber Life Insurance is a trademark. Used under license from
            Société des Produits Nestlé S.A. and Gerber Products Company.
        </p>
    </div>
);

export default Disclaimer;
