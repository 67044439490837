import React from 'react';

import H1Serif from "../../components/_common/text/heading/h1/serif";
import ReviewSlider from './slider';
import ReviewList from './list';
import CustomerReviewsPageModel from './data';
import Eyebrow from "../../components/_common/text/eyebrow";
import EyebrowSlim from "../../components/_common/text/eyebrow/slim";
import {useSiteInfoSelector} from "../../redux/siteInfo/selectors";
import TrustBox from "../../components/trustPilot/TrustBox";
import PageContent from "../../components/_common/pageContent";
import PageSection from "../../components/_common/section/page";
import Grid from "../../components/_common/grid";
import CTAModule from "../../components/_common/modules/cta";
import PrimaryButton from "../../components/_common/button/primary";
import PrimaryButtonLarge from "../../components/_common/button/primary/large";

const Reviews: React.FC = () => {
    const siteInfo = useSiteInfoSelector();
    const {title, reviews: { slider: sliderReviews, list: listReviews}, cta} = CustomerReviewsPageModel;
    return (
        <PageContent>
            <PageSection className="bg-primary pt-7 sm:pt-9 lg:pt-16 pb-32 sm:pb-28">
                <H1Serif className="text-center text-white">{title}</H1Serif>
                <Eyebrow className="hidden sm:block text-white uppercase mt-2 text-center">
                    Real Reviews from Real Customers
                </Eyebrow>
                <EyebrowSlim className="sm:hidden text-white uppercase mt-2 text-center mb-2">
                    Real Reviews from Real Customers
                </EyebrowSlim>
                <div className="mt-4">
                    <TrustBox data-theme="dark" />
                </div>
                <Grid>
                    <div className="col-span-full lg:col-span-10 lg:col-start-2">
                        <ReviewSlider reviews={sliderReviews} />
                    </div>
                </Grid>
            </PageSection>
            <PageSection className="-mt-28 sm:-mt-14 mb-8">
                <Grid>
                    <div className="col-span-full lg:col-start-2 lg:col-span-10">
                        <ReviewList reviews={listReviews}/>
                    </div>
                </Grid>
            </PageSection>
            <CTAModule
                className="bg-off-white"
                title={<>Coverage today, if approved.<br/>Yep. It's that easy.</>}>
                <PrimaryButton className="text-center sm:hidden" href={siteInfo.quote_link}>{cta.buttonText}</PrimaryButton>
                <PrimaryButtonLarge className="text-center hidden sm:block" href={siteInfo.quote_link}>{cta.buttonText}</PrimaryButtonLarge>
            </CTAModule>
        </PageContent>
    );
};

export default Reviews;