import cx from 'classnames';
import '../index.scss';
import React from 'react';

export interface Props {
    className?: string
}

const Subtitle1: React.FC<Props> = ({children, className}) => {
  return (
    <h5
        className={cx('brand-subtitle1 font-serif', className)}>
        {children}
    </h5>
  );
};


export default Subtitle1;
