const faqs = [
    {
        id: 1,
        title: {
            rendered: "Can you get life insurance without a medical exam?"
        },
        content: {
            rendered: `Yes! Term life insurance offered by Bestow is 100% exam free. By contrast, many whole life insurance 
                policies require a medical exam and other steps.`
        }
    },
    {
        id: 2,
        title: {
            rendered: "How much life insurance can you get without a medical exam?"
        },
        content: {
            rendered: `It depends on the life insurance company. For example, a no exam life insurance policy offered by 
                Bestow could provide an amount of coverage between $50,000 and $1.5 million.`
        }
    },
    {
        id: 3,
        title: {
            rendered: "Is there a waiting period for no exam life insurance?"
        },
        content: {
            rendered: `Part of the beauty of Bestow is how fast it is. You’ll get an instant decision when you apply, so 
                there’s no waiting around. Conversely, it could take weeks to check your eligibility for a whole life policy.`
        }
    },
    {
        id: 4,
        title: {
            rendered: "What is the maximum amount of life insurance I can get?"
        },
        content: {
            rendered: `This depends on where you look. Currently, Bestow offers up to $1.5 million in available coverage.`
        }
    },
    {
        id: 5,
        title: {
            rendered: "How is term life insurance different from other types of life insurance?"
        },
        content: {
            rendered: `Insurance is all about financial protections, but each kind of insurance has different features. 
                Term life insurance offers a death benefit if you pass away during the term of your policy, whereas whole 
                life usually provides a guaranteed payout for the rest of your life. As a result, term life is typically a 
                less expensive option.`
        }
    },
    {
        id: 6,
        title: {
            rendered: "Why would someone be turned down for no-exam term life insurance?"
        },
        content: {
            rendered: `Your available medical history is considered during the underwriting process. A history of poor 
                health or medical conditions (like high blood pressure, for example) can all influence your eligibility for 
                certain insurance products.`
        }
    }
];

export default faqs;