import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import NotFound from '../../../pages/404';
import { usePageSelector } from '../../../redux/pages/selectors';
import { fetchPage } from '../../../redux/pages';
import Spinner from '../../_common/spinner';
import { setCurrentPage } from '../../../redux/pages';
import QuotePage from '../../../pages/quote';
import AgentQuote from '../../../pages/agentQuote';
import SEOQuote from '../../../pages/quote/variations/seoquote';

/**
 * Fetches the appropriate page for display and passes it to the corresponding template
 */
const PageRouter = () => {
    const { slug } = useParams();
    const pathname = useLocation().pathname;
    const dispatch = useDispatch();
    const { page, loading } = usePageSelector(pathname);
    const hash = useLocation().hash;

    // Only fetch the page if we don't already have it in Memory
    useEffect(() => {
        if (!page) {
            dispatch(fetchPage(slug, pathname));
        }
    }, [dispatch, slug, page, pathname]);

    // Toggle top nav according to ACF settings
    useEffect(() => {
        dispatch(setCurrentPage(page));
    }, [dispatch, page]);

    // We may need to scroll to the right element after the page finishes loading
    useEffect(() => {
        if (hash && page) {
            document.getElementById(hash.substring(1))?.scrollIntoView();
        }
    }, [hash, page]);

    let template = <NotFound />;

    if (page) {
        switch (page.template) {
            case 'page-templates/agents.php':
                return <AgentQuote page={page} />;
            case 'page-templates/quote.php':
                return <SEOQuote page={page} />;
            case 'page-templates/partners.php':
            default:
                return <QuotePage page={page} />;
        }
    }

    return loading ? (
        <div>
            <Spinner />
        </div>
    ) : (
        template
    );
};

export default PageRouter;
