import React from 'react';

import Heading, { Props } from '../';

import './index.scss';

const H2:React.FC<Props> = ({children, className = "", ElementType= "h2", ...otherProps}) => {
  return (
    <Heading className={`brand-h2 ${className}`} ElementType={ElementType} {...otherProps}>
        {children}
    </Heading>
  );
};

export default H2;
