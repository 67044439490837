export const REVIEWS = [
    {
        "review_author": "Taeysha",
        "review_content": "I’m trying to break the generational curse of not understanding finances. I wanted to make small investments for my son and I realized life insurance was a big step that I was missing. I COULD NOT believe how easy it was.",
        "review_title": "Best decision...wish I would have done this sooner!",
        "stars": "5"
    },
    {
        "review_author": "Callista",
        "review_content": "Incredibly simple and fast. I am very pleased and feel at ease knowing that if something were to happen to me, at least the financial burdens of my death would be lessened on those I left behind.",
        "review_title": "Easy, fair and fast.",
        "stars": "5"
    },
    {
        "review_author": "Craig",
        "review_content": "I feel so much better knowing if something happens to me my family doesn’t have to worry about any financial issues! Thank you Bestow!",
        "review_title": "Easy and quick for many years of security",
        "stars": "5"
    },
];
