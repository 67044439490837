import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QuoteProps } from '../../..';
import QuoteEstimate from '../../../results/adjuster';
import NoHealthQuoteEstimate from '../../MOE-404-NohealthQuote/nhQuoteEstimate';
import QuoteEstimateMobile from '../../MOE-335-QuoteMobileOptimization/QuoteMobileOptimizationResults';

// This component routes the customer to the correct version of the <QuoteEstimate> component.
// It is conditional based on our experiment flags.

const QuoteEstimateExperimentRouter: React.FC<QuoteProps> = ({
    quoteMobileOptimizationExists = false,
}) => {
    const { moe404NoHealthQuote } = useFlags();
    console.log(useFlags());
    let quoteEstimate = <QuoteEstimate />;
    if (moe404NoHealthQuote) {
        quoteEstimate = <NoHealthQuoteEstimate />;
    } else if (quoteMobileOptimizationExists) {
        quoteEstimate = <QuoteEstimateMobile />;
    }
    return quoteEstimate;
};

export default QuoteEstimateExperimentRouter;
