import React from 'react';

import Heading, { Props } from '../';

import './index.scss';

const Subtitle2: React.FC<Props> = ({children, className = "", ElementType = "h6", ...otherProps}) => {
  return (
    <Heading className={`brand-subtitle2 font-sans ${className}`} ElementType={ElementType} {...otherProps}>
        {children}
    </Heading>
  );
};

export default Subtitle2;
