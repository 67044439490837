/**
 * All of this code was ganked from AQX. It needs to be re-written, but in order to reduce risk it was a lift & shift
 * until we could get unit tests in place before a re-write
 */

//@ts-ignore
import { FetchQuoteTypes, ShortUrlData } from './interfaces';
import { parse, format } from 'date-fns';
import pick from 'lodash/pick';
import { DATE_PATTERN } from '../../../_common/inputs/dob';
import { transformCTSQuestionData } from './transformationFunctions';
interface CityState {
    state: string;
}

/**
 * This is pretty ugly, but the usps-webtools sdk was adding ~300kb to our bundle (gzipped), so we're better off rolling
 * this one API call ourselves. Ideally we'd pull in a lightweight XML parser and use that for serializing and deserializing
 * xml data here, but for this this gets the point across.
 * @param zipcode
 */
export const getUspsCityState = (
    zipcode: string,
): Promise<CityState | null> => {
    return new Promise((resolve, reject) => {
        try {
            const xmlString = `<?xml version="1.0"?><CityStateLookupRequest USERID="653BESTO5262"><ZipCode><Zip5>${zipcode}</Zip5></ZipCode></CityStateLookupRequest>`;
            const api = 'CityStateLookup';
            return fetch(
                `https://production.shippingapis.com/ShippingAPI.dll?API=${api}&XML=${xmlString}`,
                {
                    method: 'GET',
                },
            )
                .then(response => response.text())
                .then(xmlResponse => {
                    const state = xmlResponse.match(/<State>([^<]+)<\/State>/i);

                    if (state && state[1]) {
                        resolve({ state: state[1] });
                    }

                    resolve(null);
                });
        } catch (e) {
            //Catching an error meant something unexpected has gone wrong
            reject(e);
        }
    });
};

export const getQuote = (payload: any, type: FetchQuoteTypes) => {
    // TODO: There's gotta be a better way to do this, but this works for now. V3 does not accept "extra" params
    delete payload.partner_referral;
    delete payload.query_string;

    return fetchQuote(payload, type)
        .then(result => {
            // TODO: Handle this better as well
            result = v3ToV2Quote(result);

            return {
                data: result,
                payload,
            };
        })
        .catch(result => ({
            data: {
                error: true,
                ...result,
            },
        }));
};

export const getQuotePayload = (formData: any) => {
    const {
        birthdate,
        gender,
        height,
        state,
        weight,
        tobacco,
        partner_referral,
    } = formData;
    const { feet, inches } = getHeightValues(height);
    return {
        birth_date: format(
            parse(birthdate, DATE_PATTERN, new Date()),
            'yyyy-MM-dd',
        ),
        gender,
        height_feet: feet,
        height_inches: inches,
        state: state,
        weight: Number.parseInt(weight, 10),
        tobacco: tobacco ? 'yes' : 'no',
        partner_referral: partner_referral,
    };
};

export const determineUrlType = (formData: any) => {
    const { bestow_writing_agent, short_url_id } = formData;
    let payloadType = getQuotePayload(formData);
    let sharedPayload: any = payloadType;
    sharedPayload.query_string = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );
    let payload;
    let type: FetchQuoteTypes;

    if (
        formData.hasOwnProperty('short_url_id') &&
        formData['short_url_id'] !== null
    ) {
        payload = { ...sharedPayload, short_url_id: short_url_id };
        type = 'short';
    } else {
        payload = {
            ...sharedPayload,
            bestow_writing_agent: bestow_writing_agent,
        };
        type = 'long';
    }

    return {
        payload,
        type,
    };
};

export async function fetchQuote(
    body: Object | ShortUrlData,
    type: FetchQuoteTypes,
) {
    const url =
        type === 'long'
            ? `${process.env.REACT_APP_TAKE_TWO_V3_API_URL}/quote`
            : `${process.env.REACT_APP_PROTECT_API_URL}/quotes`;

    return Fetcher(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
}

export async function ErrorHandler(response: Response) {
    if (response.ok) {
        return await response.json();
    }
    throw await response.json();
}

export async function Fetcher(url: string, config: RequestInit) {
    return fetch(url, config).then(ErrorHandler);
}

/**
 * Parses an integer of height in inches to feet and inches
 * @param heightInInches
 * @returns { feet: number, inches: number}
 */
export const getHeightValues = (
    heightInInches: string,
): { feet: number; inches: number } => {
    const feet = ~~(Number.parseInt(heightInInches) / 12);
    const inches = Number.parseInt(heightInInches) % 12;
    return { feet, inches };
};

// and finally this
export const getProductsAndOptions = (quote: any) => {
    const rates = quote.product_rates || {};
    const availableProducts = Object.keys(rates);
    const defaultProduct = availableProducts[availableProducts.length - 1];

    return {
        defaultProduct,
        productRates: rates,
    };
};

const getCleanedResponse = (response: any = {}) => {
    const valid = [
        'name',
        'display_name',
        'email',
        'telephone',
        'custom_decline_content',
        'custom_link_out',
    ];
    const partner = pick(response.partner || {}, valid);
    return { name: response.name, partner };
};

export const getQuotePartner = async (qid: string) => {
    try {
        const apiResult = await fetchQuotePartner(qid);
        const cleanedResult = getCleanedResponse(apiResult);
        return cleanedResult;
    } catch (e) {
        console.error({
            error: 'Error while fetching partner data',
            message: e,
        });
        return {};
    }
};

async function fetchQuotePartner(quoteId: string) {
    return Fetcher(
        `${process.env.REACT_APP_TAKE_TWO_API_URL}/quote/${quoteId}/partner`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
}

const getCleanedCTSQuoteResponse = (response: string) => {
    const validQuoteInputs = [
        'gender',
        'height',
        'weight',
        'birthday',
        'coverage_amount',
        'address',
        'term_choice',
    ];
    return pick(response, validQuoteInputs);
};

export const getConsumedCTSQuote = async (qid: string) => {
    try {
        const apiResult = await apiFetchCTSQuote(qid);
        const cleanedResult = getCleanedCTSQuoteResponse(apiResult.quote);
        const transformedResult = Object.entries(cleanedResult)
            .map(([key, value]) => {
                return transformCTSQuestionData(key, value);
            })
            .reduce((accumulator, val) => ({ ...accumulator, ...val }), {});

        return transformedResult;
    } catch (e) {
        console.error({ error: 'Error while fetching CTS data', message: e });
        return {};
    }
};

const apiFetchCTSQuote = async (qid: string) => {
    return Fetcher(`${process.env.REACT_APP_CTS_API_URL}/quotes/${qid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const fetchAgentCodeForURLShortID = async (
    shortID: string,
): Promise<string> => {
    try {
        const agentURLResponse = await Fetcher(
            `${process.env.REACT_APP_AGS_API_URL}/agent-urls/short-id/${shortID}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return agentURLResponse.agent_url.writing_agent_code;
    } catch (error) {
        console.error(`Error fetching agentCode for shortID ${shortID}`, error);
        return '';
    }
};

/**
 * Posts the agent code and state to the AGS API, which validates that the agent is licensed in the given state.
 * @param {string} agentCode - The agent code for the referrer (pulled from the bestow_writing_agent query parameter).
 * @param {string} state - Two-letter state code based on user input.
 * @returns {Promise<boolean>} True if agent is licensed or agentCode is not provided, false if agent is not licensed or the API returns an error
 */
export const validateAgentState = async (
    agentCode: string | null,
    state: string,
): Promise<boolean> => {
    if (!agentCode) {
        return true;
    }
    try {
        const isValid = await Fetcher(
            `${process.env.REACT_APP_AGS_API_URL}/validate`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ writing_agent_code: agentCode, state }),
            },
        );

        return isValid;
    } catch (error) {
        console.error(
            `Error validating agent ${agentCode} for state: ${state}`,
            error,
        );
        return false;
    }
};

/**
 * Transforms a v3 Quote response into the schema expected from v2 quotes. Also adds a 'v3' flag to the Quote object
 * so we can check down the line
 * @param v3Quote
 */
// just setting array of objects to object by product name
// that is, instead of [{product:bt1004, rates: [] }], it becomes {bt1004: {5000: '921}}
const v3ToV2Quote = (v3Quote: any) => {
    const product_rates = v3Quote.product_rates?.reduce(
        (ret: any, productRate: any) => {
            ret[productRate.product_code] = productRate.rates.reduce(
                (ratesRet: any, rate: any) => {
                    ratesRet[`${rate.face_value}`] = rate.price;
                    return ratesRet;
                },
                {},
            );
            return ret;
        },
        {},
    );

    return {
        ineligible: v3Quote.ineligible,
        quote_id: v3Quote.id,
        product_rates,
        v3: true,
    };
};
