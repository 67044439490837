import React from 'react';
import cx from 'classnames';

import TextFieldLabel from "../../text/textFieldLabel";
import HelperText from "../../text/helperText";
import SmallText from '../../text/small-text';
import { FormikCustomFieldCommonProps } from "../";

import './index.scss';

export interface Props extends FormikCustomFieldCommonProps {
    rows?: number
}

const OutsideLabelInput = React.forwardRef<HTMLInputElement, Props>(({
    className = "",
    label,
    rows,
    error,
    helper,
    labelHelper,
    required,
    ...otherProps
}, ref) => {
    const classes = cx("outside-input-label", className, {
        error
    });

    return (
        <div>
            {label &&
                <div className="mb-2">
                    <TextFieldLabel className="text-gray-800">
                        {label}{required && <span className="text-error">*</span>}
                    </TextFieldLabel>
                    {labelHelper &&
                        <SmallText className="block mb-0 font-medium">
                            {labelHelper}
                        </SmallText>
                    }
                </div>
            }
            <div className="flex w-full">
                {rows ?
                    // @ts-ignore
                    <textarea className={classes} rows={rows} {...otherProps} /> :
                    <input className={classes} {...otherProps} ref={ref} aria-label={label} />
                }
            </div>
            {helper &&
                <div className="mt-1">
                    <HelperText error={error}>
                        {helper}
                    </HelperText>
                </div>
            }
        </div>
    )
});

export default OutsideLabelInput;
